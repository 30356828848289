import React from 'react';
import PropTypes from 'prop-types';
import RouterHistory from '../main/RouterHistory';

/**
 * Generate JSX for body of toast notification
 *
 * @param closeToast This is added by react-toastify, see https://github.com/fkhadra/react-toastify#render-a-component
 * @param title String or JSX to be rendered in the card header
 * @param sections (optional) Array of strings or JSX to be rendered in the body of the card
 * @param callToAction (optional) Button title and associated React route
 */
function ToastCard ({ closeToast, title, sections, callToAction }) {
  return <div className="card small">
    <div className="card-header d-flex justify-content-between">
      <span className="my-auto">
        <h2>{title}</h2>
      </span>
      <button type="button" className="close my-auto" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    {sections && <ul className="list-group list-group-flush">
      {sections.map((section, index) =>
        <li key={index} className="list-group-item">
          {section}
        </li>)}
      {callToAction &&
      <li className="list-group-item">
        <div className="btn btn-sm btn-outline-secondary mr-1" onClick={() => {
          RouterHistory.push(callToAction.route);
          closeToast();
        }}>
          {callToAction.title || 'More'}
        </div>
        <div className="btn btn-sm btn-outline-secondary mr-1 float-right" onClick={() => {
          window.open(callToAction.route, '_blank');
          closeToast();
        }}>
          {(callToAction.title || 'More') + ' in new window'}
        </div>
      </li>}
    </ul>}
  </div>;
}

ToastCard.propTypes = {
  title: PropTypes.string.isRequired,
  sections: PropTypes.arrayOf(PropTypes.object),
  callToAction: PropTypes.shape({
    title: PropTypes.string,
    route: PropTypes.string
  })
};

export default ToastCard;
