export const SearchFormHorizontalDefault = ({children}) => {
  /**
   * We need to override the default behaviour on hitting the Enter key, otherwise the first
   * child component that handles this event will force the behaviour.
   * @param e
   */
  function handleKeyDown(e) {
    if (e.key === 'Enter'){
      e.preventDefault();
      e.stopPropagation();
    }
  }

  return (<form data-testid="search-form-horizontal" className="search-form form-inline" onSubmit={e => e.preventDefault()} onKeyDown={e => handleKeyDown(e)}>
    {children}
  </form>);
};
