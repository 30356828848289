import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CardCollapsible from '../shared/CardCollapsible';
import { flavour } from '../config';
import { CaseStatusBadge } from '../case/status/CaseStatus';
import { ProfileLink } from '../profile/Profile';
import { useAppConfiguration } from '../shared/contexts/AppConfiguration';
import {
  caseConfigurationVersion,
  caseOrTaskMessage,
  UiMessageKey
} from '../shared/types';
import { CaseSummary } from '../case/summary/CaseSummary';
import { CaseLink } from '../shared/components/CaseLink';

const { CaseDetails } = flavour.components;

function CaseCard({ model, state }) {
  const appConfig = useAppConfiguration();
  const [caseConfig, setCaseConfig] = useState();

  useEffect(() => {
    if (appConfig.loaded) {
      const config = caseConfigurationVersion(
        appConfig,
        model.caseRef,
        model.caseVersion
      );
      setCaseConfig(config);
    }
  }, [appConfig, appConfig.loaded, model.caseRef, model.caseVersion]);

  return (
    <CardCollapsible title="Parent Case" className={'mb-4'}>
      <dl id="collapse-body" className="row">
        <dt className="col-5">
          {`${caseOrTaskMessage(caseConfig, UiMessageKey.TitleSingular)}`}
        </dt>
        <dd className='col-7'>
          <CaseLink model={model} truncationLimit={24} newTab={true} showLabel={true} showId={true} />
        </dd>
        <dt className="col-5">Status</dt>
        <dd className="col-7">
          <CaseStatusBadge
            status={model.status}
            withDescription={true}
            withLock={true}
          />
          {!state.editable && (
            <span className="ml-1 badge badge-warning">Locked</span>
          )}
        </dd>
        <dt className="col-5">Creator</dt>
        <dd className="col-7">
          <ProfileLink
            id={model.creator.id}
            label={model.creator.displayName}
          />
        </dd>
        <dt className={'col-5'}>Details</dt>
        <dd className="col-7">
          {caseConfig && (
            <CaseSummary
              model={model}
              caseConfig={caseConfig}
              defaultComponent={<CaseDetails model={model.details} />}
            />
          )}
        </dd>
      </dl>
    </CardCollapsible>
  );
}

CaseCard.propTypes = {
  model: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired
};

export default CaseCard;
