import React, { useEffect, useState } from 'react';
import ModelInstanceView from '../models/ModelInstanceView';
import { Page, PageNav } from '../shared/Page';
import { FormattedMessage } from 'react-intl';
import { CaseStatusBadge } from './status/CaseStatus';
import { CasePriorityText } from './CasePriority';
import { CommonDate, CommonDateTime } from '../shared/Dates';
import PropTypes from 'prop-types';
import { DownloadPdfButton } from '../form/DownloadPdfButton';
import { Link } from 'react-router-dom';
import { useAppConfiguration } from '../shared/contexts/AppConfiguration';
import { caseConfigurationVersion } from '../shared/types';
import { ReadOnlyFormDisplay } from '../form/ReadOnlyFormDisplay';

const CaseExternalDetails = ({ model, match }) => {
  const appConfig = useAppConfiguration();
  const [caseConfig, setCaseConfig] = useState();
  const { databaseName } = match.params || null;

  useEffect(() => {
    if (appConfig.loaded) {
      const caseConfig = caseConfigurationVersion(
        appConfig,
        model.caseRef,
        model.caseVersion
      );
      setCaseConfig(caseConfig);
    }
  }, [appConfig, appConfig.loaded, model.caseRef, model.caseVersion]);

  return (
    <>
      <div className={'external-case-summary'}>
        <div className={'heading'}>
          <h1>Summary</h1>
        </div>
        <div className={'field-row'}>
          <div className={'label'}>ID</div>
          <div className={'value'}>{model.id}</div>
        </div>
        <div className={'field-row'}>
          <div className={'label'}>Status</div>
          <div className={'value'}>
            <CaseStatusBadge
              status={model.status}
              withDescription={true}
              withLock={true}
            />
          </div>
        </div>
        <div className={'field-row'}>
          <div className={'label'}>Priority</div>
          <div className={'value'}>
            <CasePriorityText priority={model.priority} />
          </div>
        </div>
        <div className={'field-row'}>
          <div className={'label'}>Creator</div>
          <div className={'value'}>{model.creator.displayName}</div>
        </div>
        <div className={'field-row'}>
          <div className={'label'}>Created At</div>
          <div className={'value'}>
            <CommonDateTime date={model.createdAt} withCasual={true} />
          </div>
        </div>
        <div className={'field-row'}>
          <div className={'label'}>Updated At</div>
          <div className={'value'}>
            <CommonDateTime date={model.updatedAt} withCasual={true} />
          </div>
        </div>
        <div className={'field-row'}>
          <div className={'label'}>Version</div>
          <div className={'value'}>{model.version}</div>
        </div>
        <div className={'heading-padded'}>
          <h1>Details</h1>
        </div>
        <div className={'form-container'}>
          {caseConfig && (
            <ReadOnlyFormDisplay
              values={model.details}
              schema={caseConfig.form.schema}
              uiSchema={caseConfig.form.uiSchema}
              rules={caseConfig.form.rules}
            />
          )}
        </div>
        <div className={'heading-padded'}>
          <h1>Tasks</h1>
        </div>
        <div className={'field-row'} data-testid="list">
          {model.tasks.map((task) => (
            <div key={`item${task.id}`} className="col-sm-6 col-md-4 col-lg-3">
              <div className="card mb-3">
                <div className="card-body">
                  <h5 className="card-title">
                    {task.displayName}{' '}
                    <small className="text-muted">#{task.id}</small>
                  </h5>
                  <h6 className="card-subtitle mb-2 text-muted">
                    Status: {task.status}
                  </h6>
                  <p className="card-text">
                    Last modified on <CommonDate date={task.updatedAt} />
                  </p>
                  <DownloadPdfButton
                    databaseName={databaseName}
                    taskRef={task.taskRef}
                    taskVersion={task.taskVersion}
                    formValues={task.formModelJson}
                    caseId={model.id}
                    taskId={task.id}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

CaseExternalDetails.propTypes = {
  model: PropTypes.object.isRequired
};

const CaseExternalShowPage = (props) => {
  const { caseId, databaseName } = props.match.params;
  return (
    <Page>
      <PageNav title="nav.cases.external.show">
        <span className="ml-auto" />
        <Link
          to={`/cases/external-search/${databaseName}/${caseId}/clone-details`}
          className="btn btn-sm btn-outline-primary mr-1"
        >
          <FormattedMessage id="nav.cases.external.clone" />
        </Link>
      </PageNav>
      <ModelInstanceView
        apiUrl={`/api/case/external/${databaseName}/${caseId}`}
        viewItem={CaseExternalDetails}
        {...props}
      />
    </Page>
  );
};

export default CaseExternalShowPage;
