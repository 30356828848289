import React, { useContext, useEffect, useState } from 'react';
import { sendError } from '../Airbrake';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';

/**
 * Glues some of the high level parts of application: flavour config, sidebar toggler
 * and exposes those for use in underlying components.
 * @type {React.Context<{}>}
 */
export const AppState = React.createContext({});
export const useAppState = () => useContext(AppState);
export const AppStateProvider = ({ children }) => {

  const [initializing, setInitializing] = useState(true);
  const [availableSubscriptions, setAvailableSubscriptions] = useState([]);
  const [sidebarActive, setSidebarActive] = useState(false);
  const [sidePanelActive, setSidePanelActive] = useState(false);
  const [sidePanelTitle, setSidePanelTitle] = useState(false);
  const [sidePanelComponents, setSidePanelComponents] = useState(null);
  const [hasTaskForms, setHasTaskForms] = useState(true);
  const [sidebarVisible, setSidebarVisible] = useState(true);

  /**
   * Show/hide sidebar when on small screens
   */
  const toggleSidebarActive = () => setSidebarActive(!sidebarActive);
  const showSidebar = (visible) => setSidebarVisible(visible);

  /**
   * Show/hide sidepanel within the app, set components to be rendered inside the side panel;
   */
  const toggleSidePanelActive = () => setSidePanelActive(!sidePanelActive);
  const setActiveSidePanelComponents = (components) => setSidePanelComponents(components);
  const setSidePanelHeaderTitle = (title) => setSidePanelTitle(title);


  useEffect(() => {
    const init = async () => {
      const [ subscription, forms] = await Promise.all([
        axios.get(`${process.env.REACT_APP_SERVER}/api/config/notification/subscriptions`),
        axios.get(`${process.env.REACT_APP_SERVER}/api/form`)
      ]);

      setAvailableSubscriptions(subscription.data.data || []);
      setHasTaskForms(forms && forms.data && forms.data.total > 0);
    };

    init()
      .catch((e) => {
        console.error('AppStateProvider failure', e);
        sendError(e).then(r => console.warn(JSON.stringify(r)));
      })
      .finally(() => setInitializing(false));
  }, []);

  if (initializing) return (<div className="d-flex min-vh-100 align-items-center justify-content-center">
    <Spinner animation={'border'} variant={'primary'}/>
  </div>);

  return (
    <AppState.Provider value={{
      hasTaskForms,
      availableSubscriptions,
      sidebarActive,
      sidePanelActive,
      sidePanelTitle,
      sidePanelComponents,
      sidebarVisible,
      toggleSidebarActive,
      toggleSidePanelActive,
      setSidePanelHeaderTitle,
      setActiveSidePanelComponents,
      showSidebar
    }}>
      {children}
    </AppState.Provider>
  );
};
