/**
 * https://github.com/ReactTraining/history/tree/master/docs
 */
import { createBrowserHistory, createMemoryHistory } from "history";
import { QueryParameter } from '../models/QueryParameter';
const RouterHistory = createBrowserHistory();

function getURLSearchParams () {
  const urlSearch = RouterHistory.location.search;
  return new URLSearchParams(urlSearch);
}

function getURLSearchParamsWithoutPagination () {
  const urlQueryParams = new URLSearchParams(RouterHistory.location.search);
  urlQueryParams.delete(QueryParameter.max.key);
  urlQueryParams.delete(QueryParameter.offset.key);
  return urlQueryParams;
}

function buildMemoryHistory() {
  return createMemoryHistory();
}

export {
  RouterHistory,
  getURLSearchParams,
  getURLSearchParamsWithoutPagination,
  buildMemoryHistory
};

export default RouterHistory;
