import React, { useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { parseAxiosError } from '../shared/AxiosResponseErrorParser';
import { useApiGet } from '../models/useApi';
import FormForSchemaModal from '../form/FormForSchemaModal';
import { versionedReference } from '../shared/types';

const CaseDetailsChangeModal = ({
  caseId,
  caseConfig,
  modalTitle,
  onModalClose
}) => {
  const {
    loading: fetchingInstance,
    data,
    error: fetchError
  } = useApiGet(`/api/case/${caseId}`);
  const [submitError, setSubmitError] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const onSubmit = (formData) => {
    setSubmitting(true);
    axios
      .post(`/api/case/${caseId}/details`, {
        caseRef: versionedReference(caseConfig),
        details: formData
      })
      .then(() => {
        onModalClose(formData);
      })
      .catch((e) => {
        setSubmitting(false);
        setSubmitError(parseAxiosError(e).getMessage());
      });
  };

  return (
    <FormForSchemaModal
      modalTitle={modalTitle}
      isLoading={fetchingInstance || submitting}
      onModalClose={onModalClose}
      onSubmit={onSubmit}
      errorMessage={fetchError || submitError}
      values={data && data.details}
      schema={caseConfig.form.schema}
      rules={caseConfig.form.rules}
      uiSchema={caseConfig.form.uiSchema}
    />
  );
};

CaseDetailsChangeModal.propTypes = {
  caseId: PropTypes.number.isRequired,
  caseConfig: PropTypes.object.isRequired,
  modalTitle: PropTypes.string.isRequired,
  onModalClose: PropTypes.func.isRequired
};

export default CaseDetailsChangeModal;
