import React from 'react';
import { ReactComponent as DividerCurve } from '../../../images/shapes/curves/curve-2.svg';
import { ReactComponent as BottomDivider } from '../../../images/shapes/curves/curve-1.svg';

export function AboutPageContent () {

  return (<>

    <section className="py-5 bg-white">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-8 text-center">
            <h1 className="display-4 text-primary">
              About
            </h1>
            <p className="lead text-muted mb-5">
              What to expect? We're here to help you better understand Dapian.
            </p>
          </div>
        </div>
      </div>
    </section>
    <div className="position-relative">
      <div className="shape shape-bottom shape-fluid-x svg-shim text-light">
        <DividerCurve/>
      </div>
    </div>

    <div className="bg-light py-5">
      <div className="container">
        <div className="row align-items-start justify-content-center">
          <div className="col-md-6 col-lg-4">
            <div className="card about-card-height border-white mb-5 shadow-sm rounded lift lift-lg">
              <div className="card-body">
                <h5 className="card-title">Guided forms</h5>
                <p className="card-text">Complete forms effectively with the help of expert guidance</p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <div className="card about-card-height border-white mb-5 shadow-sm rounded lift lift-lg">
              <div className="card-body">
                <h5 className="card-title">DPIA Management</h5>
                <p className="card-text">Automated workflows boost productivity by guiding the user through a flexible and interactive journey eliminating repetitive and non-relevant questions.</p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <div className="card about-card-height border-white mb-5 shadow-sm rounded lift lift-lg">
              <div className="card-body">
                <h5 className="card-title">Open collaboration</h5>
                <p className="card-text">This collaborative format allows draft, review, comment and approval contributions from inside and outside of your organisation.</p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <div className="card about-card-height border-white mb-5 shadow-sm rounded lift lift-lg">
              <div className="card-body">
                <h5 className="card-title">State-of-the-art notifications</h5>
                <p className="card-text">Keep collaborators involved and informed via email and push messaging alerts and notifications.</p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <div className="card about-card-height border-white mb-5 shadow-sm rounded lift lift-lg">
              <div className="card-body">
                <h5 className="card-title">Controlled data sharing</h5>
                <p className="card-text">Safe and secure platform to share current and historic DPIAs with other organisations.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="position-relative mb-5">
      <div className="shape shape-bottom shape-fluid-x svg-shim bg-light text-white">
        <BottomDivider/>
      </div>
    </div>
  </>);
}
