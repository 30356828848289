import React, { FC, useEffect, useState } from 'react';
import { Case } from '../../shared/types';
import useAxios from 'axios-hooks';
import { CaseStatusBadge } from '../../case/status/CaseStatus';
import { CaseLink } from '../../shared/components/CaseLink';

export interface ReferencesCasesProps {
  parentTaskId: number;
  reloadCookie: string;
}

/**
 * Renders a panel that displays all the cases reference by *the current saved*
 * version of a given task
 *
 * @constructor
 */
export const ReferencesCases: FC<ReferencesCasesProps> = ({
  parentTaskId,
  reloadCookie
}) => {
  const [referencedCases, setReferencedCases] = useState<Case[]>([]);
  const [reload, setReload] = useState<string>(reloadCookie);

  const [{ data, loading }, fetch] = useAxios({
    url: `/api/links/task/${parentTaskId}/cases`
  });

  // given that we're using a hook to load the related cases, use an effect to just
  // set the related case items
  useEffect(() => {
    if (!loading) {
      setReferencedCases(data.data);
    }
    if (reloadCookie !== reload) {
      fetch().then(() => {
        setReload(reloadCookie);
      }).catch(() => {
      });
    }
  }, [data, loading, fetch, reload, reloadCookie]);

  return (
    <div className="card mb-3 card-case-links" key={`links_${parentTaskId}`}>
      <div className="card-header pl-3 pr-2">
        <h2>References</h2>
      </div>
      <div
        className={'list-group list-group-flush flex-column'}
        data-testid="list"
      >
        {referencedCases?.map((related, index) => (
          <React.Fragment key={`referenced-${related.id}`}>
            <div className={'flex flex-row referenced-case-row'}>
              <span className={'flex-column referenced-case-link'}>
                <CaseLink
                  model={related}
                  newTab={true}
                  truncationLimit={24}
                  showLabel={true}
                  showId={true}
                />
              </span>
              <span className={'flex-column referenced-case-status'}>
                <CaseStatusBadge
                  status={related.status}
                  withLock={true}
                />
              </span>
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};
