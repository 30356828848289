import React from 'react';
import { components } from '../config/flavour';
import FooterComponent from './FooterComponent';

export default function HomePage() {
  return <>
    <components.HomePageContent />
    <FooterComponent />
  </>;
}
