import { FC, useMemo } from 'react';
import { SelectableReportField } from '../shared/types/Reports';
import { Column } from '@table-library/react-table-library/types/compact';
import {
  Data,
  TableNode
} from '@table-library/react-table-library/types/table';
import {
  SelectClickTypes,
  SelectTypes,
  useRowSelect
} from '@table-library/react-table-library/select';
import { CompactTable } from '@table-library/react-table-library/compact';

/**
 * Props for the fields editor component
 */
export interface FieldsEditorProps {
  /**
   * Unique identifier which is added to notification CBs
   */
  fieldsKey: string;

  /**
   * The list of fields to be edited
   */
  fields: SelectableReportField[];

  /**
   * Callback that is called whenever the selected fields within a given table
   * change
   * @param tableName the core table being edited
   * @param ids a list of currently selected column names
   */
  onSelectedColumnsChange: (
    fieldsKey: string,
    selectedColumnNames: string[]
  ) => void;

  /*
   * Callback that is called whenever the label for a given field is changed
   * @param tableName the core table being edited
   * @param columnName the name of the column being changed
   * @param newLabel the new label for the column
   */
  onColumnLabelChange: (
    fieldsKey: string,
    columnName: string,
    newLabel: string
  ) => void;
}

export const SelectableFieldsEditor: FC<FieldsEditorProps> = (props) => {
  // build a list of fields, memoize
  const fields = useMemo(() => {
    return props.fields.map((item) => {
      return {
        id: item.columnName,
        columnName: item.columnName,
        type: item.type,
        label: item.label,
        selected: item.selected
      };
    });
  }, [props.fields]);

  /**
   * Static set of columns used to display information relating to the fields being
   * edited
   */
  const COLUMNS: Column<TableNode>[] = [
    {
      label: 'Source field',
      renderCell: (item) => item.columnName,
      select: true,
      resize: true
    },
    {
      label: 'Source field type',
      renderCell: (item) => {
        switch (item.type) {
          case 'array':
            return 'Array';
          case 'number':
          case 'int':
          case 'integer':
          case 'Long':
            return 'Number';
          case 'LocalDate':
          case 'LocalDateTime':
            return 'Date & Time';
          case 'string':
          case 'String':
            return 'Text';
          case 'boolean':
          case 'Boolean':
            return 'Yes/No';
          case 'null':
            return 'Empty';
          default:
            return item.type;
        }
      },
      resize: true
    },
    {
      label: 'Mapped field title',
      renderCell: (item) => {
        return (
          <input
            type="text"
            aria-label={item.label}
            value={item.label}
            className={'column-label'}
            onChange={(event) =>
              props.onColumnLabelChange(
                props.fieldsKey,
                item.columnName,
                event.target.value
              )
            }
          />
        );
      },
      resize: true
    }
  ];

  // display the list of fields
  const data: Data<TableNode> = {
    nodes: fields
  };

  // set table selection state based on the selected attribute of each field
  const select = useRowSelect(
    data,
    {
      state: {
        ids: fields
          .filter((item) => item.selected)
          .map((item) => item.columnName)
      },
      onChange: (action, state) =>
        props.onSelectedColumnsChange(props.fieldsKey, state.ids)
    },
    {
      rowSelect: SelectTypes.MultiSelect,
      buttonSelect: SelectTypes.MultiSelect,
      clickType: SelectClickTypes.RowClick
    }
  );

  return <CompactTable data={data} columns={COLUMNS} select={select} />;
};
