import { FC } from 'react';
import {
  caseOrTaskMessageElse,
  SelectableTaskConfiguration,
  UiMessageKey
} from '../shared/types';
import { capitalCase } from 'text-case';
import { GeneralIcons } from '../shared/components/Icons';

export interface AvailableTaskItemRendererProps {
  model: SelectableTaskConfiguration;
}

/**
 * Renders details for a specific available task, based on information contained within a
 * {@link TaskConfiguration}
 * @constructor
 */
export const AvailableTaskItemRenderer: FC<AvailableTaskItemRendererProps> = ({
  model
}) => {
  const label = caseOrTaskMessageElse(
    model.taskConfig,
    UiMessageKey.Verb,
    (config) => config.form.schema.title,
    capitalCase
  );

  if (model.selectable) {
    return (
      <div>
        {GeneralIcons.Circle('selectable-task-icon')} {label}
      </div>
    );
  } else {
    return (
      <>
        <div>{GeneralIcons.Circle('unselectable-task-icon')} {label}</div>
        <div>
          <ul className="unavailable-task-item">
            {model.requires.map((value, index) => (
              <li key={`${model.taskConfig.ref}-li-index`}>
                {GeneralIcons.Circle('unselectable-task-icon')}
                {value}
              </li>
            ))}
          </ul>
        </div>
      </>
    );
  }
};
