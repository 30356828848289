import {  FC } from 'react';
import {
  CaseConfiguration,
  SummaryDisplayGroupSpecification
} from '../../shared/types';
import { keys } from 'lodash';
import { CaseSummaryGroup } from './CaseSummaryGroup';

/**
 * Props for the case summary component
 */
export interface CaseSummaryProps {
  model: any;
  caseConfig: CaseConfiguration;
  defaultComponent: JSX.Element;
}

// @ts-ignore
/**
 * This component is responsible for rendering a summary of a given case based on information contained within the
 * case form and case type configuration. If a server-side set of summary groups have been defined as part of the
 * case meta-data, then we utilise the {@link DisplaySpecification} instances contained within the various groups
 * to build a summary.  If no such meta-data exists, then we utilise a default component
 * @param model the actual form data associated with the case
 * @param caseConfig the {@link CaseConfiguration} associated with the case
 * @param defaultComponent a default {@link Component} to use if we're doing it old-school (based on flavour-specific
 * baked in components)
 * @constructor
 */
export const CaseSummary: FC<CaseSummaryProps> = ({
  model,
  caseConfig,
  defaultComponent
}) => {
  if (!caseConfig.meta.summaryDisplayGroups) {
    return <> {defaultComponent} </>;
  } else {
    const groups: JSX.Element[] = [];
    for (const groupName of keys(caseConfig.meta.summaryDisplayGroups)) {
      const groupSpecification: SummaryDisplayGroupSpecification =
        caseConfig.meta.summaryDisplayGroups[groupName];
      groups.push(
        <CaseSummaryGroup
          key={groupSpecification.title}
          model={model}
          caseConfig={caseConfig}
          groupSpecification={groupSpecification}
        />
      );
    }
    return <>{groups}</>;
  }
};
