import React, { useCallback, useReducer } from 'react';
import PropTypes from 'prop-types';
import ListLoader from '../loaders/ListLoader';
import PaginationWrapper from '../shared/Pagination';
import { useApiList } from './useApi';
import {Close} from "../main/icons";

function ListGroupItem ({ children, ...rest }) {
  return <button type="button" {...rest}>{children}</button>;
}

function DefaultListItem ({ model = {}, ...rest }) {
  return <div {...rest}>{model.displayName}</div>;
}

const ModelListViewSelectable = ({
  onItemSelected,
  apiUrl,
  additionalApiParams,
  listItemRenderer: ItemComponent = DefaultListItem,
  initialSelection,
  className,
  notFoundMessage,
  pusherChannelName,
  onPusherMessage,
  ...rest
}) => {

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }), {
      max: 5,
      offset: 0,
      selectedItem: initialSelection
    });

  const apiParams = additionalApiParams || {};
  const { loading, error, items, total } = useApiList(apiUrl, {
    max: state.max,
    offset: state.offset,
    ...apiParams
  });

  function isSelected(item) {
    if (!item) return false;
    const selectionJson = JSON.stringify(state.selectedItem);
    const itemJson = JSON.stringify(item);
    return selectionJson === itemJson;
  }

  function onClick (item) {
    if (onItemSelected) {
      if (isSelected(item)) {
        unselect();
      } else {
        setState({ selectedItem: item });
        onItemSelected(item);
      }
    }
  }

  function unselect (_) {
    setState({ selectedItem: null });
    onItemSelected && onItemSelected(null);
  }

  const onPagination = useCallback(({ max, offset }) => {
    setState({ max: max, offset: offset });
  }, [setState]);

  function listItemClassName (item) {
    return `list-group-item list-group-item-action ${isSelected(item) ? 'active' : ''}`;
  }

  function notFoundView (notFoundMessage) {
    return <div className="alert alert-info" data-testid="404">{notFoundMessage || 'Nothing found'}</div>;
  }

  function errorView () {
    return <div className="alert alert-danger" data-testid="error">{error}</div>;
  }

  function hasItems () {
    if (!items) {
      return false;
    }
    return items.length > 0 || total > 0;
  }

  function selectedItemView () {
    const selectedItem = state.selectedItem;
    return (<div data-testid="selected" className="mb-3 border p-3">
      <p className="small text-muted"> Selected:</p>
      <div className="small d-flex align-items-center justify-content-between">
        <div className="flex-column">
          <ItemComponent model={selectedItem}/>
        </div>
        <button onClick={() => unselect(selectedItem)} className="btn btn-sm btn-link" aria-label="Clear">
          <Close style={{ fill: 'currentColor' }} />
        </button>
      </div>
    </div>);
  }

  function listGroupItemViews () {
    if (items && items.length) {
      return items.map((item, key) =>
        <ListGroupItem key={`item${key}`} data-testid={`item${key}`} onClick={() => onClick(item)} className={listItemClassName(item)}>
          <ItemComponent model={item} {...rest}/>
        </ListGroupItem>);
    }
  }

  function hasPagination() {
    return !loading && !error && items && total > 0 && items.length < total;
  }

  return (<>
    {loading && <ListLoader data-testid="loader"/>}
    {!loading && error && errorView()}
    {!loading && !error && !hasItems() && notFoundView(notFoundMessage)}
    {!loading && !error && hasItems() &&
    <div className={className || 'list-group'} data-testid="list">
      {listGroupItemViews()}
    </div>}
    {hasPagination() &&
    <div data-testid="pagination" className="d-flex align-items-center justify-content-center">
      <PaginationWrapper size={'sm'} className="mt-3" max={state.max} offset={state.offset} total={total} onChange={onPagination}/>
    </div>}
    {hasPagination() && state.selectedItem && selectedItemView()}
  </>);
};

ModelListViewSelectable.propTypes = {
  onItemSelected: PropTypes.func,
  apiUrl: PropTypes.string.isRequired,
  additionalApiParams: PropTypes.object,
  listItemRenderer: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  initialSelection: PropTypes.any,
  notFoundMessage: PropTypes.string
};

export default ModelListViewSelectable;
