import React, {useEffect} from 'react';
import {Route, Switch} from 'react-router-dom';
import Secured from '../auth/AuthenticatedComponent';
import HomePage from './HomePage';
import FormsListPage from '../form/FormsListPage';
import FormsShowPage from '../form/FormsShowPage';
import AssessmentFlowPage from '../flows/AssessmentFlowPage';
import CaseShowPage from '../case/CaseShowPage';
import TaskShowPage from '../task/TaskShowPage';
import TasksListPage from '../task/TasksListPage';
import { MyAssignedTasksListPage, MyWatchedTasksListPage } from '../task/MyTasksListPages';
import UserProfilePage from '../profile/UserProfilePage';
import ProfilesIntro from '../profile/ProfilesIntro';
import ProfilesListPage from '../profile/ProfilesListPage';
import ProfilesShowPage from '../profile/ProfilesShowPage';
import ProfilesEditPage from '../profile/ProfilesEditPage';
import EditMyProfilePage from '../profile/EditMyProfilePage';
import CaseSearchDetailsPage from '../case/CaseSearchDetailsPage';
import CasesIntroPage from '../case/CasesIntroPage';
import TasksIntroPage from '../task/TasksIntroPage';
import {TaskEditPage} from '../task/TaskEditPage';
import ProfilesInvitePage from '../profile/ProfilesInvitePage';
import EditRolesFlowPage from '../flows/EditProfileRolesFlowPage';
import MyCasesListPage from '../case/MyCasesListPage';
import DashboardEntryPage from '../dashboard/DashboardEntryPage';
import AboutPage from './AboutPage';
import ActivityListPage from "../activity/ActivityListPage";
import ActivityShowPage from '../activity/ActivityShowPage';
import ActivityListAsNotificationsPage from '../activity/ActivityListAsNotificationsPage';
import TaskShowHistoryPage from '../task/TaskShowHistoryPage';
import CaseShowHistoryPage from '../case/CaseShowHistoryPage';
import TaskShowHistorySnapshotPage from '../task/TaskShowHistorySnapshotPage';
import { useIntl } from 'react-intl';
import CaseExternalSearchPage from '../case/CaseExternalSearchPage';
import CaseExternalShowPage from '../case/CaseExternalShowPage';
import { CaseExternalCloneFlowPage } from '../case/CaseExternalCloneFlowPage';
import { EditMyPreferencesPage } from '../profile/EditMyPreferencesPage';
import { EditProfilePreferencesPage } from '../profile/EditProfilePreferencesPage';
import { CookiePolicyPage } from "./CookiePolicyPage";
import { ReleaseNotesPage } from './ReleaseNotesPage';
import { ExportsPage } from '../reports/ExportsPage';
import { ReportGenerationPage } from '../reports/ReportGenerationPage';
import { TaskFormPreview } from '../form/TaskFormPreview';

const RouteWithTitle = ({ title, overrideTitle, ...rest }) => {
  const intl = useIntl();
  useEffect(() => {
    if (!overrideTitle) {
      document.title = intl.formatMessage({id: title});
    }else{
      document.title = overrideTitle;
    }
  }, [intl, title, overrideTitle]);
  return <Route {...rest} />;
};

const AppRoutes = ({isAuthenticated = false}) => {
  return (<Switch>
    <RouteWithTitle exact={true} title="nav.about" path='/about' component={AboutPage}/>
    <RouteWithTitle exact={true} title="nav.cookies" path='/cookie-policy' component={CookiePolicyPage}/>
    <RouteWithTitle exact={true} title="nav.whats_new" path='/release-notes' render={(props) => (<Secured onSuccess={ReleaseNotesPage} {...props} />)}/>
    <RouteWithTitle exact={true} title="nav.dashboard" path='/dashboard' render={(props) => (<Secured onSuccess={DashboardEntryPage} {...props} />)}/>
    <RouteWithTitle exact={true} title="nav.activities.user" path="/activity/me"
      render={(props) => (<Secured onSuccess={ActivityListAsNotificationsPage} title="nav.activities.user" apiEndpoint="/api/profile/me/activity" {...props}/>)}/>
    <RouteWithTitle exact={true} title="nav.activities.notifications" path="/activity/notifications"
      render={(props) => (<Secured onSuccess={ActivityListAsNotificationsPage} title="nav.activities.notifications" apiEndpoint="/api/activity/notified" {...props}/>)}/>
    <RouteWithTitle exact={true} title="nav.activities.all" path="/activity/list"
      render={(props) => (<Secured onSuccess={ActivityListPage} apiEndpoint="/api/activity" allowRefresh={true} {...props}/>)}/>
    <RouteWithTitle exact={true} title="nav.activities.show" path="/activity/show/:activityId"
      render={(props) => (<Secured onSuccess={ActivityShowPage} {...props}/>)}/>

    <RouteWithTitle exact={true} title="nav.cases" path="/cases/intro/:caseRef"
      render={(props) => (<Secured onSuccess={CasesIntroPage} {...props}/>)}/>
    <RouteWithTitle exact={true} title="nav.cases.internal.search" path="/cases/search"
      render={(props) => (<Secured onSuccess={CaseSearchDetailsPage} {...props}/>)}/>
    <RouteWithTitle exact={true} title="nav.cases.external.search" path="/cases/external-search"
      render={(props) => (<Secured onSuccess={CaseExternalSearchPage} {...props}/>)}/>
    <RouteWithTitle exact={true} title="nav.cases.external.show" path="/cases/external-search/:databaseName/:caseId"
      render={(props) => (<Secured onSuccess={CaseExternalShowPage} {...props}/>)}/>
    <RouteWithTitle exact={true} title="nav.cases.external.clone" path="/cases/external-search/:databaseName/:caseId/clone-details"
      render={(props) => (<Secured onSuccess={CaseExternalCloneFlowPage} {...props}/>)}/>
    <RouteWithTitle exact={true} title="nav.cases.user" path="/cases/created-by-you/:caseRef"
      render={(props) => (<Secured onSuccess={MyCasesListPage} {...props}/>)}/>
    <RouteWithTitle exact={true} title="nav.cases.show" overrideTitle={true} path="/cases/show/:caseId"
      render={props => (<Secured onSuccess={CaseShowPage} {...props} />)}/>
    <RouteWithTitle exact={true} title="nav.cases.history" path="/cases/show/:caseId/history"
      render={props => (<Secured onSuccess={CaseShowHistoryPage} {...props} />)}/>
    <RouteWithTitle exact={true} title="nav.tasks" path="/tasks" overrideTitle={'Tasks'}
      render={(props) => (<Secured onSuccess={TasksIntroPage} {...props}/>)}/>
    <RouteWithTitle exact={true} title="nav.tasks.all" overrideTitle={'All tasks'} path="/tasks/list"
      render={(props) => (<Secured onSuccess={TasksListPage} {...props}/>)}/>
    <RouteWithTitle exact={true} title="nav.tasks.assigned" overrideTitle={'Tasks assigned to you'} path="/tasks/assigned"
      render={(props) => (<Secured onSuccess={MyAssignedTasksListPage} {...props}/>)}/>
    <RouteWithTitle exact={true} title="nav.tasks.watched" overrideTitle={'Tasks watched by you'} path="/tasks/watched"
      render={(props) => (<Secured onSuccess={MyWatchedTasksListPage} {...props}/>)}/>
    <RouteWithTitle exact={true} title="nav.tasks.show" overrideTitle={true} path="/tasks/show/:taskId"
      render={props => (<Secured onSuccess={TaskShowPage} {...props} />)}/>
    <RouteWithTitle exact={true} title="nav.tasks.history" path="/tasks/show/:taskId/history"
      render={props => (<Secured onSuccess={TaskShowHistoryPage} {...props} />)}/>
    <RouteWithTitle exact={true} title="nav.tasks.history.show" path="/tasks/show/:taskId/history/:versionNumber"
      render={props => (<Secured onSuccess={TaskShowHistorySnapshotPage} {...props} />)}/>
    <RouteWithTitle exact={true} title="nav.tasks.edit" path="/tasks/edit/:taskId"
      render={(props) => (<Secured onSuccess={TaskEditPage} {...props}/>)}/>

    <RouteWithTitle exact={true} title="nav.profiles" path="/profiles"
      render={(props) => (<Secured onSuccess={ProfilesIntro} {...props}/>)}/>
    <RouteWithTitle exact={true} title="nav.profiles.user" path='/profile' render={(props) => (<Secured onSuccess={UserProfilePage} {...props}/>)}/>
    <RouteWithTitle exact={true} title="nav.profiles.edit" path='/profile/edit'
      render={(props) => (<Secured onSuccess={EditMyProfilePage} {...props}/>)}/>
    <RouteWithTitle exact={true} title="nav.profiles.preferences" path='/profile/preferences' render={(props) => (<Secured onSuccess={EditMyPreferencesPage} {...props}/>)}/>
    <RouteWithTitle exact={true} title="nav.profiles.all" path="/profiles/list"
      render={(props) => (<Secured onSuccess={ProfilesListPage} {...props}/>)}/>
    <RouteWithTitle exact={true} title="nav.profiles.show" overrideTitle={true} path="/profiles/show/:profileId"
      render={props => (<Secured onSuccess={ProfilesShowPage} {...props} />)}/>
    <RouteWithTitle exact={true} title="nav.profiles.edit" path="/profiles/edit/:profileId"
      render={props => (<Secured onSuccess={ProfilesEditPage} {...props} />)}/>
    <RouteWithTitle exact={true} title="nav.profiles.roles.edit" path="/profiles/edit/:profileId/roles"
      render={props => (<Secured onSuccess={EditRolesFlowPage} {...props} />)}/>
    <RouteWithTitle exact={true} title="nav.profiles.preferences" path="/profiles/edit/:profileId/preferences" render={props => (<Secured onSuccess={EditProfilePreferencesPage} {...props} />)}/>
    <RouteWithTitle exact={true} title="nav.profiles.invite" path="/profiles/invite/:profileId"
      render={props => (<Secured onSuccess={ProfilesInvitePage} {...props} />)}/>

    <RouteWithTitle exact={true} title="nav.assessment" path='/assessment/:caseRef'
      render={(props) => (<Secured onSuccess={AssessmentFlowPage} {...props}/>)}/>

    <RouteWithTitle exact={true} title="nav.tasks.forms" path="/forms" component={FormsListPage}/>
    <RouteWithTitle exact={true} title="nav.tasks.forms.show" path='/forms/:taskRef/:taskVersion' component={FormsShowPage}/>
    <RouteWithTitle exact={true} title="Preview Form" path='/formPreview/:taskRef/:taskVersion' component={TaskFormPreview}/>
    <RouteWithTitle exact={true} title="general.brand" path="/" component={isAuthenticated ? DashboardEntryPage : HomePage}/>
    <RouteWithTitle exact={true} title="nav.export" path="/export" render={(props) => (<Secured onSuccess={ExportsPage} {...props} />)}/>
    <RouteWithTitle exact={true} title="Reports" path="/reports" render={(props) => (<Secured onSuccess={ReportGenerationPage} {...props} />)}/>

    <Route component={isAuthenticated ? DashboardEntryPage : HomePage}/>
  </Switch>);
};

export default AppRoutes;
