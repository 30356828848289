import React, { FC, useContext, useEffect, useState } from 'react';
import {
  AppMetaData,
  CaseConfiguration,
  FormConfiguration,
  TaskConfiguration
} from '../types';
import axios from 'axios';

/**
 * The shape of the app configuration context
 */
export interface AppConfiguration {
  /**
   * Whether the config has yet been loaded
   */
  loaded: boolean;

  /**
   * The currently active flavour tag
   */
  activeFlavour: string;

  /**
   * Top level application meta-data including feature flags
   */
  appMetaData: AppMetaData;

  /**
   * List of {@link CaseConfiguration}
   */
  caseConfigurations: CaseConfiguration[];

  /**
   * List of {@link TaskConfiguration}s
   */
  taskConfigurations: TaskConfiguration[];

  /**
   * List of {@link FormConfiguration}s
   */
  formConfigurations: FormConfiguration[];
}

/**
 * Default state is basically empty, with no top-level feature flags set
 */
const defaultAppConfiguration: AppConfiguration = {
  loaded: false,
  activeFlavour: '',
  appMetaData: {maintenanceMode: false},
  caseConfigurations: [],
  taskConfigurations: [],
  formConfigurations: []
};

/**
 * Context for holding all the current app configuration, including things such as
 *
 * 1. Case configurations
 * 2. Task configurations
 * 3. Associated form configurations
 * 4. Statuses associated with cases
 */
export const AppConfigurationContext = React.createContext<AppConfiguration>(
  defaultAppConfiguration
);

/**
 * Hook for consuming the context
 */
export const useAppConfiguration = () => useContext(AppConfigurationContext);

/**
 * The provider for app configuration context
 * @param children child components
 * @constructor
 */
export const AppConfigurationProvider: FC<any> = ({ children }) => {
  /**
   * The *actual* configuration state object
   */
  const [configuration, setConfiguration] = useState<AppConfiguration>(
    defaultAppConfiguration
  );

  // only run this effect if we haven't already loaded the configuration
  useEffect(() => {
    const loadConfiguration = async () => {
      const token = axios.CancelToken;
      const source = token.source();
      await Promise.all([
        axios.get(`${process.env.REACT_APP_SERVER}/api/config/flavour`, {
          cancelToken: source.token
        }),
        axios.get(`${process.env.REACT_APP_SERVER}/api/config/app`, {
          cancelToken: source.token
        }),
        axios.get(
          `${process.env.REACT_APP_SERVER}/api/config/case/configurations`,
          { cancelToken: source.token }
        ),
        axios.get(
          `${process.env.REACT_APP_SERVER}/api/config/task/configurations`,
          { cancelToken: source.token }
        )
      ])
        .then(([flavour, app, cases, tasks]) => {
          console.log('configuration loaded');
          setConfiguration((config) => ({
            ...config,
            appMetaData: app.data,
            caseConfigurations: cases.data.data,
            taskConfigurations: tasks.data.data,
            activeFlavour: flavour.data.data,
            loaded: true
          }));
        })
        .catch((exception) => {
          if (axios.isCancel(exception)) {
            console.warn('request cancelled');
          } else {
            console.warn(`Caught exception during app config load`);
          }
        });
    };

    if (!configuration.loaded) {
      loadConfiguration().catch((e) => {
        console.warn(
          `Caught exception during configuration load: ${JSON.stringify(e)}`
        );
      });
    }
  }, [configuration.loaded]);

  return (
    <AppConfigurationContext.Provider value={configuration}>
      {children}
    </AppConfigurationContext.Provider>
  );
};
