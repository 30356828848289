import React, { FC, useEffect, useState } from 'react';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import { FormattedMessage } from 'react-intl';
import { BasePie } from './basePie';
import { CaseReviewStatus, CaseReviewStatusLabel } from '../shared/types';

export interface CasesDueForReviewDonutChartProps {
  caseRef?: string;
  refreshCookie: string;
}

export const CasesDueForReviewDonutChart: FC<
  CasesDueForReviewDonutChartProps
> = ({ caseRef, refreshCookie }) => {
  const [analyticsData, setAnalyticsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<string>(refreshCookie);

  useEffect(() => {
    if (!loaded) {
      const apiUrl = caseRef
        ? `/api/stats/cases/review/${caseRef}`
        : `/api/stats/cases/review`;
      axios
        .get(apiUrl)
        .then((response) => response.data.data || [])
        .then((results) => {
          setAnalyticsData(
            results.map(
              (result: { review: CaseReviewStatus | string; count: number }) => ({
                id: CaseReviewStatusLabel(result.review),
                label: CaseReviewStatusLabel(result.review),
                value: result.count
              })
            )
          );
          setLoaded(true);
        })
        .catch(() => setAnalyticsData([]))
        .finally(() => setIsLoading(false));
    }
    if (refreshCookie !== refresh){
      setLoaded(false);
      setRefresh(refreshCookie);
    }
  }, [caseRef, loaded, refresh, refreshCookie]);

  return (
    <div
      style={{ height: 300 }}
      className="d-flex align-items-center justify-content-center"
    >
      {isLoading && <Spinner animation={'border'} variant={'primary'} />}
      {!isLoading && analyticsData.length === 0 && (
        <p className="text-muted text-center">
          <FormattedMessage id="stats.cases.review.notfound" />
        </p>
      )}
      {!isLoading && analyticsData.length > 0 && (
        <BasePie analyticsData={analyticsData} />
      )}
    </div>
  );
};
