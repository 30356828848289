import React, { FC } from 'react';

/**
 * Props for the search widget
 */
export interface SearchWidgetContainerProps {

  /**
   * Whether we lay out the widget container as a row, rather than a column
   */
  row: boolean,

  /**
   * Child components
   */
  children?: React.ReactNode;
}


/**
 * Generic search widget container
 * @constructor
 */
export const SearchWidgetContainer : FC<SearchWidgetContainerProps> = ({row, children}) => {
  return (
    <div className={!row ? 'search-widget-container' : 'search-widget-container-row'}>
      {children}
    </div>
  );
};
