import React from 'react';
import { WithPermission } from '../auth/AccessConditionals';
import { TasksAssignedCard } from '../charts/tasksAssigned';
import { Page, PageNav } from '../shared/Page';
import { CardWidget } from '../shared/CardWidget';
import { Link } from 'react-router-dom';
import { AngleRight } from '../main/icons';

const TasksIntroPage = () => {
  return (
    <Page>
      <PageNav title="Overview: Tasks" translate={false} />
      <div className="row">
        <div className="col-md-6">
          <TasksAssignedCard />
        </div>
        <div className="col-md-6">
          <WithPermission name="task:read">
            <CardWidget
              title={'All tasks'}
              description={'Search all assigned tasks'}
              navItems={
                <Link
                  className="btn btn-sm btn-outline-secondary"
                  to={'/tasks/list'}
                >
                  All tasks{' '}
                  <AngleRight className="svg-gradient-primary" height={15} />
                </Link>
              }
            />
          </WithPermission>

          <WithPermission name="form:preview">
            <CardWidget
              title={'Available task forms'}
              description={'All available task forms'}
              navItems={
                <Link
                  className="btn btn-sm btn-outline-secondary"
                  to={'/forms'}
                >
                  Available task forms{' '}
                  <AngleRight className="svg-gradient-primary" height={15} />
                </Link>
              }
            />
          </WithPermission>
        </div>
      </div>
    </Page>
  );
};

export default TasksIntroPage;
