/**
 * Convenience function for combining multiple class names together
 * @param classes
 * @param existing
 */
export function combineClassNames(classes : string | string[], existing : string | string[]) : string {
  const left = Array.isArray(classes) ? classes : [classes];
  const right = Array.isArray(existing) ? existing : [existing];
  const combined = [...left, ...right];
  return combined.join(' ');
}

/**
 * Collapse a single or list of class names
 * @param classNames
 */
export function collapseClassnames(classNames: string | string[]): string {
  if (Array.isArray(classNames)) {
    return classNames.join(' ');
  } else {
    return classNames;
  }
}

