import React from 'react';

export default function DashboardContentLoader () {
  return (<div className="container-fluid py-3 mb-3">
    <div className="row">
      <section className="col-12">
        <div className="container-fluid">
          <header className="mb-4">
            <div className="row">
              <div className="col-9">
                <span className="h2 loading-text-filler"/>
              </div>
              <div className="col-5">
                <span className="h5 loading-text-filler"/>
              </div>
            </div>
          </header>
          <section className="row">
            <div className="col">
              <p className="loading-text-filler"/>
              <p className="loading-text-filler"/>
              <p className="loading-text-filler"/>
              <p className="loading-text-filler"/>
            </div>
            <div className="col">
              <p className="loading-text-filler"/>
              <p className="loading-text-filler"/>
            </div>
          </section>
          <section className="row">
            <div className="col">
              <p className="loading-text-filler"/>
              <p className="loading-text-filler"/>
              <p className="loading-text-filler"/>
              <p className="loading-text-filler"/>
            </div>
            <div className="col">
              <p className="loading-text-filler"/>
              <p className="loading-text-filler"/>
            </div>
          </section>
        </div>
      </section>
    </div>
  </div>);
}
