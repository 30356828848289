import { upperFirst } from 'lodash';

export interface Base {
  id: number;
  updatedAt?: string;
  version: number;
}

export interface BaseEntity extends Base {
  createdAt?: string;
  createdBy?: string;
  updatedBy?: string;
}

export interface File extends BaseEntity {
  bytes: number[] | undefined;
  contentType: string | undefined;
  name: string | undefined;
}

export interface ProfileRole extends BaseEntity {
  authority: string | undefined;
  profileId: number | undefined;
}

/**
 * product of different entity type identifiers, used predominantly within activities
 */
export type ModelEntityType = 'Task' | 'Case' | 'Comment';

/**
 * Various different constants used to identify different audit/activity event types
 */
export type ActivityEventType =
  | 'STATUS_CHANGE'
  | 'ASSIGNEE_CHANGE'
  | 'NEW_COMMENT'
  | 'SAVE'
  | 'CREATE'
  | 'COMMENT_ADDED'
  | 'COMMENT_REMOVED'
  | 'WATCHER_ADDED'
  | 'WATCHER_REMOVED'
  | 'LOCKED'
  | 'UNLOCKED';

export interface ProfileRestricted {
  email: string;
  name?: string;
}

export interface BaseProfile extends Base, ProfileRestricted {
  locked?: boolean;
  mobile?: string;
  username?: string;
}

type NotificationSubscription = 'EMAIL' | 'EMAIL_CASES_DUE_FOR_REVIEW';

export interface Profile extends BaseEntity, BaseProfile {
  email: string;
  locked?: boolean;
  mobile?: string;
  name?: string;
  notificationSubscriptions?: NotificationSubscription[];
  searchText?: string;
  username: string;
  displayName?: string;
}

export interface Activity extends BaseEntity {
  correlationId: string | undefined;
  entityId: number | undefined;
  entityType: ModelEntityType | undefined;
  entityVersion: number | undefined;
  event: { [key: string]: any };
  eventType: ActivityEventType;
  profileId: number | undefined;
  readers: Profile[];
}

export interface Comment extends BaseEntity {
  attachments: File[];
  entityId: number | undefined;
  entityType: ModelEntityType | undefined;
  owner: Profile | undefined;
  text: string | undefined;
}

export interface CaseLabel extends BaseEntity {
  case: Case | undefined;
  text: string | undefined;
}

export interface LocalDate {
  year: number;
  month: number;
}

export interface Case extends BaseEntity {
  caseRef: string;
  caseVersion: number;
  creator: Profile | undefined;
  details: { [key: string]: any } | undefined;
  labels?: CaseLabel[];
  links?: Case[];
  nextReviewOn?: LocalDate;
  priority: number;
  searchable?: boolean;
  status: string;
  tasks?: Task[];
  displayName: string | undefined;
}

/**
 * Partial case information, returned by certain search operations.  Should be considered
 * as a legacy aspect of the model, as it should be replaced with a full {@link Case} structure
 * in the future
 */
export interface CasePartial {
  id?: number;
  caseId?: number;
  caseStatus: string;
  caseRef: string;
  caseVersion: number;
}

type TaskStatus = 'OPEN' | 'STOPPED' | 'DONE';

export interface Task extends BaseEntity {
  assignee: Profile | undefined;
  case: Case | undefined;
  formModelJson: { [key: string]: any } | undefined;
  taskRef: string;
  taskVersion: number;
  status: TaskStatus;
  title: string | undefined;
  watchers: Profile[];
  displayName: string | undefined;
}

/**
 * Partial task information, certain API operations only really need to return partial information
 * in order to minimise network transmission
 */
export interface TaskPartial {
  taskId : number;
  taskRef : string;
  taskVersion : number;
  taskDisplayName : string;
  taskStatus : TaskStatus;
  parentCase? : CasePartial
}

export type CaseReviewStatus = 'SCHEDULED' | 'DUE' | 'NOT_SET';

export const CaseReviewStatusLabel = (key: CaseReviewStatus | string) => {
  switch (key) {
    case 'SCHEDULED':
      return 'Scheduled';
    case 'DUE':
      return 'Due';
    case 'NOT_SET':
      return 'Review not set';
    default:
      return upperFirst(key);
  }
};
