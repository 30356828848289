import React, { FC, useEffect, useState } from 'react';
import { Case, CasePartial } from '../../shared/types';
import useAxios from 'axios-hooks';
import { CaseLink } from '../../shared/components/CaseLink';
import _ from 'lodash';
import { SpinnerDotted } from 'spinners-react';

export interface CaseSummarySpanProps {
  model: CasePartial;
}

/**
 * A component that will display *limited* case summary information
 * @param model
 * @param rest
 * @constructor
 */
export const CaseSummarySpan: FC<CaseSummarySpanProps> = ({
  model,
  ...rest
}) => {
  const caseId = _.has(model, 'caseId')
    ? model.caseId
    : model.id
      ? model.id
      : undefined;
  const [caseModel, setCaseModel] = useState<Case>();

  const [{ loading }, fetchCase] = useAxios(
    {
      url: `/api/case/${caseId}`
    },
    { manual: true }
  );

  useEffect(() => {
    if (!caseModel) {
      fetchCase()
        .then((response) => {
          setCaseModel(response.data.data);
        })
        .catch(() => {});
    }
  }, [fetchCase, caseModel]);

  return (
    <>
      { loading && caseModel === undefined && (
        <SpinnerDotted style={{marginLeft : 'auto', marginRight : 'auto'}} size={24} />
      )}
      {caseModel && (
        <button className="btn p-0 m-0" {...rest}>
          <CaseLink
            model={caseModel}
            truncationLimit={24}
            newTab={true}
            showLabel={false}
            showId={true}
          />
        </button>
      )}
    </>
  );
};
