import React, { FC, useCallback, useReducer } from 'react';
import {
  caseFormInclusion,
  ReportTemplate,
  taskFormInclusion
} from '../shared/types/Reports';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { SelectableFieldsEditor } from './SelectableFieldsEditor';
import { Collapsible } from '../shared/components/Collapsible';
import {
  CaseConfiguration,
  caseOrTaskMessage,
  safeTaskConfigurationTitle,
  TaskConfiguration,
  UiMessageKey
} from '../shared/types';
import { CheckboxWidget } from '../shared/components/widgets/CheckboxWidget';
import { EntityIcons, GeneralIcons } from '../shared/components/Icons';

/**
 * Props for the report template editor
 */
export interface ReportTemplateEditorProps {
  /**
   * A valid case configuration
   */
  caseConfig?: CaseConfiguration;

  /**
   * A list of task form configurations
   */
  taskConfigs?: TaskConfiguration[];

  /**
   * The template currently being edited
   */
  template: ReportTemplate;

  /**
   * Called when the selected columns for a given core table change
   * @param tableName
   * @param ids
   */
  onSelectedTableColumnsChange: (
    fieldsKey: string,
    columnNames: string[]
  ) => void;

  /**
   * Called when a core table column label is changed
   * @param tableName
   * @param columnName
   * @param label
   */
  onTableColumnLabelChange: (
    fieldsKey: string,
    columnName: string,
    label: string
  ) => void;

  /**
   * CB called when the inclusion of a case form changes
   * @param key
   * @param included
   */
  onCaseFormInclusionChange: (key: string, included: boolean) => void;

  /**
   * CB called when the inclusion of a task form changes
   * @param key
   * @param included
   */
  onTaskFormInclusionChange: (key: string, included: boolean) => void;
}

/**
 * The state for the template editor
 */
interface ReportTemplateEditorState {
  /**
   * Array to keep track of panel expansion state
   * 0 = table panel
   * 1 = form panel
   */
  panelExpansionStates: boolean[];
}

/**
 * A component that allows for the editing of a report template, including its
 * core table mappings and individual form mappings
 * @constructor
 */
export const ReportTemplateEditor: FC<ReportTemplateEditorProps> = (props) => {
  const [state, dispatch] = useReducer(reducer, {
    panelExpansionStates: [false, false]
  });

  // place the reducer here to ensure that we capture props etc...
  function reducer(
    state: ReportTemplateEditorState,
    action: any
  ): ReportTemplateEditorState {
    const { type, payload } = action;
    switch (type) {
      case 'setExpansionState': {
        const { index, expanded } = payload;
        let newStates: boolean[] = state.panelExpansionStates;
        newStates = newStates.map((value, idx) => {
          if (expanded) {
            return index === idx ? expanded : !expanded;
          } else {
            return index === idx ? expanded : value;
          }
        });
        return { ...state, panelExpansionStates: newStates };
      }
    }
    return state;
  }

  // hook widget events here and translate to state changes
  const onWidgetValueChange = useCallback(
    (tag: string, value: any) => {
      const [prefix, suffix] = tag.split(':');
      switch (prefix) {
        case 'check_case_form':
          console.log(`case form checked: ${value}`);
          props.onCaseFormInclusionChange(suffix, value);
          return;
        case 'check_task_form':
          console.log(`task form checked: ${value}`);
          props.onTaskFormInclusionChange(suffix, value);
          return;
      }
    },
    [props]
  );

  return (
    <>
      <div className={'template-editor'}>
        <Collapsible
          index={0}
          expandedTitle={'Tables'}
          collapsedTitle={'Tables'}
          expanded={state.panelExpansionStates[0]}
          onExpandOrCollapse={(expanded) =>
            dispatch({
              type: 'setExpansionState',
              payload: { index: 0, expanded: expanded }
            })
          }
        >
          <Tabs>
            <TabList>
              <Tab>
                {EntityIcons.Table('tab-icon')}
                Cases
                {GeneralIcons.Tick('included-form-tick shift-up-4')}
              </Tab>
              <Tab>
                {EntityIcons.Table('tab-icon')}
                Tasks
                {GeneralIcons.Tick('included-form-tick shift-up-4')}
              </Tab>
            </TabList>
            <TabPanel>
              <SelectableFieldsEditor
                fieldsKey={'cases'}
                fields={props.template.coreTableFields!['cases']}
                onSelectedColumnsChange={props.onSelectedTableColumnsChange}
                onColumnLabelChange={props.onTableColumnLabelChange}
              />
            </TabPanel>
            <TabPanel>
              <SelectableFieldsEditor
                fieldsKey={'tasks'}
                fields={props.template.coreTableFields!['tasks']}
                onSelectedColumnsChange={props.onSelectedTableColumnsChange}
                onColumnLabelChange={props.onTableColumnLabelChange}
              />
            </TabPanel>
          </Tabs>
        </Collapsible>

        {props.caseConfig && (
          <Collapsible
            index={1}
            expandedTitle={'Forms'}
            collapsedTitle={'Forms'}
            expanded={state.panelExpansionStates[1]}
            onExpandOrCollapse={(expanded) =>
              dispatch({
                type: 'setExpansionState',
                payload: { index: 1, expanded: expanded }
              })
            }
          >
            <Tabs>
              <TabList>
                <Tab>
                  {EntityIcons.Case('tab-icon')}
                  {caseOrTaskMessage(
                    props.caseConfig,
                    UiMessageKey.TitleSingular
                  )}
                  {caseFormInclusion(
                    props.caseConfig.ref,
                    props.caseConfig.version,
                    props.template
                  )
                    ? GeneralIcons.Tick('included-form-tick shift-up-4')
                    : GeneralIcons.Cross('excluded-form-cross shift-up-4')}
                </Tab>
                {props.taskConfigs &&
                  props.taskConfigs.map((config) => {
                    return (
                      <Tab key={config.ref}>
                        {EntityIcons.Task('tab-icon')}
                        {safeTaskConfigurationTitle(config)}
                        {taskFormInclusion(
                          config.ref,
                          config.version,
                          props.template
                        )
                          ? GeneralIcons.Tick('included-form-tick shift-up-4')
                          : GeneralIcons.Cross(
                            'excluded-form-cross shift-up-4'
                          )}
                      </Tab>
                    );
                  })}
              </TabList>
              <TabPanel>
                <div className={'tab-content'}>
                  <div className={'tab-content-row right-justified-content'}>
                    <div className={'form'}>
                      <div className={'form-row'}>
                        {props.caseConfig && (
                          <CheckboxWidget
                            checked={caseFormInclusion(
                              props.caseConfig.ref,
                              props.caseConfig.version,
                              props.template
                            )}
                            tag={`check_case_form:${props.caseConfig.ref}_${props.caseConfig.version}`}
                            label={'Include in report?'}
                            disabled={false}
                            onChange={(checked) =>
                              onWidgetValueChange(
                                `check_case_form:${props.caseConfig!!.ref}_${props.caseConfig!!.version}`,
                                checked
                              )
                            }
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={'tab-content-row'}>
                    <SelectableFieldsEditor
                      fieldsKey={`case:${props.caseConfig.ref}_${props.caseConfig.version}`}
                      fields={
                        props.template.coreCaseFormFields![
                        `${props.caseConfig.ref}_${props.caseConfig.version}`
                        ]
                      }
                      onSelectedColumnsChange={
                        props.onSelectedTableColumnsChange
                      }
                      onColumnLabelChange={props.onTableColumnLabelChange}
                    />
                  </div>
                </div>
              </TabPanel>
              {props.taskConfigs &&
                props.taskConfigs.map((config) => {
                  return (
                    <TabPanel key={config.ref}>
                      <div className={'tab-content'}>
                        <div
                          className={'tab-content-row right-justified-content'}
                        >
                          <div className={'form'}>
                            <div className={'form-row'}>
                              <CheckboxWidget
                                checked={taskFormInclusion(
                                  config.ref,
                                  config.version,
                                  props.template
                                )}
                                tag={`check_task_form:${config.ref}_${config.version}`}
                                label={'Include in report?'}
                                disabled={false}
                                onChange={(checked) =>
                                  onWidgetValueChange(
                                    `check_task_form:${config.ref}_${config.version}`,
                                    checked
                                  )
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className={'tab-content-row'}>
                          <SelectableFieldsEditor
                            fieldsKey={`task:${config.ref}_${config.version}`}
                            fields={
                              props.template.coreTaskFormFields![
                              `${config.ref}_${config.version}`
                              ]
                            }
                            onSelectedColumnsChange={
                              props.onSelectedTableColumnsChange
                            }
                            onColumnLabelChange={props.onTableColumnLabelChange}
                          />
                        </div>
                      </div>
                    </TabPanel>
                  );
                })}
            </Tabs>
          </Collapsible>
        )}
      </div>
    </>
  );
};
