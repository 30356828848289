import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ModelTableWithRouterState from '../models/ModelTableWithRouterState';
import { Page, PageNav } from '../shared/Page';
import { useAuthContext } from '../auth/AuthContextProvider';
import { PusherUserChannelListener } from '../pusher/PusherChannelListeners';
import { CasualTime } from '../shared/Dates';
import { ActivitySubjectDescription } from './ActivitySubjectDescription';
import { ActivityEventDescription } from './ActivityEventDescription';
import { ActivityTimestampDescription } from './ActivityTimestampDescription';
import { ActivityActorDescription } from './ActivityActorDescription';

function ActivityList(props) {
  const columns = [
    {
      label: 'Subject',
      fromModel: (item) => <ActivitySubjectDescription activity={item} />
    },
    {
      label: 'Event',
      fromModel: (item) => (
        <>
          <ActivityEventDescription activity={item} />
          <ActivityTimestampDescription activity={item} />
          <ActivityActorDescription activity={item} />
        </>
      )
    }
  ];

  return (
    <ModelTableWithRouterState
      caption="nav.activities.all"
      notFoundMessage="No notifications"
      columns={columns}
      additionalQueryParams={{ cacheBuster: props.lastEventTimestamp }}
      {...props}
    />
  );
}

ActivityList.propTypes = {
  apiEndpoint: PropTypes.string.isRequired,
  lastEventTimestamp: PropTypes.number,
  onPagination: PropTypes.func
};

/**
 * Pretty notifications list, just change the title and api endpoint
 * @return {null|jsx}
 */
export default function ActivityListAsNotificationsPage(props) {
  const { profile, loading } = useAuthContext();
  const [lastEventTimestamp, setLastEventTimestamp] = useState(
    new Date().getTime()
  );

  function onEvent() {
    setLastEventTimestamp(new Date().getTime());
  }

  if (loading) return null;
  return (
    <Page>
      <PageNav title={props.title}>
        <span className="ml-auto" />
        <span>Last updated</span>
        <CasualTime date={lastEventTimestamp} className="mx-1" />
        {props.allowRefresh && (
          <div className="btn btn-sm btn-outline-secondary" onClick={onEvent}>
            Refresh
          </div>
        )}
      </PageNav>
      {profile && (
        <>
          <ActivityList
            lastEventTimestamp={lastEventTimestamp}
            onPagination={onEvent}
            {...props}
          />
          <PusherUserChannelListener
            profileId={profile.id}
            showNotifications={false}
            afterStatusChange={onEvent}
            afterComment={onEvent}
            afterAssigneeChange={onEvent}
          />
        </>
      )}
    </Page>
  );
}

ActivityListAsNotificationsPage.propTypes = {
  title: PropTypes.string.isRequired,
  apiEndpoint: PropTypes.string.isRequired,
  allowRefresh: PropTypes.bool
};
