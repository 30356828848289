import React, { useState } from 'react';
import axios from 'axios';
import FlowContainerModal from './core/FlowContainerModal';
import PropTypes from 'prop-types';
import FormStep from './core/FormStep';
import { parseAxiosError } from '../shared/AxiosResponseErrorParser';
import SelectProfileStep from './core/SelectProfileStep';
import { FormForSchema } from '@lookinglocal/react-jsonschema-form-extensions';
import * as AppWidgets from '../form/widgets/AppWidgets';

export default function ChangeTaskAssigneeFlow({ model, ...rest }) {
  const taskId = model.id;
  const [errorMessage, setErrorMessage] = useState(null);

  function onSubmit(data) {
    return axios
      .post(`/api/task/${taskId}/assignee`, {
        assigneeId: data[0].id,
        comment: data[1].text
      })
      .then((response) => {
        if (response.data && response.data.data && response.data.data.assignee)
          rest.onModalClose(response.data.data.assignee);
        else return null;
      })
      .catch((e) => {
        setErrorMessage(parseAxiosError(e).getMessage());
      });
  }

  function onCancel() {
    rest.onModalClose(null);
  }

  const TaskCommentOptionalForm = (props) => {
    const schema = {
      type: 'object',
      properties: {
        text: {
          title: 'New comment',
          type: 'string'
        },
        helpText: {
          title: 'Notifications',
          description:
            'System will attempt to send notification which will include this text',
          type: 'null'
        }
      }
    };

    const uiSchema = {
      text: {
        'ui:widget': 'textarea'
      }
    };

    return (
      <FormForSchema
        schema={schema}
        uiSchema={uiSchema}
        {...props}
        appWidgets={AppWidgets.appWidgets}
      />
    );
  };

  return (
    <FlowContainerModal
      errorMessage={errorMessage}
      onFinalSubmit={onSubmit}
      onCancel={onCancel}
      {...rest}
    >
      <SelectProfileStep
        key={'one'}
        title="Select assignee"
        apiUrl={`/api/task/${taskId}/assignee`}
        withSearch={true}
        defaultRole="ANY-ROLE"
        notFoundMessage="No available assignees found"
        {...rest}
      />
      <FormStep
        key={'two'}
        title="Leave a comment"
        schemaFormComponent={TaskCommentOptionalForm}
      />
    </FlowContainerModal>
  );
}

ChangeTaskAssigneeFlow.propTypes = {
  model: PropTypes.object.isRequired
};
