import { FC } from 'react';
import {
  CaseConfiguration, mapDisplaySpecifications,
  SummaryDisplayGroupSpecification
} from '../../shared/types';
import { Collapsible } from '../../shared/components/Collapsible';
import {CaseSummaryGroupItem} from "./CaseSummaryGroupItem";

export interface CaseSummaryGroupProps {
  model: any;
  caseConfig: CaseConfiguration;
  groupSpecification: SummaryDisplayGroupSpecification;
}

/**
 * Wraps a series of {@link DisplaySpecification} instances within a {@link Collapsible}. Used by the top-level
 * {@link CaseSummary} component to build up a summary view of a case
 * @param model the raw data for the case
 * @param caseConfig the associated {@link CaseConfiguration}
 * @param groupSpecification the {@link SummaryDisplayGroupSpecification}
 * @constructor
 */
export const CaseSummaryGroup: FC<CaseSummaryGroupProps> = ({
  model,
  caseConfig,
  groupSpecification
}) => {
  const items : JSX.Element[] = mapDisplaySpecifications<JSX.Element>(caseConfig, groupSpecification.displaySpecifications, (label, f) => {
    return <CaseSummaryGroupItem key={label} model={model} caseConfig={caseConfig} label={label} valueFunc={f} />;
  });
  return (
    <Collapsible
      index={0}
      expandedTitle={groupSpecification.title}
      collapsedTitle={groupSpecification.title}
      expanded={
        groupSpecification.expandOnLoad !== null
          ? groupSpecification.expandOnLoad
          : true
      }
      children={items}
      floatRight={true}
    />
  );
};
