import React from 'react';
import PropTypes from 'prop-types';
import AbstractFlowStep from './AbstractFlowStep';

/**
 * Allows adding arbitrary step which will pass `allSuccessResults`
 * to a `component` defined in a prop.
 *
 * eg: <FlowContainer>
 *       <FormStep title="Your details" schemaFormComponent={ProfileForm}/>
 *       <Preview title="Preview" component={FooBar} />
 *     </FlowContainer>
 * where:
 *     function FooBar({allSuccessResults}) {
 *       return <pre>{JSON.stringify(allSuccessResults)}</pre>;
 *     }
 */
class PreviewStep extends AbstractFlowStep {

  constructor (props) {
    super(props);
    this.doNext = this.doNext.bind(this);
  }

  doNext () {
    this.props.onNextSuccess();
  }

  render () {
    const { title, component: Component, prevSuccessResult, allSuccessResults, onNextSuccess, ...rest } = this.props;
    return <>
      {title && <h3>{title}</h3>}
      <Component allSuccessResults={allSuccessResults} {...rest} />
    </>;
  }
}

PreviewStep.propTypes = {
  ...AbstractFlowStep.propTypes,
  title: PropTypes.string.isRequired,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired
};

export default PreviewStep;
