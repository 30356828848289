import React, { useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { parseAxiosError } from '../shared/AxiosResponseErrorParser';
import FormForSchemaModal from '../form/FormForSchemaModal';
import { useIntl } from 'react-intl';

const CaseSearchableEditorModal = ({ caseId, modalTitle, onModalClose, currentlySearchable }) => {

  const intl = useIntl();
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const onSubmit = (formData) => {
    setLoading(true);
    axios.post(`/api/case/${caseId}/searchable`, formData)
      .then(() => {
        onModalClose(formData.searchable);
      })
      .catch(e => {
        setLoading(false);
        setErrorMessage(parseAxiosError(e).getMessage());
      });
  };

  const schema = {
    type: 'object',
    properties: {
      searchable: {
        title: intl.formatMessage({ id: 'model.case.searchable' }),
        type: 'boolean'
      }
    }
  };

  const uiSchema = {
    "searchable": {
      "ui:widget": "radio"
    }
  };

  return (<FormForSchemaModal
    modalTitle={modalTitle}
    isLoading={loading}
    onModalClose={onModalClose}
    onSubmit={onSubmit}
    errorMessage={errorMessage}
    values={{ searchable: currentlySearchable }}
    schema={schema}
    uiSchema={uiSchema}
    rules={[]}
  />);
};

CaseSearchableEditorModal.propTypes = {
  caseId: PropTypes.number.isRequired,
  modalTitle: PropTypes.string.isRequired,
  currentlySearchable: PropTypes.bool,
  onModalClose: PropTypes.func.isRequired
};

export default CaseSearchableEditorModal;
