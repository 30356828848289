import React, { useEffect, useState } from 'react';
import { releaseNotes } from '../config/flavour';
import { MarkdownProcessor } from '../shared/MarkdownProcessor';

const ReleaseNotesComponent = () => {
  const [content, setContent] = useState(null);
  useEffect(() => {
    releaseNotes.getReleaseNotes().then((result) => setContent(result));
  }, []);

  return(
    <div className="container mb-5">
      <div className="mb-5 notes">
        {content && <MarkdownProcessor contents={content} />}
      </div>
    </div>
  );
};

export default ReleaseNotesComponent;
