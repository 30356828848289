import React, { useEffect, useState } from 'react';
import { generatePath } from 'react-router';
import ModelTableWithRouterState from '../models/ModelTableWithRouterState';
import { CaseStatusBadge } from './status/CaseStatus';
import { Page, PageNav } from '../shared/Page';
import { WithPermission } from '../auth/AccessConditionals';
import { CasePriorityText } from './CasePriority';
import { toUkDateTimeString } from '../search/MemorableDate';
import { useIntl } from 'react-intl';
import { CommonDate } from '../shared/Dates';
import AddCaseFlowModal from './AddCaseFlowModal';
import { flavour } from '../config';
import {
  caseOrTaskMessage,
  latestCaseConfiguration,
  UiMessageKey
} from '../shared/types';
import { useAppConfiguration } from '../shared/contexts/AppConfiguration';
import { useParams } from 'react-router-dom';
import { CaseDetailsSearchFields } from './CaseDetailsSearchFields';
import {useDynamicDocumentTitle} from "../shared/hooks/DocumentTitleHooks";

const MyCasesListPage = (props) => {
  const intl = useIntl();
  const { caseRef } = useParams();
  const [showAddCaseModal, setShowAddCaseModal] = useState(false);
  const appConfig = useAppConfiguration();
  const [caseConfig, setCaseConfig] = useState();
  const [timestamp, setTimestamp] = useState(new Date().getTime());
  const [caseSearchFields, setCaseSearchFields] = useState(
    new CaseDetailsSearchFields()
  );
  const [apiEndpoint, setApiEndpoint] = useState();
  const columns = [
    {
      label: intl.formatMessage({ id: 'model.case.id' }),
      sortBy: 'id',
      fromModel: (item) => item.id
    },
    ...caseSearchFields.getListColumnConfig(flavour.showCaseDetailsInList),
    {
      label: intl.formatMessage({ id: 'model.case.status' }),
      sortBy: 'status',
      fromModel: (item) => (
        <CaseStatusBadge
          status={item.status}
          withLock={true}
        />
      )
    },
    {
      label: intl.formatMessage({ id: 'model.case.priority' }),
      sortBy: 'priority',
      fromModel: (item) => <CasePriorityText priority={item.priority} />
    },
    {
      label: intl.formatMessage({ id: 'model.case.updatedAt' }),
      sortBy: 'updatedAt',
      fromModel: (item) => toUkDateTimeString(item.updatedAt)
    },
    {
      label: intl.formatMessage({ id: 'model.case.nextReviewOn' }),
      sortBy: 'nextReviewOn',
      fromModel: (item) => (
        <CommonDate date={item.nextReviewOn} fallback={'Not set'} />
      )
    }
  ];

  useDynamicDocumentTitle(() => {
    return `Your ${caseOrTaskMessage(caseConfig, UiMessageKey.TitlePlural)}`;
  });

  useEffect(() => {
    if (appConfig.loaded) {
      const config = latestCaseConfiguration(appConfig, caseRef);
      setCaseConfig(config);
      setCaseSearchFields(
        new CaseDetailsSearchFields(config.form.schema, config.form.uiSchema)
      );
      setApiEndpoint(`/api/profile/me/cases/${config.ref}`);
    }
  }, [appConfig, caseRef]);

  function openAddCaseModal() {
    setShowAddCaseModal(true);
  }

  function onAddCaseModalClose(value) {
    if (value) setTimestamp(new Date().getTime());
    setShowAddCaseModal(false);
  }

  return (
    <Page>
      {caseConfig && (
        <PageNav
          title={`Your ${caseOrTaskMessage(
            caseConfig,
            UiMessageKey.TitlePluralShort
          )}`}
        >
          <span className="mx-auto" />
          <WithPermission name="case:create">
            <button
              onClick={() => openAddCaseModal()}
              className="btn btn-sm btn-outline-secondary"
            >
              {`Add ${caseOrTaskMessage(
                caseConfig,
                UiMessageKey.TitleSingularShort
              )}`}
            </button>
          </WithPermission>
        </PageNav>
      )}

      {caseConfig && (
        <ModelTableWithRouterState
          caption="nav.cases.user"
          apiEndpoint={apiEndpoint}
          notFoundMessage={`No ${caseOrTaskMessage(
            caseConfig,
            UiMessageKey.TitlePluralShort
          )} found`}
          additionalQueryParams={{ t: timestamp }}
          columns={columns}
          itemHref={(item) => generatePath(`/cases/show/${item.id}`)}
          {...props}
        />
      )}

      {appConfig.loaded && caseConfig && showAddCaseModal && (
        <AddCaseFlowModal
          caseRef={caseConfig.ref}
          caseVersion={caseConfig.version}
          appConfig={appConfig}
          modalTitle={`Add ${caseOrTaskMessage(
            caseConfig,
            UiMessageKey.TitleSingularShort
          )}`}
          onModalClose={onAddCaseModalClose}
        />
      )}
    </Page>
  );
};

export default MyCasesListPage;
