import React, {FC, useEffect, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import {ResponsiveBar} from '@nivo/bar';
import {
  allCaseStatuses,
  allCaseStatusesByCaseType,
  CaseConfiguration,
  caseOrTaskMessage,
  caseStatusFromId,
  latestCaseConfiguration,
  UiMessageKey
} from '../shared/types';
import {useAppConfiguration} from '../shared/contexts/AppConfiguration';

/**
 * Only here to make things more readable
 */
type Datum = {
  availableStatuses: Array<string>;
  colors: Array<string>;
  stats: Array<object>;
};

export interface AgeOfUnresolvedCasesStackedBarChartProps {
  caseRef: string;
  refreshCookie: string;
}

export const AgeOfUnresolvedCasesStackedBarChart: FC<
  AgeOfUnresolvedCasesStackedBarChartProps
> = ({ caseRef, refreshCookie }) => {
  const appConfig = useAppConfiguration();
  const [caseConfig, setCaseConfig] = useState<CaseConfiguration>();
  const [analyticsData, setAnalyticsData] = useState<Datum>({} as Datum);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<string>(refreshCookie);
  const caseStatuses = caseRef
    ? allCaseStatusesByCaseType(appConfig, caseRef)
    : allCaseStatuses(appConfig);

  useEffect(() => {
    if (!loaded && appConfig.loaded) {
      // grab the case configuration
      setCaseConfig(latestCaseConfiguration(appConfig, caseRef));

      // query for the data and map
      const apiUrl = `/api/stats/cases/age-of-unresolved/${caseRef}`;
      axios
        .get(apiUrl)
        .then((response) => response.data.data || [])
        .then((results) => {
          const ages: { [key: string]: object } = {};
          const availableStatuses: { [key: string]: string } = {};
          results.forEach(
            (result: { status: string; age: number; count: number }) => {
              const statusLabel = caseStatusFromId(
                result.status,
                caseStatuses
              ).label;
              const ageStats: any = ages[result.age] || {};
              ageStats.age = `${result.age}`;
              ageStats[statusLabel] = result.count;
              availableStatuses[statusLabel] = statusLabel;
              ages[result.age] = ageStats;
            }
          );

          const usedStatuses = Object.keys(availableStatuses);
          const mappedStatuses = caseStatuses
            .filter((status) => usedStatuses.includes(status.label))
            .sort((a, b) => {
              if (a.label < b.label) return -1;
              if (a.label > b.label) return 1;
              return 0;
            });

          const chartStats = Object.keys(ages).map((k) => {
            const ageStats = ages[k];
            // make sure all available statuses are in each object
            usedStatuses.forEach(
              // @ts-ignore
              (status) => !ageStats[status] && (ageStats[status] = 0)
            );
            return ageStats;
          });
          setAnalyticsData({
            availableStatuses: mappedStatuses.map((status) => status.label),
            colors: mappedStatuses.map((status) => status.colour),
            stats: chartStats
          });
          setLoaded(true);
        })
        .catch(() => setAnalyticsData({} as Datum))
        .finally(() => setIsLoading(false));
    }
    if (refreshCookie !== refresh) {
      setLoaded(false);
      setRefresh(refreshCookie);
    }
  }, [appConfig, appConfig.loaded, caseRef, caseStatuses, loaded, refresh, refreshCookie]);

  return (
    <div
      style={{ height: 300 }}
      className="d-flex align-items-center justify-content-center"
    >
      {isLoading && <Spinner animation={'border'} variant={'primary'} />}
      {!isLoading && !analyticsData && (
        <p className="text-muted text-center">
          <FormattedMessage id="stats.cases.unresolved.age.notfound" />
        </p>
      )}
      {!isLoading && analyticsData && caseConfig && (
        <ResponsiveBar
          data={analyticsData.stats}
          keys={analyticsData.availableStatuses}
          indexBy="age"
          margin={{ top: 50, right: 5, bottom: 50, left: 50 }}
          padding={0.2}
          colors={{
            scheme : "paired"
          }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: -45,
            legend: 'Age in days',
            legendPosition: 'middle',
            legendOffset: 32
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            format: (y) => Math.floor(y) === y && y,
            legend: `Number of ${caseOrTaskMessage(caseConfig, UiMessageKey.TitlePluralShort)}`,
            legendPosition: 'middle',
            legendOffset: -40
          }}
          labelSkipWidth={12}
          labelSkipHeight={12}
          legends={[
            {
              dataFrom: 'keys',
              anchor: 'top-left',
              direction: 'row',
              justify: false,
              translateX: 0,
              translateY: -30,
              itemsSpacing: 4,
              itemWidth: 120,
              itemHeight: 20,
              itemDirection: 'left-to-right',
              itemOpacity: 0.85,
              symbolSize: 20
            }
          ]}
        />
      )}
    </div>
  );
};
