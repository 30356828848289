import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { usePusher } from "./PusherContext";

/**
 * Bind to event(s) in a given channel
 *
 * @param channelName Unprefixed channel name, e.g. 'task-10'
 * @param bindings Object where key=event name, value=callback receiving (data, channel, eventName)
 * @return Empty JSX element
 */
const PusherListener = ({channelName, bindings}) => {

  const {loading: pusherLoading, pusher} = usePusher();

  useEffect(() => {
    let channel;
    if (channelName && !pusherLoading) {
      const prefixedChannelName = channelName.startsWith('presence-') ? channelName : `${process.env.REACT_APP_PUSHER_CHANNEL_PREFIX || ''}${channelName}`;

      // avoid same channel subscription multiple times;
      if (pusher && pusher.channels && !pusher.channels.channels[prefixedChannelName]) {
        console.debug(`PusherListener: Subscribing to channel ${prefixedChannelName}`);
        channel = pusher.subscribe(prefixedChannelName);
        Object.entries(bindings).forEach(([eventName, onMessage]) => {
          console.debug(`PusherListener: Binding to event ${eventName} on channel ${channel.name}`);
          channel.bind(eventName, data => {
            console.debug(`PusherListener: received ${eventName} on channel ${channel.name}`);
            onMessage && onMessage(typeof data === 'string' ? JSON.parse(data) : data, channel, eventName);
          });
        });
      }
    }
    return () => {
      // Unbind and unsubscribe channel to prevent state changes on unmounted component
      if (channel && channel.subscribed) {
        console.debug(`PusherListener: Unbinding from events on channel ${channel.name}`);
        channel.unbind();
        console.debug(`PusherListener: Unsubscribing from channel ${channel.name}`);
        channel.unsubscribe();
      }
    };
  }, [channelName, pusher, pusherLoading, bindings]);

  return <></>;

};

PusherListener.propTypes = {
  channelName: PropTypes.string.isRequired,
  bindings: PropTypes.object.isRequired
};

export default PusherListener;
