import React, { FC, useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import { ResponsiveBar } from '@nivo/bar';
import { useAppConfiguration } from '../shared/contexts/AppConfiguration';
import * as _ from 'lodash';
import {
  caseOrTaskMessage,
  latestCaseConfiguration,
  UiMessageKey
} from '../shared/types';
import { toUkDateString } from '../search/MemorableDate';

/**
 * Only here to make things more readable
 */
type Datum = {
  data: Array<object>;
  keys: Array<string>;
};

export interface CaseCreationsByTypeStackedBarChartProps {
  refreshCookie: string;
}

/**
 * A stacked bar chart that tracks the number of cases being created over time, split across case types
 * @param refreshCookie used to force refreshes if required (for example when changing base parameters)
 * @constructor
 */
export const CaseCreationsByTypeStackedBarChart: FC<
  CaseCreationsByTypeStackedBarChartProps
> = ({ refreshCookie }) => {
  const appConfig = useAppConfiguration();
  const [analyticsData, setAnalyticsData] = useState<Datum>({} as Datum);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<string>(refreshCookie);

  // grab the case name, using an optional acronym in order to preserve space
  const caseDisplayName = useCallback(
    (caseRef: string) => {
      const caseConfig = latestCaseConfiguration(appConfig, caseRef);
      return caseOrTaskMessage(caseConfig, UiMessageKey.TitleAcronym) ===
        '!undefined!'
        ? caseOrTaskMessage(caseConfig, UiMessageKey.TitlePlural)
        : caseOrTaskMessage(caseConfig, UiMessageKey.TitleAcronym);
    },
    [appConfig]
  );

  useEffect(() => {
    if (!loaded && appConfig.loaded) {
      const apiUrl = '/api/stats/cases/per-day';
      axios
        .get(apiUrl)
        .then((response) => response.data.data || [])
        .then((results) => {
          let buckets: { [key: string]: { [key: string]: any } } = {};
          results.forEach(
            (result: { date: string; count: number; caseRef: string }) => {
              const dateKey = toUkDateString(result.date) as string;
              if (!_.has(buckets, dateKey)) {
                buckets[dateKey] = { date: dateKey };
              }
              let bucket = buckets[dateKey];
              bucket[caseDisplayName(result.caseRef)] = result.count;
            }
          );

          setAnalyticsData({
            data: _.values(buckets),
            keys: _.uniqBy(
              results,
              (result: { date: string; count: number; caseRef: string }) =>
                result.caseRef
            ).map((value) => caseDisplayName(value.caseRef))
          });
          setLoaded(true);
        })
        .catch(() => setAnalyticsData({} as Datum))
        .finally(() => setIsLoading(false));
    }
    if (refreshCookie !== refresh) {
      setLoaded(false);
      setRefresh(refreshCookie);
    }
  }, [appConfig.loaded, caseDisplayName, loaded, refresh, refreshCookie]);

  return (
    <div
      style={{ height: 300 }}
      className="d-flex align-items-center justify-content-center"
    >
      {isLoading && <Spinner animation={'border'} variant={'primary'} />}
      {!isLoading && !analyticsData && (
        <p className="text-muted text-center">
          <FormattedMessage id="stats.cases.unresolved.age.notfound" />
        </p>
      )}
      {!isLoading && analyticsData && (
        <ResponsiveBar
          data={analyticsData.data}
          keys={analyticsData.keys}
          indexBy="date"
          margin={{ top: 50, right: 5, bottom: 50, left: 50 }}
          padding={0.2}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: -25,
            legend: '',
            legendPosition: 'middle',
            legendOffset: 36
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            format: (y) => Math.floor(y),
            legend: 'Number of cases created',
            legendPosition: 'middle',
            legendOffset: -40
          }}
          colors={{
            scheme: 'accent'
          }}
          defs={[
            {
              id: 'dots',
              type: 'patternDots',
              background: 'inherit',
              color: 'rgba(255, 255, 255, 0.3)',
              size: 4,
              padding: 1,
              stagger: true
            },
            {
              id: 'lines',
              type: 'patternLines',
              background: 'inherit',
              color: 'rgba(255, 255, 255, 0.3)',
              rotation: -45,
              lineWidth: 6,
              spacing: 10
            }
          ]}
          fill={[
            {
              match: {
                id: 'Information Assets'
              },
              id: 'dots'
            },
            {
              match: {
                id: 'Data Flows'
              },
              id: 'lines'
            },
            {
              match: {
                id: 'Signed off'
              },
              id: 'dots'
            }
          ]}
          labelSkipWidth={12}
          labelSkipHeight={12}
          legends={[
            {
              dataFrom: 'keys',
              anchor: 'top-left',
              direction: 'row',
              justify: false,
              translateX: 0,
              translateY: -40,
              itemsSpacing: 24,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: 'left-to-right',
              itemOpacity: 0.85,
              symbolSize: 20
            }
          ]}
        />
      )}
    </div>
  );
};
