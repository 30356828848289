import React, { useState } from 'react';
import { parseAxiosError } from '../shared/AxiosResponseErrorParser';
import axios from 'axios';
import ListLoader from '../loaders/ListLoader';
import CardCollapsible from '../shared/CardCollapsible';
import { FormForSchema } from '@lookinglocal/react-jsonschema-form-extensions';
import * as AppWidgets from '../form/widgets/AppWidgets';

export const schema = {
  type: 'object',
  properties: {
    files: {
      title: 'Files to attach',
      type: 'array',
      items: {
        type: 'string'
      },
      minItems: 0
    },
    text: {
      title: 'Comment',
      type: 'string'
    }
  },
  required: ['text']
};

export const uiSchema = {
  text: {
    'ui:widget': 'textarea'
  },
  files: {
    'ui:widget': 'files'
  }
};

function TaskCommentsAttachments(props) {
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const save = (formData) => {
    setSuccess(false);
    setLoading(true);
    axios
      .post(`/api/task/${props.taskId}/comment`, formData)
      .then(() => {
        setLoading(false);
        setSuccess(true);
        props.reload();
      })
      .catch((e) => {
        setLoading(false);
        setSuccess(false);
        setErrorMessage(parseAxiosError(e).getMessage());
      });
  };

  return (
    <CardCollapsible
      open={true}
      title={'Add Comments / Attach file(s)'}
      isOpen="true"
      className={'mb-4'}
    >
      {errorMessage && (
        <div className="alert alert-danger mb-4" data-testid="server-error">
          {errorMessage}
        </div>
      )}
      {loading && <ListLoader />}
      {!loading && (
        <FormForSchema
          appWidgets={AppWidgets.appWidgets}
          schema={schema}
          uiSchema={uiSchema}
          onSubmit={save}
          isSubmitVisible={true}
        />
      )}
      {success && (
        <div data-testid="success" className="text-success float-left">
          <strong>&#10003; Added</strong>
        </div>
      )}
    </CardCollapsible>
  );
}

export default TaskCommentsAttachments;
