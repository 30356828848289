import { Component } from "react";

/**
 * The props for the progress bar
 */
type CircularProgressBarProps = {
  strokeWidth: number;
  width: number;
  height: number;
  percentage: number;
};

/**
 * Circular progress bar Component
 */
export class CircularProgressBar extends Component<CircularProgressBarProps> {
  constructor(props: CircularProgressBarProps) {
    super(props);
    this.state = {};
  }

  render() {
    const width = this.props.width;
    const height = this.props.height;
    const radius = (this.props.width - this.props.strokeWidth) / 2;
    const viewBox = `0 0 ${width} ${height}`;
    const dashArray = radius * Math.PI * 2;
    const dashOffset = dashArray - (dashArray * this.props.percentage) / 100;

    return (
      <svg
        width={this.props.width}
        height={this.props.height}
        viewBox={viewBox}
      >
        <circle
          className="circle-background"
          cx={this.props.width / 2}
          cy={this.props.width / 2}
          r={radius}
          strokeWidth={`${this.props.strokeWidth}px`}
        />
        <circle
          className="circle-progress"
          cx={this.props.width / 2}
          cy={this.props.width / 2}
          r={radius}
          strokeWidth={`${this.props.strokeWidth}px`}
          transform={`rotate(-90 ${this.props.width / 2} ${
            this.props.width / 2
          })`}
          style={{
            strokeDasharray: dashArray,
            strokeDashoffset: dashOffset,
          }}
        />
        <text
          className="circle-text"
          x="50%"
          y="50%"
          dy=".3em"
          textAnchor="middle"
        >
          {`${this.props.percentage}%`}
        </text>
      </svg>
    );
  }
};

export default CircularProgressBar;
