import React, { FC } from 'react';
import { isValid } from '../form/utils';
import {ColourDot} from "../shared/components/ColourDot";

const TaskStatusSchema = {
  type: 'object',
  additionalProperties: {
    type: 'object',
    properties: {
      name: {
        type: 'string'
      },
      title: {
        type: 'string'
      },
      description: {
        type: 'string'
      },
      editable: {
        type: 'boolean'
      }
    },
    required: ['name', 'title', 'description', 'editable']
  }
};

export const TaskStatus = {
  OPEN: {
    name: 'OPEN',
    title: 'Open',
    description: 'Ready to be completed',
    editable: true,
    colour: 'rgb(149,178,44)'
  },
  STOPPED: {
    name: 'STOPPED',
    title: 'Stopped',
    description: 'Progress was stopped, cannot finish yet',
    editable: true,
    colour: 'rgb(198, 0, 0)'
  },
  DONE: {
    name: 'DONE',
    title: 'Done',
    description: 'Form was filled and task is complete',
    editable: false,
    colour: 'rgb(198, 198, 198)'
  }
};

if (!isValid(TaskStatusSchema, TaskStatus)) {
  throw new Error('TaskStatus does not validate against TaskStatusSchema');
}

export interface TaskStatusBadgeProps {
  status: 'OPEN' | 'STOPPED' | 'DONE';
  className: string;
}

export const TaskStatusBadge: FC<TaskStatusBadgeProps> = ({
  status,
  className,
  ...rest
}) => {
  const taskStatus = TaskStatus[status] || {};
  return (
    <>
      <ColourDot colour={taskStatus?.colour} />
      <span
        className={`badge badge-light ${className}`}
        title={taskStatus.description}
        {...rest}
      >
        {taskStatus.title}
      </span>
    </>
  );
};
