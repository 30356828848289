import React from 'react';
import PropTypes from 'prop-types';
import ReassignTaskFlow from '../flows/ReassignTaskFlowComponent';
import ModelInstanceView from '../models/ModelInstanceView';

const ReassignTaskFlowModal = (props) => {
  return (<ModelInstanceView floating={true} apiUrl={`/api/profile`} viewItem={ReassignTaskFlow} {...props}/>);
};

ReassignTaskFlowModal.propTypes = {
  onModalClose: PropTypes.func.isRequired,
};

export default ReassignTaskFlowModal;
