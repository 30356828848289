import React, { useEffect, useState } from 'react';
import {
  CommonDate,
  CommonDateTime,
  IfBeforeToday,
  IfToday
} from '../shared/Dates';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { WithPermission } from '../auth/AccessConditionals';
import { CaseStatusBadge } from './status/CaseStatus';
import PresenceList from '../pusher/PresenceList';
import ModelListView from '../models/ModelListView';
import { PusherCaseChannelListener } from '../pusher/PusherChannelListeners';
import { Page, PageNav, PageSubNav } from '../shared/Page';
import ChangeCasePriorityModal from './ChangeCasePriorityModal';
import { CasePriorityText } from './CasePriority';
import CaseLabelComponent from '../case/CaseLabelComponent';
import { flavour } from '../config';
import { FormattedMessage, useIntl } from 'react-intl';
import CaseStatusChangeModal from './CaseStatusChangeModal';
import CaseReviewChangeModal from './CaseReviewChangeModal';
import CaseCommentAddModal from './CaseCommentAddModel';
import CaseUnlockModal from './CaseUnlockModal';
import CloneCaseFlowModal from './CloneCaseFlowModal';
import AddCaseTaskFlowModal from './AddCaseTaskFlowModal';
import CaseDetailsChangeModal from './CaseDetailsChangeModal';
import { ProfileLink } from '../profile/Profile';
import ToastCard from '../shared/ToastCard';
import { toast } from 'react-toastify';
import CaseSearchableEditorModal from './CaseSearchableEditorModal';
import { CaseComments } from './CaseComments';
import { Question } from '../main/icons';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import {
  caseConfigurationVersion,
  caseOrTaskMessage,
  caseStatusesByCaseVersion,
  caseStatusFromId,
  UiMessageKey
} from '../shared/types';
import { CaseSummary } from './summary/CaseSummary';
import { CaseTaskListItem } from './CaseTaskListItem';
import { useAppConfiguration } from '../shared/contexts/AppConfiguration';
import { RelatedCases } from './related/RelatedCases';
import { newUUID } from '../shared/functions';
import { useDynamicDocumentTitle } from '../shared/hooks/DocumentTitleHooks';
import { ReferencedByTasksCard } from './related/ReferencedByTasks';
import { CaseSearchModal } from '../search/components/CaseSearchModal';
import axios from 'axios';
import { useExternalSources } from '../shared/hooks/ExternalSearchHooks';
import CaseFilesAttachModal from './CaseFilesAttachModal';

// TODO all references to flavour hard-wired components need to be removed at some point in the future
const { CaseDetails } = flavour.components;

export const CaseDetailsPage = ({ model, state, reload }) => {
  const intl = useIntl();
  const appConfig = useAppConfiguration();
  const [caseConfig, setCaseConfig] = useState(undefined);
  const [searchable, setSearchable] = useState(model.searchable);
  const [casePriority, setCasePriority] = useState(model.priority);
  const [nextReviewOn, setNextReviewOn] = useState(model.nextReviewOn);
  const [timestamp, setTimestamp] = useState(new Date().getTime());
  const [commentTimestamp, setCommentTimestamp] = useState(
    new Date().getTime()
  );
  const [requestStatus, setRequestStatus] = useState(model.status);
  const [caseStatus, setCaseStatus] = useState(undefined);
  const [showChangePriorityModal, setShowChangePriorityModal] = useState(false);
  const [showCaseStatusChangeModal, setShowCaseStatusChangeModal] =
    useState(false);
  const [showCaseUnlockModal, setShowCaseUnlockModal] = useState(false);
  const [showCaseReviewChangeModal, setShowCaseReviewChangeModal] =
    useState(false);
  const [showCloneCaseModal, setShowCloneCaseModal] = useState(false);
  const [showAddCaseTaskModal, setShowAddCaseTaskModal] = useState(false);
  const [showEditCaseDetailsModal, setShowEditCaseDetailsModal] =
    useState(false);
  const [showCaseSearchableEditorModal, setShowCaseSearchableEditorModal] =
    useState(false);
  const [showCaseLinkModal, setShowCaseLinkModal] = useState(false);
  const [showCaseCommentAddModal, setShowCaseCommentAddModal] = useState(false);
  const [showCaseAttachmentAddModal, setShowCaseAttachmentAddModal] =
    useState(false);
  const [hasTaskForms, setHasTaskForms] = useState(false);
  const [caseModel, setCase] = useState(model);
  const [caseForm, setCaseForm] = useState(model.details);
  const [relatedCaseCookie, setRelatedCaseCookie] = useState(newUUID());
  const [haveExternal] = useExternalSources();

  // Helper function to make an out-call to link a related case
  const linkRelatedCase = (item) =>
    axios
      .post(`/api/links/case/${model.id}/cases/${item.id}`)
      .then(() => {
        setRelatedCaseCookie(newUUID());
      })
      .catch((e) => {
        console.error(
          `Failed to link a related case: ${model.id} -> ${item.id}`
        );
      });

  // we use an effect dependent on the case form data to ensure that
  // we can force updates to components if the form is edited
  useEffect(() => {
    const newModel = {
      ...model,
      details: caseForm
    };
    setCase(newModel);
  }, [caseForm, model]);

  useEffect(() => {
    if (appConfig.loaded && caseConfig === undefined) {
      const caseConfig = caseConfigurationVersion(
        appConfig,
        model.caseRef,
        model.caseVersion
      );
      const statuses = caseStatusesByCaseVersion(
        appConfig,
        model.caseRef,
        model.caseVersion
      );
      const status = caseStatusFromId(model.status, statuses);
      setCaseConfig(caseConfig);
      setHasTaskForms(
        caseConfig.meta.tasks !== null && caseConfig.meta.tasks.length > 0
      );
      setCaseStatus(status);
    }
  }, [
    appConfig,
    caseStatus,
    caseConfig,
    model.caseRef,
    model.caseVersion,
    model.status
  ]);

  useDynamicDocumentTitle(() => {
    return `${caseOrTaskMessage(caseConfig, UiMessageKey.TitleSingular)} #${model.id
      }`;
  });

  return (
    <Page>
      <PageNav
        title={
          caseOrTaskMessage(caseConfig, UiMessageKey.TitleSingular) +
          ' #' +
          model.id
        }
      >
        <span>
          <PresenceList channelName={`case-${model.id}`} />
        </span>

        {!state.editable && hasTaskForms && (
          <WithPermission name="role:admin" instance={model}>
            <button
              onClick={() => setShowCaseUnlockModal(true)}
              className="btn btn-sm btn-danger mr-1"
            >
              <FormattedMessage id="nav.cases.unlock" />
            </button>
          </WithPermission>
        )}

        {hasTaskForms && (
          <WithPermission name="case:clone" instance={model}>
            <button
              onClick={() => setShowCloneCaseModal(true)}
              className="btn btn-sm btn-outline-secondary mr-1"
            >
              {`Clone ${caseOrTaskMessage(
                caseConfig,
                UiMessageKey.TitleSingular
              )}`}
            </button>
          </WithPermission>
        )}

        {hasTaskForms && caseStatus && !caseStatus.terminating && (
          <button
            onClick={() => setShowAddCaseTaskModal(true)}
            className="btn btn-sm btn-outline-secondary mr-1"
          >
            {`New Task`}
          </button>
        )}
      </PageNav>

      {caseConfig && caseStatus && state.editable && (
        <WithPermission name="case:update" instance={model}>
          <PageSubNav>
            {!caseStatus.terminating && (
              <button
                onClick={() => setShowEditCaseDetailsModal(true)}
                className="btn btn-sm btn-outline-secondary mr-1"
              >
                {`Edit ${caseOrTaskMessage(
                  caseConfig,
                  UiMessageKey.TitleSingular
                )}`}
              </button>
            )}
            <button
              onClick={() => setShowCaseStatusChangeModal(true)}
              className="btn btn-sm btn-outline-secondary mr-1"
            >
              {`Change ${caseOrTaskMessage(
                caseConfig,
                UiMessageKey.TitleSingularShort
              )} status`}
            </button>
            <button
              onClick={() => setShowCaseCommentAddModal(true)}
              className="btn btn-sm btn-outline-secondary mr-1"
            >
              <FormattedMessage id="nav.cases.comment.add" />
            </button>
            <button
              onClick={() => setShowCaseAttachmentAddModal(true)}
              className="btn btn-sm btn-outline-secondary mr-1"
            >
              Attach file
            </button>
            <button
              onClick={() => setShowCaseReviewChangeModal(true)}
              className="btn btn-sm btn-outline-secondary mr-1"
            >
              <FormattedMessage id="nav.cases.review.edit" />
            </button>
            <button
              onClick={() => setShowChangePriorityModal(true)}
              className="btn btn-sm btn-outline-secondary mr-1"
            >
              {`Change ${caseOrTaskMessage(
                caseConfig,
                UiMessageKey.TitleSingularShort
              )} priority`}
            </button>
            <button
              onClick={() => setShowCaseLinkModal(true)}
              className="btn btn-sm btn-outline-secondary mr-1"
            >
              {`Link related case`}
            </button>
          </PageSubNav>
        </WithPermission>
      )}

      <div className="card mb-4">
        <div className="card-body">
          <div className="row">
            <div className="col-sm-6">
              <dl className="row mb-md-0">
                <dt className="col-sm-4">
                  <FormattedMessage id="model.case.id" />
                </dt>
                <dd className="col-sm-8">{model.id}</dd>

                <dt className="col-sm-4">
                  <FormattedMessage id="model.case.status" />
                </dt>
                <dd className="col-sm-8">
                  <CaseStatusBadge
                    status={requestStatus}
                    withDescription={true}
                    withLock={true}
                  />
                </dd>

                <dt className="col-sm-4">
                  <FormattedMessage id="model.case.priority" />
                </dt>
                <dd className="col-sm-8">
                  {state.editable && (
                    <WithPermission name="case:update" instance={model}>
                      <button
                        onClick={() => setShowChangePriorityModal(true)}
                        className="btn btn-sm btn-outline-secondary"
                      >
                        <CasePriorityText priority={casePriority} />
                      </button>
                    </WithPermission>
                  )}
                  {state.editable && (
                    <WithPermission
                      name="case:update"
                      instance={model}
                      inverse={true}
                    >
                      <CasePriorityText priority={casePriority} />
                    </WithPermission>
                  )}
                  {!state.editable && (
                    <CasePriorityText priority={casePriority} />
                  )}
                </dd>

                <dt className="col-sm-4">
                  {caseOrTaskMessage(caseConfig, UiMessageKey.TitleSingular)}
                  <span className="font-weight-normal">
                    {' '}
                    (
                    <Link
                      to={`#`}
                      role="button"
                      onClick={() => setShowEditCaseDetailsModal(true)}
                    >
                      Edit
                    </Link>
                    )
                  </span>
                </dt>
                <div className="col-sm-8 mb-3">
                  {caseConfig && (
                    <CaseSummary
                      model={caseModel}
                      caseConfig={caseConfig}
                      defaultComponent={
                        <CaseDetails model={caseModel.details} />
                      }
                    />
                  )}
                </div>

                {caseConfig &&
                  caseConfig.meta.labels !== null &&
                  caseConfig.meta.labels.length > 0 && (
                    <>
                      <dt className="col-sm-4">
                        <FormattedMessage id="model.case.labels" />
                      </dt>
                      <dd className="col-sm-8">
                        <CaseLabelComponent
                          caseId={model.id}
                          caseConfig={caseConfig}
                        />
                      </dd>
                    </>
                  )}
              </dl>
            </div>
            <div className="col-sm-6">
              <dl className="row mb-md-0">
                <dt className="col-sm-4">
                  <FormattedMessage id="model.case.creator" />
                </dt>
                <dd className="col-sm-8">
                  <ProfileLink
                    id={model.creator.id}
                    label={model.creator.displayName}
                  />
                </dd>

                <dt className="col-sm-4">
                  <FormattedMessage id="model.case.createdAt" />
                </dt>
                <dd className="col-sm-8">
                  <CommonDateTime date={model.createdAt} withCasual={true} />
                </dd>

                <dt className="col-sm-4">
                  <FormattedMessage id="model.case.updatedAt" />
                </dt>
                <dd className="col-sm-8">
                  <CommonDateTime date={model.updatedAt} withCasual={true} />
                </dd>

                <dt className="col-sm-4">
                  <FormattedMessage id="model.case.nextReviewOn" />
                </dt>
                <dd className="col-sm-8">
                  <WithPermission name="case:update" instance={model}>
                    <button
                      onClick={() => setShowCaseReviewChangeModal(true)}
                      className="btn btn-sm btn-outline-secondary"
                    >
                      <CommonDate date={nextReviewOn} fallback={'Not set'} />
                      <IfToday date={nextReviewOn}>
                        <span className="ml-2 badge badge-warning">
                          Due for review
                        </span>
                      </IfToday>
                      <IfBeforeToday date={nextReviewOn}>
                        <span className="ml-2 badge badge-danger">
                          Due for review
                        </span>
                      </IfBeforeToday>
                    </button>
                  </WithPermission>
                  <WithPermission
                    name="case:update"
                    instance={model}
                    inverse={true}
                  >
                    <CommonDate date={nextReviewOn} fallback={'Not set'} />
                    <IfToday date={nextReviewOn}>
                      <span className="ml-2 badge badge-warning">
                        Due for review
                      </span>
                    </IfToday>
                    <IfBeforeToday date={nextReviewOn}>
                      <span className="ml-2 badge badge-danger">
                        Due for review
                      </span>
                    </IfBeforeToday>
                  </WithPermission>

                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip tabIndex={0} id="tooltip-help">
                        <FormattedMessage id="model.notification.EMAIL_CASES_DUE_FOR_REVIEW.help" />
                      </Tooltip>
                    }
                  >
                    <button
                      role="tooltip"
                      className="btn ml-2 p-0"
                      aria-label="Help"
                    >
                      <Question />
                    </button>
                  </OverlayTrigger>
                </dd>

                {haveExternal && (
                  <>
                    <dt className="col-sm-4">
                      <FormattedMessage id="model.case.searchable" />
                    </dt>
                    <dd className="col-sm-8">
                      <WithPermission name="case:update" instance={model}>
                        <button
                          onClick={() => setShowCaseSearchableEditorModal(true)}
                          className="btn btn-sm btn-outline-secondary"
                        >
                          {searchable ? 'Yes' : 'No'}
                        </button>
                      </WithPermission>
                      <WithPermission
                        inverse={true}
                        name="case:update"
                        instance={model}
                      >
                        {searchable ? 'Yes' : 'No'}
                      </WithPermission>
                    </dd>
                  </>
                )}

                <dt className="col-sm-4">
                  <FormattedMessage id="model.case.version" />
                </dt>
                <dd className="col-sm-8">
                  {model.version} (
                  <Link to={`/cases/show/${model.id}/history`}>History</Link>)
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </div>

      {hasTaskForms && (
        <div className="row">
          <div className="col-sm-8">
            <div className="card">
              <div className="card-header pl-3 pr-2">
                <h2>Tasks</h2>
              </div>
              <ModelListView
                apiUrl={`/api/case/${model.id}/task?t=${timestamp}`}
                caseState={state}
                reload={setTimestamp}
                listItemRenderer={CaseTaskListItem}
                notFoundMessage={intl.formatMessage({
                  id: 'nav.tasks.notfound'
                })}
              />
            </div>
          </div>
          <div className="col-sm-4">
            <RelatedCases
              parentCaseId={model.id}
              reloadCookie={relatedCaseCookie}
            />
            <ReferencedByTasksCard
              parentCaseId={model.id}
              reloadCookie={relatedCaseCookie}
            />
            <CaseComments caseId={model.id} timestamp={commentTimestamp} />
          </div>
        </div>
      )}

      {!hasTaskForms && (
        <div className="row">
          <div className="col-sm-12">
            <RelatedCases
              parentCaseId={model.id}
              reloadCookie={relatedCaseCookie}
            />
            <CaseComments caseId={model.id} timestamp={commentTimestamp} />
          </div>
        </div>
      )}

      <PusherCaseChannelListener caseId={model.id} showNotifications={true} />

      {showChangePriorityModal && (
        <ChangeCasePriorityModal
          modalTitle={`Change ${caseOrTaskMessage(
            caseConfig,
            UiMessageKey.TitleSingularShort
          )} priority`}
          caseId={model.id}
          priority={casePriority}
          onModalClose={(value) => {
            setCasePriority(value);
            model.priority = value;
            setShowChangePriorityModal(false);
          }}
        />
      )}

      {caseConfig && showCaseStatusChangeModal && (
        <CaseStatusChangeModal
          modalTitle={`Change ${caseOrTaskMessage(
            caseConfig,
            UiMessageKey.TitleSingular
          )} status`}
          model={model}
          onModalClose={(value) => {
            if (value) {
              setRequestStatus(value);
              setTimestamp(new Date().getTime());
              reload(timestamp);
            }
            setShowCaseStatusChangeModal(false);
          }}
        />
      )}

      {showCaseUnlockModal && (
        <CaseUnlockModal
          modalTitle={`Unlock ${caseOrTaskMessage(
            caseConfig,
            UiMessageKey.TitleSingular
          )}`}
          model={model}
          onModalClose={(value) => {
            if (value) {
              setRequestStatus(value);
              setTimestamp(new Date().getTime());
              reload(timestamp);
            }
            setShowCaseUnlockModal(false);
          }}
        />
      )}

      {showCaseCommentAddModal && (
        <CaseCommentAddModal
          modalTitle={'Add comment'}
          caseId={model.id}
          onModalClose={(value) => {
            if (value) {
              setCommentTimestamp(new Date().getTime());
            }
            setShowCaseCommentAddModal(false);
          }}
        />
      )}

      {showCaseAttachmentAddModal && (
        <CaseFilesAttachModal
          modalTitle={'Attach file'}
          caseId={model.id}
          onModalClose={(value) => {
            if (value) {
              setCommentTimestamp(new Date().getTime());
            }
            setShowCaseAttachmentAddModal(false);
          }}
        />
      )}

      {showCaseReviewChangeModal && (
        <CaseReviewChangeModal
          modalTitle={intl.formatMessage({ id: 'nav.cases.review.edit' })}
          caseId={model.id}
          currentReviewDate={nextReviewOn || ''}
          onModalClose={(value) => {
            if (value !== null) setNextReviewOn(value);
            setShowCaseReviewChangeModal(false);
          }}
        />
      )}

      {showCloneCaseModal && (
        <CloneCaseFlowModal
          modalTitle={`Clone ${caseOrTaskMessage(
            caseConfig,
            UiMessageKey.TitleSingular
          )}`}
          caseId={model.id}
          onModalClose={(value) => {
            if (value) {
              const toastCardProps = {
                title: 'Success',
                sections: [
                  <>
                    {intl.formatMessage({ id: 'model.case' })} created
                    successfully!
                  </>
                ],
                callToAction: {
                  route: `/cases/show/${value}`,
                  title: 'View'
                }
              };

              toast(<ToastCard {...toastCardProps} />, {
                autoClose: false,
                closeOnClick: true,
                closeButton: false
              });
            }
            setShowCloneCaseModal(false);
          }}
        />
      )}

      {showAddCaseTaskModal && (
        <AddCaseTaskFlowModal
          modalTitle={`New ${caseOrTaskMessage(
            caseConfig,
            UiMessageKey.TitleSingular
          )} Task`}
          caseId={model.id}
          onModalClose={(value) => {
            if (value) setTimestamp(new Date().getTime());
            setShowAddCaseTaskModal(false);
          }}
        />
      )}

      {showEditCaseDetailsModal && (
        <CaseDetailsChangeModal
          modalTitle={`Edit ${caseOrTaskMessage(
            caseConfig,
            UiMessageKey.TitleSingular
          )}`}
          caseId={model.id}
          caseConfig={caseConfig}
          onModalClose={(value) => {
            if (value) {
              setCaseForm(value);
            }
            setShowEditCaseDetailsModal(false);
          }}
        />
      )}

      {showCaseSearchableEditorModal && (
        <CaseSearchableEditorModal
          modalTitle={`Edit ${caseOrTaskMessage(
            caseConfig,
            UiMessageKey.TitleSingular
          )}`}
          caseId={model.id}
          currentlySearchable={searchable}
          onModalClose={(value) => {
            if (value != null) setSearchable(value === true);
            setShowCaseSearchableEditorModal(false);
          }}
        />
      )}

      <CaseSearchModal
        show={showCaseLinkModal}
        title={'Search for related case'}
        onModalClose={(cancelled, item) => {
          if (!cancelled) {
            console.log(`Linking case with id: ${item.id}`);
            linkRelatedCase(item).then(() => {
              setShowCaseLinkModal(false);
            });
          } else {
            setShowCaseLinkModal(false);
          }
        }}
      />
    </Page>
  );
};

CaseDetailsPage.propTypes = {
  model: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired
};
