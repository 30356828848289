import React, { FC, useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import { FormattedMessage } from 'react-intl';
import {
  caseOrTaskMessage,
  CaseReviewStatus,
  CaseReviewStatusLabel,
  latestCaseConfiguration,
  UiMessageKey
} from '../shared/types';
import { ResponsiveBar } from '@nivo/bar';
import _ from 'lodash';
import { useAppConfiguration } from '../shared/contexts/AppConfiguration';

/**
 * Only here to make things more readable
 */
type Datum = {
  data: Array<object>;
  keys: Array<string>;
};

export interface CasesDueForReviewStackedBarChartProps {
  refreshCookie: string;
}

export const CasesDueForReviewStackedBarChart: FC<
  CasesDueForReviewStackedBarChartProps
> = ({ refreshCookie }) => {
  const appConfig = useAppConfiguration();
  const [analyticsData, setAnalyticsData] = useState<Datum>({} as Datum);
  const [isLoading, setIsLoading] = useState(true);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<string>(refreshCookie);

  // grab the case name, using an optional acronym in order to preserve space
  const caseDisplayName = useCallback(
    (caseRef: string) => {
      const caseConfig = latestCaseConfiguration(appConfig, caseRef);
      return caseOrTaskMessage(caseConfig, UiMessageKey.TitleAcronym) ===
        '!undefined!'
        ? caseOrTaskMessage(caseConfig, UiMessageKey.TitlePlural)
        : caseOrTaskMessage(caseConfig, UiMessageKey.TitleAcronym);
    },
    [appConfig]
  );

  useEffect(() => {
    if (!loaded && appConfig.loaded) {
      const apiUrl = `/api/stats/cases/review`;
      axios
        .get(apiUrl)
        .then((response) => response.data.data || [])
        .then((results) => {
          let buckets: { [key: string]: { [key: string]: any } } = {};
          results.forEach(
            (result: {
              review: CaseReviewStatus | string;
              count: number;
              caseRef: string;
            }) => {
              const caseTypeKey = caseDisplayName(result.caseRef) as string;
              if (!_.has(buckets, caseTypeKey)) {
                buckets[caseTypeKey] = { caseType: caseTypeKey };
              }
              let bucket = buckets[caseTypeKey];
              bucket[CaseReviewStatusLabel(result.review)] = result.count;
            }
          );

          setAnalyticsData({
            data: _.values(buckets),
            keys: _.uniqBy(
              results,
              (result: {
                review: CaseReviewStatus | string;
                count: number;
                caseRef: string;
              }) => result.review
            ).map((value) => CaseReviewStatusLabel(value.review))
          });
          setLoaded(true);
        })
        .catch(() => setAnalyticsData({} as Datum))
        .finally(() => setIsLoading(false));
    }
    if (refreshCookie !== refresh) {
      setLoaded(false);
      setRefresh(refreshCookie);
    }
  }, [appConfig.loaded, caseDisplayName, loaded, refresh, refreshCookie]);

  return (
    <div
      style={{ height: 300 }}
      className="d-flex align-items-center justify-content-center"
    >
      {isLoading && <Spinner animation={'border'} variant={'primary'} />}
      {!isLoading && analyticsData.data.length === 0 && (
        <p className="text-muted text-center">
          <FormattedMessage id="stats.cases.review.notfound" />
        </p>
      )}
      {!isLoading && analyticsData.data.length > 0 && (
        <ResponsiveBar
          data={analyticsData.data}
          keys={analyticsData.keys}
          indexBy="caseType"
          margin={{ top: 50, right: 5, bottom: 50, left: 50 }}
          padding={0.2}
          axisTop={null}
          axisRight={null}
          colors={{ scheme: 'category10' }}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Case Type',
            legendPosition: 'middle',
            legendOffset: 40
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            format: (y) => Math.floor(y),
            legend: 'Count',
            legendPosition: 'middle',
            legendOffset: -40
          }}
          defs={[
            {
              id: 'dots',
              type: 'patternDots',
              background: 'inherit',
              color: 'rgba(255, 255, 255, 0.3)',
              size: 4,
              padding: 1,
              stagger: true
            },
            {
              id: 'lines',
              type: 'patternLines',
              background: 'inherit',
              color: 'rgba(255, 255, 255, 0.3)',
              rotation: -45,
              lineWidth: 6,
              spacing: 10
            }
          ]}
          fill={[
            {
              match: {
                id: 'Due'
              },
              id: 'dots'
            },
            {
              match: {
                id: 'Review not set'
              },
              id: 'lines'
            },
            {
              match: {
                id: 'Signed off'
              },
              id: 'dots'
            }
          ]}
          labelSkipWidth={12}
          labelSkipHeight={12}
          legends={[
            {
              dataFrom: 'keys',
              anchor: 'top-left',
              direction: 'row',
              justify: false,
              translateX: 0,
              translateY: -40,
              itemsSpacing: 24,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: 'left-to-right',
              itemOpacity: 0.85,
              symbolSize: 20
            }
          ]}
        />
      )}
    </div>
  );
};
