import React, { FC, useState, Children, useEffect } from 'react';
import { useCollapse } from 'react-collapsed';
import { GeneralIcons } from './Icons';
import { newUUID } from '../functions';

export interface CollapsibleProps {
  index?: number,
  children?: React.ReactNode;
  expandedTitle: string;
  collapsedTitle: string;
  expanded?: boolean;
  showCount?: boolean;
  floatRight?: boolean;
  onExpandOrCollapse?: (expanded : boolean, index? : number) => void
}

/**
 * Generic collapsible function component
 * @constructor
 * @param props
 */
export const Collapsible: FC<CollapsibleProps> = (
  props
) => {
  const [isExpanded, setExpanded] = useState(props.expanded);
  const { getCollapseProps, getToggleProps } = useCollapse({isExpanded, id:newUUID()});

  function handleOnClick() {
    setExpanded(!isExpanded);
    if (props.onExpandOrCollapse){
      props.onExpandOrCollapse(!isExpanded, props.index);
    }
  }

  useEffect(() => {
    setExpanded(props.expanded);
  }, [props.expanded]);

  return (
    <>
      <div className="collapsible nav-link flex-sm-column ">
        <div className="collapsible-header">
          <a
            className="header "
            {...getToggleProps({ onClick: handleOnClick })}
          >
            <div className="title">
              {isExpanded ? props.expandedTitle : props.collapsedTitle}
              {props.showCount ? `  (${Children.count(props.children)})` : ''}
            </div>
            <div className={'caret'}>
              {!isExpanded ? (
                <>{GeneralIcons.SquareCaretDown('')}</>
              ) : (
                <>{GeneralIcons.SquareCaretUp('')}</>
              )}
            </div>
          </a>
        </div>
        <div {...getCollapseProps()} className="flex-row">
          <div className="content">{props.children}</div>
        </div>
      </div>
    </>
  );
};
