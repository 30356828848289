import { useEffect, useState } from 'react';
import { PriorityHigh, PriorityMedium, PriorityLow } from '../main/icons';

export const CasePriority = [
  {
    value: 1,
    label: 'High',
    icon: <PriorityHigh height={14} />
  },
  {
    value: 0,
    label: 'Medium',
    icon: <PriorityMedium height={14} />
  },
  {
    value: -1,
    label: 'Low',
    icon: <PriorityLow height={14} />
  }
];

export const CasePriorityText = ({ priority, ...rest }) => {
  const [casePriority, setCasePriority] = useState();

  useEffect(() => {
    setCasePriority((prev) => CasePriority.find((p) => p.value === priority));
  }, [priority]);

  if (casePriority)
    return (
      <span {...rest}>
        {casePriority.icon}&nbsp;{casePriority.label}
      </span>
    );
  else return <span {...rest}>{priority}</span>;
};
