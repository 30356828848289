import React, {FC} from "react";

/**
 * Props for a details badge
 */
export interface CaseDetailsRowProps {
  label : string,
  value : string,
  className?: string
}

export const CaseDetailsRow : FC<CaseDetailsRowProps> = (props) => {
  return (
    <div key={props.label}  className={props.className ? props.className : "details-row"} >
      <div data-testid='label' className={'row-label'}>{props.label}:</div>
      <div data-testid='value' className={'row-value'}>{props.value}</div>
    </div>
  );
};
