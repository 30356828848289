import React from 'react';
import PropTypes from 'prop-types';

function ProfileRolesCard ({ model }) {

  return (<div className="card mb-4">
    <div className="card-header">
      <h2>Roles</h2>
    </div>
    <ul className="list-group list-group-flush">
      {model.flavourRoles.map(role =>
        <li key={role.authority} className="list-group-item">{role.label} - {role.description}</li>)}
    </ul>
    {!model.flavourRoles.length && <div className="card-body">This profile does not have any roles.</div>}
  </div>);
}

ProfileRolesCard.propTypes = {
  model: PropTypes.shape({
    flavourRoles: PropTypes.arrayOf(PropTypes.shape({
      authority: PropTypes.string,
      label: PropTypes.string,
      description: PropTypes.string
    }))
  })
};

export default ProfileRolesCard;
