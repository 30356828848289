import React from 'react';
import TaskDetails from './TaskDetails';
import ModelInstanceView from '../models/ModelInstanceView';

const TaskShowPage = (props) => {
  const { taskId } = props.match.params;
  return <ModelInstanceView apiUrl={`/api/task/${taskId}`} viewItem={TaskDetails} {...props}/>;
};

export default TaskShowPage;
