import {v4} from 'uuid';
/**
 * Wrapper around UUID generation.  Only wrapped in case we hit issues
 * with browser compatibility
 */
export function newUUID() : string {
  return v4();
}

/**
 * Compute a simple hash (non-secure) value based on a given string
 * @param value
 */
export function simpleHash(value : string) : string {
  let hash = 0;
  for (let i = 0; i < value.length; i++) {
    const char = value.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash &= hash; // Convert to 32bit integer
  }
  return new Uint32Array([hash])[0].toString(36);
}
