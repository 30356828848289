import {
  AppFeatureFlag,
  CaseConfiguration,
  CaseFeatureFlag,
  CaseStatus,
  DisplaySpecification,
  TaskConfiguration
} from './Config';
import { capitalCase, lowerCase } from 'text-case';
import { AppConfiguration } from '../contexts/AppConfiguration';
import {
  dateTimeRenderer,
  dynamicRenderer,
  rawRenderer,
  trueFalseBooleanRenderer,
  valueByJsonPointerPath,
  ValueRenderingFunction,
  yesNoBooleanRenderer
} from '../functions';
import _ from 'lodash';
import { caseOrTaskMessage, UiMessageKey } from './Messages';

/**
 * Return a specific {@link CaseConfiguration} version
 * @param appConfiguration
 * @param caseRef
 * @param caseVersion
 */
export function caseConfigurationVersion(
  appConfiguration: AppConfiguration,
  caseRef: string,
  caseVersion: number
): CaseConfiguration {
  return appConfiguration.caseConfigurations.find(
    (config) => config.ref === caseRef && config.version === caseVersion
  )!;
}

/**
 * Generates an array of task configurations based on the given application configuration and case configuration.
 *
 * @param {AppConfiguration} appConfig - The application configuration.
 * @param {CaseConfiguration} caseConfig - The case configuration.
 *
 * @return {TaskConfiguration[]} - An array of task configurations.
 */
export function caseTaskConfigurations(
  appConfig: AppConfiguration,
  caseConfig: CaseConfiguration
): TaskConfiguration[] {
  if (caseConfig.meta.tasks !== null) {
    return caseConfig.meta.tasks.map((caseTask) => {
      return taskConfigurationVersion(
        appConfig,
        caseTask.ref,
        caseTask.version
      );
    });
  } else {
    return [];
  }
}

/**
 * Return a specific {@link TaskConfiguration} version
 * @param appConfiguration
 * @param taskRef
 * @param taskVersion
 */
export function taskConfigurationVersion(
  appConfiguration: AppConfiguration,
  taskRef: string,
  taskVersion: number
): TaskConfiguration {
  return appConfiguration.taskConfigurations.find(
    (config) => config.ref === taskRef && config.version === taskVersion
  )!;
}

/**
 * Returns the latest {@link CaseConfiguration} for a given case reference/type
 * @param appConfiguration
 * @param caseRef
 */
export function latestCaseConfiguration(
  appConfiguration: AppConfiguration,
  caseRef: string
): CaseConfiguration {
  return appConfiguration.caseConfigurations
    .filter((config) => config.ref === caseRef)
    .sort((a, b) => b.version - a.version)
    .pop()!;
}

/**
 * Gets the latest version of the first non-global case configuration
 * @param appConfiguration
 */
export function firstNonGlobalLatestCaseConfiguration(
  appConfiguration: AppConfiguration
) {
  return appConfiguration.caseConfigurations
    .filter((config) => config.ref !== 'data-flow')
    .pop();
}

/**
 * Grab the value of an application-level feature flag from an instance
 * of [AppConfiguration]
 */
export function appFeatureFlag(
  appConfig: AppConfiguration,
  flag: AppFeatureFlag
): boolean {
  if (appConfig.loaded) {
    if (appConfig.appMetaData && appConfig.appMetaData.featureFlags) {
      return appConfig.appMetaData.featureFlags[flag]
        ? appConfig.appMetaData.featureFlags[flag]
        : false;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

/**
 * Check whether a specific case or task is importable, based on the presence of
 * a well-known feature flag value
 */
export function isImportable(
  config: CaseConfiguration | TaskConfiguration
): boolean {
  return hasFeatureFlag(config, 'importable');
}

/**
 * Check whether a specif config has a specific feature flag set
 * @param config
 * @param flag
 */
export function hasFeatureFlag(
  config: CaseConfiguration | TaskConfiguration,
  flag: CaseFeatureFlag
): boolean {
  if (config.meta.featureFlags) {
    return config.meta.featureFlags[flag]
      ? config.meta.featureFlags[flag]!
      : false;
  }
  return false;
}

/**
 * Applies a default sort to a given list of [CaseConfiguration]s
 * @param caseConfigurations
 */
export function sortCaseConfigurations(
  caseConfigurations: CaseConfiguration[]
): CaseConfiguration[] {
  return caseConfigurations.sort((a, b) => {
    const titleA = caseOrTaskMessage(a, UiMessageKey.TitlePlural);
    const titleB = caseOrTaskMessage(b, UiMessageKey.TitlePlural);
    return titleA.localeCompare(titleB);
  });
}

/**
 * Get a list of the valid statuses for a given {@link CaseConfiguration}
 * @param appConfiguration
 * @param caseRef
 * @param caseVersion
 */
export const caseStatusesByCaseVersion = (
  appConfiguration: AppConfiguration,
  caseRef: string,
  caseVersion: number
): CaseStatus[] => {
  const caseConfig = caseConfigurationVersion(
    appConfiguration,
    caseRef,
    caseVersion
  );
  return caseConfig.meta.statuses;
};

/**
 * Get a list of statuses across all versions of a specific case type
 * @param appConfiguration
 * @param caseRef
 */
export const allCaseStatusesByCaseType = (
  appConfiguration: AppConfiguration,
  caseRef: string
) => {
  const allConfigs = appConfiguration.caseConfigurations.filter(
    (config) => config.ref === caseRef
  );

  let results: CaseStatus[] = [];
  for (const config of allConfigs) {
    results = results.concat(config.meta.statuses);
  }

  return _.uniq(results);
};

/**
 * Convenience function for grabbing all the statuses for a specific {@link CaseConfiguration}
 * @param appConfiguration
 * @param caseConfig
 */
export const statusesForCase = (
  appConfiguration: AppConfiguration,
  caseConfig: CaseConfiguration
) => {
  return caseStatusesByCaseVersion(
    appConfiguration,
    caseConfig.ref,
    caseConfig.version
  );
};

/**
 * Grab a de-duped list of all possible {@link CaseStatus} instances, across all {@link CaseConfiguration} instances
 */
export const allCaseStatuses = (appConfiguration: AppConfiguration) => {
  const statuses = [] as CaseStatus[];
  for (let caseConfig of appConfiguration.caseConfigurations) {
    for (let caseStatus of caseConfig.meta.statuses) {
      if (!statuses.find((v) => v.id === caseStatus.id)) {
        statuses.push(caseStatus);
      }
    }
  }
  return statuses;
};

/**
 * Given a case status id, try and locate *any* case status that matches
 * @param appConfiguration a valid [AppConfiguration]
 * @param id the string identifier for the status
 */
export const matchCaseStatus = (
  appConfiguration: AppConfiguration,
  id: string
): CaseStatus | undefined => {
  const statuses = allCaseStatuses(appConfiguration);
  return statuses.find((s) => s.id === id);
};

/**
 * Takes a task or case configuration and constructs a "versioned" reference by concatenating the
 * name of the task/case with the version number
 * @param config
 */
export const versionedReference = (
  config: CaseConfiguration | TaskConfiguration
) => {
  return `${config.ref}_${config.version}`;
};

/**
 * Given a list of {@link DisplaySpecification}s inspect and map. Used to derive table columns, summary details display
 * elements etc...
 * @param config a {@link CaseConfiguration} or {@link TaskConfiguration} used to perform title lookups
 * @param specifications a list of {@link DisplaySpecification}s
 * @param mapper
 */
export function mapDisplaySpecifications<T>(
  config: CaseConfiguration | TaskConfiguration,
  specifications: DisplaySpecification[],
  mapper: (label: string, f: ValueRenderingFunction) => T
) {
  const results = [] as T[];
  for (const spec of specifications) {
    const result = mapDisplaySpecification(config, spec, mapper);
    if (result !== undefined) {
      results.push(result);
    }
  }
  return results;
}

/**
 * Given a {@link DisplaySpecification}, return the label for it
 * @param config a valid {@link CaseConfiguration} or {@link TaskConfiguration}
 * @param specification a {@link DisplaySpecification}
 */
export function displaySpecificationLabel(
  config: CaseConfiguration | TaskConfiguration,
  specification: DisplaySpecification
) {
  if (specification.title != null) {
    // we've got a title override, so this takes precedent
    return specification.title;
  } else if (specification.titlePointer != null) {
    // grab the constant label value from the schema
    return valueByJsonPointerPath(
      specification.titlePointer,
      config.form.schema
    );
  }
}

/**
 * Utility for trying to obtain a sensible title for a task configuration
 * @param taskConfig
 */
export function safeTaskConfigurationTitle(
  taskConfig: TaskConfiguration
): string {
  if (
    caseOrTaskMessage(taskConfig, UiMessageKey.TitleSingular) !== '!undefined!'
  ) {
    return caseOrTaskMessage(taskConfig, UiMessageKey.TitleSingular);
  } else if (taskConfig.meta.titleDisplaySpecification) {
    return displaySpecificationLabel(
      taskConfig,
      taskConfig.meta.titleDisplaySpecification
    );
  } else {
    return `${taskConfig.form.schema.title}`;
  }
}

/**
 * Map a single {@link DisplaySpecification}
 * @param config
 * @param specification
 * @param mapper
 */
export function mapDisplaySpecification<T>(
  config: CaseConfiguration | TaskConfiguration,
  specification: DisplaySpecification,
  mapper: (label: string, f: ValueRenderingFunction) => T
) {
  let label = '';
  if (specification.title != null) {
    // we've got a title override, so this takes precedent
    label = specification.title;
  } else if (specification.titlePointer != null) {
    // grab the constant label value from the schema
    label = valueByJsonPointerPath(
      specification.titlePointer,
      config.form.schema
    );
  }

  // work out what rendering function we're using
  if (specification.clientFunc != null) {
    // use a predefined client-side rendering function
    switch (specification.clientFunc) {
      case 'raw': {
        return mapper(label, rawRenderer(specification.valuePointer));
      }
      case 'dateTime': {
        return mapper(label, dateTimeRenderer(specification.valuePointer));
      }
      case 'yesNo': {
        return mapper(label, yesNoBooleanRenderer(specification.valuePointer));
      }
      case 'trueFalse': {
        return mapper(
          label,
          trueFalseBooleanRenderer(specification.valuePointer)
        );
      }
    }
  } else if (specification.serverFunc != null) {
    // use a "compiled" server-side function - yeah, this sucks massively - we should constrain
    // to well-defined client side functions only
    return mapper(
      label,
      dynamicRenderer(specification.valuePointer, specification.serverFunc)
    );
  } else {
    // fallback is to just utilise the raw renderer
    return mapper(label, rawRenderer(specification.valuePointer));
  }
}

/**
 * Create a default {@link CaseStatus} for use in the event of something dodgy/non-existent being returned by the server
 * @param id
 * @constructor
 */
export const defaultCaseStatus = (id: string) => {
  return {
    id: 'invalid',
    label: capitalCase(id),
    description: '',
    terminating: false,
    colour: '#111111',
    default: false,
    requiredForms: null,
    requiredRoles: null
  } as CaseStatus;
};

/**
 * Helper function for checking whether a given case status is currently loaded within an app configuration
 * @param appConfig
 * @param caseRef
 */
export const isValidCaseRef = (
  appConfig: AppConfiguration,
  caseRef: string
): boolean => {
  return (
    appConfig.caseConfigurations.find((config) => config.ref === caseRef) !==
    undefined
  );
};

/**
 * Take an identifier, and attempt to locate a {@link CaseStatus} with the corresponding id
 * @param id
 * @param statuses
 */
export const caseStatusFromId = (id: string, statuses: CaseStatus[]) => {
  const status = statuses.find((s) => lowerCase(s.id) === lowerCase(id));
  if (status === undefined) {
    return {
      ...defaultCaseStatus(id),
      id: capitalCase(id),
      description: capitalCase(id)
    };
  } else {
    if (status.description !== undefined) {
      if (status.description.trim().length === 0) {
        return {
          ...status,
          description: capitalCase(status.label)
        };
      } else {
        return status;
      }
    }
    return status;
  }
};

/**
 * Filter a list of {@link CaseStatus} instances, based on whether they should be part of the status filter
 * within search. If it hasn't been specified whether a status should appear, then include it by default
 * @param appConfiguration
 * @param caseConfig
 */
export const caseSearchStatuses = (
  appConfiguration: AppConfiguration,
  caseConfig: CaseConfiguration
) => {
  return statusesForCase(appConfiguration, caseConfig)
    .filter((s) => (s.showInSearch != null ? s.showInSearch : true))
    .map((s) => s.id);
};

/**
 * Retrieve a list of the assessment tasks for a given case
 * @param appConfig
 * @param caseConfig
 */
export const caseAssessmentTasks = (
  appConfig: AppConfiguration,
  caseConfig: CaseConfiguration
) => {
  if (caseConfig.meta.tasks !== undefined) {
    return caseConfig.meta
      .tasks!.filter((task) => task.assessment)
      .sort((a, b) => {
        const orderA = a.order ? a.order : 0;
        const orderB = b.order ? b.order : 0;
        if (orderA < orderB) {
          return -1;
        } else if (orderA > orderB) {
          return 1;
        }
        return 0;
      })
      .map((task) => {
        return appConfig.taskConfigurations.find(
          (t) => t.ref === task.ref && t.version === task.version
        );
      }) as TaskConfiguration[];
  } else {
    return [] as TaskConfiguration[];
  }
};
