import { simpleHash } from './Crypto';

/**
 * Deep comparison of objects to check for equality
 * Hived off here so that different implementations could be used if performance sucks
 * @param a one object
 * @param b the other object
 */
export function areObjectsEqual(a? : object, b?: object) : boolean {
  if (a === undefined && b === undefined) return true;
  if (a === undefined && b) return false;
  if (b === undefined && a) return false;
  const hashA= simpleHash(JSON.stringify(a));
  const hashB= simpleHash(JSON.stringify(b));
  return hashA === hashB;
}
