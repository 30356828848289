import React from 'react';
import { components } from '../config/flavour';
import FooterComponent from './FooterComponent';

export default function AboutPage () {
  return <>
    <components.AboutPageContent />
    <FooterComponent />
  </>;
}
