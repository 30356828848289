import { Activity } from '../shared/types';
import { FC } from 'react';
import { CommonDateTime } from '../shared/Dates';

export interface ActivityTimestampDescriptionProps {
  activity: Activity;
}

export const ActivityTimestampDescription: FC<
  ActivityTimestampDescriptionProps
> = ({ activity }) => {
  return (
    <>
      <div className={'activityListTimestamp'}>
        <CommonDateTime date={activity.createdAt} withCasual={true} />
      </div>
    </>
  );
};
