import { FC } from 'react';
import {
  CaseConfiguration,
  caseOrTaskMessage,
  TaskConfiguration,
  UiMessageKey
} from '../shared/types';

import { EntityIcons } from '../shared/components/Icons';
import { ExportTarget } from './types';
import { ExportButton } from './ExportButton';

export interface ExportWidgetProps {
  /**
   * The type of entity to be exported
   */
  exportTarget: ExportTarget;

  /**
   * The configuration used to drive the export.  If no configuration
   * is provided, then do a complete export
   */
  targetConfig: CaseConfiguration | TaskConfiguration | undefined;
}

/**
 * Build a base label for the widget
 * @param config
 */
const targetLabel = (config: CaseConfiguration | TaskConfiguration) => {
  return caseOrTaskMessage(config, UiMessageKey.TitlePlural) !== '!undefined!'
    ? caseOrTaskMessage(config, UiMessageKey.TitlePlural)
    : config.ref;
};

const targetIcon = (exportTarget: ExportTarget) => {
  switch (exportTarget) {
    case 'Case':
      return EntityIcons.Case('export-widget-icon-case');
    case 'Task':
      return EntityIcons.Task('export-widget-icon-task');
  }
};

/**
 * Widget that exposes a series of buttons/controls for the export of either
 * cases or tasks
 * @constructor
 */
export const ExportWidget: FC<ExportWidgetProps> = ({
  exportTarget,
  targetConfig
}) => {
  if (targetConfig) {
    return (
      <>
        <div className="row export-widget">
          <div className="col export-row-header">
            <span className="export-row-header-element">
              {targetIcon(exportTarget)}
            </span>{' '}
            <span className="export-row-header-element">
              {targetLabel(targetConfig)}
            </span>
          </div>
          <div className="col-2 export-button-container">
            <ExportButton
              exportType={'Csv'}
              exportTarget={exportTarget}
              targetConfig={targetConfig}
            />
          </div>
          <div className="col-2 export-button-container">
            <ExportButton
              exportType={'Json'}
              exportTarget={exportTarget}
              targetConfig={targetConfig}
            />
          </div>
        </div>
        <hr/>
      </>
    );
  } else {
    return (
      <>
        <div className="row export-widget">
          <div className="col export-row-header">
            <span className="export-row-header-element">
              {targetIcon(exportTarget)}
            </span>
            <span className="export-row-header-element">
              All {exportTarget === 'Case' ? 'Cases' : 'Tasks'}
            </span>
          </div>
          <div className="col-2 export-button-container">
            <ExportButton
              exportType={'Csv'}
              exportTarget={exportTarget}
              targetConfig={targetConfig}
            />
          </div>
          <div className="col-2 export-button-container">
            <ExportButton
              exportType={'Json'}
              exportTarget={exportTarget}
              targetConfig={targetConfig}
            />
          </div>
        </div>
        <hr />
      </>
    );
  }
};
