import React from 'react';
import { CardWidget } from '../shared/CardWidget';
import {CaseCreationsByTypeStackedBarChart} from "./CaseCreationsByTypeStackedBarChart";
import {newUUID} from "../shared/functions";

export const CasesPerDayCard = () => {
  return (<CardWidget
    title={'Case creations per day'}
    navItems={undefined}>
    <CaseCreationsByTypeStackedBarChart refreshCookie={newUUID()}/>
  </CardWidget>);
};
