import React, { useEffect, useState } from 'react';
import { useAuthContext } from '../auth/AuthContextProvider';
import { Link } from 'react-router-dom';
import { TasksAssignedCard } from '../charts/tasksAssigned';
import { CasesCreatedCard } from '../charts/casesCreated';
import { Page, PageNav } from '../shared/Page';
import { FormattedMessage } from 'react-intl';
import { useAppState } from '../main/AppState';
import { useAppConfiguration } from '../shared/contexts/AppConfiguration';
import { caseOrTaskMessage, firstNonGlobalLatestCaseConfiguration, UiMessageKey } from '../shared/types';

/**
 * @return {null|jsx}
 */
export default function DashboardNoRole() {
  const appConfig = useAppConfiguration();
  const [defaultCaseConfig, setDefaultCaseConfig] = useState(undefined);
  const { isAuthenticated } = useAuthContext();
  const { hasTaskForms } = useAppState();

  useEffect(() => {
    if (appConfig.loaded) {
      setDefaultCaseConfig(firstNonGlobalLatestCaseConfiguration(appConfig));
    }
  }, [appConfig, appConfig.loaded]);

  if (!isAuthenticated) {
    return null;
  }

  return (
    <Page>
      <PageNav title="nav.dashboard" />
      <div className="row align-items-start">
        <div className="col-lg-6">
          <div className="card mb-6">
            {defaultCaseConfig && (
              <div className="card-body">
                <h5 className="card-title">
                  {`Create new ${caseOrTaskMessage(defaultCaseConfig, UiMessageKey.TitleSingularShort)}`}
                </h5>
                <p className="card-text white-space-pre-line">
                  {`Create  a new ${caseOrTaskMessage(defaultCaseConfig, UiMessageKey.TitleSingular)}`}
                </p>
                <Link
                  to={`/assessment/${defaultCaseConfig.ref}`}
                  className="btn btn-primary btn-block"
                >
                  {`Create new ${caseOrTaskMessage(defaultCaseConfig, UiMessageKey.TitleSingularShort)}`}
                </Link>
              </div>
            )}
          </div>
        </div>
        <div className="col-lg-6">
          <div className="card mb-6">
            <div className="card-body">
              <h5 className="card-title">
                <FormattedMessage id="nav.activities.user" />
              </h5>
              <p className="card-text white-space-pre-line">
                <FormattedMessage id="nav.activities.user.description" />
              </p>
              <Link to={'/activity/me'} className="card-link">
                <FormattedMessage id="nav.activities.user" />
              </Link>
              <Link to={'/profile/preferences'} className="card-link">
                <FormattedMessage id="nav.profiles.preferences" />
              </Link>
            </div>
          </div>
        </div>
      </div>
      {hasTaskForms && (
        <div className="row align-items-start mt-3">
          <div className="col-md-6">
            <TasksAssignedCard />
          </div>
          <div className="col-md-6">
            <CasesCreatedCard />
          </div>
        </div>
      )}
      {!hasTaskForms && (
        <div className="row align-items-start mt-3">
          <div className="col-md-12">
            <CasesCreatedCard />
          </div>
        </div>
      )}
    </Page>
  );
}
