import {processSchemaForDocument} from "./SchemaDocumentProcessor";
import $RefParser from "@apidevtools/json-schema-ref-parser";
import jsPDF from 'jspdf';
import 'jspdf-autotable';

/**
 * Generate a PDF from a schema and a document associated with that schema
 *
 * @param schema without $ref elements
 * @param values form data
 * @param onError optional non-fatal error callback with message parameter, may be called multiple times during processing
 *
 * @returns {Promise} Promise object containing jsPDF object
 */
function generatePdf(schema, values, onError) {

  // PDF Format
  const format = 'a4';

  // Font sizes
  const headingFontSizes = [16, 14, 12];
  const baseFontSize = 12;
  const headerFontSize = baseFontSize;
  const footerFontSize = baseFontSize;

  // Spacing
  const margin = {top: 30, bottom: 30, left: 20, right: 20};
  const headerFooterLineOffset = 5; // vertical distance between the horizontal rule and the top margin

  const tableDefaults = {
    styles: {fontSize: baseFontSize},
    theme: 'grid',
    margin: margin,
    tableLineColor: '#ced4da',
    headStyles: {
      lineWidth: 0.1,
      fillColor: '#f0f0f0',
      textColor: '#000000',
      fontStyle: 'normal'
    },
    bodyStyles: {
      lineWidth: 0.1,
      fillColor: '#ffffff',
      textColor: '#000000',
    },
    footStyles: {
      lineWidth: 0.1,
      fillColor: '#ffffff',
      textColor: '#000000',
      fontStyle: 'italic',
      fontSize: 8
    }
  };

  // Style for the document section headings
  const sectionHeadStyle = {fillColor: '#c0c0c0'};

  return new Promise((resolve, reject) => {
    $RefParser.dereference(schema, (err, dereferenced) => {
      if (err) {
        reject(err);
      } else {
        // noinspection JSPotentiallyInvalidConstructorUsage
        const doc = new jsPDF({format: format, compress: true});

        doc.autoTableSetDefaults(tableDefaults);

        // Body
        processSchemaForDocument(
          dereferenced,
          values,
          (title, level) => {
            const fontSize = headingFontSizes[Math.min(level, headingFontSizes.length - 1)];
            doc.autoTable({
              head: [[title]],
              styles: {fontSize: fontSize},
              headStyles: sectionHeadStyle
            });
          },
          ({title, values, description, type}) => {
            doc.autoTable({
              head: [[title]],
              body: type !== "null" && values.map(v => (v === undefined || v === '') ? ['Not Set'] : [v]), // render each value in its own table cell
              foot: description && [[description]]
            });
          },
          onError);

        // Add headers and footers to every page
        const pageCount = doc.internal.getNumberOfPages();
        for (let pageNum = 1; pageNum <= pageCount; pageNum++) {
          doc.setPage(pageNum);

          //
          // Header
          //
          doc.setFontSize(headerFontSize);
          // Title
          if (schema.title) doc.text(
            schema.title,
            doc.internal.pageSize.width / 2,
            (margin.top - headerFontSize),
            null, null, "center"
          );

          // Horizontal rule
          const headerLineY = margin.top - headerFooterLineOffset;
          doc.line(margin.left, headerLineY, doc.internal.pageSize.width - margin.right, headerLineY);

          //
          // Footer
          //
          doc.setFontSize(footerFontSize);
          // Page number
          doc.text(
            `Page ${pageNum} of ${pageCount}`,
            doc.internal.pageSize.width / 2,
            doc.internal.pageSize.height - margin.bottom / 2,
            null, null, "center"
          );
          // Horizontal rule
          const footerLineY = doc.internal.pageSize.height - margin.bottom + headerFooterLineOffset;
          doc.line(margin.left, footerLineY, doc.internal.pageSize.width - margin.right, footerLineY);
        }

        resolve(doc);

      }

    });
  });
}

/**
 * Generate a PDF from a schema and a document associated with that schema, then trigger browser download
 *
 * @param schema without $ref elements
 * @param values form data
 * @param onError optional nom-fatal error callback with message parameter, may be called multiple times during processing
 */
function generateAndSavePdf(schema, values, onError) {
  return generatePdf(schema, values, onError)
    .then((doc) => {
      // Trigger browser download
      const title = schema.title || new Date().toLocaleString();
      const filename = title.replace(/\W+/g, '_');
      return doc.save(`${filename}.pdf`, {returnPromise: true});
    });
}

export {generatePdf, generateAndSavePdf};
