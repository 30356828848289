import React from 'react';
import { useAuthContext } from '../auth/AuthContextProvider';
import {ProfileCardDetails, ProfileNotificationsCard} from './Profile';
import { Link } from 'react-router-dom';
import CardLoader from '../loaders/CardLoader';
import { Page, PageNav } from '../shared/Page';
import { FormattedMessage } from 'react-intl';
import ModelInstanceView from "../models/ModelInstanceView";

const UserProfilePage = () => {
  const { loading, isAuthenticated, profile } = useAuthContext();

  if (!isAuthenticated) {
    return null;
  }

  return (<Page>
    <PageNav title="nav.profiles.user" />
    <div className="row align-items-start profile-header">
      <div className="col-md-6">
        {loading && <CardLoader/>}
        {!loading && <div className="card mb-4">
          <div className="card-body">
            <ProfileCardDetails model={profile}/>
          </div>
          <div className="card-footer d-flex">
            <span className="mr-auto"/>
            <Link to={'/profile/preferences'} className="btn btn-sm btn-outline-secondary mr-3">
              <FormattedMessage id="nav.profiles.preferences" />
            </Link>
            <Link to={'/profile/edit'} className="btn btn-sm btn-outline-primary">
              <FormattedMessage id="nav.profiles.edit" />
            </Link>
          </div>
        </div>}
      </div>
      <div className="col-md-6">
        <ModelInstanceView
          apiUrl={'/api/profile/me/preferences'}
          viewItem={ProfileNotificationsCard} />
      </div>
    </div>
  </Page>);
};

export default UserProfilePage;
