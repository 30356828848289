import UserPicture from "../shared/UserPicture";

export function AssigneeListItem({model = {}}) {
  return (<div className="d-flex flex-row align-items-center">
    <UserPicture className="avatar comments-avatar" email={model.email} title={model.name}/>
    <div className="flex-grow-1 ml-2" style={{lineHeight:1}}>
      <div className="mb-1">{model.name}</div>
      <span className="small text-muted mr-2">{model.email}</span>
      {model.username && !model.locked && <span className="badge badge-status-success">Has login account</span>}
      {model.locked && <span className="badge badge-danger">Access revoked</span>}
    </div>
  </div>);
}
