import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { InputQueryWithCallback } from '../search/InputText';
import { SearchFormHorizontal } from '../search/SearchFormHorizontal';
import PaginationWrapper from '../shared/Pagination';
import { WithPermission } from '../auth/AccessConditionals';
import { useApiGet, useApiList } from '../models/useApi';
import axios from 'axios';
import { useAuthContext } from '../auth/AuthContextProvider';
import { Add, Close } from '../main/icons';
import { AssigneeListItem } from '../profile/AssigneeListItem';
import { toast } from 'react-toastify';

const CurrentUserWatchSettings = ({ taskId, onChange, timestamp }) => {
  const { loading: isProfileLoading, profile } = useAuthContext();
  const [cacheTime, setCacheTime] = useState(timestamp || new Date().getTime());
  const { loading, data } = useApiGet(
    profile && `/api/task/${taskId}/watcher/${profile.id}`,
    { cacheBuster: cacheTime }
  );
  const isWatching = data != null;

  const stopWatching = () => {
    axios
      .delete(`/api/task/${taskId}/watcher/${profile.id}`)
      .then(() => {
        const t = new Date().getTime();
        setCacheTime(t);
        onChange && onChange(t);
      })
      .catch((e) => {
        const msg = e.response?.data?.errors[0]?.error || null;
        if (msg) {
          toast.error(msg, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2500
          });
        }
      });
  };

  const startWatching = () => {
    axios
      .post(`/api/task/${taskId}/watcher`, { id: profile.id })
      .then(() => {
        const t = new Date().getTime();
        setCacheTime(t);
        onChange && onChange(t);
      })
      .catch((e) => {
        const msg = e.response?.data?.errors[0]?.error || null;
        if (msg) {
          toast.error(msg, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2500
          });
        }
      });
  };

  const startStopWatching = () => {
    if (isWatching) {
      stopWatching();
    } else {
      startWatching();
    }
  };

  useEffect(() => {
    setCacheTime(timestamp);
  }, [timestamp]);

  return (
    <>
      {!isProfileLoading && !loading && (
        <button
          type="button"
          className="btn btn-outline-secondary btn-sm w-100"
          onClick={() => startStopWatching()}
        >
          {isWatching ? 'Stop watching this task' : 'Start watching'}
        </button>
      )}
    </>
  );
};

const CurrentWatchersListView = ({ taskId, onChange, timestamp }) => {
  const apiUrl = `/api/task/${taskId}/watcher`;
  const [cacheTime, setCacheTime] = useState(timestamp || new Date().getTime());

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      max: 4,
      offset: 0
    },
    (initialArg) => initialArg
  );

  const { loading, error, items, total } = useApiList(apiUrl, {
    max: state.max,
    offset: state.offset,
    cacheBuster: cacheTime
  });

  const removeWatcher = (userId) => {
    axios
      .delete(`/api/task/${taskId}/watcher/${userId}`)
      .then(() => {
        const t = new Date().getTime();
        setCacheTime(t);
        onChange && onChange(t);
      })
      .catch((e) => {
        const msg = e.response?.data?.errors[0]?.error || null;
        if (msg) {
          toast.error(msg, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2500
          });
        }
      });
  };

  useEffect(() => {
    setCacheTime(timestamp);
  }, [timestamp]);

  function errorView() {
    return (
      <div className="alert alert-danger" data-testid="error">
        {error}
      </div>
    );
  }

  function watchersItemViews() {
    if (items && items.length) {
      return items.map((item) => (
        <li
          key={`item${item.id}`}
          className="list-group-item d-flex flex-row align-items-center"
        >
          <AssigneeListItem model={item} />
          <WithPermission name="task:watcher:delete">
            <button
              className="ml-auto btn btn-sm btn-link"
              onClick={() => removeWatcher(item.id)}
              aria-label="Remove"
            >
              <Close height={20} style={{ fill: 'currentColor' }} />
            </button>
          </WithPermission>
        </li>
      ));
    }
  }

  function hasWatchers() {
    if (!items) return false;
    return items.length > 0 || total > 0;
  }

  const onPagination = useCallback(
    ({ max, offset }) => {
      setState({ max: max, offset: offset });
    },
    [setState]
  );

  return (
    <>
      {
        <div className="mt-3 small text-muted">
          {total} {total === 1 ? 'Person' : 'People'} watching this task
        </div>
      }
      {!loading && error && errorView()}
      {!error && hasWatchers() && (
        <ul className="list-group">{watchersItemViews()}</ul>
      )}
      {!error && hasWatchers() && items.length < total && (
        <div
          data-testid="pagination"
          className="d-flex align-items-center justify-content-center"
        >
          <PaginationWrapper
            size={'sm'}
            className="mt-3 mb-0"
            max={state.max}
            offset={state.offset}
            total={total}
            onChange={onPagination}
          />
        </div>
      )}
    </>
  );
};

const SuggestionItem = ({ taskId, item }) => {
  const [watcherAdded, setWatcherAdded] = useState(false);
  const addWatchers = (userId) => {
    axios
      .post(`/api/task/${taskId}/watcher`, { id: userId })
      .then(() => {
        setWatcherAdded(true);
      })
      .catch((e) => {
        const msg = e.response?.data?.errors[0]?.error || null;
        if (msg) {
          toast.error(msg, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2500
          });
        }
      });
  };

  const removeWatchers = (userId) => {
    axios
      .delete(`/api/task/${taskId}/watcher/${userId}`)
      .then(() => {
        setWatcherAdded(false);
      })
      .catch((e) => {
        const msg = e.response?.data?.errors[0]?.error || null;
        if (msg) {
          toast.error(msg, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2500
          });
        }
      });
  };

  return (
    <>
      <li className="list-group-item d-flex flex-row align-items-center">
        <AssigneeListItem model={item} />
        {!watcherAdded && !item.locked && (
          <button
            className="ml-auto btn btn-sm btn-link"
            onClick={() => addWatchers(item.id)}
            aria-label="Add"
          >
            <Add height={20} style={{ fill: 'currentColor' }} />
          </button>
        )}
        {watcherAdded && (
          <button
            className="ml-auto btn btn-sm btn-link"
            onClick={() => removeWatchers(item.id)}
            aria-label="Remove"
          >
            <Close height={20} style={{ fill: 'currentColor' }} />
          </button>
        )}
      </li>
    </>
  );
};

const ChangeWatchersListView = ({ taskId }) => {
  const apiUrl = `/api/task/${taskId}/watcher/suggestions`;
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      max: 4,
      offset: 0,
      query: ''
    },
    (initialArg) => initialArg
  );

  const { loading, error, items, total } = useApiList(apiUrl, {
    max: state.max,
    offset: state.offset,
    query: state.query
  });

  function suggestionItemViews() {
    if (items && items.length) {
      return items.map((item) => (
        <SuggestionItem key={`sitem${item.id}`} taskId={taskId} item={item} />
      ));
    }
  }

  function hasSuggestionItems() {
    if (!items) {
      return false;
    }
    return items.length > 0 || total > 0;
  }

  const onSearch = useCallback(
    (query) => {
      setState({ offset: 0 });
      setState({ query: query });
    },
    [setState]
  );

  const onPagination = useCallback(
    ({ max, offset }) => {
      setState({ max: max, offset: offset });
    },
    [setState]
  );

  return (
    <>
      <div className="mb-3">
        <SearchFormHorizontal>
          <InputQueryWithCallback
            className="w-100"
            placeholder="Name, phone, email"
            onSearch={onSearch}
          />
        </SearchFormHorizontal>
      </div>
      {!loading && error && (
        <div className="alert alert-danger" data-testid="error">
          {error}
        </div>
      )}
      {!loading && !error && !hasSuggestionItems() && (
        <div className="alert alert-info">No users found!</div>
      )}
      {!error && hasSuggestionItems() && (
        <div>
          <span className="small text-muted">Search result</span>
          <ul className="list-group">{suggestionItemViews()}</ul>
        </div>
      )}
      {!error && hasSuggestionItems() && items.length < total && (
        <div
          data-testid="pagination"
          className="d-flex align-items-center justify-content-center"
        >
          <PaginationWrapper
            size={'sm'}
            className="mt-3 mb-0"
            max={state.max}
            offset={state.offset}
            total={total}
            onChange={onPagination}
          />
        </div>
      )}
    </>
  );
};

const ChangeTaskWatchersModal = (props) => {
  const [mode, setMode] = useState('view');
  const [cacheTime, setCacheTime] = useState(new Date().getTime());

  return (
    <Modal
      data-testid="form-watch-dialog"
      centered
      show
      onHide={() => props.onModalClose()}
      animation={false}
    >
      {mode === 'add' && (
        <>
          <Modal.Header closeButton>
            <Modal.Title>Add watchers</Modal.Title>
          </Modal.Header>
          <Modal.Body className="watch-body">
            <WithPermission name="task:watcher:update">
              <ChangeWatchersListView taskId={props.taskId} />
            </WithPermission>
          </Modal.Body>
          <Modal.Footer>
            <button
              data-testid="confirm-cancel-btn"
              type="button"
              className="btn btn-outline-secondary btn-sm pl-5 pr-5 mr-auto"
              onClick={() => setMode('view')}
            >
              Back
            </button>
          </Modal.Footer>
        </>
      )}

      {mode === 'view' && (
        <>
          <Modal.Header closeButton>
            <Modal.Title>Change watchers</Modal.Title>
          </Modal.Header>
          <Modal.Body className="watch-body">
            <CurrentUserWatchSettings
              taskId={props.taskId}
              onChange={(t) => setCacheTime(t)}
              timestamp={cacheTime}
            />
            <CurrentWatchersListView
              taskId={props.taskId}
              onChange={(t) => setCacheTime(t)}
              timestamp={cacheTime}
            />
          </Modal.Body>
          <WithPermission name="task:watcher:update">
            <Modal.Footer>
              <button
                data-testid="confirm-add-btn"
                type="button"
                className="btn btn-outline-secondary btn-sm pl-5 pr-5"
                onClick={() => setMode('add')}
              >
                Add Watchers
              </button>
            </Modal.Footer>
          </WithPermission>
        </>
      )}
    </Modal>
  );
};

ChangeTaskWatchersModal.propTypes = {
  taskId: PropTypes.number.isRequired,
  onModalClose: PropTypes.func.isRequired
};

export default ChangeTaskWatchersModal;
