import { WidgetProps } from '@rjsf/core';
import _ from 'lodash';
import { FC, useEffect, useReducer, useState } from 'react';
import { GeneralIcons } from '../../shared/components/Icons';
import { newUUID } from '../../shared/functions';
import { combineClassNames } from '../../shared/functions/ClassNameUtils';

export const TrafficLightWidgetName = 'TrafficLightWidget';

/**
 * Set of default colours
 */
const DEFAULT_COLOURS: TrafficLightColours = {
  Red: 'red',
  Amber: 'amber',
  Green: 'green'
};

/**
 * Check to see if we have colour options set, if not just use the default
 */
const configureColours = (values: string[], options?: any) => {
  return {};
};

/**
 * A map of values to a selection state
 */
type TrafficLightWidgetSelectionState = {
  [key in string]: boolean;
};

type TrafficLightColours = {
  [key in string]: string;
};

/**
 * For tracking internal state of the widget
 */
interface TrafficLightMemberState {
  value?: string;
  init: boolean;
  colourClasses: TrafficLightColours;
  labels: string[];
  values: string[];
  options: any;
  selectionState: TrafficLightWidgetSelectionState;
}

/**
 * Flip the selection state for a given value, invert all other values if the value
 * is set to true
 */
const toggleSelectionState = (
  stateKey: string,
  value: boolean,
  selectionState: TrafficLightWidgetSelectionState
): TrafficLightWidgetSelectionState => {
  if (value) {
    for (let key of _.keys(selectionState)) {
      selectionState[key] = key === stateKey;
    }
  } else {
    for (let key of _.keys(selectionState)) {
      if (key === stateKey) {
        selectionState[key] = false;
      }
    }
  }
  return selectionState;
};

/**
 * Construct the initial state
 */
const initialSelectionState = (
  key: string,
  values: string[]
): TrafficLightWidgetSelectionState => {
  const state: TrafficLightWidgetSelectionState = {};
  for (const candidate of values) {
    state[candidate] = candidate === key;
  }
  return state;
};

/**
 * This is a widget for use in forms that require a field such a risk enumeration
/* where each selected option has a specific and natural colour coding
 */
export const TrafficLightWidget: FC<WidgetProps> = (props) => {
  const values = props.schema.enum;

  const [state, dispatch] = useReducer(reducer, {
    init: false,
    labels: [],
    values: [],
    colourClasses: {},
    options: props.uiSchema['ui:options'],
    selectionState: {}
  });

  // simple spread reducer
  function reducer(
    state: TrafficLightMemberState,
    mutations: Partial<TrafficLightMemberState>
  ): TrafficLightMemberState {
    return {
      ...state,
      ...mutations
    };
  }

  useEffect(() => {
    if (!values) {
      dispatch({ init: false });
    } else {
      const stringValues = values as string[];
      dispatch({
        value: props.value,
        init: true,
        labels: stringValues,
        values: stringValues,
        colourClasses: _.has(state.options, 'colourClasses')
          ? state.options['colourClasses']
          : DEFAULT_COLOURS,
        selectionState: initialSelectionState(props.value, stringValues)
      });
    }
  }, [values]);

  // fired when a specific option is selected just notify the parent form
  const onSelect = (selected: string | null) => {
    if (props.onChange) {
      props.onChange(selected);
    }
  };

  // fired by a child component when an element is selected
  const onMemberSelect = (value: string) => {
    dispatch(toggleSelectionState(value, true, state.selectionState));
    onSelect(value);
  };

  // fired by a child component when an element is unselected
  const onMemberDeselect = (value: string) => {
    dispatch(toggleSelectionState(value, false, state.selectionState));
    onSelect(null);
  };

  if (!state.init) {
    return <div>Widget not initialised correctly</div>;
  } else {
    return (
      <div className="traffic-light-container">
        {state.values.map((value) => {
          return (
            <TrafficLightMember
              key={newUUID()}
              label={value}
              colourClass={state.colourClasses[value]}
              selected={state.selectionState[value]}
              value={value}
              onSelected={onMemberSelect}
              onDeselected={onMemberDeselect}
            />
          );
        })}
      </div>
    );
  }
};

/**
 * Props for an individual traffic light member component
 */
export interface TrafficLightMemberProps {
  /**
   * The colour to display when the item is selected
   */
  colourClass: string;

  /**
   * Whether the component is currently selected or not
   */
  selected: boolean;

  /**
   * The value to emit when selected
   */
  value: string;

  /**
   * The label to display
   */
  label: string;

  /**
   * Fired when the component is selected
   */
  onSelected: (value: string) => void;

  /**
   * Fired when the component is deselected
   */
  onDeselected: (value: string) => void;
}

export const TrafficLightMember: FC<TrafficLightMemberProps> = (props) => {
  const [selected] = useState(props.selected);

  // allow the value to be toggled using the space bar
  const handleKeypress = (event: any) => {
    console.log('keyUp:' + JSON.stringify(event.keyCode));
    if (_.has(event, 'keyCode')) {
      if (event.keyCode === 32) {
        switch (props.selected) {
          case true: {
            props.onDeselected(props.value);
            break;
          }
          default: {
            props.onSelected(props.value);
            break;
          }
        }
      }
    }
  };

  return (
    <>
      <div
        className="member"
        tabIndex={0}
        onKeyUp={(event) => handleKeypress(event)}
      >
        {selected && (
          <span
            className={combineClassNames('light', props.colourClass)}
            onClick={() => props.onDeselected(props.value)}
          >
            {GeneralIcons.CircleTick('')}&nbsp;{props.label}
          </span>
        )}
        {!selected && (
          <span
            className={combineClassNames('light', [
              props.colourClass,
              'unselected'
            ])}
            onClick={() => props.onSelected(props.value)}
          >
            {GeneralIcons.CircleCross('')}&nbsp;{props.label}
          </span>
        )}
      </div>
    </>
  );
};
