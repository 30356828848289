import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import { BasePie } from './basePie';
import { useAuthContext } from '../auth/AuthContextProvider';
import { FormattedMessage, useIntl } from 'react-intl';
import { CardWidget } from '../shared/CardWidget';
import { useAppState } from '../main/AppState';
import { Link } from 'react-router-dom';
import { AngleRight } from '../main/icons';
import { useAppConfiguration } from '../shared/contexts/AppConfiguration';
import {
  caseStatusFromId,
  firstNonGlobalLatestCaseConfiguration,
  statusesForCase
} from '../shared/types';

export const CasesCreatedCard = () => {
  const intl = useIntl();
  return (
    <CardWidget
      title={intl.formatMessage({ id: 'stats.cases.by.user' })}
      navItems={
        <Link
          className="btn btn-sm btn-outline-secondary"
          to={'/cases/created-by-you'}
        >
          <FormattedMessage id="nav.cases.user" />{' '}
          <AngleRight className="svg-gradient-primary" height={15} />
        </Link>
      }
    >
      <CasesCreatedChart />
    </CardWidget>
  );
};

export const CasesCreatedChart = () => {
  const { loading, profile } = useAuthContext();
  const appConfig = useAppConfiguration();
  const [ defaultCaseConfig, setDefaultCaseConfig ] = useState();
  const [ defaultCaseStatuses, setDefaultCasesStatuses ] = useState();
  const { caseStatusFrom } = useAppState();
  const [analyticsData, setAnalyticsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // use an effect to load a default case configuration and the
  // statuses associated with it
  useEffect(() => {
    if (appConfig.loaded) {
      const caseConfig = firstNonGlobalLatestCaseConfiguration(appConfig);
      setDefaultCaseConfig(caseConfig);
      setDefaultCasesStatuses(statusesForCase(appConfig, caseConfig));
    }
  }, [
    appConfig,
    appConfig.loaded,
    setDefaultCaseConfig,
    setDefaultCasesStatuses
  ]);

  useEffect(() => {
    if (
      appConfig.loaded &&
      defaultCaseConfig &&
      defaultCaseStatuses &&
      !loading &&
      profile
    ) {
      axios
        .get('/api/stats/cases/created')
        .then((response) => response.data.data || [])
        .then((results) => {
          setAnalyticsData(
            results.map((result) => {
              const status = caseStatusFromId(
                result.status,
                defaultCaseStatuses
              );
              return {
                id: status.label,
                label: status.label,
                value: result.count
              };
            })
          );
        })
        .catch(() => setAnalyticsData([]))
        .finally(() => setIsLoading(false));
    }
  }, [caseStatusFrom, profile, loading, appConfig.loaded, defaultCaseConfig, defaultCaseStatuses]);

  return (
    <div
      style={{ height: 300 }}
      className="d-flex align-items-center justify-content-center"
    >
      {isLoading && <Spinner animation={'border'} variant={'primary'} />}
      {!isLoading && analyticsData.length === 0 && (
        <p className="text-muted text-center">
          <FormattedMessage id="stats.cases.by.user.notfound" />
        </p>
      )}
      {!isLoading && analyticsData.length > 0 && (
        <BasePie analyticsData={analyticsData} />
      )}
    </div>
  );
};
