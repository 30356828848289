import { useEffect, useReducer } from 'react';
import axios from 'axios';
import { parseAxiosError } from '../shared/AxiosResponseErrorParser';

export interface ApiListResult {
    error? : string | null,
    items? : any[] | null,
    loading? : boolean,
    total? : number | null
}


export interface ApiGetResult {
    data?: any,
    error?: string | null,
    loading?: boolean,
    state?: any,
}


/**
 * Use in functional components
 * @example
 * const { loading, data, state, error } = useApiGet(apiUrl, {foo: "bar"});
 * @param {string} apiPath
 * @param {Object} [apiParams]
 * @return {object}
 */
export function useApiGet (apiPath : string, apiParams : object)  : ApiGetResult {

  const [state, setState] = useReducer(
    (state : Partial<ApiGetResult>, newState : Partial<ApiGetResult>) => ({ ...state, ...newState }), {
      loading: true,
      data: null,
      state: null,
      error: null
    }, (initialArg) => initialArg);

  const params = JSON.stringify(apiParams || {});

  useEffect(() => {
    let mounted = true;
    if (apiPath != null && params != null) {
      setState({ loading: true });
      axios.get(apiPath, { params: JSON.parse(params) })
        .then(response => {
          if (!mounted) return;
          const responseData = response.data || {};
          setState({
            data: responseData.data,
            state: responseData.state,
            error: null,
            loading: false
          });
        })
        .catch(e => {
          if (!mounted) return;
          const axiosError = parseAxiosError(e, 'Failed to retrieve results from server');
          setState({
            data: null,
            state: null,
            error: axiosError.status === 404 ? null : axiosError.getMessage(),
            loading: false
          });
        });
    }
    return () => {
      mounted = false;
    };
  }, [apiPath, params]);

  return { ...state };
}

/**
 * Use in functional components, (note this is basically useless in components that need to dynamically refresh
 * because you can't use it inside an effect...triggering a refresh due to external state changes)
 * @example
 * const { loading, error, items, total } = useApiList(apiUrl, {max: 5, offset: 0});
 * @param apiPath {string}
 * @param [apiParams] {object}
 * @return {object}
 */
export function useApiList (apiPath : string, apiParams : object) : ApiListResult  {

  const [state, setState] = useReducer(
    (state : Partial<ApiListResult>, newState : Partial<ApiListResult>) => ({ ...state, ...newState }), {
      loading: false,
      error: null,
      items: null,
      total: null
    }, (initialArg) => initialArg);

  const params = JSON.stringify(apiParams || {});

  useEffect(() => {
    let mounted = true;
    if (apiPath != null && params != null) {
      setState({ loading: true });
      axios.get(apiPath, { params: JSON.parse(params) })
        .then(response => {
          if (!mounted) return;
          const responseData = response.data || {};
          const total = responseData.total || 0;
          const items = responseData.data || [];
          setState({
            total: total,
            items: items,
            error: null,
            loading: false
          });
        })
        .catch(e => {
          if (!mounted) return;
          setState({
            total: null,
            items: null,
            error: parseAxiosError(e, 'Failed to retrieve results from server').getMessage(),
            loading: false
          });
        });
    }
    return () => {
      mounted = false;
    };
  }, [apiPath, params]);

  return { ...state };
}

