import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ModelTableWithRouterState from '../models/ModelTableWithRouterState';
import { Page, PageNav } from '../shared/Page';
import { generatePath } from 'react-router-dom';
import { useAuthContext } from '../auth/AuthContextProvider';
import { toUkDateTimeString } from '../search/MemorableDate';
import { CasualTime } from '../shared/Dates';

function ActivityList (props) {
  const columns = [{
    label: 'ID',
    sortBy: 'id',
    fromModel: (item) => item.id
  }, {
    label: 'Event type',
    sortBy: 'eventType',
    fromModel: (item) => item.eventType
  }, {
    label: 'Object type',
    sortBy: 'entityType',
    fromModel: (item) => item.entityType
  }, {
    label: 'Object ID',
    sortBy: 'entityId',
    fromModel: (item) => item.entityId
  }, {
    label: 'Object version',
    sortBy: 'entityVersion',
    fromModel: (item) => item.entityVersion
  }, {
    label: 'Created',
    sortBy: 'createdAt',
    fromModel: (item) => toUkDateTimeString(item.createdAt)
  }];

  return <ModelTableWithRouterState
    caption="nav.activities.all"
    columns={columns}
    itemHref={(item) => generatePath(`/activity/show/${item.id}`)}
    additionalQueryParams={{ cacheBuster: props.lastEventTimestamp }}
    {...props}/>;
}

ActivityList.propTypes = {
  apiEndpoint: PropTypes.string.isRequired,
  lastEventTimestamp: PropTypes.number,
  onPagination: PropTypes.func
};

/**
 * @return {JSX.Element}
 */
export default function ActivityListPage (props) {

  const { loading, profile } = useAuthContext();
  const [lastEventTimestamp, setLastEventTimestamp] = useState(new Date().getTime());

  function onEvent () {
    setLastEventTimestamp(new Date().getTime());
  }

  if (loading || !profile) return null;

  return <Page>
    <PageNav title="nav.activities.all">
      <span className="ml-auto"/>
      <span>Last updated</span>
      <CasualTime date={lastEventTimestamp} className="mx-1" />
      {props.allowRefresh && <div className="btn btn-sm btn-outline-secondary" onClick={onEvent}>Refresh</div>}
    </PageNav>
    <ActivityList lastEventTimestamp={lastEventTimestamp} onPagination={onEvent} {...props} />
  </Page>;
};

ActivityListPage.propTypes = {
  apiEndpoint: PropTypes.string.isRequired,
  allowRefresh: PropTypes.bool
};
