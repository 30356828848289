import React from 'react';
import PropTypes from 'prop-types';

export const CssColClassesFlow = "col-sm-8 col-xl-5 offset-xl-3";
export const CssColClassesSteps = "col-sm-4 col-xl-4";

class FlowContainer extends React.PureComponent {

  constructor (props) {
    super(props);

    this.state = {
      stepIndex: 0,
      childrenSuccessResults: props.primaryValues || []
    };

    this.flowContainerRef = React.createRef();
    this.childrenRefs = [];
    this.childrenWithRefs = [];
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.stepIndex !== this.state.stepIndex) {
      if (this.flowContainerRef.current !== null){
        this.flowContainerRef.current.scrollIntoView();
      }
    }
  }

  updateClonedChildren () {
    this.childrenWithRefs = React.Children.toArray(this.props.children).map((child, idx) => React.cloneElement(child, {
      key: idx,
      ref: el => this.childrenRefs[idx] = el,
      onNextSuccess: (result) => {
        const updated = Array.from(this.state.childrenSuccessResults);
        updated[idx] = result;
        this.setState({
          childrenSuccessResults: updated
        }, () => this.transitionToNext());
      },
      prevSuccessResult: this.state.childrenSuccessResults[idx],
      allSuccessResults: this.state.childrenSuccessResults
    }));
  }

  setStepIndex (idx) {
    this.setState({ stepIndex: idx });
  }

  getCurrentCard () {
    let childComponent;
    if (this.state.stepIndex > -1 && this.state.stepIndex < this.childrenWithRefs.length) {
      childComponent = this.childrenWithRefs[this.state.stepIndex];
    } else {
      childComponent = this.childrenWithRefs[0];
    }
    return (<div data-testid={`flow-step-${this.state.stepIndex}`}>
      {childComponent}
    </div>);
  }

  goPrevious () {
    if (!this.isFirstStep()) {
      this.setStepIndex(this.state.stepIndex - 1);
    } else if (this.props.onCancel) {
      this.props.onCancel();
    }
  }

  goNext () {
    const ref = this.childrenRefs[this.state.stepIndex];
    if (ref && 'function' === typeof ref.doNext) {
      ref.doNext();
    }
  }

  transitionToNext () {
    if (!this.isLastStep())
      this.setStepIndex(this.state.stepIndex + 1);
    else
      this.props.onFinalSubmit(this.state.childrenSuccessResults);
  }

  isFirstStep () {
    return this.state.stepIndex < 1;
  }

  isLastStep () {
    return this.state.stepIndex >= (this.childrenWithRefs.length - 1);
  }

  getNav () {
    return <>
      <nav aria-label="flow navigation" data-testid="flow-nav" className="my-3 d-flex">
        {!this.isFirstStep() && (
          <button className="btn btn-lg btn-outline-secondary" data-testid="flow-btn-previous" onClick={() => this.goPrevious()}>Previous</button>)}
        {this.isFirstStep() && this.props.onCancel && (
          <button className="btn btn-lg btn-outline-secondary" data-testid="flow-btn-cancel" onClick={() => this.goPrevious()}>Cancel</button>)}
        <span className="mx-auto"/>
        <button className="btn btn-lg btn-primary" data-testid="flow-btn-next" onClick={() => this.goNext()}>
          {this.isLastStep() ? 'Submit' : 'Next step'}
        </button>
      </nav>
    </>;
  }

  render () {

    this.updateClonedChildren();

    return (<div ref={this.flowContainerRef} className="flow container-fluid" data-testid="flow-wrapper">
      <div className="row align-items-center">

        <div className={`flex-column ${CssColClassesFlow}`}>
          {this.getCurrentCard()}
          {this.getNav()}
        </div>

      </div>
    </div>);
  }
}

FlowContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  onFinalSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  primaryValues: PropTypes.array
};

export default FlowContainer;
