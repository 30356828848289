import React, { useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { parseAxiosError } from '../shared/AxiosResponseErrorParser';
import FormForSchemaModal from '../form/FormForSchemaModal';

const CaseFilesAttachModal = ({ caseId, modalTitle, onModalClose }) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const schema = {
    type: 'object',
    properties: {
      files: {
        title: 'Files to attach',
        type: 'array',
        items: {
          type: 'string'
        },
        minItems: 1
      },
      text: {
        title: 'Comment',
        type: 'string'
      }
    },
    required: ['files', 'text']
  };

  const uiSchema = {
    text: {
      'ui:widget': 'textarea'
    },
    files: {
      'ui:widget': 'files'
    }
  };

  const onSubmit = (formData) => {
    setLoading(true);
    axios
      .post(`/api/case/${caseId}/comment`, formData)
      .then(() => {
        setLoading(false);
        onModalClose();
      })
      .catch((e) => {
        setLoading(false);
        setErrorMessage(parseAxiosError(e).getMessage());
      });
  };

  return (
    <FormForSchemaModal
      modalTitle={modalTitle}
      isLoading={loading}
      onModalClose={onModalClose}
      onSubmit={onSubmit}
      errorMessage={errorMessage}
      schema={schema}
      uiSchema={uiSchema}
    />
  );
};

CaseFilesAttachModal.propTypes = {
  caseId: PropTypes.number.isRequired,
  modalTitle: PropTypes.string.isRequired,
  onModalClose: PropTypes.func.isRequired
};

export default CaseFilesAttachModal;
