import {generatePath} from "react-router";


export function getActivitySubjectLink(entityType : string, id : number) {
  switch (entityType) {
    case 'Case':
      return generatePath(`/cases/show/${id}`);
    case 'Task':
      return generatePath(`/tasks/show/${id}`);
    default:
      return generatePath(`/activity/show/${id}`);
  }
}
