import React, { useCallback, useReducer } from 'react';
import PropTypes from 'prop-types';
import ListLoader from '../loaders/ListLoader';
import PaginationWrapper from '../shared/Pagination';
import { useApiList } from './useApi';

function DefaultListItem({ model = {}, ...rest }) {
  return <div {...rest}>{model.displayName}</div>;
}

const ModelListView = ({
  apiUrl,
  listItemRenderer: ItemComponent = DefaultListItem,
  className,
  itemClassName,
  notFoundMessage,
  pusherChannelName,
  onPusherMessage,
  ...rest
}) => {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      max: 5,
      offset: 0,
      query: ''
    },
    (initialArg) => initialArg
  );

  const { loading, error, items, total } = useApiList(apiUrl, {
    max: state.max,
    offset: state.offset,
    query: state.query
  });

  const onPagination = useCallback(
    ({ max, offset }) => {
      setState({ max: max, offset: offset });
    },
    [setState]
  );

  function notFoundView(notFoundMessage) {
    return (
      <div className="p-4 text-muted text-center" data-testid="404">
        {notFoundMessage || 'Nothing found'}
      </div>
    );
  }

  function errorView() {
    return (
      <div className="p-4 text-danger text-center" data-testid="error">
        {error}
      </div>
    );
  }

  function hasItems() {
    if (!items) {
      return false;
    }
    return items.length > 0 || total > 0;
  }

  function listGroupItemViews() {
    if (items && items.length) {
      return items.map((item, key) => (
        <div
          key={`item${key}`}
          data-testid={`item${key}`}
          className={itemClassName || 'list-group-item'}
        >
          <ItemComponent model={item} {...rest} />
        </div>
      ));
    }
  }

  return (
    <>
      {loading && <ListLoader data-testid="loader" />}
      {!loading && error && errorView()}
      {!loading && !error && !hasItems() && notFoundView(notFoundMessage)}
      {!loading && !error && hasItems() && (
        <div className={className || 'list-group'} data-testid="list">
          {listGroupItemViews()}
        </div>
      )}
      {!loading && !error && items && total > 0 && items.length < total && (
        <div
          data-testid="pagination"
          className="d-flex align-items-center justify-content-center"
        >
          <PaginationWrapper
            size={'sm'}
            className="mt-3"
            max={state.max}
            offset={state.offset}
            total={total}
            onChange={onPagination}
          />
        </div>
      )}
    </>
  );
};

ModelListView.propTypes = {
  apiUrl: PropTypes.string.isRequired,
  listItemRenderer: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  className: PropTypes.string,
  itemClassName: PropTypes.string,
  notFoundMessage: PropTypes.string
};

export default ModelListView;
