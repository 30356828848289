import React from 'react';
import PropTypes from 'prop-types';

class AbstractFlowStep extends React.PureComponent {

  constructor (props) {
    super(props);
    this.doNext = this.doNext.bind(this);
  }

  doNext () { throw new Error('onNext not implemented'); }

}

AbstractFlowStep.propTypes = {
  onNextSuccess: PropTypes.func,
  prevSuccessResult: PropTypes.any,
  allSuccessResults: PropTypes.any
};

export default AbstractFlowStep;
