import React, { useEffect, useState } from 'react';
import {
  buildMemoryHistory,
  getURLSearchParams,
  getURLSearchParamsWithoutPagination,
  RouterHistory
} from '../main/RouterHistory';
import PropTypes from 'prop-types';
import { QueryParameter } from '../models/QueryParameter';
import { Search } from '../main/icons';
import { InputSize, sizeToInputGroupClassName } from './InputSize';

/**
 * Abstracted search filter input operating on search query params, implementation
 * needs to implement query parameter change listeners.
 * @param parameterKey {string} query parameter name/key
 * @param params {URLSearchParams}
 * @param history {object} - one of history objects in corresponding module
 * @param placeholder {string}
 * @param className {string} - additional classes next to input-group
 * @param size {InputSize}
 * @return {*}
 */
export const InputText = ({
  withSearchOption,
  parameterKey,
  params,
  history,
  placeholder,
  className = '',
  size = InputSize.md
}) => {

  const urlQuery = params.get(parameterKey) || '';
  const [searchQuery, setSearchQuery] = useState(urlQuery);
  const sizeClassName = sizeToInputGroupClassName(size);
  useEffect(() => {
    setSearchQuery(urlQuery);
  }, [urlQuery]);

  function handleChange (event) {
    setSearchQuery(event.target.value);
  }

  function search (q) {
    const urlQueryParams = getURLSearchParamsWithoutPagination();
    urlQueryParams.set(parameterKey, q);
    history.push({
      pathname: history.location.path,
      search: urlQueryParams.toString()
    });
  }

  function handleKeyUp (event) {
    if (event.keyCode === 13) {
      search(searchQuery);
    }
  }

  return (<>
    {!withSearchOption && <div className={`input-group ${sizeClassName} ${className}`}>
      <input data-testid={`search-field-${parameterKey}`} name={parameterKey} type="search" className="form-control" placeholder={placeholder} aria-label={placeholder} aria-describedby={placeholder.replace('...', 'x').replaceAll(' ','_')+'_btn'} defaultValue={searchQuery} />
    </div>}
    {withSearchOption && <div className={`input-group ${sizeClassName} ${className}`}>
      <input data-testid={`search-field-${parameterKey}`} type="search" className="form-control" placeholder={placeholder} aria-label={placeholder} aria-describedby={placeholder.replace('...', 'x').replaceAll(' ','_')+'_btn'} value={searchQuery} onChange={handleChange} onKeyUp={handleKeyUp}/>
      <div className="input-group-append">
        <button data-testid={`search-field-${parameterKey}-btn`} className="btn btn-outline-secondary" type="button" id={placeholder.replace('...', 'x').replaceAll(' ','_')+'_btn'} onClick={() => search(searchQuery)}>
          <Search aria-hidden="true" height={16}/>
          <span className="sr-only">Filter</span>
        </button>
      </div>
    </div>}
  </>);
};

InputText.propTypes = {
  size: PropTypes.oneOf(Object.keys(InputSize)),
  parameterKey: PropTypes.string.isRequired,
  params: PropTypes.instanceOf(URLSearchParams).isRequired,
  history: PropTypes.object.isRequired,
  placeholder: PropTypes.string.isRequired,
  className: PropTypes.string
};

export const InputTextForUrlParam = (props) => <InputText history={RouterHistory} params={getURLSearchParams()} {...props} />;
InputTextForUrlParam.propTypes = {
  size: PropTypes.oneOf(Object.keys(InputSize)),
  placeholder: PropTypes.string.isRequired,
  parameterKey: PropTypes.string.isRequired
};

export const InputQueryForUrl = (props) => <InputTextForUrlParam parameterKey={QueryParameter.query.key} history={RouterHistory} params={getURLSearchParams()} {...props} />;
InputQueryForUrl.propTypes = {
  size: PropTypes.oneOf(Object.keys(InputSize)),
  placeholder: PropTypes.string.isRequired
};

export const InputQueryWithCallback = ({parameterKey = QueryParameter.query.key, onSearch, ...rest}) => {
  const [history, setHistory] = useState(null);
  useEffect(() => {
    const inMemoryHistory = buildMemoryHistory();
    setHistory(inMemoryHistory);
    return inMemoryHistory.listen((location, _) => {
      onSearch(new URLSearchParams(location.search).get(parameterKey));
    });
  }, [onSearch, parameterKey]);
  if (!history) return null;
  return <InputText withSearchOption={true} parameterKey={parameterKey} history={history} params={new URLSearchParams(history.location.search)} {...rest} />;
};
InputQueryWithCallback.propTypes = {
  size: PropTypes.oneOf(Object.keys(InputSize)),
  placeholder: PropTypes.string.isRequired,
  onSearch: PropTypes.func.isRequired
};
