import React from 'react';
import { ReactComponent as ShapeBlob } from '../../../images/shapes/blurs/blur-1.svg';
import { ReactComponent as DividerCurve } from '../../../images/shapes/curves/curve-2.svg';
import { ReactComponent as BottomDivider } from '../../../images/shapes/curves/curve-1.svg';
import { ReactComponent as Process } from '../../../images/homepage/process.svg';
import dashboardSketchImg from '../../../images/homepage/coliberty-dashboard-icon.png';
import { DocView, Doc, Keys, Folder } from '../../../main/icons';
import { IsNotLoggedIn } from '../../../auth/AccessConditionals';
import { useAuthContext } from '../../../auth/AuthContextProvider';
import { FormattedMessage } from 'react-intl';

function FeatureCard ({ svgIcon: SvgComponent, title, text }) {
  return (<div className="card feature-card border-white mb-5 shadow-lg rounded mt-5">
    <div className="text-center">
      <div className="d-inline-block bg-white rounded-circle mt-n5 p-4">
        <SvgComponent height={50} />
      </div>
    </div>
    <div className="card-body pt-1">
      <h2 className="font-weight-lighter">{title}</h2>
      <p className="text-muted mb-0">{text}</p>
    </div>
  </div>);
}

export function HomePageContent () {

  const { loginWithRedirect } = useAuthContext();

  return (<>
    <section className="position-relative py-4 py-md-5 mb-5">
      <div className="shape shape-fluid-x shape-blur-1 svg-shim text-light">
        <ShapeBlob/>
      </div>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12 col-md-6 order-md-2">

            <div className="img-skewed img-skewed-left mb-8 mb-md-0 p-1">
              <img src={dashboardSketchImg} alt="Dashboard preview sketch"
                className="screenshot img-fluid mw-md-50 border-primary img-skewed-item-left"
                style={{ borderWidth: 2, borderStyle: 'solid', borderRadius: '1rem' }} />
            </div>

          </div>
          <div className="col-12 col-md-6 order-md-1">

            <h1 className="display-4">
              Collaboration <br/>
              <span className="text-primary">simplified</span>.
            </h1>

            <p className="lead text-muted mb-6 mb-md-8">
              Dapian simplifies the process of completing Data Protection Impact Assessments (DPIAs). Sign in to start the screening tool and complete a DPIA.
            </p>

            <IsNotLoggedIn>
              <button className="btn btn-lg btn-primary lift" onClick={() => loginWithRedirect()}>
                <FormattedMessage id="general.log_in"/> / <FormattedMessage id="general.sign_up"/>
              </button>
            </IsNotLoggedIn>

          </div>
        </div>
      </div>
    </section>

    <section className="container py-3">
      <div className="row">
        <div className="col-sm-6 col-md-4 text-center mb-4">
          <h2 className="font-weight-lighter">Collaboration</h2>
          <p className="text-muted">Invite colleagues and partners to contribute to the DPIA process and share your progress with them.</p>
        </div>
        <div className="col-sm-6 col-md-4 text-center mb-4">
          <h2 className="font-weight-lighter">Notifications</h2>
          <p className="text-muted">Alerts/notifications of updates and changes are sent to interested parties (relevant people), improving response times enabling a more efficient process.</p>
        </div>
        <div className="col-sm-6 col-md-4 text-center mb-4">
          <h2 className="font-weight-lighter">Messaging</h2>
          <p className="text-muted">For users who don't wish to create an identity/account with Dapian, you can respond to notifications via email and still remain fully involved in the process.</p>
        </div>
      </div>
    </section>

    <section className="container py-3">
      <div className="row mt-3 mb-5">
        <div className="col-lg-8 offset-lg-2 d-flex justify-content-center align-items-center">
          <div className="mr-4">
            <Process height={200} width={200} style={{fill: "#cad4e3"}} />
          </div>
          <div>
            <h2 className="font-weight-lighter">Remove the complexity</h2>
            <p className="text-muted">Dapian has been designed by Public Sector IG experts. It empowers the wider organisation to take responsibility for their own DPIAs, by explaining the process of assessing and reducing data protection risk, in plain English.</p>
          </div>
        </div>
      </div>

    </section>

    <div className="position-relative">
      <div className="shape shape-bottom shape-fluid-x svg-shim text-light">
        <DividerCurve/>
      </div>
    </div>
    <div className="bg-light mb-5">

      <section className="container-fluid py-4 py-md-5 px-sm-5 bg-light">

        <h1 className="text-center font-weight-lighter mb-4">Solution features</h1>

        <div className="row justify-content-center align-items-start">
          <div className="col-sm-6 text-center" style={{maxWidth: 400}}>
            <FeatureCard svgIcon={Doc} title={'Tasks'}
              text={'Assign tasks to colleagues and partners requesting their input to progress the DPIA.'}/>
          </div>
          <div className="col-sm-6 text-center" style={{maxWidth: 400}}>
            <FeatureCard svgIcon={DocView} title={'Screening questions'}
              text={'An interactive tool to determine if a DPIA is required and which type of template would best suit your needs, based on the answers provided.'}/>
          </div>
          <div className="col-sm-6 text-center" style={{maxWidth: 400}}>
            <FeatureCard svgIcon={Keys} title={'Authentication'}
              text={'Role based accessibility ensures only the appropriate roles within and outside of your organisation can participate/contribute in the process, governed by an administrator.'}/>
          </div>
          <div className="col-sm-6 text-center" style={{maxWidth: 400}}>
            <FeatureCard svgIcon={Folder} height={150} width={150} title={'Cloning a DPIA'}
              text={'The ability to clone an existing DPIA held in the library and edit it to your company’s requirements to save both time and resources.'}/>
          </div>
        </div>

      </section>

      <div className="position-relative">
        <div className="shape shape-bottom shape-fluid-x svg-shim text-white">
          <BottomDivider/>
        </div>
      </div>

    </div>

  </>);
}
