import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { WithPermission } from '../auth/AccessConditionals';
import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import UserPicture from '../shared/UserPicture';
import { toUkDateTimeString } from '../search/MemorableDate';
import { useAppState } from '../main/AppState';
import { FormForSchema } from '@lookinglocal/react-jsonschema-form-extensions';
import Moment from 'react-moment';
import axios from 'axios';
import * as AppWidgets from '../form/widgets/AppWidgets';
import { useAuthContext } from '../auth/AuthContextProvider';

const sp = (e) => e.stopPropagation();

export const ProfileLink = ({ id, label }) => {
  return (
    <>
      <WithPermission name="profile:read">
        <Link to={`/profiles/show/${id}`} onClick={sp}>
          {label}
        </Link>
      </WithPermission>
      <WithPermission name="profile:read" inverse={true}>
        {label}
      </WithPermission>
    </>
  );
};

ProfileLink.propTypes = {
  id: PropTypes.any.isRequired,
  label: PropTypes.string.isRequired
};

export const ProfileCardDetails = ({ model, showSignupDetails = false }) => {
  const authContext = useAuthContext();
  return (
    <>
      <dl className="row">
        <dt className="col-sm-3" />
        <dd className="col-sm-9">
          <UserPicture email={model.email} size={50} />
        </dd>
        <dt className="col-sm-3">User Id</dt>
        <dd className="col-sm-9">{model.id}</dd>
        <dt className="col-sm-3">Name</dt>
        <dd className="col-sm-9">{model.name}</dd>
        <dt className="col-sm-3">Mobile</dt>
        <dd className="col-sm-9">{model.mobile || 'N/A'}</dd>
        <dt className="col-sm-3">Email</dt>
        <dd className="col-sm-9">{model.email}</dd>
        <dt className="col-sm-3">First created</dt>
        <dd className="col-sm-9">{toUkDateTimeString(model.createdAt)}</dd>
        <dt className="col-sm-3">Details last updated</dt>
        <dd className="col-sm-9">{toUkDateTimeString(model.updatedAt)}</dd>
        <WithPermission name="role:superadmin">
          <dt className="col-sm-3">Username</dt>
          <dd className="col-sm-9">{model.username}</dd>
        </WithPermission>

        {showSignupDetails && (
          <>
            <dt className="col-sm-3">Did sign up</dt>
            <dd className="col-sm-9">
              {model.username != null ? 'Yes' : 'No'}
            </dd>
          </>
        )}
      </dl>
    </>
  );
};
ProfileCardDetails.propTypes = {
  model: PropTypes.object.isRequired,
  showSignupDetails: PropTypes.bool
};

export const ProfileAuthCardDetails = ({ model }) => {
  const lastLoginDate = model.user.last_login;
  const emailVerified = model.user.email_verified;
  return (
    <dl className="row">
      <dt className="col-sm-3">Last login</dt>
      <dd className="col-sm-9">
        {lastLoginDate && (
          <>
            <Moment from={lastLoginDate} utc={true} ago={true} /> ago
          </>
        )}
        {!lastLoginDate && 'Have not attempted to login yet'}
      </dd>
      <dt className="col-sm-3">Email verification</dt>
      <dd className="col-sm-9">
        {emailVerified ? (
          <span className="badge badge-success">Done</span>
        ) : (
          'Not yet'
        )}
      </dd>
    </dl>
  );
};
ProfileAuthCardDetails.propTypes = {
  model: PropTypes.shape({
    user: PropTypes.shape({
      name: PropTypes.string,
      last_login: PropTypes.string,
      email: PropTypes.string,
      email_verified: PropTypes.bool,
      identities: PropTypes.arrayOf(
        PropTypes.shape({
          provider: PropTypes.string
        })
      )
    }),
    roles: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        description: PropTypes.string
      })
    )
  })
};

export function ProfileNotificationsCard({ model }) {
  const { availableSubscriptions } = useAppState();
  const activeSubscriptions = model.subscriptions.reduce((memo, sub) => {
    memo[sub] = sub;
    return memo;
  }, {});
  return (
    <div className="card mb-4">
      <div className="card-header">
        <h2>
          <FormattedMessage id="model.notifications" />
        </h2>
      </div>
      <ul className="list-group list-group-flush">
        {availableSubscriptions.map((subscription) => (
          <li className="list-group-item" key={subscription}>
            <span className="d-block">
              {activeSubscriptions[subscription] && (
                <span className="badge badge-success">Active</span>
              )}
              {!activeSubscriptions[subscription] && (
                <span className="badge badge-danger">Inactive</span>
              )}
              <span className="ml-2">
                <FormattedMessage id={`model.notification.${subscription}`} />
              </span>
            </span>
            <span className="small text-muted">
              <FormattedMessage
                id={`model.notification.${subscription}.help`}
              />
            </span>
          </li>
        ))}
      </ul>
      {!model.subscriptions.length && (
        <div className="card-body">
          <FormattedMessage id="model.notifications.empty" />
        </div>
      )}
    </div>
  );
}

export function ProfileTasksCard({ model, reassignTasks, reload }) {
  const [totalTasks, setTotalTasks] = useState(0);
  const [reassignableTasks, setReassignableTasks] = useState(0);

  useEffect(() => {
    axios
      .get('/api/task', { params: { assigneeId: model.id, max: 1 } })
      .then((response) => {
        if (response.data) setTotalTasks(response.data.total);
      });
    axios.get(`/api/task/reassignable/${model.id}`).then((response) => {
      if (response.data) setReassignableTasks(response.data.total);
    });
  }, [model, reload]);

  return (
    <div className="card mb-4">
      <div className="card-header">
        <h2>
          <FormattedMessage id="nav.profiles.assigned.task" />
        </h2>
        <WithPermission name="task:update">
          {reassignableTasks > 0 && (
            <button
              className="float-right btn btn-sm btn-outline-secondary"
              onClick={() => reassignTasks(model.id)}
            >
              <FormattedMessage id="nav.profiles.re-assign" />
            </button>
          )}
        </WithPermission>
      </div>
      <div className="card-body">
        {totalTasks > 0 && (
          <>
            This profile has{' '}
            <Link
              title="View all assigned tasks"
              to={`/tasks/list?assigneeId=${model.id}|${model.name}`}
              onClick={sp}
            >{`${totalTasks} assigned tasks`}</Link>
            {reassignableTasks > 0 && (
              <>, of which {`${reassignableTasks}`} can be reassigned</>
            )}
            .
          </>
        )}
        {totalTasks === 0 && <>This profile does not have any tasks.</>}
      </div>
    </div>
  );
}

const ProfileSchema = {
  type: 'object',
  properties: {
    name: {
      title: 'Name',
      type: 'string',
      minLength: 1
    },
    mobile: {
      title: 'Mobile phone number',
      type: 'string',
      format: 'phone-mobile'
    },
    email: {
      title: 'Email',
      type: 'string',
      format: 'email',
      description: 'Email must be unique'
    }
  },
  required: ['name', 'email']
};

export const ProfileForm = (props) => {
  const emailNotEditable =
    props && props.values && props.values.username != null;
  const schema = Object.assign({}, ProfileSchema);
  schema.properties.email.readOnly = !!emailNotEditable;
  schema.properties.email.description = emailNotEditable
    ? 'Cannot change email after authentication account is set up or after invitation was sent'
    : 'Email must be unique. It cannot be changed after person logs in with it or after invitation is sent.';
  const uiSchema = {};
  if (emailNotEditable) {
    uiSchema.email = { 'ui:readonly': true };
  }
  return (
    <FormForSchema
      schema={schema}
      uiSchema={uiSchema}
      {...props}
      appWidgets={AppWidgets.appWidgets}
    />
  );
};

export const MyProfileForm = (props) => {
  const emailNotEditable =
    props && props.values && props.values.username != null;
  const schema = Object.assign({}, ProfileSchema);
  schema.properties.email.readOnly = !!emailNotEditable;
  schema.properties.email.description = emailNotEditable
    ? 'Email cannot be changed'
    : 'Email must be unique.';
  const uiSchema = {};
  if (emailNotEditable) {
    uiSchema.email = { 'ui:readonly': true };
  }
  return (
    <FormForSchema
      schema={schema}
      uiSchema={uiSchema}
      {...props}
      appWidgets={AppWidgets.appWidgets}
    />
  );
};

export const NotificationPreferencesForm = (props) => {
  const intl = useIntl();
  const { availableSubscriptions } = useAppState();
  const schema = {
    type: 'object',
    properties: {
      subscriptions: {
        title: intl.formatMessage({ id: 'model.notifications' }),
        type: 'array',
        items: {
          type: 'string',
          enum: availableSubscriptions,
          enumNames: availableSubscriptions.map((sub) =>
            intl.formatMessage({ id: `model.notification.${sub}` })
          )
        },
        uniqueItems: true
      }
    }
  };
  const uiSchema = {
    subscriptions: {
      'ui:widget': 'checkboxes'
    }
  };
  return (
    <FormForSchema
      schema={schema}
      uiSchema={uiSchema}
      {...props}
      appWidgets={AppWidgets.appWidgets}
    />
  );
};
