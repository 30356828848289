import React, {useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as Showdown from "showdown";

const converter = new Showdown.Converter({tables: true, simplifiedAutoLink: true});

export const MarkdownProcessor = ({ contents }) => {
  const [markdown, setMarkdown] = useState(null);

  useEffect(() => {
    setMarkdown({ __html: converter.makeHtml(contents)});
  }, [contents]);

  return(<div dangerouslySetInnerHTML={markdown} />);
};

MarkdownProcessor.propTypes = {
  contents: PropTypes.string.isRequired
};
