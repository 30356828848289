import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { PromptModal } from '../shared/Modal';
import axios from 'axios';
import ProfileRolesCard from '../auth/ProfileRolesCard';
import ModelInstanceView from '../models/ModelInstanceView';
import ListLoader from '../loaders/ListLoader';
import { parseAxiosError } from '../shared/AxiosResponseErrorParser';
import { WithPermission } from '../auth/AccessConditionals';
import RouterHistory from '../main/RouterHistory';
import { Page, PageNav } from '../shared/Page';
import { FormattedMessage, useIntl } from 'react-intl';
import {ProfileAuthCardDetails, ProfileCardDetails, ProfileNotificationsCard, ProfileTasksCard} from "./Profile";
import ReassignTaskFlowModal from '../task/ReassignTaskFlowModal';
import {useDynamicDocumentTitle} from "../shared/hooks/DocumentTitleHooks";

const ProfileDetails = ({model}) => {
  const intl = useIntl();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [revokeAccessModalOpen, setRevokeAccessModalOpen] = useState(false);
  const [restoreAccessModalOpen, setRestoreAccessModalOpen] = useState(false);
  const [reload, setReload] = useState(false);
  const [profileId, setProfileId] = useState(null);
  const [reassignModalOpen, setReassignModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const sendVerificationEmail = () => {
    if (!model.username) return;
    setLoading(true);
    axios.post(`/api/auth/verification/send/${encodeURIComponent(model.username)}`)
      .catch(e => setErrorMessage(parseAxiosError(e).getMessage()))
      .finally(() => setLoading(false));
  };

  useDynamicDocumentTitle(() => {
    return `User profile: ${model.name}`;
  });

  const removeProfile = () => {
    setLoading(true);
    setDeleteModalOpen(false);
    axios.delete(`/api/profile/${model.id}`)
      .then(() => {
        setLoading(false);
        RouterHistory.push('/profiles/list');
      })
      .catch(e => {
        setLoading(false);
        setErrorMessage(parseAxiosError(e).getMessage());
      });
  };

  const handleUserAccess = (revokeAccess) => {
    let api = `/api/profile/revoke/${model.id}`;
    if (revokeAccess) setRevokeAccessModalOpen(false);
    else {
      api = `/api/profile/restore/${model.id}`;
      setRestoreAccessModalOpen(false);
    }

    setLoading(true);
    axios.post(api)
      .then(() => {
        setLoading(false);
        RouterHistory.push('/profiles/list');
      })
      .catch(e => {
        setLoading(false);
        setErrorMessage(parseAxiosError(e).getMessage());
      });
  };

  const reassignAllTasks = (toProfileId, comment) => {
    setReassignModalOpen(false);
    if (toProfileId && profileId !== toProfileId) {
      setLoading(true);
      axios.post('/api/task/reassign', {fromAssigneeId: profileId, toAssigneeId: toProfileId, comment: comment}).then(() => {
        setLoading(false);
        setReload(!reload);
      }).catch(e => {
        setLoading(false);
        setErrorMessage(parseAxiosError(e).getMessage());
      });
    }
    setProfileId(null);
  };

  const handleReassignTasks = (profileId) => {
    setProfileId(profileId);
    setReassignModalOpen(true);
  };

  return (<Page>
    <PageNav title={`User profile: #${model.id}`}>
      <span className="mx-auto" />
      <WithPermission name="profile:update">
        {!model.locked && <>
          <Link className="btn btn-sm btn-outline-secondary mr-1" to={`/profiles/edit/${model.id}`} aria-label="Edit details">Edit details</Link>
          {!model.username && <Link className="btn btn-sm btn-primary mr-1" to={`/profiles/invite/${model.id}`} aria-label="Create login account">Create login account</Link>}
          {model.username && <button className="btn btn-sm btn-primary mr-1" onClick={sendVerificationEmail} aria-label="Send account verification email">Send account verification email</button>}
          <Link className="btn btn-sm btn-outline-secondary mr-1" to={`/profiles/edit/${model.id}/roles`} aria-label="Edit roles">Edit roles</Link>
          <Link className="btn btn-sm btn-outline-secondary mr-1" to={`/profiles/edit/${model.id}/preferences`} aria-label="Notification subscriptions"><FormattedMessage id="nav.profiles.preferences" /></Link>
        </>}
      </WithPermission>
      <WithPermission name="profile:revoke">
        {!model.locked && <button className="btn btn-sm btn-outline-danger mr-1" onClick={() => setRevokeAccessModalOpen(true)} aria-label="Revoke Access">Revoke Access</button>}
        {model.locked && <button className="btn btn-sm btn-outline-secondary mr-1" onClick={() => setRestoreAccessModalOpen(true)} aria-label="Restore Access">Restore Access</button>}
      </WithPermission>
      <WithPermission name="profile:delete">
        <button className="btn btn-sm btn-outline-danger" onClick={() => setDeleteModalOpen(true)} aria-label="Delete">Delete</button>
      </WithPermission>
    </PageNav>

    {deleteModalOpen && <PromptModal title="Are you sure?" text="User profile will be permanently removed!"
      onCancel={() => setDeleteModalOpen(false)}
      onSuccess={() => removeProfile()}/>}

    {reassignModalOpen && <ReassignTaskFlowModal
      modalTitle={intl.formatMessage({id: "nav.profiles.re-assign"})}
      onModalClose={reassignAllTasks}
    />}

    {revokeAccessModalOpen && <PromptModal title="Are you sure?" text="User will NOT be able to access the system!"
      onCancel={() => setRevokeAccessModalOpen(false)}
      onSuccess={() => handleUserAccess(true)}/>}

    {restoreAccessModalOpen && <PromptModal title="Are you sure?" text="User access will be restored!"
      onCancel={() => setRestoreAccessModalOpen(false)}
      onSuccess={() => handleUserAccess(false)}/>}

    {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}

    <div className="row">
      <div className="col-sm-12 col-lg-6">
        <div className="card mb-4">
          <div className="card-body">
            <ProfileCardDetails model={model} showSignupDetails={true}/>
            {!loading && model.username && <ModelInstanceView
              apiUrl={`/api/profile/${model.id}/auth`}
              viewItem={ProfileAuthCardDetails}
              notFoundMessage="No more account details available" />}
          </div>
        </div>
      </div>
      <div className="col-sm-12 col-lg-6">
        {loading && <ListLoader/>}
        {!loading && !model.username && !model.locked && <div className="alert alert-warning">
          This person has not signed up yet
        </div>}
        {!loading && !model.username && model.locked && <div className="alert alert-warning">
          This person does not have access (access has been revoked)
        </div>}
        {!loading && <ModelInstanceView
          apiUrl={`/api/auth/roles/${model.id}`}
          viewItem={ProfileRolesCard}
          notFoundMessage="There are no roles added to current profile" />}
        <WithPermission name="task:update">
          <ProfileTasksCard model={model} reload={reload} reassignTasks={handleReassignTasks} />
        </WithPermission>
        {!loading && <ModelInstanceView
          apiUrl={`/api/profile/${model.id}/preferences`}
          viewItem={ProfileNotificationsCard} />}
      </div>
    </div>
  </Page>);
};

ProfileDetails.propTypes = {
  model: PropTypes.object.isRequired
};

export default ProfileDetails;
