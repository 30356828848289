import { Draft07, JsonSchema } from 'json-schema-library';
import { JsonPointer, JsonStringPointerListItem } from 'json-ptr';
import _ from 'lodash';
import { pointsToArrayItem } from '../functions';
import { CaseConfiguration, TaskConfiguration } from './Config';
import { BooleanMap } from './General';

/**
 * Available/known core tables
 */
export type CoreTable = 'cases' | 'tasks';

/**
 * Description of a core (table) field and its extraction properties
 */
export interface ReportField {
  /**
   * The name of the field in the database
   */
  columnName: string;

  /**
   * The type of the field in the database
   */
  type: string;

  /**
   * The label to be used for the field within extraction jobs
   */
  label: string;

  /**
   * An optional JSON pointer
   */
  pointer?: string;
}

/**
 * Selection mix-in
 */
export type SelectionStatus = {
  /**
   * Whether a given column is currently selected within a template
   */
  selected: boolean;
};

/**
 * A report table field with some additional selection cruft
 */
export type SelectableReportField = SelectionStatus & ReportField;

/**
 * For each available core table, we have a list of fields which will be
 * reported on
 */
export type SelectableReportFieldSet = {
  [key: string]: SelectableReportField[];
};

/**
 * A template which determines the format of a given report
 */
export type ReportTemplate = {
  /**
   * The name of the template
   */
  name?: string;

  /**
   * Core table column definitions
   */
  coreTableFields?: SelectableReportFieldSet;

  /**
   * Task form column definitions
   */
  coreTaskFormFields?: SelectableReportFieldSet;

  /**
   * Case form column definitions
   */
  coreCaseFormFields?: SelectableReportFieldSet;

  /**
   * The currently selected task forms for inclusion within the report
   */
  taskFormInclusions: BooleanMap;

  /**
   * The currently selected case forms for inclusion within the report
   */
  caseFormInclusions: BooleanMap;
};

/**
 * Combinators for use within a filter
 */
export type ReportFilterCombinator = 'and' | 'or';

/**
 * A filter which determines what is going to be selected into a given report. Currently only supports
 * filtration based on core table fields and elements from within the core case task form
 */
export type ReportFilter = {
  /**
   * The name of the filter (to be used for persistence purposes)
   */
  name?: string;

  /**
   * Core table filter clauses
   */
  coreTableClauses: {
    [key in CoreTable]: ReportFilterClause[];
  };

  /**
   * Core case form filter clauses
   */
  coreCaseFormClauses: ReportFilterClause[];
};

/**
 * Bind the two type of clauses together
 */
export type ReportFilterClause =
  | ReportFilterAtomicClause
  | ReportFilterCompoundClause;

/**
 * Used for de-structuring filter clause targets
 */
export type ReportFilterClauseDestructuredId = {
  parent: string;
  fieldName: string;
};

/**
 * Essentially an enumeration type to track what kind of clause we are dealing with
 */
export type ReportFilterClauseType = 'table' | 'case' | 'task';

/**
 * Enumeration of comparison operators
 */
export type ReportFilterComparisonOperator =
  | 'eq'
  | 'lte'
  | 'lt'
  | 'gte'
  | 'gt'
  | 'neq'
  | 'contains';

/**
 * An atomic filter clause, possibly negated
 */
export type ReportFilterAtomicClause = {

  /**
   * The type of the clause
   */
  clauseType: ReportFilterClauseType;

  /**
   * Unique id for the clause, only used by the UX, stripped by the server
   */
  id: string;

  /**
   * If this clause is a negation
   */
  negated?: boolean;

  /**
   * If the target refers to a JSON pointer address
   */
  isPointer?: boolean;

  /**
   * Either a column name or a JSON pointer
   */
  target: string;

  /**
   * The comparison operator
   */
  operator?: ReportFilterComparisonOperator;

  /**
   * The value to look for
   */
  value: string;

};

/**
 * A compound filter clause
 */
export type ReportFilterCompoundClause = {
  /**
   * The type of the clause
   */
  clauseType: ReportFilterClauseType;

  /**
   * Unique id for the clause
   */
  id: string;

  /**
   * The combinator to use
   */
  combinator: ReportFilterCombinator;

  /**
   * The left side of the clause
   */
  left: ReportFilterAtomicClause | ReportFilterCompoundClause;

  /**
   * The right side of the clause
   */
  right: ReportFilterAtomicClause | ReportFilterCompoundClause;
};

/**
 * Construct an atomic filter clause based on
 * @param clauseType
 * @param id
 * @param target
 * @param value
 * @param operator
 */
export function atomicFilterClause(
  clauseType: ReportFilterClauseType,
  id: string,
  target: string,
  value: string,
  operator: ReportFilterComparisonOperator,
): ReportFilterAtomicClause {
  return {
    clauseType: clauseType,
    id: id,
    negated: false,
    operator: operator,
    target: target,
    value: value
  };
}

/**
 * Combine two filter clauses to spawn a third
 * @param clauseType
 * @param id
 * @param left
 * @param right
 * @param combinator
 */
export function compoundFilterClause(
  clauseType: ReportFilterClauseType,
  id: string,
  left: ReportFilterClause,
  right: ReportFilterClause,
  combinator: ReportFilterCombinator
): ReportFilterCompoundClause {
  return {
    clauseType: clauseType,
    id: id,
    combinator: combinator,
    left: left,
    right: right
  };
}

/**
 * Find a filter clause with a given id for a specific core table
 * @param table the core table name
 * @param id the specific id for the clause
 * @param filter the filter to search in
 */
export function findCoreTableFilterClause(
  table: CoreTable,
  id: string,
  filter: ReportFilter
): ReportFilterClause | undefined {
  if (filter.coreTableClauses) {
    return filter.coreTableClauses[table].find((clause) => clause.id === id);
  }
  return undefined;
}

/**
 * Find a filter clause with a given id for the current case form
 * @param id the specific id for the clause
 * @param filter the filter to search in
 */
export function findCoreCaseFormFilterClause(
  id: string,
  filter: ReportFilter
): ReportFilterClause | undefined {
  if (filter.coreCaseFormClauses) {
    return filter.coreCaseFormClauses.find((clause) => clause.id === id);
  }
  return undefined;
}

/**
 * Function which will mutate the current filter by applying discrete canonical operations to it
 * @param filter the filter to mutate
 * @param additions
 * @param removals
 */
export function mutateReportFilter(
  filter: ReportFilter,
  additions: ReportFilterClause[],
  removals: { clauseType: ReportFilterClauseType; id: string }[]
): ReportFilter {
  const mutated = _.cloneDeep(filter);

  // remove clauses
  removals.forEach((removal) => {
    switch (removal.clauseType) {
      case 'table':
        mutated.coreTableClauses['cases'] = mutated.coreTableClauses[
          'cases'
        ].filter((value) => value.id !== removal.id);
        mutated.coreTableClauses['tasks'] = mutated.coreTableClauses[
          'tasks'
        ].filter((value) => value.id !== removal.id);
        break;
      case 'case':
        mutated.coreCaseFormClauses = mutated.coreCaseFormClauses.filter(
          (clause) => clause.id !== removal.id
        );
        break;
    }
  });

  // add new clauses
  additions.forEach((addition) => {
    let clause: ReportFilterClause;
    if (_.has(addition, 'combinator')) {
      clause = addition as ReportFilterCompoundClause;
    } else {
      clause = addition as ReportFilterAtomicClause;
    }
    switch (addition.clauseType) {
      case 'table':
        const id = clause.id.split('.');
        mutated.coreTableClauses[id[0] as CoreTable] =
          mutated.coreTableClauses[id[0] as CoreTable].filter(
            (item) => item.id !== clause.id
          );
        mutated.coreTableClauses[id[0] as CoreTable].push(clause);
        break;
      default:
        mutated.coreCaseFormClauses = mutated.coreCaseFormClauses.filter(
          (item) => item.id !== clause.id
        );
        mutated.coreCaseFormClauses.push(clause);
        break;
    }
  });

  return mutated;
}

/**
 * A general settings structure for an individual report
 */
export type ReportSettings = {
  /**
   * The currently active case type reference
   */
  caseRef?: string;

  /**
   * The title of the template currently being applied
   */
  templateTitle: string;
};

/**
 * Convenience type for use during mapping ops
 */
export type SchemaMapElement = {
  ptr: JsonStringPointerListItem;
  schema: JsonSchema;
};

/**
 * Type for a column mutation function
 */
type FieldMutationFunction = (
  columns: SelectableReportField[]
) => SelectableReportField[];

/**
 * This function takes a report template, and a key which identifies one of the many
 * fields structures within the template.  It also then takes a mutation function which
 * is applied to the fields structure if the provided key is valid.
 * @param template the existing `ReportTemplate`
 * @param fieldsKey a key determining which field set is going to be mutated
 * @param mutationFunc a mutation function to apply to the field set
 */
export function mutateTemplateFieldSet(
  template: ReportTemplate,
  fieldsKey: string,
  mutationFunc: FieldMutationFunction
): ReportTemplate {
  const hasPrefix = fieldsKey.indexOf(':') !== -1;
  let newTemplate: ReportTemplate;

  // two cases, dependent on whether we're updating core table columns or
  // form field columns
  if (!hasPrefix) {
    switch (fieldsKey) {
      case 'cases': {
        const fields = template.coreTableFields!!['cases'];
        return {
          ...template,
          coreTableFields: {
            ...template.coreTableFields,
            cases: mutationFunc(fields)
          }
        };
      }
      case 'tasks': {
        const fields = template.coreTableFields!!['tasks'];
        return {
          ...template,
          coreTableFields: {
            ...template.coreTableFields,
            tasks: mutationFunc(fields)
          }
        };
      }
    }
  } else {
    const [prefix, key] = fieldsKey.split(':');
    switch (prefix) {
      case 'case': {
        const fields = template.coreCaseFormFields!![key];
        if (fields) {
          newTemplate = {
            ...template,
            coreCaseFormFields: {
              ...template.coreCaseFormFields
            }
          };
          newTemplate.coreCaseFormFields!![key] = mutationFunc(fields);
          return newTemplate;
        }
        break;
      }
      case 'task': {
        const fields = template.coreTaskFormFields!![key];
        if (fields) {
          newTemplate = {
            ...template,
            coreTaskFormFields: {
              ...template.coreTaskFormFields
            }
          };
          newTemplate.coreTaskFormFields!![key] = mutationFunc(fields);
          return newTemplate;
        }
        break;
      }
    }
  }
  return template;
}

/**
 * A utility function that consumes a given form schema, and maps (sensible) elements within the
 * schema to a `SelectableFormTableField` element which is suitable for use within a report
 * template.  Yeah, this is some fairly twisted stuff given the absolutely horrible nature of
 * JSON schema...
 * @param raw
 */
export function mapFormFieldsToReportFields(raw: any): SelectableReportField[] {
  const schema = new Draft07(raw);
  const template = schema.getTemplate(undefined, undefined, {
    addOptionalProps: true
  });
  const templatePtrs = JsonPointer.listPointers(template);
  const filteredPtrs = templatePtrs.filter((value) => {
    return (
      value.pointer !== '' &&
      !pointsToArrayItem(JsonPointer.create(value.pointer))
    );
  });

  const mappedSchemas: SchemaMapElement[] = filteredPtrs
    .map((ptr) => {
      return {
        ptr: ptr,
        schema: schema.getSchema({ pointer: ptr.pointer }) as JsonSchema
      };
    })
    .filter((item) => {
      if (_.has(item.schema, 'type')) {
        const schemaType = _.get(item.schema, 'type');
        return schemaType !== 'object';
      } else {
        return false;
      }
    });

  // expand any array sub schema items, so that we actually get paths to their child properties
  const canonicalSchemas: SchemaMapElement[] = mappedSchemas.concat(
    expandArraySchemas(mappedSchemas)
  );

  // finally map into a format which is more tabular friendly
  return canonicalSchemas.map((item) => {
    return {
      type: item.schema.type,
      selected: true,
      columnName: item.ptr.pointer,
      label: item.schema.title,
      pointer: item.ptr.pointer
    };
  });
}

/**
 * We need to synthesise elements for array child schemas, because the templating functionality
 * is pure crap when it comes to creating array entries (and we use a blank template to generate our
 * initial set of json pointers).
 * @param source
 */
function expandArraySchemas(source: SchemaMapElement[]): SchemaMapElement[] {
  const results: SchemaMapElement[] = [];
  let root: JsonStringPointerListItem;
  for (let item of source) {
    if (item.schema.type === 'array') {
      root = item.ptr;
      if (item.schema.items.type === 'object') {
        for (let key of _.keys(item.schema.items.properties)) {
          results.push({
            ptr: { pointer: root.pointer + `/${key}`, value: undefined },
            schema: item.schema.items.properties[key]
          });
        }
      }
    }
  }
  return results;
}

/**
 * Helper function for checking whether a given task form is included within a template
 * @param ref
 * @param version
 * @param template
 */
export function taskFormInclusion(
  ref: string,
  version: number,
  template: ReportTemplate
): boolean {
  return template.taskFormInclusions[`${ref}_${version}`];
}

/**
 * Builds a default map of included task forms
 * @param taskConfigs
 */
export function buildDefaultTaskFormInclusionMap(
  taskConfigs: TaskConfiguration[]
): BooleanMap {
  const result: BooleanMap = {};
  taskConfigs.forEach((config) => {
    result[`${config.ref}_${config.version}`] = true;
  });
  return result;
}

/**
 * Builds a default map of included case forms (currently there will only ever be one in here)
 * @param caseConfig
 */
export function buildDefaultCaseFormInclusionMap(
  caseConfig: CaseConfiguration
): BooleanMap {
  const result: BooleanMap = {};
  result[`${caseConfig.ref}_${caseConfig.version}`] = true;
  return result;
}

/**
 * Helper function for checking whether a given task form is included within a template
 * @param ref
 * @param version
 * @param template
 */
export function caseFormInclusion(
  ref: string,
  version: number,
  template: ReportTemplate
): boolean {
  return template.caseFormInclusions[`${ref}_${version}`];
}

/**
 * Toggle the state of a specific case form inclusion within the template
 * @param template
 * @param key
 */
export function toggleCaseFormInclusion(
  template: ReportTemplate,
  key: string
): BooleanMap {
  const caseFormInclusions = _.cloneDeep(template.caseFormInclusions);
  caseFormInclusions[key] = !caseFormInclusions[key];
  return caseFormInclusions;
}

/**
 * Toggle the state of a specific case form inclusion within the template
 * @param template
 * @param key
 */
export function toggleTaskFormInclusion(
  template: ReportTemplate,
  key: string
): BooleanMap {
  const taskFormInclusions = _.cloneDeep(template.taskFormInclusions);
  taskFormInclusions[key] = !taskFormInclusions[key];
  return taskFormInclusions;
}

/**
 * HoF that will produce a function for updating field selection status
 * @param fieldNames
 */
export function updateFieldSelectionStatus(fieldNames: string[]) {
  return (fields: SelectableReportField[]) => {
    const updated = _.cloneDeep(fields);
    updated.forEach((field) => {
      field.selected = fieldNames.includes(field.columnName);
    });
    return updated;
  };
}

/**
 * HoF that returns a function to update a column label
 * @param label
 * @param columnName
 */
export function updateFieldLabel(label: string, columnName: string) {
  return (fields: SelectableReportField[]) => {
    const updated = _.cloneDeep(fields);
    for (const field of updated) {
      if (field.columnName === columnName) {
        field.label = label;
        return updated;
      }
    }
    return updated;
  };
}
