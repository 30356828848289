import React from 'react';
import { combineClassNames } from '../../shared/functions/ClassNameUtils';
import { newUUID } from '../../shared/functions';

/**
 * Props for a search dropdown widget
 */
export interface SearchDropdownWidgetProps {
  /**
   * The label for the widget
   */
  label: string;

  /**
   * The classname for the label element (prepended to the input)
   */
  labelClassName?: string;

  /**
   * Classname for the input component (a select element)
   */
  inputClassName?: string;

  /**
   * A list of items to use within the dropdown
   */
  items: object[];

  /**
   * Initial selection value
   */
  initialSelection?: string;

  /**
   * Is a blank selection allowed?
   */
  allowBlank: boolean;

  /**
   * Optional label for blanks
   */
  blankLabel?: string;

  /**
   * Label lambda
   * @param item an individual item
   */
  labelCallback: (item: object) => string;

  /**
   * Value lambda
   * @param item an individual item
   */
  valueCallback: (item: object) => string;

  /**
   * The parameter key associated with the dropdown (used during onSelect callbacks)
   */
  widgetKey: string;

  /**
   * A selection change callback
   * @param parameterKey
   * @param newValue
   */
  onValueChange: (parameterKey: string, newValue?: string) => void;
}

/**
 * Generic search dropdown widget based on a flex layout
 * @param label the label for the component
 * @param labelClassName (optional) label class name
 * @param inputClassName (optional) input class name
 * @param items a list of items to display within the dropdown
 * @param labelCallback a callback used to extract a label for each item
 * @param valueCallback a callback used to extract a value for each item
 * @param parameterKey passed through to the onSelect/onChange callback functions
 * @constructor
 */
export const SearchDropdownWidget = React.forwardRef<
  HTMLSelectElement,
  SearchDropdownWidgetProps
>((props, ref) => {
  function notifySelectionChange(
    event: React.SyntheticEvent<HTMLSelectElement, Event>
  ) {
    props.onValueChange(props.widgetKey, event.currentTarget.value);
  }

  return (
    <>
      <div className="widget" >
        <label
          htmlFor={props.widgetKey}
          className={
            props.labelClassName
              ? combineClassNames(
                props.labelClassName,
                'widget-label no-right-border input-group-text'
              )
              : 'widget-label no-right-border input-group-text'
          }
        >
          {props.label}
        </label>
        <select
          id={props.widgetKey}
          data-testid={props.widgetKey}
          value={props.initialSelection}
          onChange={(event) => notifySelectionChange(event)}
          className={
            props.inputClassName
              ? combineClassNames(props.inputClassName, [
                'select-widget-field',
                'no-left-border',
                'custom-select'
              ])
              : 'widget-field no-left-border custom-select'
          }
        >
          {props.allowBlank && (
            <option
              label={props.blankLabel ? props.blankLabel : '-------'}
              value={''}
            ></option>
          )}
          {props.items.map((item, index) => (
            <option key={newUUID()} value={props.valueCallback(item)}>
              {props.labelCallback(item)}
            </option>
          ))}
        </select>
      </div>
    </>
  );
});
