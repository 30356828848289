import React, { FC, useEffect, useState } from 'react';
import { Task } from '../../shared/types';
import useAxios from 'axios-hooks';
import { TaskLink } from '../../shared/components/TaskLink';
import { CaseLink } from '../../shared/components/CaseLink';
import { GeneralIcons } from '../../shared/components/Icons';

export interface ReferencedByTasksCardProps {
  parentCaseId: number;
  reloadCookie: string;
}

/**
 * Renders a panel that lists the tasks that reference a particular
 * case
 * @constructor
 */
export const ReferencedByTasksCard: FC<ReferencedByTasksCardProps> = ({
  parentCaseId,
  reloadCookie
}) => {
  const [relatedTasks, setRelatedTasks] = useState<Task[]>([]);
  const [reload, setReload] = useState<string>(reloadCookie);

  const [{ data, loading }, fetch] = useAxios({
    url: `/api/links/case/${parentCaseId}/tasks`
  });

  // given that we're using a hook to load the related cases, use an effect to just
  // set the related case items
  useEffect(() => {
    if (!loading) {
      setRelatedTasks(data.data);
    }
    if (reloadCookie !== reload) {
      fetch();
      setReload(reloadCookie);
    }
  }, [data, loading, fetch, reload, reloadCookie]);

  return (
    <div className="card mb-3 card-case-links" key={`links_${parentCaseId}`}>
      <div className="card-header pl-3 pr-2">
        <h2>Referenced By</h2>
      </div>
      <div
        className={'flex-column'}
        data-testid="list"
      >
        {relatedTasks?.map((related, index) => (
          <React.Fragment key={`referenced-${related.id}`}>
            <div className={'flex flex-row referenced-task-row'}>
              <span className={'flex-column referenced-task-link'}>
                <CaseLink
                  model={related.case!!}
                  truncationLimit={24}
                  newTab={true}
                  showLabel={true}
                  showId={true}
                />
              </span>
            </div>
            <div className={'flex flex-row referenced-task-row'}>
              <span className={'flex-column referenced-task-link'}>{GeneralIcons.Link('child-task-icon')}</span>
              <span className={'flex-column referenced-task-link'} style={{marginLeft : '-18px'}}>
                <TaskLink
                  model={related}
                  newTab={true}
                  truncationLimit={24}
                  showLabel={true}
                  showRefBadge={true}
                  showId={true}
                />
              </span>
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};
