import moment from 'moment';

moment.locale('en');

// globally change moments locale and update "a few seconds ago" to "just now"
moment.updateLocale('en', {
  relativeTime : {
    past: function(input) {
      return input === 'just now' ? input : input + ' ago';
    },
    s: 'just now'
  }
});
