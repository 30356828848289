export const InputSize = Object.freeze({
  sm: 'sm',
  md: 'md',
  lg: 'lg'
});

export const sizeToInputGroupClassName = (size) => {
  switch (size) {
    case InputSize.sm:
      return 'input-group-sm';
    case InputSize.lg:
      return 'input-group-lg';
    default:
      return '';
  }
};

export const sizeToFormControlClassName = (size) => {
  switch (size) {
    case InputSize.sm:
      return 'form-control-sm';
    case InputSize.lg:
      return 'form-control-lg';
    default:
      return '';
  }
};


