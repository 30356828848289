import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import moment from "moment";
import Dropdown from "react-bootstrap/Dropdown";
import { CSVLink } from "react-csv";
import { Download, Close } from "../main/icons";
import { CircularProgressBar } from "./CircularProgressBar";

const ExportData = ({
  apiEndpoint,
  apiParams,
  pagedDownload,
  showProgress,
  format,
  buttonTitle,
  file,
  fileExt,
}) => {
  const csvLinkRef = React.useRef(null);
  const downloadButtonTitle = buttonTitle;
  const downloadFormat = format;
  const [response, setResponse] = useState(null);
  const [headers, setHeaders] = useState(null);
  const [filename, setFilename] = useState("dpian.csv");
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const cancelRef = React.useRef(false);
  const pageSize = 1000;

  useEffect(() => {
    if (response && csvLinkRef && csvLinkRef.current) {
      csvLinkRef.current.link.click();
      // delay 2 sec for quick downloads (to avoid multiple clicks);
      setTimeout(() => {
        setProgress(0);
        setLoading(false);
      }, 2000);
    }
  }, [response]);

  const sanitise = (obj) => {
    if (typeof obj === "string") {
      return obj.replaceAll("\n", "\r");
    } else {
      return obj;
    }
  };

  const flatten = (obj) => {
    let newObj = {};
    for (let key in obj) {
      if (typeof obj[key] === "object" && obj[key] !== null) {
        let temp = flatten(obj[key]);
        for (let key2 in temp) {
          newObj[key + "." + key2] = sanitise(temp[key2]);
        }
      } else newObj[key] = sanitise(obj[key]);
    }
    return newObj;
  };

  const fetchPagedData = async (data, totalRecords) => {
    const params = Object.assign({}, apiParams);
    do {
      if (cancelRef.current === true) break;
      params.offset = data.length;
      const result = await axios
        .get(apiEndpoint, { params: params })
        .catch(() => null);
      if (result) {
        if (result.data.data.length === 0) break;
        data.push(...result.data.data);
        const currentProgress = Math.round((data.length / totalRecords) * 100);
        if (showProgress && currentProgress < 100) setProgress(currentProgress);
        else setProgress(100);
      } else break;
    } while (data.length < totalRecords);
  };

  const downloadCSV = (response) => {
    const header = [];
    const data = response || [];
    const flatCSV = data.map((item) => {
      const flatRecord = flatten(item);
      const headerKeys = Object.keys(flatRecord);
      headerKeys.forEach((key) => {
        if (header.indexOf(key) === -1) header.push(key);
      });
      return flatRecord;
    });
    setHeaders(header.sort());
    setResponse(flatCSV);
  };

  const downloadJSON = (response, filename) => {
    const dataStr =
      "data:text/json;charset=utf-8," +
      encodeURIComponent(JSON.stringify(response));
    const downloadAnchor = document.getElementById("downloadAnchor");
    downloadAnchor.setAttribute("href", dataStr);
    downloadAnchor.setAttribute("download", filename);
    downloadAnchor.click();
    // delay 2 sec for quick downloads (to avoid multiple clicks);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  const downloadPagedCSV = async (response) => {
    const data = response.data || [];
    const totalRecords = response.total || 1;

    if (showProgress) setProgress(0);
    if (data.length < totalRecords) await fetchPagedData(data, totalRecords);

    if (showProgress) setProgress(100);
    if (!cancelRef.current) downloadCSV(data);
    else {
      setProgress(0);
      setLoading(false);
    }
  };

  const downloadPagedJSON = async (response) => {
    const data = response.data || [];
    const totalRecords = response.total || 1;

    if (showProgress) setProgress(0);
    if (data.length < totalRecords) await fetchPagedData(data, totalRecords);

    if (showProgress) setProgress(100);
    if (!cancelRef.current) {
      const name =
        file + "-" + moment(Date.now()).format("MMDDYYYY-hmms") + ".json";
      downloadJSON(data, name);
    } else {
      setProgress(0);
      setLoading(false);
    }
  };

  const downloadData = () => {
    setLoading(true);
    const name =
      file + "-" + moment(Date.now()).format("MMDDYYYY-hmms") + "." + fileExt;
    setFilename(name);
    if (!pagedDownload) {
      axios
        .get(apiEndpoint)
        .then((response) => {
          if (downloadFormat === "csv") downloadCSV(response.data);
          else if (downloadFormat === "json") downloadJSON(response.data, name);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const downloadPagedData = (formatType) => {
    setLoading(true);
    cancelRef.current = false;
    if (formatType === "csv") {
      const name =
        file + "-" + moment(Date.now()).format("MMDDYYYY-hmms") + ".csv";
      setFilename(name);
    }
    apiParams.max = pageSize;
    apiParams.offset = 0;
    axios
      .get(apiEndpoint, { params: apiParams })
      .then((response) => {
        if (formatType === "csv") downloadPagedCSV(response.data);
        else downloadPagedJSON(response.data);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {response && (
        <CSVLink
          ref={csvLinkRef}
          headers={headers}
          data={response}
          className="btn btn-sm btn-outline-secondary d-none"
          filename={filename}
          target="_blank"
        ></CSVLink>
      )}
      {
        <a id="downloadAnchor" href="/" className="d-none">
          &nbsp;
        </a>
      }
      {(downloadButtonTitle || !pagedDownload) && (
        <button
          className="btn btn-sm btn-outline-secondary"
          data-testid="export-data-button"
          title={downloadButtonTitle}
          aria-label={downloadButtonTitle}
          disabled={loading}
          onClick={downloadData}
        >
          <Download height={14} className="svg-gradient-primary" />
          <span className="pl-1 pr-2">{downloadButtonTitle}</span>
        </button>
      )}
      {pagedDownload && (
        <>
          <Dropdown className="download-dropdown">
            <Dropdown.Toggle
              className="h-100"
              size="sm"
              variant="secondary"
              data-testid="export-data-button"
              title="Download option"
              aria-label="Download option"
              disabled={loading}
            >
              <Download height={14} className="svg-gradient-primary" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item href="#" onClick={() => downloadPagedData("csv")} aria-label="Download CSV">
                Download CSV
              </Dropdown.Item>
              <Dropdown.Item href="#" onClick={() => downloadPagedData("json")} aria-label="Download JSON">
                Download JSON
              </Dropdown.Item>
            </Dropdown.Menu>
            {loading && (
              <button
                className="h-100 ml-1 btn btn-sm btn-outline-secondary"
                title="Cancel download"
                aria-label="Cancel download"
                onClick={() => (cancelRef.current = true)}
              >
                <Close height={20} />
              </button>
            )}
            {loading && showProgress && (
              <span className="ml-1">
                <CircularProgressBar
                  strokeWidth="3"
                  width="55"
                  height="55"
                  percentage={progress}
                />
              </span>
            )}
            {loading && !showProgress && (
              <span className="ml-1 btn btn-sm loading-animation"></span>
            )}
          </Dropdown>
        </>
      )}
      {loading && !showProgress && (
        <span className="ml-2 btn btn-sm loading-animation"></span>
      )}
    </>
  );
};

ExportData.defaultProps = {
  pagedDownload: false,
  showProgress: false,
  format: "csv",
  file: "dapian-export",
  fileExt: "csv",
};

ExportData.propTypes = {
  apiEndpoint: PropTypes.string.isRequired,
  format: PropTypes.string.isRequired,
  file: PropTypes.string.isRequired,
  fileExt: PropTypes.string.isRequired,
};

export default ExportData;
