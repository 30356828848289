import React, { useState, useEffect } from 'react';
import { Router } from 'react-router-dom';
import { useAuthContext } from '../auth/AuthContextProvider';
import axios from 'axios';
import AppRoutes from './AppRoutes';
import SidebarComponent from './SidebarComponent';
import SidePanelComponent from './SidePanelComponent';
import RouterHistory from './RouterHistory';
import Spinner from 'react-bootstrap/Spinner';
import SessionExpiryMonitor from './SessionExpiryMonitor';
import { sendError } from '../Airbrake';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import moment from 'moment';
import 'moment-precise-range-plugin';
import { useAppState } from './AppState';

let axiosRequestInterceptorSetup;
let axiosResponseInterceptorSetup;
let bufferOf401PromiseResolvers = [];
let bufferOf403PromiseResolvers = [];

const App = () => {
  axios.defaults.baseURL = process.env.REACT_APP_SERVER;

  const expireSessions = process.env.REACT_APP_EXPIRE_SESSIONS || false;
  const {
    loading,
    isAuthenticated,
    getTokenSilently,
    loginWithRedirect,
    logoutWithRedirect,
    sessionExpired,
    setSessionExpired
  } = useAuthContext();
  const [dialog401Open, setDialog401Open] = useState(false);
  const [dialog403Open, setDialog403Open] = useState(false);
  const [dialogServerErrorOpen, setDialogServerErrorOpen] = useState(false);
  const {sidebarVisible} = useAppState();

  const ERROR = {
    unauthorized: 401,
    forbidden: 403,
    serverError: 500
  };

  const idleToastId = React.useRef(null);
  const loginToastId = React.useRef(null);

  // track application idle and login timeout;
  useEffect(() => {
    const logoutToastOptions = {
      position: 'top-right',
      hideProgressBar: true,
      closeButton: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
      progress: undefined
    };

    const dismissInactivityToast = () => {
      if (idleToastId.current) {
        toast.dismiss(idleToastId.current);
        idleToastId.current = undefined;
      }
    };

    const dismissStaleLoginToast = () => {
      console.log('dismissStaleLoginToast');
      if (loginToastId.current) {
        toast.dismiss(loginToastId.current);
        loginToastId.current = undefined;
      }
    };

    const showInactivityToast = (millisToLogout) => {
      const durationUntilLogout =
        millisToLogout < 1000
          ? 'now'
          : 'in ' +
            moment.preciseDiff(moment(), moment().add(millisToLogout, 'ms'));
      const message = `You will be logged out due to inactivity ${durationUntilLogout}.`;
      if (idleToastId.current) {
        toast.update(idleToastId.current, {
          render: () => <>{message}</>
        });
      } else {
        idleToastId.current = toast.warn(message, logoutToastOptions);
      }
    };

    const showExpiringLoginToast = (millisToLogout) => {
      const now = moment();
      const maximumLoggedInDuration = moment.duration(
        process.env.REACT_APP_LOGIN_TIMEOUT,
        'seconds'
      );
      const maximumLoggedInDurationString = moment.preciseDiff(
        now,
        now.clone().add(maximumLoggedInDuration, 's')
      );
      const durationUntilLogout = moment.preciseDiff(
        now,
        now.clone().add(millisToLogout, 'ms')
      );

      const message = `In ${durationUntilLogout} you will need to login again, ${maximumLoggedInDurationString} after your last login.`;

      if (loginToastId.current) {
        toast.update(loginToastId.current, {
          render: () => <>{message}</>
        });
      } else {
        loginToastId.current = toast.warn(message, logoutToastOptions);
      }
    };

    if (!loading && isAuthenticated && expireSessions) {
      new SessionExpiryMonitor({
        secondsIdleBeforeLogout: process.env.REACT_APP_IDLE_TIMEOUT || null,
        secondsToShowIdleWarning:
          process.env.REACT_APP_NOTIFY_IDLE_TIMEOUT || null,
        secondsSinceLastLoginBeforeLogout:
          process.env.REACT_APP_LOGIN_TIMEOUT || null,
        secondsToShowStaleLoginWarning:
          process.env.REACT_APP_NOTIFY_LOGIN_TIMEOUT || null,
        onIdleTimeout: () => {
          setSessionExpired(true);
        },
        onStaleLoginTimeout: () => {
          setSessionExpired(true);
        },
        warnUserAboutInactivity: (millisToLogout) => {
          showInactivityToast(millisToLogout);
        },
        warnUserAboutStaleLogin: (millisToLogout) => {
          showExpiringLoginToast(millisToLogout);
        },
        onActivityDetected: () => {
          dismissInactivityToast();
        },
        onLoginDetected: () => {
          dismissStaleLoginToast();
        }
      });
    }
  }, [loading, isAuthenticated, expireSessions, setSessionExpired]);

  // triggers logout when setSessionExpired state has changed to true;
  useEffect(() => {
    if (
      !loading &&
      isAuthenticated &&
      expireSessions &&
      sessionExpired === true
    ) {
      logoutWithRedirect();
    }
  }, [
    loading,
    isAuthenticated,
    expireSessions,
    logoutWithRedirect,
    sessionExpired
  ]);

  /**
   * Can be used only after Auth0Client is ready!
   * @param config
   * @return {Q.Promise<any> | Promise<any>}
   */
  function axiosRequestInterceptor(config) {
    return getTokenSilently()
      .then((token) => {
        config.headers = config.headers || {};
        config.headers.Authorization = `Bearer ${token.raw}`;
        return config;
      })
      .catch(() => {
        return config;
      });
  }

  /**
   * Can be used only after Auth0Client is ready!
   * @param error
   * @return {Q.Promise<any> | Promise<any>}
   */
  function axiosResponseFailureInterceptor(error) {
    if (error && error.response) {
      switch (error.response.status) {
        case ERROR.unauthorized:
          return new Promise((resolve) => {
            bufferOf401PromiseResolvers.push(resolve);
            setDialog401Open(true);
          }).then(() => Promise.reject(error));
        case ERROR.forbidden:
          return new Promise((resolve) => {
            bufferOf403PromiseResolvers.push(resolve);
            setDialog403Open(true);
          }).then(() => Promise.reject(error));
        default:
          if (error.response.status >= ERROR.serverError) {
            setDialogServerErrorOpen(true);
            // it is doubtful if following action is useful as
            // server error handler will duplicate these
            sendError(error);
          }
          return Promise.reject(error);
      }
    } else {
      // check here for cancellations because if we're using a 'smart' axios hook
      // we will get them if multiple requests are banged out in a very short period
      // of time
      if (error && error.code) {
        if (error.code === 'ERR_CANCELED') {
          return Promise.reject(error);
        }
      }

      // if we haven't got a cancellation, then report some kind of non-specific error and log
      // to console for debug purposes
      console.error(`network error: ${JSON.stringify(error)}`);
      setDialogServerErrorOpen(true);
    }

    return Promise.reject(error);
  }

  function closeDialog(errorType, reauthenticate) {
    switch (errorType) {
      case ERROR.unauthorized:
        while (bufferOf401PromiseResolvers.length > 0) {
          bufferOf401PromiseResolvers.shift().call();
        }
        setDialog401Open(false);
        break;
      case ERROR.forbidden:
        while (bufferOf403PromiseResolvers.length > 0) {
          bufferOf403PromiseResolvers.shift().call();
        }
        setDialog403Open(false);
        break;
      default:
        setDialog401Open(false);
        setDialog403Open(false);
        setDialogServerErrorOpen(false);
    }

    if (reauthenticate) loginWithRedirect();
  }

  if (axiosRequestInterceptorSetup != null) {
    axios.interceptors.request.eject(axiosRequestInterceptorSetup);
  }

  if (axiosResponseInterceptorSetup != null) {
    axios.interceptors.response.eject(axiosResponseInterceptorSetup);
  }

  if (!loading) {
    axiosRequestInterceptorSetup = axios.interceptors.request.use(
      axiosRequestInterceptor
    );
    axiosResponseInterceptorSetup = axios.interceptors.response.use(
      (resp) => resp,
      axiosResponseFailureInterceptor
    );
  }

  let apiErrorsIfAny = null;
  let api401ErrorIfAny = null;
  let api403ErrorIfAny = null;
  let apiServerErrorIfAny = null;

  if (dialog401Open)
    api401ErrorIfAny = (
      <div className="container">
        <div className="alert alert-danger mb-2">
          Authentication is required
        </div>
        <button
          className="btn btn-secondary mr-1"
          onClick={() => closeDialog(ERROR.unauthorized)}
        >
          Cancel
        </button>
        <button
          className="btn btn-secondary"
          onClick={() => closeDialog(ERROR.unauthorized, true)}
        >
          <FormattedMessage id="general.log_in" />
        </button>
      </div>
    );

  if (dialog403Open)
    api403ErrorIfAny = (
      <div className="container">
        <div className="alert alert-danger mb-2">
          You're not authorized to perform an action
        </div>
        <button
          className="btn btn-secondary mr-1"
          onClick={() => closeDialog(ERROR.forbidden)}
        >
          Cancel
        </button>
        <button
          className="btn btn-secondary"
          onClick={() => closeDialog(ERROR.forbidden, true)}
        >
          <FormattedMessage id="general.log_in" />
        </button>
      </div>
    );

  if (dialogServerErrorOpen)
    apiServerErrorIfAny = (
      <div className="container">
        <div className="alert alert-danger mb-2">
          Server responded with an unexpected error, please try reloading the
          page after a couple of minutes to see if this error goes away.
          <button
            type="button"
            onClick={() => closeDialog(ERROR.serverError)}
            className="close"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
    );

  if (api401ErrorIfAny || api403ErrorIfAny || apiServerErrorIfAny) {
    apiErrorsIfAny = (
      <div className="bg-dark p-3">
        {api401ErrorIfAny}
        {api403ErrorIfAny}
        {apiServerErrorIfAny}
      </div>
    );
  }

  RouterHistory.listen(() => {
    window.scrollTo(0, 0);
    const rootElement = document.getElementById('root');
    if (rootElement) {
      rootElement.scrollTo(0, 0);
    }
    const contentElement = document.getElementById('content');
    if (contentElement) {
      contentElement.scrollTo(0, 0);
    }
  });

  if (loading)
    return (
      <div className="d-flex min-vh-100 align-items-center justify-content-center">
        <Spinner animation={'border'} variant={'primary'} />
      </div>
    );

  return (
    <Router history={RouterHistory}>
      <div className="d-flex flex-row vh-100">
        {sidebarVisible && <SidebarComponent />}
        <SidePanelComponent />
        <div
          role="main"
          id="content"
          tabIndex={-1}
          className={`${
            isAuthenticated ? 'bg-light' : ''
          } d-flex flex-column flex-grow-1 overflow-auto`}
        >
          {apiErrorsIfAny}
          <AppRoutes isAuthenticated={isAuthenticated} />
        </div>
      </div>
    </Router>
  );
};

export default App;
