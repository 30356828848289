import { Activity } from '../shared/types';
import { FC } from 'react';
import { ProfileLink } from '../profile/Profile';

export interface ActivityActorDescriptionProps {
  activity: Activity;
}

export const ActivityActorDescription: FC<ActivityActorDescriptionProps> = ({
  activity
}) => {
  if (activity.event && activity.event.updateBy) {
    return (
      <>
        <div className={'activity-actor'}>
          <ProfileLink
            id={activity.event.updateBy.id}
            label={activity.event.updateBy.displayName}
          ></ProfileLink>
        </div>
      </>
    );
  } else if (activity.event && activity.event.profile) {
    return (
      <>
        <div className={'activity-actor'}>
          <ProfileLink
            id={activity.event.profile.id}
            label={activity.event.profile.displayName}
          ></ProfileLink>
        </div>
      </>
    );
  } else {
    return <div></div>;
  }
};
