import React, { useState } from 'react';
import {GeneralIcons} from './components/Icons';

const CardCollapsible = ({ title, className, children }) => {

  const [isOpen, setIsOpen] = useState(false);
  const handleKeyDown = (e => {
    if (e.key === "Enter") {
      setIsOpen(!isOpen);
    }
  });

  return (<div className={'card ' + className}>
    <div tabIndex={0} className="card-header collapsible-header d-flex justify-content-between" style={{ 'cursor': 'pointer' }}
      onClick={() => setIsOpen(!isOpen)} onKeyDown={handleKeyDown}>
      <h2>{title}</h2>
      {!isOpen && GeneralIcons.SquareCaretDown(['caret'])}
      {isOpen && GeneralIcons.SquareCaretUp(['caret'])}
    </div>
    {isOpen ? (
      <div className="card-body mb-0 d-print-block">
        {children}
      </div>
    ) : (
      <div className="card-body mb-0 d-none d-print-block">
        {children}
      </div>
    )
    }
  </div>);
};

export default CardCollapsible;
