import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { parseAxiosError } from '../shared/AxiosResponseErrorParser';
import { PromptModal } from '../shared/Modal';
import { useIntl } from 'react-intl';
import FormForSchemaModal from '../form/FormForSchemaModal';
import { useAppConfiguration } from '../shared/contexts/AppConfiguration';
import { caseConfigurationVersion, caseStatusFromId } from '../shared/types';

const CaseUnlockModal = ({ model, modalTitle, onModalClose }) => {
  const intl = useIntl();
  const appConfig = useAppConfiguration();
  const [caseStatuses, setCaseStatuses] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isConfirmVisible, setIsConfirmVisible] = useState(false);
  const [availableStatuses, setAvailableStatuses] = useState([]);
  const [submittableDetails, setSubmittableDetails] = useState(null);

  useEffect(() => {
    if (model.id) {
      setLoading(true);
      axios
        .get(`/api/case/${model.id}/status/unlocked`)
        .then((statusesResponse) => {
          setErrorMessage(null);
          setAvailableStatuses(statusesResponse.data.data);
        })
        .catch((e) => {
          setErrorMessage(parseAxiosError(e).getMessage());
        })
        .finally(() => {
          setLoading(false);
        });
    }

    if (appConfig.loaded) {
      const caseConfig = caseConfigurationVersion(
        appConfig,
        model.caseRef,
        model.caseVersion
      );
      setCaseStatuses(caseConfig.meta.statuses);
    }
  }, [model, caseStatuses, appConfig]);

  const onSubmit = (formData) => {
    const newStatus = caseStatusFromId(formData.status, availableStatuses);
    if (newStatus.terminating) {
      setIsConfirmVisible(true);
      setSubmittableDetails(formData);
    } else {
      postChanges(formData);
    }
  };

  const postChanges = (formData) => {
    setLoading(true);
    axios
      .post(`/api/case/${model.id}/unlock`, formData)
      .then(() => {
        setLoading(false);
        onModalClose(formData.status);
      })
      .catch((e) => {
        setLoading(false);
        setErrorMessage(parseAxiosError(e).getMessage());
      });
  };

  // Retrieve the appropriate case noun for the flavour
  const caseNoun = intl.formatMessage({ id: 'model.case' });

  const schema = {
    type: 'object',
    properties: {
      status: {
        title: `Unlock ${caseNoun}`,
        description:
          'Select a new unlocked status from a list of available ones',
        type: 'string',
        enum: availableStatuses.map((status) => status.id),
        enumNames: availableStatuses.map(
          (status) => `${status.label} - ${status.description}`
        )
      },
      comment: {
        title: 'Comment',
        type: 'string',
        description: `Leave a comment describing why you are unlocking this ${caseNoun}. It will be sent through notifications as well`
      },
      helpText: {
        title: intl.formatMessage({
          id: 'nav.cases.status.edit.notifications'
        }),
        description: intl.formatMessage({
          id: 'nav.cases.status.edit.notifications.description'
        }),
        type: 'null'
      }
    },
    required: ['status', 'comment']
  };

  const uiSchema = {
    comment: {
      'ui:widget': 'textarea'
    }
  };

  return (
    <>
      <FormForSchemaModal
        modalTitle={modalTitle}
        isLoading={loading}
        onModalClose={onModalClose}
        onSubmit={onSubmit}
        errorMessage={errorMessage}
        schema={schema}
        uiSchema={uiSchema}
        rules={[]}
      />
      {isConfirmVisible && (
        <PromptModal
          title="Are you sure you want to unlock?"
          text="Please ensure that you have a provided a valid reason for unlocking."
          onCancel={() => setIsConfirmVisible(false)}
          onSuccess={() => postChanges(submittableDetails)}
        />
      )}
    </>
  );
};

CaseUnlockModal.propTypes = {
  model: PropTypes.object.isRequired,
  modalTitle: PropTypes.string.isRequired,
  onModalClose: PropTypes.func.isRequired,
  bodyClass: PropTypes.string
};

export default CaseUnlockModal;
