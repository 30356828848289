class ParsedError {
  constructor (status, method, errors, defaultMessage) {
    this.status = status || 500;
    this.method = method ? `${method}`.toLowerCase() : null;
    this.errors = errors || [];
    this.defaultMessage = defaultMessage || 'Server returned an error.';
  }

  getMessage () {
    if (this.errors.length) {
      let messages = this.errors
        .map((e) => e.error)
        .filter((e) => !!e);

      if (messages.length) {
        return messages.join(', ');
      }
    }

    if (this.status === 404 && (!this.method || ['get', 'options', 'head'].indexOf(this.method) > -1)) {
      return 'Item was not found';
    }

    if (this.status === 404 && this.method && ['get', 'options', 'head'].indexOf(this.method) === -1) {
      return `Cannot [${this.method}] server returned 404`;
    }

    return this.defaultMessage;
  }
}

export function parseAxiosError (axiosError, defaultMessage) {
  const responseObj = axiosError && axiosError.response ? axiosError.response : {};
  const responseData = responseObj.data || {};
  const responseConfig = responseObj.config || {};
  const apiErrors = responseData.errors || [];
  const message = defaultMessage ? defaultMessage : axiosError && axiosError.message;
  const status = responseObj.status || 500;
  if (status >= 400 && status !== 404) {
    axiosError.response && console.log(axiosError.response);
  }
  return new ParsedError(responseObj.status || 500, responseConfig.method, apiErrors, message);
}
