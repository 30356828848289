import React, { FC, useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import { FormattedMessage } from 'react-intl';
import {
  caseOrTaskMessage,
  caseStatusFromId,
  isValidCaseRef,
  latestCaseConfiguration,
  UiMessageKey
} from '../shared/types';
import { ResponsiveBar } from '@nivo/bar';
import _ from 'lodash';
import { useAppConfiguration } from '../shared/contexts/AppConfiguration';

/**
 * Only here to make things more readable
 */
type Datum = {
  data: Array<object>;
  keys: Array<string>;
};

export interface CaseStatusesStackedBarChartProps {
  refreshCookie: string;
}

export const CaseStatusesStackedBarChart: FC<
  CaseStatusesStackedBarChartProps
> = ({ refreshCookie }) => {
  const appConfig = useAppConfiguration();
  const [analyticsData, setAnalyticsData] = useState<Datum>({} as Datum);
  const [isLoading, setIsLoading] = useState(true);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<string>(refreshCookie);

  // callback to retrieve a friendly case type display name
  const caseDisplayName = useCallback(
    (caseRef: string) => {
      const caseConfig = latestCaseConfiguration(appConfig, caseRef);
      return caseOrTaskMessage(caseConfig, UiMessageKey.TitlePlural);
    },
    [appConfig]
  );

  // callback to retrieve a friendly case type status title
  const caseStatusTitle = useCallback(
    (caseRef: string, status: string) => {
      const caseConfig = latestCaseConfiguration(appConfig, caseRef);
      return caseStatusFromId(status, caseConfig.meta.statuses).label;
    },
    [appConfig]
  );

  // use an effect to load in the actual chart data, post-process it etc...
  useEffect(() => {
    if (!loaded && appConfig.loaded) {
      const apiUrl = `/api/stats/cases/status`;
      axios
        .get(apiUrl)
        .then((response) => response.data.data || [])
        .then((results) => {
          const filteredResults = results.filter(
            (result: { status: string; count: number; caseRef: string }) =>
              isValidCaseRef(appConfig, result.caseRef)
          );
          let buckets: { [key: string]: { [key: string]: any } } = {};
          filteredResults
            .forEach(
              (result: { status: string; count: number; caseRef: string }) => {
                const caseTypeKey = caseDisplayName(result.caseRef) as string;
                if (!_.has(buckets, caseTypeKey)) {
                  buckets[caseTypeKey] = { caseType: caseTypeKey };
                }
                let bucket = buckets[caseTypeKey];
                bucket[caseStatusTitle(result.caseRef, result.status)] =
                  result.count;
              }
            );

          setAnalyticsData({
            data: _.values(buckets),
            keys: _.uniqBy(
              filteredResults,
              (result: { status: string; count: number; caseRef: string }) =>
                result.status
            ).map((value) => caseStatusTitle(value.caseRef, value.status))
          });
          setLoaded(true);
        })
        .catch(() => setAnalyticsData({} as Datum))
        .finally(() => setIsLoading(false));
    }
    if (refreshCookie !== refresh) {
      setLoaded(false);
      setRefresh(refreshCookie);
    }
  }, [
    appConfig,
    appConfig.loaded,
    caseDisplayName,
    caseStatusTitle,
    loaded,
    refresh,
    refreshCookie
  ]);

  return (
    <div
      style={{ height: 300 }}
      className="d-flex align-items-center justify-content-center"
    >
      {isLoading && <Spinner animation={'border'} variant={'primary'} />}
      {!isLoading && analyticsData.data.length === 0 && (
        <p className="text-muted text-center">
          <FormattedMessage id="stats.cases.review.notfound" />
        </p>
      )}
      {!isLoading && analyticsData.data.length > 0 && (
        <ResponsiveBar
          data={analyticsData.data}
          keys={analyticsData.keys}
          indexBy="caseType"
          margin={{ top: 50, right: 5, bottom: 50, left: 50 }}
          padding={0.2}
          axisTop={null}
          axisRight={null}
          colors={{ scheme: 'nivo' }}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Case Type',
            legendPosition: 'middle',
            legendOffset: 40
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            format: (y) => Math.floor(y),
            legend: 'Count',
            legendPosition: 'middle',
            legendOffset: -40
          }}
          defs={[
            {
              id: 'dots',
              type: 'patternDots',
              background: 'inherit',
              color: 'rgba(255, 255, 255, 0.3)',
              size: 4,
              padding: 1,
              stagger: true
            },
            {
              id: 'lines',
              type: 'patternLines',
              background: 'inherit',
              color: 'rgba(255, 255, 255, 0.3)',
              rotation: -45,
              lineWidth: 6,
              spacing: 10
            }
          ]}
          fill={[
            {
              match: {
                id: 'Rejected'
              },
              id: 'dots'
            },
            {
              match: {
                id: 'Inactive'
              },
              id: 'lines'
            },
            {
              match: {
                id: 'Signed off'
              },
              id: 'dots'
            }
          ]}
          labelSkipWidth={12}
          labelSkipHeight={12}
          legends={[
            {
              dataFrom: 'keys',
              anchor: 'top-left',
              direction: 'row',
              justify: false,
              translateX: 0,
              translateY: -40,
              itemsSpacing: 24,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: 'left-to-right',
              itemOpacity: 0.85,
              symbolSize: 20
            }
          ]}
        />
      )}
    </div>
  );
};
