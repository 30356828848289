import React, { FC, useEffect, useState } from 'react';
import { useAppConfiguration } from '../../shared/contexts/AppConfiguration';
import {
  CaseStatus,
  matchCaseStatus
} from '../../shared/types';
import { ColourDot } from '../../shared/components/ColourDot';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { newUUID } from '../../shared/functions';

/**
 * Props for the case status badge component
 */
export interface CaseStatusBadgeProps {
  status: string;
  withDescription?: boolean;
  withLock: boolean;
}

/**
 * Reworked case status component which takes into account a specific case configuration
 * @param status the status of a case
 * @param withDescription whether a description should be displayed
 * @param className optional CSS class
 * @param rest everything else
 * @constructor
 */
export const CaseStatusBadge: FC<CaseStatusBadgeProps> = ({
  status,
  withDescription,
  withLock = true,
  ...rest
}) => {
  const appConfig = useAppConfiguration();
  const [caseStatus, setCaseStatus] = useState<CaseStatus | undefined>(
    undefined
  );

  useEffect(() => {
    if (appConfig.loaded && caseStatus === undefined) {
      setCaseStatus(matchCaseStatus(appConfig, status));
    }
  }, [appConfig, caseStatus,  status]);

  if (withDescription) {
    return (
      <span className={'case-status-container'}>
        <span className={'case-status-item'} {...rest}>
          <ColourDot colour={caseStatus?.colour} />
          <OverlayTrigger
            overlay={
              <Tooltip id={`case-status-${newUUID()}`}>
                {caseStatus?.description}
              </Tooltip>
            }
            placement={'top'}
          >
            <>
              <span className={'case-status-label'}>
                {caseStatus?.description}{' '}
              </span>
            </>
          </OverlayTrigger>
        </span>{' '}
        {withLock && caseStatus && caseStatus.terminating && (
          <span className={'case-status-item'}>
            <span className={'warning-badge '}>locked</span>
          </span>
        )}
      </span>
    );
  } else {
    return (
      <span className={'case-status-container'}>
        <span className={'case-status-item'} {...rest}>
          <ColourDot colour={caseStatus?.colour} />
          <OverlayTrigger
            overlay={
              <Tooltip id={`case-status-${newUUID()}`}>
                {caseStatus?.description}
              </Tooltip>
            }
            placement={'top'}
          >
            <>
              <span className={'case-status-label'}>{caseStatus?.label}</span>
            </>
          </OverlayTrigger>
        </span>
        <span>{' '}</span>
        {withLock && caseStatus && caseStatus.terminating && (
          <span className={'case-status-item'}>
            <span className={'warning-badge'}>locked</span>
          </span>
        )}
      </span>
    );
  }
};
