import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { WithPermission } from '../auth/AccessConditionals';
import { useAuthContext } from '../auth/AuthContextProvider';
import { ReactComponent as Logo } from '../images/logo.svg';
import UserPicture from '../shared/UserPicture';
import { useAppState } from './AppState';
import { FormattedMessage, useIntl } from 'react-intl';
import { Logout, ExternalLink } from './icons';
import { flavour } from '../config';
import { SidebarLink } from './SidebarLink';
import { SidebarCaseTypeLinks } from './SidebarCaseTypeLinks';
import { SidebarUserCaseLinks } from './SidebarUserCaseLinks';
import { SidebarSearchLinks } from './SidebarSearchLinks';
import { QuickSearch } from '../search/components/QuickSearch';
import { SidebarExternalSearchLinks } from './SidebarExternalSearchLinks';
import { useAppConfiguration } from '../shared/contexts/AppConfiguration';
import {
  caseOrTaskMessage,
  firstNonGlobalLatestCaseConfiguration,
  UiMessageKey
} from '../shared/types';
import { SidebarReportingLinks } from './SidebarReportingLinks';
import { useExternalSources } from '../shared/hooks/ExternalSearchHooks';

export const SidebarToggle = ({ className }) => {
  const { sidebarActive, toggleSidebarActive } = useAppState();

  return (
    <button
      aria-label="Menu"
      className={`btn sidebar-toggler ${!sidebarActive ? 'collapsed' : ''
        } ${className}`}
      type="button"
      onClick={() => toggleSidebarActive()}
    >
      <span className="icon-bar top-bar" />
      <span className="icon-bar middle-bar" />
      <span className="icon-bar bottom-bar" />
    </button>
  );
};

export default function SidebarComponent() {
  const intl = useIntl();
  const appConfig = useAppConfiguration();
  const { sidebarActive, toggleSidebarActive, hasTaskForms } = useAppState();
  const { profile, logoutWithRedirect } = useAuthContext();
  const [defaultCaseConfig, setDefaultCaseConfig] = useState(null);
  const [haveExternal] = useExternalSources();

  useEffect(() => {
    if (appConfig.loaded) {
      setDefaultCaseConfig(firstNonGlobalLatestCaseConfiguration(appConfig));
    }
  }, [appConfig, appConfig.loaded]);

  const closeSidebar = () => {
    if (sidebarActive) toggleSidebarActive();
    const main = document.getElementById('content') || null;
    main && main.focus();
  };

  if (!profile) return null;

  return (
    <nav
      id="sidebar"
      className={`${sidebarActive ? 'active' : ''} d-flex flex-column`}
    >
      <div
        id="sidebar-overlay"
        onClick={() => toggleSidebarActive()}
        className={`${sidebarActive ? 'enabled d-block d-lg-none' : ''}`}
      />
      <div className="navbar logo-container pl-3">
        <Link to="/">
          <Logo height="45px" title="Home" className="customer-logo" />
          <div className="navbar-brand ml-4">
            <h1>
              <FormattedMessage id="general.brand" />
            </h1>
          </div>
        </Link>
        <a
          className="skip-link"
          href="#content"
          aria-label="Skip to main content"
        >
          Skip to main content
        </a>
        <SidebarToggle className="d-lg-none" />
      </div>
      <WithPermission name="case:read">
        <div className="sidebar-search">
          <QuickSearch />
        </div>
      </WithPermission>

      <div className="sidebar-nav overflow-auto mb-auto">
        <ul className="nav flex-column">
          <li>
            <SidebarLink
              to="/dashboard"
              label="nav.dashboard"
              translate={true}
            />
          </li>
          <li className="nav-item">
            <SidebarLink
              to="/activity/me"
              label="nav.activities.user"
              translate={true}
            />
          </li>
          <WithPermission name="audit:read">
            <li className="nav-item">
              <SidebarLink
                to="/activity/notifications"
                label="nav.activities.notifications"
                translate={true}
              />
            </li>
            <li className="nav-item">
              <SidebarLink
                to="/activity/list"
                label="nav.activities.all"
                translate={true}
              />
            </li>
          </WithPermission>
        </ul>
        <hr />
        <ul className="nav flex-row">
          <li>
            <SidebarCaseTypeLinks title="Case dashboards" expanded={true} />
          </li>
        </ul>
        <ul className="nav flex-row">
          <WithPermission name="none">
            {defaultCaseConfig && (
              <li>
                <SidebarLink
                  to={`/assessment/${defaultCaseConfig.ref}`}
                  label={`New ${caseOrTaskMessage(
                    defaultCaseConfig,
                    UiMessageKey.TitleSingularShort
                  )}`}
                  translate={true}
                />
              </li>
            )}
          </WithPermission>

          <WithPermission name="case:read">
            <li>
              <SidebarUserCaseLinks title="Your cases" expanded={true} />
            </li>
          </WithPermission>
        </ul>
        <ul className="nav flex-column">
          <WithPermission name="case:read">
            <li>
              <SidebarSearchLinks title={'Search'} expanded={true} />
            </li>
          </WithPermission>
        </ul>

        {haveExternal && (
          <ul className="nav flex-column">
            <WithPermission name="case:read">
              <li>
                <SidebarExternalSearchLinks
                  title={"Search other organisations' cases"}
                  expanded={false}
                />
              </li>
            </WithPermission>
          </ul>
        )}

        {hasTaskForms && (
          <ul className="nav flex-column">
            <li>
              <SidebarLink to="/tasks" label="Tasks" translate={false} />
            </li>
            <li>
              <SidebarLink
                to="/tasks/assigned"
                label="Tasks assigned to you"
                translate={false}
              />
            </li>
            <li>
              <SidebarLink
                to="/tasks/watched"
                label="Tasks watched by you"
                translate={false}
              />
            </li>
            <WithPermission name="task:read">
              <li>
                <SidebarLink
                  to="/tasks/list"
                  label="All tasks"
                  translate={false}
                />
              </li>
            </WithPermission>
            <WithPermission name="form:preview">
              <li>
                <SidebarLink
                  to="/forms"
                  label="Available task forms"
                  translate={false}
                />
              </li>
            </WithPermission>
          </ul>
        )}

        <WithPermission name="profile:read">
          <ul className="nav flex-column">
            <li>
              <SidebarLink
                to="/profiles"
                label="nav.profiles"
                translate={true}
              />
            </li>
            <li>
              <SidebarLink
                to="/profiles/list"
                label="nav.profiles.all"
                translate={true}
              />
            </li>
          </ul>
        </WithPermission>
        <hr />
        <WithPermission name="case:reporter">
          <ul className="nav flex-column">
            <li>
              <SidebarReportingLinks />
            </li>
          </ul>
          <hr></hr>
        </WithPermission>
        <ul className="nav flex-column">
          <li>
            <SidebarLink
              to="/release-notes"
              label="nav.whats_new"
              translate={true}
            />
          </li>
          {flavour.userGuide && (
            <li>
              <a
                className="nav-link"
                title={
                  intl.formatMessage({ id: 'nav.user_guide' }) +
                  ' (opens new window)'
                }
                href={flavour.userGuide}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FormattedMessage id="nav.user_guide" />{' '}
                <ExternalLink height={12} />
              </a>
            </li>
          )}
          {process.env.REACT_APP_TERMS_URL && (
            <li>
              <a
                className="nav-link"
                title={
                  intl.formatMessage({ id: 'nav.terms' }) +
                  ' (opens new window)'
                }
                href={process.env.REACT_APP_TERMS_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FormattedMessage id="nav.terms" /> <ExternalLink height={12} />
              </a>
            </li>
          )}
          {process.env.REACT_APP_PRIVACY_URL && (
            <li>
              <a
                className="nav-link"
                title={
                  intl.formatMessage({ id: 'nav.privacy' }) +
                  ' (opens new window)'
                }
                href={process.env.REACT_APP_PRIVACY_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FormattedMessage id="nav.privacy" />{' '}
                <ExternalLink height={12} />
              </a>
            </li>
          )}
          <li>
            <SidebarLink
              to="/cookie-policy"
              label="nav.cookies"
              translate={true}
            />
          </li>
          <li>
            <a
              className="nav-link"
              title={
                intl.formatMessage({ id: 'nav.api' }) + ' (opens new window)'
              }
              href={`${process.env.REACT_APP_SERVER}/documentation/swagger-ui/`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage id="nav.api" /> <ExternalLink height={12} />
            </a>
          </li>
        </ul>
      </div>

      <div className="user-profile-container p-2">
        <table className="w-100">
          <tbody>
            <tr>
              <td className="avatar-container">
                {profile && (
                  <UserPicture
                    className="user-avatar"
                    size="35"
                    email={profile.email}
                  />
                )}
              </td>
              <td className="w-100">
                <span className="overflow-container">
                  <span className="ellipsis text-capitalize">
                    <Link
                      to="/profile"
                      aria-label={intl.formatMessage({
                        id: 'nav.profiles.user'
                      })}
                      onClick={closeSidebar}
                    >
                      &nbsp;{profile && profile.name}
                    </Link>
                  </span>
                </span>
              </td>
              <td className="logout-container p-2">
                <button
                  id="qsLogoutBtn"
                  aria-label={intl.formatMessage({ id: 'general.log_out' })}
                  title={intl.formatMessage({ id: 'general.log_out' })}
                  className="p-1 btn btn-sm btn-outline-secondary btn-logout border-0"
                  type="button"
                  onClick={() => logoutWithRedirect()}
                >
                  <Logout height={28} />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </nav>
  );
}
