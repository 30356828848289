import React from 'react';
import { combineClassNames } from '../../shared/functions/ClassNameUtils';
import { newUUID } from '../../shared/functions';

/**
 * Props for a search text widget
 */
export interface SearchTextWidgetProps {
  /**
   * Optional id
   */
  id?: string;

  /**
   * The label for the widget
   */
  label: string;

  /**
   * A prompt to go in the text input
   */
  prompt?: string;

  /**
   * The classname for the label element (prepended to the input)
   */
  labelClassName?: string;

  /**
   * Classname for the input component (a select element)
   */
  inputClassName?: string;

  /**
   * Initial text value
   */
  initialValue?: any;

  /**
   * Optional input field type
   */
  inputType?: string;

  /**
   * Optional input mode for the input field
   */
  isNumeric?: boolean;

  /**
   * The parameter key associated with the dropdown (used during onSelect callbacks)
   */
  widgetKey: string;

  /**
   * A selection change callback
   * @param parameterKey
   * @param newValue
   */
  onValueChange: (parameterKey: string, newValue?: string) => void;

  /**
   * A callback for when the enter key is pressed
   * @param parameterKey
   * @param newValue
   */
  onEnterPressed?: (parameterKey: string, currentValue?: string) => void;
}

/**
 * Generic search text widget based on a flex layout
 * @param label the label for the component
 * @param labelClassName (optional) label class name
 * @param inputClassName (optional) input class name
 * @param items a list of items to display within the dropdown
 * @constructor
 */
export const SearchTextWidget = React.forwardRef<
  HTMLInputElement,
  SearchTextWidgetProps
>((props, ref) => {

  // proxy the key up event so that we can dispatch a separate event for
  // enter key presses
  function onKeyUpProxy(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter') {
      if (props.onEnterPressed){
        props.onEnterPressed(props.widgetKey, event.currentTarget.value);
      }
    }else{
      props.onValueChange(props.widgetKey, event.currentTarget.value);
    }
  }

  return (
    <>
      <div className="search-widget">
        <label
          htmlFor={`search_text_${props.id}`}
          className={
            props.labelClassName
              ? combineClassNames(
                props.labelClassName,
                'search-widget-label input-group-text'
              )
              : 'search-widget-label input-group-text'
          }
        >
          {props.label}
        </label>
        <input
          ref={ref}
          id={`search_text_${props.id}`}
          type={props.inputType ? props.inputType : 'text'}
          inputMode={props.isNumeric ? 'numeric' : 'text'}
          pattern={props.isNumeric ? '[0-9]*' : undefined}
          placeholder={
            props.prompt ? props.prompt : 'Enter search text here...'
          }
          onKeyUp={(event) =>
            onKeyUpProxy(event)
          }
          defaultValue={props.initialValue}
          className={
            props.inputClassName
              ? combineClassNames(props.inputClassName, ['select-widget-field'])
              : 'search-widget-field '
          }
        ></input>
      </div>
    </>
  );
});
