import { FC, useRef, useState } from 'react';
import { SearchTextWidget } from './SearchTextWidget';
import useAxios from 'axios-hooks';
import _ from 'lodash';
import { QuickSearchActionsButton } from './QuickSearchActionsButton';
import { CaseSearchModal } from './CaseSearchModal';

/**
 * Props for the quick search component
 */
export interface QuickSearchProps {}

/**
 * A simple component that allows for the quick searching of cases, based
 * on id, or alternatively based on the more flexible criteria allowed by
 * the {@link CaseSearchModal} component
 * @constructor
 */
export const QuickSearch: FC<QuickSearchProps> = () => {
  const [searchCaseId, setSearchCaseId] = useState<number | undefined>(
    undefined
  );
  const [haveValidCaseId, setHaveValidCaseId] = useState<boolean>(false);
  const [showCaseSearchModal, setShowCaseSearchModal] =
    useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  function updateInputField(caseId: number) {
    if (inputRef.current) {
      inputRef.current.value = caseId.toString();
      inputRef.current.focus();
    }
  }

  // the basic query to search for a case by id
  const [{ loading }, searchForCase] = useAxios(
    {
      url: `/api/search/case`
    },
    {
      manual: true,
      autoCancel: true,
      useCache: false
    }
  );

  // we debounce the search
  const debouncedSearch = _.debounce(
    (caseId: number) => {
      searchForCase({ url: `/api/search/case/${caseId}` })
        .then((response) => {
          if (response.status === 200) {
            setSearchCaseId(caseId);
            setHaveValidCaseId(true);
            updateInputField(caseId);
          }
        })
        .catch((error) => {
          setSearchCaseId(undefined);
        });
    },
    500,
    { leading: true }
  );

  function onEnterPressed(parameterKey: string, currentValue?: string) {
    console.log(`enter key pressed: ${currentValue}`);
    onActionCallback('open');
  }

  // callback for when the case id changes
  function onCaseIdChange(parameterKey: string, newValue?: string) {
    if (newValue && newValue.length > 0) {
      if (!isNaN(parseInt(newValue))) {
        debouncedSearch(parseInt(newValue));
      }
    } else {
      setSearchCaseId(undefined);
    }
  }

  // handle any actions spat out by the action widget
  function onActionCallback(action: string) {
    switch (action) {
      case 'open': {
        if (haveValidCaseId && searchCaseId) {
          window.open(`/cases/show/${searchCaseId}`, '_blank');
        }
        break;
      }
      case 'advanced': {
        setShowCaseSearchModal(true);
        break;
      }
      default: {
        break;
      }
    }
  }

  // handle feedback from the case search modal
  function onCaseSelectionModalClose(cancelled: boolean, item?: any) {
    if (!cancelled) {
      onCaseIdChange('', JSON.stringify(item.id));
      window.open(`/cases/show/${item.id}`, '_blank');
    }
    setShowCaseSearchModal(false);
  }

  return (
    <>
      <div className={'quick-search-container'}>
        <SearchTextWidget
          ref={inputRef}
          label={'Quicksearch'}
          isNumeric={true}
          widgetKey={'quick-search-case-id'}
          prompt={'Enter a case id'}
          onValueChange={onCaseIdChange}
          onEnterPressed={onEnterPressed}
        />
        <QuickSearchActionsButton
          loading={loading}
          caseId={searchCaseId}
          onActionCallback={onActionCallback}
        />
      </div>
      <CaseSearchModal
        show={showCaseSearchModal}
        title={'Search for a case'}
        onModalClose={onCaseSelectionModalClose}
      />
    </>
  );
};
