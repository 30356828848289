import React from 'react';

import {FileAudio, FileText} from "../main/icons";
import {FileWord} from "../main/icons";
import {FileArchive} from "../main/icons";
import {FileVideo} from "../main/icons";
import {FileImage} from "../main/icons";
import {FileCode} from "../main/icons";
import {FileExcel} from "../main/icons";
import {FilePowerpoint} from "../main/icons";
import {FilePdf} from "../main/icons";
import {FileAlt} from "../main/icons";
import {CalendarAlt} from "../main/icons";

function ContentTypeIcon({contentType, ...rest}) {
  // Source: https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Complete_list_of_MIME_types
  const componentsByContentType = {
    "application/epub+zip": <FileAlt alt="Electronic publication (EPUB)" title="Electronic publication (EPUB)" {...rest} />,
    "application/gzip": <FileArchive alt="GZip Compressed Archive" title="GZip Compressed Archive" {...rest}/>,
    "application/java-archive": <FileArchive alt="Java Archive (JAR)" title="Java Archive (JAR)" {...rest}/>,
    "application/json": <FileCode alt="JSON format" title="JSON format" {...rest} />,
    "application/ld+json": <FileCode alt="JSON-LD format" title="JSON-LD format" {...rest} />,
    "application/msword": <FileWord alt="Microsoft Word" title="Microsoft Word" {...rest} />,
    "application/octet-stream": <FileCode alt="Any kind of binary data" title="Any kind of binary data" {...rest} />,
    "application/ogg": <FileVideo alt="OGG" title="OGG" {...rest} />,
    "application/pdf": <FilePdf alt="Adobe Portable Document Format (PDF)" title="Adobe Portable Document Format (PDF)" {...rest} />,
    "application/php": <FileCode alt="Hypertext Preprocessor (Personal Home Page)" title="Hypertext Preprocessor (Personal Home Page)" {...rest} />,
    "application/rtf": <FileText alt="Rich Text Format (RTF)" title="Rich Text Format (RTF)" {...rest} />,
    "application/vnd.amazon.ebook": <FileAlt alt="Amazon Kindle eBook format" title="Amazon Kindle eBook format" {...rest} />,
    "application/vnd.apple.installer+xml": <FileAlt alt="Apple Installer Package" title="Apple Installer Package" {...rest} />,
    "application/vnd.mozilla.xul+xml": <FileCode alt="XUL" title="XUL" {...rest} />,
    "application/vnd.ms-excel": <FileExcel alt="Microsoft Excel" title="Microsoft Excel" {...rest} />,
    "application/vnd.ms-fontobject": <FileAlt alt="MS Embedded OpenType fonts" title="MS Embedded OpenType fonts" {...rest} />,
    "application/vnd.ms-powerpoint": <FilePowerpoint alt="Microsoft PowerPoint" title="Microsoft PowerPoint" {...rest} />,
    "application/vnd.oasis.opendocument.presentation": <FilePowerpoint alt="OpenDocument presentation document" title="OpenDocument presentation document" {...rest} />,
    "application/vnd.oasis.opendocument.spreadsheet": <FileExcel alt="OpenDocument spreadsheet document" title="OpenDocument spreadsheet document" {...rest} />,
    "application/vnd.oasis.opendocument.text": <FileWord alt="OpenDocument text document" title="OpenDocument text document" {...rest} />,
    "application/vnd.openxmlformats-officedocument.presentationml.presentation": <FilePowerpoint alt="Microsoft PowerPoint (OpenXML)" title="Microsoft PowerPoint (OpenXML)" {...rest} />,
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": <FileExcel alt="Microsoft Excel (OpenXML)" title="Microsoft Excel (OpenXML)" {...rest} />,
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": <FileWord alt="Microsoft Word (OpenXML)" title="Microsoft Word (OpenXML)" {...rest} />,
    "application/vnd.visio": <FileAlt alt="Microsoft Visio" title="Microsoft Visio" {...rest} />,
    "application/x-7z-compressed": <FileArchive alt="7-zip archive" title="7-zip archive" {...rest} />,
    "application/x-abiword": <FileWord alt="AbiWord document" title="AbiWord document" {...rest} />,
    "application/x-bzip2": <FileArchive alt="BZip2 archive" title="BZip2 archive" {...rest} />,
    "application/x-bzip": <FileArchive alt="BZip archive" title="BZip archive" {...rest} />,
    "application/x-csh": <FileCode alt="C-Shell script" title="C-Shell script" {...rest} />,
    "application/x-freearc": <FileArchive alt="Archive document (multiple files embedded)" title="Archive document (multiple files embedded)" {...rest} />,
    "application/xhtml+xml": <FileCode alt="XHTML" title="XHTML" {...rest} />,
    "application/xml": <FileCode alt="XML" title="XML" {...rest} />,
    "application/x-rar-compressed": <FileArchive alt="RAR archive" title="RAR archive" {...rest} />,
    "application/x-sh": <FileCode alt="Bourne shell script" title="Bourne shell script" {...rest} />,
    "application/x-shockwave-flash": <FileAlt alt="Small web format (SWF) or Adobe Flash document" title="Small web format (SWF) or Adobe Flash document" {...rest} />,
    "application/x-tar": <FileArchive alt="Tape Archive (TAR)" title="Tape Archive (TAR)" {...rest} />,
    "application/zip": <FileArchive alt="ZIP archive" title="ZIP archive" {...rest} />,
    "audio/3gpp2": <FileAudio alt="3GPP2 audio container" title="3GPP2 audio container" {...rest} />,
    "audio/3gpp": <FileAudio alt="3GPP audio container" title="3GPP audio container" {...rest} />,
    "audio/aac": <FileAudio alt="AAC audio" title="AAC audio" {...rest} />,
    "audio/midi audio/x-midi": <FileAudio alt="Musical Instrument Digital Interface (MIDI)" title="Musical Instrument Digital Interface (MIDI)" {...rest} />,
    "audio/mp3": <FileAudio alt="MP3 audio" title="MP3 audio" {...rest} />,
    "audio/mpeg": <FileAudio alt="MP3 audio" title="MP3 audio" {...rest} />,
    "audio/ogg": <FileAudio alt="OGG audio" title="OGG audio" {...rest} />,
    "audio/opus": <FileAudio alt="Opus audio" title="Opus audio" {...rest} />,
    "audio/wav": <FileAudio alt="Waveform Audio Format" title="Waveform Audio Format" {...rest} />,
    "audio/webm": <FileAudio alt="WEBM audio" title="WEBM audio" {...rest} />,
    "font/otf": <FileAlt alt="OpenType font" title="OpenType font" {...rest} />,
    "font/ttf": <FileAlt alt="TrueType Font" title="TrueType Font" {...rest} />,
    "font/woff2": <FileAlt alt="Web Open Font Format (WOFF)" title="Web Open Font Format (WOFF)" {...rest} />,
    "font/woff": <FileAlt alt="Web Open Font Format (WOFF)" title="Web Open Font Format (WOFF)" {...rest} />,
    "image/bmp": <FileImage alt="Windows OS/2 Bitmap Graphics" title="Windows OS/2 Bitmap Graphics" {...rest} />,
    "image/gif": <FileImage alt="Graphics Interchange Format (GIF)" title="Graphics Interchange Format (GIF)" {...rest} />,
    "image/jpeg": <FileImage alt="JPEG images" title="JPEG images" {...rest} />,
    "image/png": <FileImage alt="Portable Network Graphics" title="Portable Network Graphics" {...rest} />,
    "image/svg+xml": <FileImage alt="Scalable Vector Graphics (SVG)" title="Scalable Vector Graphics (SVG)" {...rest} />,
    "image/tiff": <FileImage alt="Tagged Image File Format (TIFF)" title="Tagged Image File Format (TIFF)" {...rest} />,
    "image/vnd.microsoft.icon": <FileImage alt="Icon format" title="Icon format" {...rest} />,
    "image/webp": <FileImage alt="WEBP image" title="WEBP image" {...rest} />,
    "text/calendar": <CalendarAlt alt="iCalendar format" title="iCalendar format" {...rest} />,
    "text/css": <FileCode alt="Cascading Style Sheets (CSS)" title="Cascading Style Sheets (CSS)" {...rest} />,
    "text/csv": <FileExcel alt="Comma-separated values (CSV)" title="Comma-separated values (CSV)" {...rest} />,
    "text/html": <FileCode alt="HyperText Markup Language (HTML)" title="HyperText Markup Language (HTML)" {...rest} />,
    "text/javascript": <FileCode alt="JavaScript" title="JavaScript" {...rest} />,
    "text/plain": <FileAlt alt="Text, (generally ASCII or ISO 8859-n)" title="Text, (generally ASCII or ISO 8859-n)" {...rest} />,
    "text/xml ": <FileCode alt="XML" title="XML" {...rest} />,
    "video/3gpp2": <FileVideo alt="3GPP2 video container" title="3GPP2 video container" {...rest} />,
    "video/3gpp": <FileVideo alt="3GPP video container" title="3GPP video container" {...rest} />,
    "video/mp2t": <FileVideo alt="MPEG transport stream" title="MPEG transport stream" {...rest} />,
    "video/mp4": <FileVideo alt="MP4 video" title="MP4 video" {...rest} />,
    "video/mpeg": <FileVideo alt="MPEG Video" title="MPEG Video" {...rest} />,
    "video/ogg": <FileVideo alt="OGG video" title="OGG video" {...rest} />,
    "video/quicktime": <FileVideo alt="QuickTime Video" title="QuickTime Video" {...rest} />,
    "video/webm": <FileVideo alt="WEBM video" title="WEBM video" {...rest} />,
    "video/x-ms-asf": <FileVideo alt="Windows Media Video" title="Windows Media Video" {...rest} />,
    "video/x-ms-wmv": <FileVideo alt="Windows Media Video" title="Windows Media Video" {...rest} />,
    "video/x-msvideo": <FileVideo alt="AVI: Audio Video Interleave" title="AVI: Audio Video Interleave" {...rest} />,
  };

  return componentsByContentType[contentType]|| <FileAlt alt="Unknown file type" title="Unknown file type" {...rest} />;
}

export default ContentTypeIcon;
