import { WidgetProps } from '@rjsf/core';
import { FC, useEffect, useState } from 'react';
import useAxios from 'axios-hooks';
import { Case } from '../../shared/types';
import { CaseStatusBadge } from '../../case/status/CaseStatus';
import { CaseLink } from '../../shared/components/CaseLink';

export const CaseLinkWidgetName = 'CaseLinkWidget';

export const CaseLinkWidget: FC<WidgetProps> = (props) => {
  const caseId = props.value;
  const [caseData, setCaseData] = useState<Case>();
  const [previousCaseId, setPreviousCaseId] = useState<number>();

  const [{ loading }, fetchCase] = useAxios(
    {
      url: `/api/case/${caseId}`
    },
    { manual: true }
  );

  useEffect(() => {
    if (caseId !== previousCaseId) {
      setCaseData(undefined);
      setPreviousCaseId(caseId);
    }

    if (caseId && !caseData) {
      fetchCase()
        .then((response) => {
          setCaseData(response.data.data);
        })
        .catch((err) => {});
    }
  }, [caseData, caseId, fetchCase, loading, previousCaseId]);

  if (caseData) {
    return (
      <>
        <div className={'embedded-case-link'}>
          <CaseLink
            model={caseData}
            truncationLimit={24}
            newTab={true}
            showLabel={true}
            showId={true}
          />
          <CaseStatusBadge
            status={caseData.status}
            withLock={true}
          />
        </div>
      </>
    );
  } else {
    return <div>Loading...</div>;
  }
};
