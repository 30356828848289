import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { InputSize, sizeToInputGroupClassName } from './InputSize';
import moment from 'moment';

/**
 * @param date {*}
 * @return {boolean}
 */
const isDate = (date) => {
  return Object.prototype.toString.call(date) === '[object Date]';
};

/**
 * @param isoDateString {string}
 * @param toAdd {number}
 * @return {string|null}
 */
export const changeYearInIsoDateString = (isoDateString, toAdd) => {
  const date = dateFromISODateString(isoDateString);
  if (!date) return null;
  const newDate = dateFromYearMonthDay(
    date.getUTCFullYear() + toAdd,
    date.getUTCMonth() + 1,
    date.getUTCDate()
  );
  return toIsoDateString(newDate);
};

/**
 * @param val {string|number}
 * @param from {number}
 * @param to {number}
 * @return {boolean}
 */
export const isNumInRangeIncl = (val, from, to) => {
  if ((val + '').trim() === '') return false;
  const numVal = +val;
  if (isNaN(numVal)) {
    return false;
  }
  return numVal >= from && numVal <= to;
};

/**
 * @param isoDateString {string}
 * @return {Date|null}
 */
export const dateFromISODateString = (isoDateString) => {
  let d = null;
  const parts = (isoDateString + '').split('-');
  if (parts.length === 3)
    d = dateFromYearMonthDay(+parts[0], +parts[1], +parts[2]);
  return d;
};

/**
 * @param year {number}
 * @param month {number} 1 through 12
 * @param day {number} 1 through 31
 * @return {Date|null}
 */
export const dateFromYearMonthDay = (year, month, day) => {
  let d = null;
  try {
    d = new Date(Date.UTC(year, month - 1, day, 0, 0, 0, 0));
  } catch (_) { }
  return d;
};

/**
 * @param date {Date}
 * @return {string|null}
 */
export const toIsoDateString = (date) => {
  if (isDate(date)) {
    return date.toISOString().split('T')[0];
  }
  return null;
};

/**
 * @param date {Date|string}
 * @return {string|null}
 */
export const toUkDateString = (date) => {
  if (isDate(date)) {
    const parts = date.toISOString().split('T')[0].split('-');
    return `${parts[2]}/${parts[1]}/${parts[0]}`;
  }
  if (typeof date === 'string') {
    return moment.utc(date).local().format('DD/MM/YYYY');
  }
  return null;
};

/**
 * @param {string} date - ISO date string
 * @return {string|null}
 */
export const toUkDateTimeString = (date) => {
  return date ? moment.utc(date).local().format('DD/MM/YYYY HH:mm:ss') : null;
};

/**
 * @param dd {string|number} 1 to 31
 * @param mm {string|number} 1 to 12
 * @param yyyy {string|number} any integer
 * @return {boolean}
 */
export const isDayValid = (dd, mm, yyyy) => {
  const d = dateFromYearMonthDay(+yyyy, +mm, +dd);
  return !!d && d.getUTCDate() === +dd;
};

/**
 * @param dd {string|number} 1 to 31
 * @param mm {string|number} 1 to 12
 * @param yyyy {string|number} any integer
 * @return {boolean}
 */
export const isMonthValid = (dd, mm, yyyy) => {
  const d = dateFromYearMonthDay(+yyyy, +mm, +dd);
  return !!d && d.getUTCMonth() === +mm - 1;
};

/**
 * @param dd {string|number} 1 to 31
 * @param mm {string|number} 1 to 12
 * @param yyyy {string|number} any integer
 * @return {boolean}
 */
export const isYearValid = (dd, mm, yyyy) => {
  const d = dateFromYearMonthDay(+yyyy, +mm, +dd);
  return !!d && d.getUTCFullYear() === +yyyy;
};

/**
 * Will treat null and undefined as the same
 * @param x {Date|null|undefined}
 * @param y {Date|null|undefined}
 * @return {boolean}
 */
export const areDateObjectsSame = (x, y) => {
  if (!isDate(x) && !isDate(y)) return true;
  if ((isDate(x) && !isDate(y)) || (!isDate(x) && isDate(y))) return false;
  return x.toISOString() === y.toISOString();
};

export const MemorableDate = ({
  label,
  paramKey,
  className,
  initialValue,
  minDateIso,
  maxDateIso,
  onChange,
  size = InputSize.md
}) => {
  const minDate =
    dateFromISODateString(minDateIso) || dateFromYearMonthDay(1800, 1, 1);
  const maxDate = dateFromISODateString(maxDateIso) || new Date();
  const minYear = minDate.getUTCFullYear();
  const maxYear = maxDate.getUTCFullYear();
  const minMonth = 1;
  const maxMonth = 12;
  const minDay = 1;
  const maxDay = 31;

  const dayRef = useRef();
  const monthRef = useRef();
  const yearRef = useRef();

  const sizeClassName = sizeToInputGroupClassName(size);

  const [selection, setSelection] = useState(null);
  const [day, setDay] = useState('');
  const [dayValid, setDayValid] = useState(true);
  const [month, setMonth] = useState('');
  const [monthValid, setMonthValid] = useState(true);
  const [year, setYear] = useState('');
  const [yearValid, setYearValid] = useState(true);
  const anyDatePartSet = !!year || !!month || !!day;

  const clearAll = (shouldFocus) => {
    const doesSelectionChange = selection !== null;
    setSelection(null);
    setDay('');
    setDayValid(true);
    setMonth('');
    setMonthValid(true);
    setYear('');
    setYearValid(true);
    doesSelectionChange && onChange(null);
    shouldFocus === true && dayRef.current && dayRef.current.focus();
  };

  /**
   * @param dd {string|number}
   * @return {boolean}
   */
  const canSetDay = (dd) => {
    const isRemoved = dd === '';
    return isRemoved || isNumInRangeIncl(dd, minDay, maxDay);
  };

  /**
   * @param mm {string|number}
   * @return {boolean}
   */
  const canSetMonth = (mm) => {
    const isRemoved = mm === '';
    return isRemoved || isNumInRangeIncl(mm, minMonth, maxMonth);
  };

  /**
   * @param yyyy {string|number}
   * @return {boolean}
   */
  const canSetYear = (yyyy) => {
    const isRemoved = yyyy === '';
    // special case, user needs to be able to type in year character by character therefore it starts at 0
    return isRemoved || isNumInRangeIncl(yyyy, 0, maxYear);
  };

  /**
   * @param date {Date}
   * @return {boolean}
   */
  const isValidInitialDate = (date) => {
    return date === null || isDate(date);
  };

  /**
   * @param date {Date}
   * @return {boolean}
   */
  const isValidDate = (date) => {
    return (
      date === null || (isDate(date) && date >= minDate && date <= maxDate)
    );
  };

  /**
   * @param dd {string|number}
   * @param mm {string|number}
   * @param yyyy {string|number}
   * @return {boolean}
   */
  const allDatePartsFullyValid = (dd, mm, yyyy) => {
    return (
      isDayValid(dd, mm, yyyy) &&
      isMonthValid(dd, mm, yyyy) &&
      isYearValid(dd, mm, yyyy) &&
      isNumInRangeIncl(yyyy, minYear, maxYear)
    );
  };

  useEffect(() => {
    if (!onChange || !minYear || !maxYear) return;
    if (!initialValue) {
      clearAll(false);
    } else {
      // populate inputs and show errors if any
      const [yyyy, mm, dd] = (initialValue + '').split('-');
      setYear(canSetYear(yyyy) ? yyyy : '');
      setMonth(canSetMonth(mm) ? mm : '');
      setDay(canSetDay(dd) ? dd : '');
      setDayValid(isDayValid(dd, mm, yyyy));
      setMonthValid(isMonthValid(dd, mm, yyyy));
      setYearValid(
        isYearValid(dd, mm, yyyy) && isNumInRangeIncl(yyyy, minYear, maxYear)
      );
      const initialDate = dateFromISODateString(initialValue);

      if (isValidInitialDate(initialDate)) {
        // const doesSelectionChange = !areDateObjectsSame(initialDate, selection);
        setSelection(initialDate);
        isValidDate(initialDate) && onChange(toIsoDateString(initialDate));
      } else {
        setSelection(null); // make sure to clear it out in case value exists
      }
    }
    // Interested only in changes from the outside
  }, [initialValue, onChange, minDateIso, maxDateIso]);
  const updateSelection = (dayNumber, monthNumber, yearNumber) => {
    let parsedSelection = null;
    if (allDatePartsFullyValid(dayNumber, monthNumber, yearNumber)) {
      try {
        const d = dateFromYearMonthDay(yearNumber, monthNumber, dayNumber);
        if (d) parsedSelection = d;
      } catch (_) { }
    }

    if (isValidDate(parsedSelection)) {
      const doesSelectionChange = !areDateObjectsSame(
        parsedSelection,
        selection
      );
      setSelection(parsedSelection);
      doesSelectionChange && onChange(toIsoDateString(parsedSelection));
    }
  };

  const onDayChange = (event) => {
    const val = event.target.value;
    const isRemoved = val === '';
    const needsFullValidation = !isRemoved && !!val && !!month && !!year;
    const isValidFully = needsFullValidation && isDayValid(val, month, year);

    if (canSetDay(val)) {
      setDay(val);

      // only if input is not rejected
      if (needsFullValidation && !isValidFully) {
        setDayValid(false);
        setMonthValid(false);
      } else {
        setDayValid(true);
        setMonthValid(true);
      }

      if (val >= 10) {
        monthRef.current && monthRef.current.focus();
      }
    }

    if (isValidFully) {
      updateSelection(val, month, year);
    }
  };

  const onMonthChange = (event) => {
    const val = event.target.value;
    const isRemoved = val === '';
    const needsFullValidation = !isRemoved && !!val && !!month && !!year;
    const isValidFully = needsFullValidation && isMonthValid(day, val, year);

    if (canSetMonth(val)) {
      setMonth(val);

      // only if input is not rejected
      if (needsFullValidation && !isValidFully) {
        setDayValid(false);
        setMonthValid(false);
      } else {
        setDayValid(true);
        setMonthValid(true);
      }

      if (val >= 10) {
        yearRef.current && yearRef.current.focus();
      }
    }

    if (isValidFully) {
      updateSelection(day, val, year);
    }
  };

  const onYearChange = (event) => {
    const val = event.target.value;
    const isRemoved = val === '';
    const needsFullValidation = !isRemoved && !!val && !!day && !!month;
    const isValidFully =
      needsFullValidation &&
      isYearValid(day, month, val) &&
      isNumInRangeIncl(val, minYear, maxYear);

    if (canSetYear(val)) {
      setYear(val);

      // only if input is not rejected
      if (needsFullValidation && !isValidFully) {
        setYearValid(false);
      } else {
        setYearValid(true);
      }
    }

    if (isValidFully) {
      updateSelection(day, month, val);
    }
  };

  let dayClassName = `form-control ${dayValid ? '' : 'is-invalid'} px-0 text-center`;
  let monthClassName = `form-control ${monthValid ? '' : 'is-invalid'} px-0 text-center`;
  let yearClassName = `form-control ${yearValid ? '' : 'is-invalid'} px-0 text-center`;
  const dayValue = day.length < 2 ? "0" + day : day;
  const monthValue = month.length < 2 ? "0" + month : month;
  const dateValue = (day && month && year) ? year + "-" + monthValue + "-" + dayValue : "";

  return (
    <div
      className={`input-group ${sizeClassName} ${className}`}
      data-testid="memorable-date"
    >
      {label && (
        <div className="input-group-prepend" data-testid="memorable-date-label">
          <span className="input-group-text">{label}</span>
        </div>
      )}
      <input type="hidden" id={paramKey} name={paramKey} value={dateValue} />
      <input
        style={{ width: '2.5em', borderRight: 0 }}
        data-testid="memorable-date-day"
        ref={dayRef}
        type="number"
        step={1}
        min={minDay}
        max={maxDay}
        placeholder="d"
        value={day}
        className={dayClassName}
        onChange={onDayChange}
      />
      <span
        className="form-control px-0 flex-grow-0 flex-shrink-0 text-center text-muted"
        style={{ minWidth: '0.5em', borderLeft: 0, borderRight: 0 }}
      >
        /
      </span>
      <input
        style={{ width: '2.5em', borderLeft: 0, borderRight: 0 }}
        data-testid="memorable-date-month"
        ref={monthRef}
        type="number"
        step={1}
        min={minMonth}
        max={maxMonth}
        placeholder="m"
        value={month}
        className={monthClassName}
        onChange={onMonthChange}
      />
      <span
        className="form-control px-0 flex-grow-0 flex-shrink-0 text-center text-muted"
        style={{ minWidth: '0.5em', borderLeft: 0, borderRight: 0 }}
      >
        /
      </span>
      <input
        style={{ width: '5em', borderLeft: 0 }}
        data-testid="memorable-date-year"
        ref={yearRef}
        type="number"
        step={1}
        min={minYear}
        max={maxYear}
        placeholder="yyyy"
        value={year}
        className={yearClassName}
        onChange={onYearChange}
      />

      {(!!selection || anyDatePartSet) && (
        <div className="input-group-append">
          <button
            type="button"
            data-testid="memorable-date-btn-clear"
            className="btn btn-sm btn-outline-light"
            onClick={() => clearAll(true)}
            aria-label="Clear"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      )}
    </div>
  );
};

MemorableDate.propTypes = {
  size: PropTypes.oneOf(Object.keys(InputSize)),
  label: PropTypes.string.isRequired,
  paramKey: PropTypes.string.isRequired,
  minDateIso: PropTypes.string,
  maxDateIso: PropTypes.string,
  initialValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string
};
