import React, { useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { parseAxiosError } from '../shared/AxiosResponseErrorParser';
import FlowContainerModal from '../flows/core/FlowContainerModal';
import SelectEntitiesStep from '../flows/core/SelectEntitiesStep';
import ModelInstanceView from '../models/ModelInstanceView';

const CloneCaseFlow = ({ model, modalTitle, onModalClose }) => {

  const [errorMessage, setErrorMessage] = useState(null);

  function onSubmit (flowData) {
    return axios.post(`/api/case/clone`, {
      caseId: model.id,
      caseTaskIds: (flowData[0] || []).map(item => item.id)
    })
      .then(response => {
        onModalClose(response.data.data.id);
      })
      .catch(e => {
        setErrorMessage(parseAxiosError(e, 'Failed to retrieve results from server').getMessage());
      });
  }

  return (<FlowContainerModal modalTitle={modalTitle} errorMessage={errorMessage} onFinalSubmit={onSubmit} onCancel={() => onModalClose()}>
    <SelectEntitiesStep key={'one'} title="Tasks to copy" apiUrl={`/api/case/${model.id}/task`} required={false} />
  </FlowContainerModal>);
};
CloneCaseFlow.propTypes = {
  model: PropTypes.object.isRequired,
  modalTitle: PropTypes.string.isRequired,
  onModalClose: PropTypes.func.isRequired
};

const CloneCaseFlowModal = ({caseId, ...rest}) =>
  <ModelInstanceView floating={true} apiUrl={`/api/case/${caseId}`} viewItem={CloneCaseFlow} {...rest}/>;

CloneCaseFlowModal.propTypes = {
  caseId: PropTypes.number.isRequired,
  modalTitle: PropTypes.string.isRequired,
  onModalClose: PropTypes.func.isRequired
};

export default CloneCaseFlowModal;
