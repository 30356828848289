import React from 'react';
import { useAppState } from './AppState';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import FocusTrap from "focus-trap-react"

export default function SidePanelComponent (props) {
  const { sidePanelActive, sidePanelTitle, sidePanelComponents, toggleSidePanelActive } = useAppState();

  const closeSidePanel = () => {toggleSidePanelActive();};
  const onOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      toggleSidePanelActive();
    }
  };
  const handleKeyDown = (e => {
    if (e.key === "Enter" || e.key === " ") {
      closeSidePanel();
    }
  });

  return (<>
    {sidePanelActive &&
    <FocusTrap
      focusTrapOptions={{
        tabbableOptions: {
          displayCheck: "none"
        }
      }}
    >
      <div onClick={onOverlayClick} className="side-panel side-panel-visible">
        <header className="side-panel-header shadow">
          <OverlayTrigger placement="bottom" overlay={<Tooltip>Close this information panel</Tooltip>}>
            <div tabIndex={0} onClick={() => closeSidePanel()} onKeyDown={handleKeyDown} className="close-btn">
              <span className="sr-only">Close</span>
            </div>
          </OverlayTrigger>
          <div className="side-panel-title pl-4 ml-5"><h1>{sidePanelTitle || "Information"}</h1></div>
        </header>
        <div className="side-panel-container shadow">
          <div className="side-panel-content">
            {sidePanelComponents}
          </div>
        </div>
      </div>
    </FocusTrap>}
  </>);
};
