import React from 'react';
import { ProfileForm } from './Profile';
import ModelInstanceEditView from '../models/ModelInstanceEditView';
import { Page, PageNav } from '../shared/Page';

const ProfilesEditPage = (props) => {
  const { profileId } = props.match.params;
  const apiEndpoint = `/api/profile/${profileId}`;

  return (<Page>
    <PageNav title="nav.profiles.edit" />
    <ModelInstanceEditView
      viewTitle={'Edit profile'}
      apiUrl={apiEndpoint}
      schemaFormComponent={ProfileForm}
      pathAfterSuccess={`/profiles/show/${profileId}`}
      {...props}/>
  </Page>);
};

export default ProfilesEditPage;
