import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { parseAxiosError } from '../shared/AxiosResponseErrorParser';
import { PromptModal } from '../shared/Modal';
import { useIntl } from 'react-intl';
import FormForSchemaModal from '../form/FormForSchemaModal';
import * as StatusUtils from '../utils/Status';
import { caseConfigurationVersion, caseStatusFromId } from '../shared/types';
import { useAppConfiguration } from '../shared/contexts/AppConfiguration';

const CaseStatusChangeModal = ({ model, modalTitle, onModalClose }) => {
  const intl = useIntl();
  const appConfig = useAppConfiguration();
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isConfirmVisible, setIsConfirmVisible] = useState(false);
  const [submittableDetails, setSubmittableDetails] = useState(null);
  const caseConfig = caseConfigurationVersion(
    appConfig,
    model.caseRef,
    model.caseVersion
  );
  const [schema, setSchema] = useState(undefined);

  const onSubmit = (formData) => {
    const newStatus = caseStatusFromId(
      formData.status,
      caseConfig.meta.statuses
    );
    if (newStatus.terminating) {
      setIsConfirmVisible(true);
      setSubmittableDetails(formData);
    } else {
      postChanges(formData);
    }
  };

  const postChanges = (formData) => {
    setLoading(true);
    axios
      .post(`/api/case/${model.id}/status`, formData)
      .then(() => {
        onModalClose(formData.status);
      })
      .catch((e) => {
        setLoading(false);
        setErrorMessage(parseAxiosError(e).getMessage());
      });
  };

  // load in the valid available statuses *prior* to trying to construct a valid
  // form schema
  useEffect(() => {
    if (model) {
      axios
        .get(
          `${process.env.REACT_APP_SERVER}/api/case/${model.id}/status/available`
        )
        .then((response) => {
          const statuses = response.data.data;
          setSchema({
            type: 'object',
            properties: {
              status: {
                title: 'New status',
                description:
                  'Select a new status from a list of available ones',
                type: 'string',
                enum: statuses
                  .filter((status) => status.id !== model.status)
                  .map((status) => status.id),
                enumNames: statuses
                  .filter((status) => status.id !== model.status)
                  .map((status) => StatusUtils.sanitisedStatusLabel(status))
              },
              comment: {
                title: 'Comment',
                type: 'string',
                description:
                  'Leave a comment describing why status is being changed. It will be sent through notifications as well'
              },
              helpText: {
                title: intl.formatMessage({
                  id: 'nav.cases.status.edit.notifications'
                }),
                description: intl.formatMessage({
                  id: 'nav.cases.status.edit.notifications.description'
                }),
                type: 'null'
              }
            },
            required: ['status', 'comment']
          });
        })
        .catch(function (e) {
          console.warn('an error occurred during available status load');
        });
    }
  }, [model]);

  const uiSchema = () => {
    return {
      comment: {
        'ui:widget': 'textarea'
      }
    };
  };

  return (
    <>
      {schema && (
        <FormForSchemaModal
          modalTitle={modalTitle}
          isLoading={loading}
          onModalClose={onModalClose}
          onSubmit={onSubmit}
          errorMessage={errorMessage}
          schema={schema}
          uiSchema={uiSchema()}
          rules={[]}
        />
      )}
      {isConfirmVisible && (
        <PromptModal
          title="Are you sure?"
          text="It will be locked!"
          onCancel={() => setIsConfirmVisible(false)}
          onSuccess={() => postChanges(submittableDetails)}
        />
      )}
    </>
  );
};

CaseStatusChangeModal.propTypes = {
  model: PropTypes.object.isRequired,
  modalTitle: PropTypes.string.isRequired,
  onModalClose: PropTypes.func.isRequired,
  bodyClass: PropTypes.string
};

export default CaseStatusChangeModal;
