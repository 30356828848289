import React from 'react';

import { ReactComponent as AngleRightSvg } from '../images/icons/forward.svg';
import { ReactComponent as CaretSquareDownSvg } from '../images/icons/expand.svg';
import { ReactComponent as CaretSquareUpSvg } from '../images/icons/collapse.svg';
import { ReactComponent as ExternalLinkSvg } from '../images/icons/external-link.svg';
import { ReactComponent as SearchSvg } from '../images/icons/search.svg';
import { ReactComponent as DownloadSvg } from '../images/icons/download.svg';
import { ReactComponent as LogoutSvg } from '../images/icons/logout.svg';
import { ReactComponent as EllipsisSvg } from '../images/icons/ellipsis.svg';
import { ReactComponent as RefreshSvg } from '../images/icons/refresh.svg';
import { ReactComponent as CloseSvg } from '../images/icons/close.svg';
import { ReactComponent as AddSvg } from '../images/icons/add.svg';
import { ReactComponent as PriorityHighSvg } from '../images/icons/priority-high.svg';
import { ReactComponent as PriorityMediumSvg } from '../images/icons/priority-medium.svg';
import { ReactComponent as PriorityLowSvg } from '../images/icons/priority-low.svg';
import { ReactComponent as QuestionSvg } from '../images/icons/question.svg';

import { ReactComponent as AssessmentSvg } from '../images/icons/assessment.svg';
import { ReactComponent as CaseSvg } from '../images/icons/case.svg';
import { ReactComponent as FormSvg } from '../images/icons/form.svg';
import { ReactComponent as TasksSvg } from '../images/icons/tasks.svg';
import { ReactComponent as UsersSvg } from '../images/icons/people.svg';
import { ReactComponent as UserShieldSvg } from '../images/icons/user_shield.svg';
import { ReactComponent as KeysSvg } from '../images/icons/keys.svg';
import { ReactComponent as DocViewSvg } from '../images/icons/docview.svg';
import { ReactComponent as DocSvg } from '../images/icons/doc.svg';
import { ReactComponent as FolderSvg } from '../images/icons/folder.svg';

import { ReactComponent as FileAltSvg } from '../images/icons/files/file.svg';
import { ReactComponent as FileArchiveSvg } from '../images/icons/files/archive.svg';
import { ReactComponent as FileAudioSvg } from '../images/icons/files/audio.svg';
import { ReactComponent as FileExcelSvg } from '../images/icons/files/excel.svg';
import { ReactComponent as FileCodeSvg } from '../images/icons/files/code.svg';
import { ReactComponent as CalendarAltSvg } from '../images/icons/files/calendar.svg';
import { ReactComponent as FileImageSvg } from '../images/icons/files/image.svg';
import { ReactComponent as FilePdfSvg } from '../images/icons/files/pdf.svg';
import { ReactComponent as FilePowerpointSvg } from '../images/icons/files/ppt.svg';
import { ReactComponent as FileTextSvg } from '../images/icons/files/text.svg';
import { ReactComponent as FileVideoSvg } from '../images/icons/files/video.svg';
import { ReactComponent as FileWordSvg } from '../images/icons/files/word.svg';

const defaultIconHeight = 20;

export const Assessment = ({ height, ...rest }) => <AssessmentSvg height={height || defaultIconHeight} {...rest} />;
export const Case = ({ height, ...rest }) => <CaseSvg height={height || defaultIconHeight} {...rest} />;
export const Form = ({ height, ...rest }) => <FormSvg height={height || defaultIconHeight} {...rest} />;
export const Tasks = ({ height, ...rest }) => <TasksSvg height={height || defaultIconHeight} {...rest} />;
export const Users = ({ height, ...rest }) => <UsersSvg height={height || defaultIconHeight} {...rest} />;
export const UserShield = ({ height, ...rest }) => <UserShieldSvg height={height || defaultIconHeight} {...rest} />;
export const Keys = ({ height, ...rest }) => <KeysSvg height={height || defaultIconHeight} {...rest} />;
export const DocView = ({ height, ...rest }) => <DocViewSvg height={height || defaultIconHeight} {...rest} />;
export const Doc = ({ height, ...rest }) => <DocSvg height={height || defaultIconHeight} {...rest} />;
export const Folder = ({ height, ...rest }) => <FolderSvg height={height || defaultIconHeight} {...rest} />;

export const AngleRight = ({ height, ...rest }) => <AngleRightSvg height={height || defaultIconHeight} {...rest} />;
export const CaretSquareDown = ({ height, ...rest }) => <CaretSquareDownSvg height={height || defaultIconHeight} {...rest} />;
export const CaretSquareUp = ({ height, ...rest }) => <CaretSquareUpSvg height={height || defaultIconHeight} {...rest} />;
export const Download = ({ height, ...rest }) => <DownloadSvg height={height || defaultIconHeight} {...rest} />;
export const Search = ({ height, ...rest }) => <SearchSvg height={height || defaultIconHeight} {...rest} />;
export const Logout = ({ height, ...rest }) => <LogoutSvg height={height || defaultIconHeight} {...rest} />;
export const Ellipsis = ({ height, ...rest }) => <EllipsisSvg height={height || defaultIconHeight} {...rest} />;
export const PriorityHigh = ({ height, ...rest }) => <PriorityHighSvg height={height || defaultIconHeight} {...rest} />;
export const PriorityMedium = ({ height, ...rest }) => <PriorityMediumSvg height={height || defaultIconHeight} {...rest} />;
export const PriorityLow = ({ height, ...rest }) => <PriorityLowSvg height={height || defaultIconHeight} {...rest} />;
export const Refresh = ({ height, ...rest }) => <RefreshSvg height={height || defaultIconHeight} {...rest} />;
export const Add = ({ height, ...rest }) => <AddSvg height={height || defaultIconHeight} {...rest} />;
export const Close = ({ height, ...rest }) => <CloseSvg height={height || defaultIconHeight} {...rest} />;
export const ExternalLink = ({ height, ...rest }) => <ExternalLinkSvg height={height || defaultIconHeight} {...rest} />;
export const Question = ({ height, ...rest }) => <QuestionSvg height={height || defaultIconHeight} {...rest} />;

// files
export const CalendarAlt = ({ height, ...rest }) => <CalendarAltSvg height={height || defaultIconHeight} {...rest} />;
export const FileAlt = ({ height, ...rest }) => <FileAltSvg height={height || defaultIconHeight} {...rest} />;
export const FileCode = ({ height, ...rest }) => <FileCodeSvg height={height || defaultIconHeight} {...rest} />;
export const FilePdf = ({ height, ...rest }) => <FilePdfSvg height={height || defaultIconHeight} {...rest} />;
export const FileImage = ({ height, ...rest }) => <FileImageSvg height={height || defaultIconHeight} {...rest} />;
export const FileArchive = ({ height, ...rest }) => <FileArchiveSvg height={height || defaultIconHeight} {...rest} />;
export const FileAudio = ({ height, ...rest }) => <FileAudioSvg height={height || defaultIconHeight} {...rest} />;
export const FileExcel = ({ height, ...rest }) => <FileExcelSvg height={height || defaultIconHeight} {...rest} />;
export const FilePowerpoint = ({ height, ...rest }) => <FilePowerpointSvg height={height || defaultIconHeight} {...rest} />;
export const FileText = ({ height, ...rest }) => <FileTextSvg height={height || defaultIconHeight} {...rest} />;
export const FileVideo = ({ height, ...rest }) => <FileVideoSvg height={height || defaultIconHeight} {...rest} />;
export const FileWord = ({ height, ...rest }) => <FileWordSvg height={height || defaultIconHeight} {...rest} />;
