import React, { FC, useEffect, useState } from 'react';
import {
  Case,
  CaseConfiguration,
  caseConfigurationVersion,
  caseOrTaskMessage,
  mapDisplaySpecification,
  UiMessageKey
} from '../types';
import { useAppConfiguration } from '../contexts/AppConfiguration';
import { Link } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

/**
 * Props structure
 */
export interface CaseLinkProps {
  /**
   * The actual {@link Case}
   */
  model: Case;

  /**
   * The limiting number characters to display from the title of the case
   */
  truncationLimit: number;

  /**
   * Whether the link should open in a new tab or not
   */
  newTab: boolean;

  /**
   * Whether the label for the case should be displayed
   */
  showLabel : boolean;

  /**
   * Whether the id for the case should be displayed as a badge
   */
  showId: boolean;
}

/**
 * Have a look at the supplied case, along with its config and build a sensible label for it
 * @param model the case model
 * @param config the case configuration
 * @param truncationLimit
 */
function buildCaseLabel(
  model: Case,
  config: CaseConfiguration,
  truncationLimit: number
): string[] {
  let label: string | undefined = '';

  // map the title of the case (we may need to extract it from the actual form data)
  if (config.meta.titleDisplaySpecification) {
    label = mapDisplaySpecification(
      config,
      config.meta.titleDisplaySpecification,
      (label, f) => {
        return f(model);
      }
    );
  }

  // grab the label (case type) for the case
  if (!label) {
    return [
      caseOrTaskMessage(config, UiMessageKey.TitleSingular),
      caseOrTaskMessage(config, UiMessageKey.TitleSingular)
    ];
  }

  return [
    label.length > truncationLimit
      ? label.slice(0, truncationLimit) + '...'
      : label,
    caseOrTaskMessage(config, UiMessageKey.TitleSingular)
  ];
}

/**
 * Generic component for displaying a one-line link to a given case, with
 * additional information included such as title, id and status
 * @constructor
 */
export const CaseLink: FC<CaseLinkProps> = ({
  model,
  truncationLimit = 24,
  newTab = true,
  showLabel = true,
  showId = false
}) => {
  const appConfig = useAppConfiguration();
  const [caseLabel, setCaseLabel] = useState<string[]>([]);

  useEffect(() => {
    if (appConfig.loaded) {
      const config = caseConfigurationVersion(
        appConfig,
        model.caseRef,
        model.caseVersion
      );
      setCaseLabel(buildCaseLabel(model, config, truncationLimit));
    }
  }, [appConfig, model, model.caseRef, model.caseVersion, truncationLimit]);

  return (
    <>
      <span className={'case-link-container flex-row'}>
        <span className={'small-badge flex-column'}>
          {caseLabel[1]}
        </span>
        {showId && (
          <span className={'small-badge-id flex-column'}>{model.id}</span>
        )}
        <span
          className={'case-link-detail flex-column'}
          style={{ maxWidth: 50 }}
        >
          {newTab && showLabel && (
            <Link
              to={`/cases/show/${model.id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <OverlayTrigger
                overlay={
                  <Tooltip id={`related-case-tooltip-${model.id}`}>
                    {caseLabel[1]}
                  </Tooltip>
                }
                placement={'top'}
              >
                <span className={'related-case-label'}>{caseLabel[0]}</span>
              </OverlayTrigger>
            </Link>
          )}
          {!newTab && showLabel && (
            <Link to={`/cases/show/${model.id}`}>
              <OverlayTrigger
                overlay={
                  <Tooltip id={`related-case-tooltip-${model.id}`}>
                    {caseLabel[1]}
                  </Tooltip>
                }
                placement={'top'}
              >
                <span className={'related-case-label'}>{caseLabel[0]}</span>
              </OverlayTrigger>
            </Link>
          )}
        </span>
      </span>
    </>
  );
};
