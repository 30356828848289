import React from 'react';
import { NotificationPreferencesForm } from './Profile';
import ModelInstanceEditView from '../models/ModelInstanceEditView';
import { Page, PageNav } from '../shared/Page';

const EditProfilePreferencesPage = (props) => {
  const { profileId } = props.match.params;
  return (<Page>
    <PageNav title="nav.profiles.preferences"/>
    <ModelInstanceEditView
      viewTitle={'Notification Preferences'}
      apiUrl={`/api/profile/${profileId}/preferences`}
      schemaFormComponent={NotificationPreferencesForm}
      pathAfterSuccess={`/profiles/show/${profileId}`}
      {...props}/>
  </Page>);
};

export { EditProfilePreferencesPage };
