import React from 'react';
import PropTypes from 'prop-types';
import AbstractFlowStep from './AbstractFlowStep';
import ModelListViewSelectMultipleItems from '../../models/ModelListViewSelectMultipleItems';

class SelectItemsStep extends AbstractFlowStep {

  constructor (props) {
    super(props);
    this.state = {
      hasError: false,
      selections: props.prevSuccessResult
    };
    this.doNext = this.doNext.bind(this);
    this.onItemsUpdated = this.onItemsUpdated.bind(this);
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (this.props.prevSuccessResult !== prevProps.prevSuccessResult) {
      this.setState({
        selections: this.props.prevSuccessResult
      });
    }
  }

  doNext () {
    if (this.state.selections && this.state.selections.length) {
      this.props.onNextSuccess(this.state.selections);
    } else if (!this.props.required) {
      this.props.onNextSuccess([]);
    }
  }

  onItemsUpdated (items) {
    this.setState({
      selections: items
    });
  }

  render () {
    const { title, items, prevSuccessResult, allSuccessResults, onNextSuccess, ...rest } = this.props;
    return <>
      {title && <h3>{title}</h3>}
      <ModelListViewSelectMultipleItems items={items} initialSelection={this.state.selections} onItemsUpdated={this.onItemsUpdated} {...rest}/>
    </>;
  }
}

SelectItemsStep.propTypes = {
  ...AbstractFlowStep.propTypes,
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  required: PropTypes.bool.isRequired
};

export default SelectItemsStep;
