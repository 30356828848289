const defaultMessages = require("../../messages.json");

module.exports = Object.assign({}, defaultMessages, {
  "general.brand": "Dapian",

  "model.case.details": "Service/Product",
  "model.case.searchable": "Visible outside organisation",
  "model.case": "Project",

  "model.notification.EMAIL_CASES_DUE_FOR_REVIEW": "Email notification of any visible Projects due for review",

  "nav.assessment": "Create new Project",
  "nav.assessment.description": "Create a new project",
  "nav.assessment.step.last.description": "If the screening tool suggested that a Project is required, you need to complete the relevant Project form. If you assign tasks to other users for their input, you are able to login and check the progress of the task, otherwise notifications will be sent to your registered email address when the task status' changes.",

  "nav.export.cases": "Export projects",

  "nav.cases": "Projects",
  "nav.cases.notfound": "No Projects found",
  "nav.cases.internal.search": "Search Projects",
  "nav.cases.internal.search.description": "A list of all Projects",
  "nav.cases.external.search": "Search other organisations’ Projects",
  "nav.cases.edit": "Edit service/product",
  "nav.cases.show": "Project Case",
  "nav.cases.history": "Project history",
  "nav.cases.add": "Create new Project",
  "nav.cases.status.edit": "Change Project status",
  "nav.cases.status.edit.notifications.description": "System will attempt to send notifications to the Project creator and any assignees currently working on tasks. Comment will be part of that notification text.",
  "nav.cases.priority.edit": "Change Project priority",
  "nav.cases.clone": "Clone Project",
  "nav.cases.user": "Your Projects",
  "nav.cases.links": "Related Project",
  "nav.cases.links.notfound": "No related Projects found",
  "nav.cases.links.add": "Link related Project",

  "nav.tasks.all.description": "Search all assigned tasks",
  "nav.tasks.forms": "Available forms",
  "nav.tasks.forms.description": "Tasks relevant to the Project",
  "nav.tasks.add.step.last.description": "Notifications will be sent to the task assignee(s) and any watchers of the Project.",
  "nav.tasks.show": "Project Task",
  "nav.profiles.add.description": "Add new user profiles and admin rights",

  "nav.whats_new_in_product": "What's new in Dapian",

  "stats.cases.by.user": "Your Projects",
  "stats.cases.by.user.notfound": "No Projects found",
  "stats.cases.unresolved.age": "Age of active Projects",
  "stats.cases.unresolved.age.items": "Number of Projects",
  "stats.cases.daily": "Daily Projects",
  "stats.cases.daily.items": "Number of Projects"

});
