import axios from 'axios';
import { useEffect, useState } from 'react';
import { sendError } from '../../Airbrake';
import { useAuthContext } from '../../auth/AuthContextProvider';

/**
 * Hook used to load/determine whether any external search sources
 * are currently configured
 */
export const useExternalSources = () => {
  const authContext = useAuthContext() as { isAuthenticated: boolean };
  const [haveExternal, setHaveExternal] = useState(false);

  useEffect(() => {
    if (authContext.isAuthenticated) {
      axios
        .get(
          `${process.env.REACT_APP_SERVER}/api/config/search/external-sources`
        )
        .then((response) => {
          if (response) {
            if (response.data && response.data.total > 0) {
              setHaveExternal(true);
            }
          }
        })
        .catch((e) => {
          console.warn('error caught');
          console.warn(e);
          sendError(e);
        });
    }
  }, []);

  return [haveExternal];
};
