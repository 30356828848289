import {CaseStatus} from "../shared/types";

/**
 * Simple function that will take a case/task status and just sanitise the display string for it
 */
export function sanitisedStatusLabel(status : CaseStatus) : string {
  if (status.description !== null && status.description !== undefined){
    if (status.description.length > 0) {
      return `${status.label} - ${status.description}`;
    }else{
      return `${status.label}`;
    }
  }
  else{
    return `${status.label}`;
  }
}
