import { CommonWidgetProps } from './types';
import React from 'react';

export interface CheckboxWidgetProps extends CommonWidgetProps {
  /**
   * Are we checked?
   */
  checked?: boolean;

  /**
   * CB for responding to checked/unchecked events
   * @param checked
   */
  onChange: (checked: boolean) => void;
}

export const CheckboxWidget = React.forwardRef<
  HTMLInputElement,
  CheckboxWidgetProps
>((props, ref) => {

  return (
    <div
      className={props.disabled ? 'widget widget-checkbox disabled' : 'widget'}
    >
      <input
        id={`checkbox_${props.tag}`}
        className={'widget-checkbox-input'}
        ref={ref}
        key={`checkbox_${props.tag}`}
        type={'checkbox'}
        disabled={props.disabled}
        checked={props.checked ? props.checked : false}
        onChange={(event) => props.onChange(event.target.checked)}
      />
      <label
        htmlFor={`checkbox_${props.tag}`}
        className={
          props.labelClassName
            ? `widget-checkbox-label`
            : 'widget-checkbox-label'
        }
        onClick={() => props.onChange(!props.checked)}
      >
        {props.label}
      </label>
    </div>
  );
});
