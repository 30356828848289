import { ReportField } from '../types/Reports';
import React, { FC, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useAuthContext } from '../../auth/AuthContextProvider';

/**
 * The shape of the reporting configuration which is embedded within the
 * main app configuration
 */
export interface ReportingConfiguration {
  /**
   * Whether the configuration is loaded or not
   */
  loaded: boolean;

  /**
   * The available reporting fields for the main case table
   */
  caseTableFields: ReportField[];

  /**
   * The available reporting fields for the main task table
   */
  taskTableFields: ReportField[];
}

/**
 * Default state is basically empty
 */
const defaultReportingConfiguration: ReportingConfiguration = {
  loaded: false,
  caseTableFields: [],
  taskTableFields: []
};

/**
 * Context for holding the current app reporting configuration, including things such as
 *
 * 1. Available case table reporting fields
 * 2. Available task table reporting fields
 */
export const ReportingConfigurationContext =
  React.createContext<ReportingConfiguration>(defaultReportingConfiguration);

/**
 * Hook for consuming the context
 */
export const useReportingConfiguration = () =>
  useContext(ReportingConfigurationContext);

/**
 * The provider for reporting configuration context
 * @param children child components
 * @constructor
 */
export const ReportingConfigurationProvider: FC<any> = ({ children }) => {
  const authContext: any = useAuthContext();

  /**
   * The *actual* configuration state object
   */
  const [configuration, setConfiguration] = useState<ReportingConfiguration>(
    defaultReportingConfiguration
  );

  // only run this effect if we haven't already loaded the configuration
  useEffect(() => {
    const loadConfiguration = async () => {
      const [caseFields, taskFields] = await Promise.all([
        axios.get(
          `${process.env.REACT_APP_SERVER}/api/reports/config/fields/case`
        ),
        axios.get(
          `${process.env.REACT_APP_SERVER}/api/reports/config/fields/task`
        )
      ]);
      setConfiguration((config) => ({
        ...config,
        caseTableFields: caseFields.data.data,
        taskTableFields: taskFields.data.data,
        loaded: true
      }));
    };

    if (!authContext.loading && !configuration.loaded) {
      loadConfiguration().catch((e) => {
        console.warn(
          `Caught exception during reporting configuration load: ${JSON.stringify(
            e
          )}`
        );
      });
    }
  }, [authContext.loading, configuration.loaded]);

  return (
    <ReportingConfigurationContext.Provider value={configuration}>
      {children}
    </ReportingConfigurationContext.Provider>
  );
};
