import React, { FC } from 'react';
import { Case } from '../../shared/types';
import { CaseSearchResultItem } from './CaseSearchResultItem';
import { SpinnerDotted } from 'spinners-react';
import { GeneralIcons } from '../../shared/components/Icons';

export interface CaseSearchResultsListProps {
  /**
   * A list of case objects
   */
  results: Case[] | undefined;

  /**
   * Are we are currently loading results?
   */
  loading: boolean;

  /**
   * Callback for selection
   * @param result
   */
  onSelected: (result : Case) => void
}

/**
 * Displays a list of {@link Case} items, in a compressed format for use within search forms etc...
 * @constructor
 */
export const CaseSearchResultsList: FC<CaseSearchResultsListProps> = ({
  results,
  loading,
  onSelected
}) => {

  if (results && results.length > 0) {
    return (
      <>
        {!loading && (
          <>
            <div className={'search-results-header'}>{results?.length} results found</div>
            <div className={'search-results-container'}>
              {results?.map((result) => (
                <CaseSearchResultItem key={result.id} result={result} onSelected={onSelected} />
              ))}
            </div>
          </>
        )}
        {loading && <div className={'search-results-centered-container'}>
          <span><SpinnerDotted size={24} /></span>
          <span>Searching...</span>
        </div>}
      </>
    );
  }else{
    return (
      <div className={'search-results-centered-container'}>
        <span>{GeneralIcons.Warning('warning')}</span>
        <span>No results found</span>
      </div>
    );
  }
};
