// noinspection JSCheckFunctionSignatures

import React, { useEffect, useReducer, useRef } from 'react';
import { Prompt } from 'react-router-dom';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import ModelInstanceView from '../models/ModelInstanceView';
import RouterHistory from '../main/RouterHistory';
import { useAuthContext } from '../auth/AuthContextProvider';
import axios from 'axios';
import TaskDetailsCase from './TaskDetailsCase';
import TaskCommentsAttachments from './TaskCommentsAttachments';
import ListLoader from '../loaders/ListLoader';
import { Ellipsis, Tasks } from '../main/icons';
import { TaskComments } from './TaskComments';
import { parseAxiosError } from '../shared/AxiosResponseErrorParser';
import { FormFromTaskConfiguration } from '../form/FormFromTaskRef';
import { objectHash } from '../shared/ObjectUtils';
import { useAppState } from '../main/AppState';
import { TaskStatus } from './TaskStatusBadge';
import { isTaskFormValid } from '../form/utils';
import { toast } from 'react-toastify';
import { useDynamicDocumentTitle } from '../shared/hooks/DocumentTitleHooks';
import { ReferencesCases } from './related/ReferencesCases';
import { useAppConfiguration } from '../shared/contexts/AppConfiguration';
import { taskConfigurationVersion } from '../shared/types';

const TaskEditPage = (props) => {
  const { taskId } = props.match.params;
  return (
    <ModelInstanceView
      apiUrl={`/api/task/${taskId}`}
      viewItem={TaskEditForm}
      {...props}
    />
  );
};

const TaskEditForm = ({ model }) => {
  const {
    setSidePanelHeaderTitle,
    setActiveSidePanelComponents,
    toggleSidePanelActive,
    showSidebar
  } = useAppState();
  const {
    id: taskId,
    title,
    taskRef,
    taskVersion,
    formModelJson,
    case: taskCase
  } = model;
  const initialFormModelJson = JSON.stringify(formModelJson);
  const appConfig = useAppConfiguration();
  const rsfRef = useRef();
  const { sessionExpired } = useAuthContext();

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      outsideModel: formModelJson,
      draftModel: formModelJson,
      isPromptDisabled: false,
      loading: true,
      errorMessage: null,
      confirmModalVisible: false,
      modalText: null,
      submitAttemptMade: false
    }
  );

  useDynamicDocumentTitle(() => {
    return `Edit ${model.title} #${model.id}`;
  });

  // hide sidebar on full page edit;
  useEffect(() => showSidebar && showSidebar(false), []);

  // prompt default browser message for reloading form;
  useEffect(() => {
    if (!sessionExpired)
      window.onbeforeunload = () => {
        return true;
      };
    else window.onbeforeunload = null;

    return () => {
      window.onbeforeunload = null;
    };
  }, [sessionExpired]);

  // updated so that the task configuration is read from app config, and then the
  // state just updated
  useEffect(() => {
    if (appConfig.loaded) {
      const taskConfiguration = taskConfigurationVersion(
        appConfig,
        taskRef,
        taskVersion
      );
      setState({
        taskConfiguration: taskConfiguration,
        workingSchema : taskConfiguration.form.schema,
        workingUiSchema : taskConfiguration.form.uiSchema,
        workingRules : taskConfiguration.form.rules,
        loading: false
      });
    }
  }, [appConfig, appConfig.loaded, initialFormModelJson, taskRef, taskVersion]);

  // this stuff here appears to be specific to *one* type of form,
  // containing calculated risks.  Very BAD. It's essentially
  // tied to a specific widget type in the external library and should be
  // culled
  const updateFormData = (newData) => {
    toast.dark('Risk suggestion has been added!', {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2500
    });
    setState({
      draftModel: newData
    });
  };

  // as RJSF conditional form spits out
  // unpredictable empty objects (on update)
  // NOT UNPREDICTABLE - ONLY HAPPENS IF THE SCHEMA CONTAINS NULL TYPES
  // WHICH OF COURSE, THEY SHOULDN'T BECAUSE THEY ARE TECHNICALLY POINTLESS
  const initialHash = objectHash(state.outsideModel, true);
  const draftHash = objectHash(state.draftModel, true);

  const submitForm = async () => {
    const valid = await isTaskFormValid(
      state.taskConfiguration,
      state.draftModel
    );
    if (!valid) {
      setTimeout(() => {
        if (rsfRef.current !== null) {
          rsfRef.current.scrollIntoView();
        }
      }, 0);
      setState({
        submitAttemptMade: true
      });
    } else {
      await submit(true); //save full (complete);
      setState({
        isPromptDisabled: true,
        confirmModalVisible: false
      });
      showSidebar(true);
      setTimeout(() => RouterHistory.push(`/tasks/show/${taskId}`), 0);
    }
  };

  // Build the payload for form submission.  This will be different, depending
  // on whether we've received any updated rules throughout the course of
  // the editing process. If the isComplete flag is set, then server-side
  // validation will be carried out
  const buildFormSubmissionPayload = (isComplete) => {
    return {
      taskRef: taskRef,
      taskVersion: taskVersion,
      formModel: state.draftModel,
      isComplete: isComplete,
      rulesOverride: state.workingRules
    };
  };

  /**
   * There is no need to send HTTP POST if form data does not change
   */
  const submit = (isComplete) => {
    setState({
      submitAttemptMade: false,
      loading: true
    });

    if (initialHash !== draftHash) {
      return axios
        .put(`/api/task/${taskId}/form`, buildFormSubmissionPayload(isComplete))
        .then(() => {
          //update task status to DONE after complete form submit;
          if (isComplete) {
            return axios
              .put(`/api/task/${taskId}/status/${TaskStatus.DONE.name}`, {rulesOverride : state.workingRules})
              .then(() => {
                setState({
                  loading: false
                });
              })
              .catch((e) => {
                setState({
                  errorMessage: parseAxiosError(
                    `Failed to update status. ${e.message || ''}`
                  ).getMessage(),
                  loading: false
                });
              });
          }
        })
        .catch((e) => {
          setState({
            errorMessage: parseAxiosError(
              `Failed to save. ${e.message || ''}`
            ).getMessage(),
            loading: false
          });
        });
    } else {
      //update task status to DONE after form submit even if form has not changed (for cloned form);
      if (isComplete) {
        return axios
          .put(`/api/task/${taskId}/status/${TaskStatus.DONE.name}`, {rulesOverride : state.workingRules})
          .then(() => {
            setState({
              loading: false
            });
          })
          .catch((e) => {
            setState({
              errorMessage: parseAxiosError(
                `Failed to update status. ${e.message || ''}`
              ).getMessage(),
              loading: false
            });
          });
      }
    }
  };

  /**
   * Store drafts after each change to later check if it differs from initial model
   */
  const onFormChange = (formData) =>
    setState({
      draftModel: formData
    });

  // dynamic forms can alter rules, schema and uiSchema on the fly in response
  // to UX events, so we need to track this so that if the rules change we
  // can submit modified rules back to the server for validation
  const onRulesChange = (rules, schema, uiSchema) => {
    console.log('onRulesChange');
    setState({
      workingRules: rules,
      workingSchema: schema,
      workingUiSchema: uiSchema
    });
  };

  const onCancelEdit = () => {
    if (initialHash !== draftHash) {
      setState({
        confirmModalVisible: true,
        modalText: 'Form has unsaved changes, discard or save the changes?'
      });
    } else {
      showSidebar(true);
      RouterHistory.push(`/tasks/show/${taskId}`);
    }
  };

  const onModalCancelHandle = () =>
    setState({
      confirmModalVisible: false
    });

  const onModalSaveHandle = async () => {
    await submit(false); //save partial;
    setState({
      isPromptDisabled: true,
      confirmModalVisible: false
    });
    showSidebar(true);
    setTimeout(() => RouterHistory.push(`/tasks/show/${taskId}`), 0);
  };

  const onModalDiscardHandle = () => {
    setState({
      isPromptDisabled: true,
      confirmModalVisible: false
    });
    showSidebar(true);
    setTimeout(() => RouterHistory.push(`/tasks/show/${taskId}`), 0);
  };

  const sidePanelComponents = (timestamp) => {
    return (
      <>
        <TaskCommentsAttachments
          taskId={taskId}
          reload={() => reloadSidePanel()}
        />
        <hr />
        <ModelInstanceView
          apiUrl={`/api/case/${taskCase.id}`}
          viewItem={TaskDetailsCase}
        />
        <hr />
        <TaskComments taskId={taskId} reload={timestamp} />
        <hr />
        <ReferencesCases parentTaskId={taskId} reloadCookie={timestamp} />
      </>
    );
  };

  const reloadSidePanel = () => {
    setActiveSidePanelComponents(sidePanelComponents(new Date().getTime()));
  };

  const openSidePanel = () => {
    setSidePanelHeaderTitle('Comments & Related information');
    setActiveSidePanelComponents(sidePanelComponents());
    toggleSidePanelActive(true);
  };

  return (
    <>
      <Prompt
        when={
          !sessionExpired &&
          initialHash !== draftHash &&
          !state.isPromptDisabled
        }
        message={() => `Are you sure you want to navigate away from this page?`}
      />

      <div className="fullscreen-container pt-3">
        <nav className="navbar navbar-expand-lg navbar-light bg-light app-navbar shadow fixed-top">
          <div className="container pl-2">
            <div className="navbar-brand page-title">
              <Tasks className="mr-2 mb-1" height={28} />
              <h1 tabIndex={0}>{title}</h1>
            </div>
            <div className="navbar-options-container mr-1">
              <button
                aria-label="Open side panel"
                onClick={() => openSidePanel()}
                className="btn btn-outline-secondary mr-1"
              >
                <Ellipsis height={20} />
              </button>
              <button
                className="btn btn-md btn-outline-secondary mr-1"
                type="button"
                onClick={onModalSaveHandle}
              >
                Save
              </button>
              <button
                data-testid="save-btn"
                className="btn btn-md btn-primary mr-5"
                type="button"
                onClick={() => submitForm()}
              >
                Submit
              </button>
            </div>
          </div>
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip>Close this form</Tooltip>}
          >
            <button
              data-testid="close-btn"
              className="close-btn close-btn-full-screen"
              onClick={onCancelEdit}
            >
              <span className="sr-only">Close</span>
            </button>
          </OverlayTrigger>
        </nav>

        <div id="form-container" className="container form-container"  ref={rsfRef}>
          <div className="row justify-content-center">
            <div className="col-sm-12 col-lg-7">
              {state.errorMessage && (
                <div
                  data-testid="server-error-submit"
                  className="alert alert-danger"
                >
                  {state.errorMessage}
                </div>
              )}
              {state.loading && <ListLoader data-testid="submit-loading" />}
              {!state.loading && (
                <FormFromTaskConfiguration
                  className="pb-5 pt-5"
                  model={state.taskConfiguration}
                  values={state.draftModel}
                  rules={state.workingRules}
                  isSubmitVisible={false}
                  onChange={onFormChange}
                  onRulesChange={onRulesChange}
                  showErrorList={true}
                  liveValidate={state.submitAttemptMade}
                  formContext={{
                    formData: state.draftModel,
                    updateFormData: updateFormData
                  }}
                />
              )}

              <Modal
                data-testid="confirm-dialog"
                centered
                show={state.confirmModalVisible}
                onHide={onModalCancelHandle}
                animation={false}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Confirm</Modal.Title>
                </Modal.Header>
                <Modal.Body>{state.modalText}</Modal.Body>
                <Modal.Footer>
                  <button
                    data-testid="confirm-discard-btn"
                    type="button"
                    className="btn btn-outline-secondary pl-5 pr-5"
                    onClick={onModalDiscardHandle}
                  >
                    Discard
                  </button>
                  <button
                    data-testid="confirm-save-btn"
                    type="button"
                    className="btn btn-primary pl-5 pr-5"
                    onClick={onModalSaveHandle}
                  >
                    Save
                  </button>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { TaskEditPage, TaskEditForm };
