import { CommonWidgetProps } from './types';
import React from 'react';
import { combineClassNames } from '../../functions/ClassNameUtils';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { SHORT_DATE_FORMAT } from '../../functions/DateRange';
import { newUUID } from '../../functions';

export interface DateSelectWidgetProps extends CommonWidgetProps {
  /**
   * The currently selected date for the widget
   */
  selectedDate?: Date;

  /**
   * Callback fired when the selected date changes
   */
  onChange: (tag: string, value?: Date) => void;
}

export const DateSelectionWidget = React.forwardRef<
  HTMLInputElement,
  DateSelectWidgetProps
>((props, ref) => {
  return (
    <>
      <div className={'widget'}>
        <label
          htmlFor={props.label}
          key={newUUID()}
          className={
            props.labelClassName
              ? combineClassNames(
                props.labelClassName,
                'widget-label no-right-border input-group-text'
              )
              : 'widget-label no-right-border input-group-text'
          }
        >
          {props.label}
        </label>
        <div style={{ zIndex: 999 }}>
          <ReactDatePicker
            id={props.label}
            key={newUUID()}
            disabled={props.disabled}
            className={'widget-field no-left-border'}
            popperClassName={'widget-popper'}
            calendarClassName={'widget-calendar'}
            showMonthDropdown={true}
            showYearDropdown={true}
            showQuarterYearPicker={false}
            isClearable={true}
            todayButton={true}
            dropdownMode={'scroll'}
            dateFormat={SHORT_DATE_FORMAT}
            selected={props.selectedDate}
            onChange={(date) => {
              date !== null
                ? props.onChange(props.tag, date)
                : props.onChange(props.tag, undefined);
            }}
          ></ReactDatePicker>
        </div>
      </div>
    </>
  );
});
