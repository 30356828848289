import React, {useEffect, useState} from 'react';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import {BasePie} from './basePie';
import { useAuthContext } from '../auth/AuthContextProvider';
import { FormattedMessage} from 'react-intl';
import { CardWidget } from '../shared/CardWidget';
import {newUUID} from "../shared/functions";
import {CasesDueForReviewStackedBarChart} from "./CasesDueForReviewStackedBarChart";

const ReviewStatus = {
  SCHEDULED: 'Scheduled',
  DUE: 'Due',
  NOT_SET: 'Review not set'
};

export const CasesDueForReviewCard = () => {
  return (<CardWidget
    title='Cases due for review'
    navItems={undefined}>
    <CasesDueForReviewStackedBarChart refreshCookie={newUUID()}/>
  </CardWidget>);
};

export const CasesDueForReviewChart = () => {

  const { loading, profile } = useAuthContext();
  const [analyticsData, setAnalyticsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (!loading && profile) {
      axios.get('/api/stats/cases/review')
        .then(response => response.data.data || [])
        .then(results => {
          setAnalyticsData(results.map(result => ({
            id: ReviewStatus[result.review] || result.review,
            label: ReviewStatus[result.review] || result.review,
            value: result.count
          })));
        })
        .catch(() => setAnalyticsData([]))
        .finally(() => setIsLoading(false));
    }
  }, [profile, loading]);

  return (<div style={{ height: 300 }} className="d-flex align-items-center justify-content-center">
    {isLoading && <Spinner animation={'border'} variant={'primary'}/>}
    {!isLoading && analyticsData.length === 0 && <p className="text-muted text-center">
      <FormattedMessage id="stats.cases.review.notfound" />
    </p>}
    {!isLoading && analyticsData.length > 0 && <BasePie analyticsData={analyticsData}/>}
  </div>);
};
