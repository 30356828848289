import React, { FC, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { CaseSearchFilter } from './CaseSearchFilter';
import { Case } from '../../shared/types';
import { CaseSearchResultsList } from './CaseSearchResults';
import useAxios from 'axios-hooks';
import _ from 'lodash';
import { jsonPointerToJsonPath } from '../../shared/functions';

export interface CaseSearchModalProps {
  /**
   * Whether the modal should be displayed or not
   */
  show: boolean;

  /**
   * The title for the modal
   */
  title: string;

  /**
   * Callback which is called after the modal is closed, either through a close operation or a
   * "select" operation that provides a selected {@link Case} entity
   */
  onModalClose: (cancelled: boolean, item: Case | undefined) => void;
}

/**
 * This is a generic reusable component that allows for the search and selection of a case entity
 * based on a set of pre-defined query terms, governed by the backend configuration of a given case
 * type.
 * @param title
 * @param show
 * @param onModalClose
 * @constructor
 */
export const CaseSearchModal: FC<CaseSearchModalProps> = ({
  title,
  show,
  onModalClose
}) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<Case[]>();

  // hook the backend api
  const [{ loading }, searchCases] = useAxios(
    {
      url: `/api/search/cases`
    },
    {
      manual: true,
      autoCancel: true,
      useCache: false
    }
  );

  const debouncedSearch = _.debounce((params) => {
    console.log(`onQuery changed called : ${JSON.stringify(params)} `);
    searchCases({ url: `/api/search/cases?${searchQueryString(params)}` }).then(
      (response) => {
        setSearchResults(response.data.data);
      }
    );
  }, 300);

  useEffect(() => {
    setVisible(show);
  }, [show]);

  // build a valid query string based on the supplied params object
  function searchQueryString(params: any): string {

    // case ref and status are special cases
    let partial = `caseRef=${params['caseRef']}`;
    if (_.has(params, 'caseStatus')) {
      partial = `${partial}&caseStatus=${params['caseStatus']}`;
    }

    // the rest of the params are treated as json search terms at specific paths
    let paths = [];
    let terms = [];
    for (let key of _.keys(params)){
      if (key !== 'caseRef' && key !== 'caseStatus'){
        paths.push(jsonPointerToJsonPath(key));
        terms.push(params[key]);
      }
    }

    if (paths.length > 0){
      partial = `${partial}&jsonPaths=${encodeURIComponent(paths.join(','))}&queryTerms=${encodeURIComponent(terms.join(','))}`;
    }

    return partial;
  }

  // when the current params object (containing search parameters) changes,
  // we debounce a new search against the server
  function onQueryChanged(params: object) {
    debouncedSearch(params);
  }

  // when an item is selected from the current results list, notify our
  // parent component (passing the selecting case as a parameter) and then
  // close ourselves
  function onSelected(result : Case){
    console.log(`${JSON.stringify(result)} selected`);
    onModalClose(false, result);
  }

  return (
    <Modal
      centered={true}
      show={visible}
      onHide={() => {
        setVisible(false);
        onModalClose(true, undefined);
      }}
    >
      <Modal.Header title={title} closeButton={true}>
        <h1>{title}</h1>
      </Modal.Header>
      <Modal.Body>
        <div className={'search-container'}>
          <CaseSearchFilter onQueryChanged={onQueryChanged} />
          <CaseSearchResultsList results={searchResults} loading={loading} onSelected={onSelected} />
        </div>
      </Modal.Body>
    </Modal>
  );
};
