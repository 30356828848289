import { useMemo } from 'react';
import { useAppConfiguration } from '../contexts/AppConfiguration';
import { CaseConfiguration} from '../types';

/**
 * Grab a memoised list of all available {@link CaseConfiguration} instances
 */
export const useCaseConfigurations = () => {
  const appConfiguration = useAppConfiguration();
  return useMemo<CaseConfiguration[]>(() => {
    return appConfiguration.caseConfigurations;
  }, [appConfiguration.caseConfigurations]);
};

