import React from 'react';
import PropTypes from 'prop-types';
import AbstractFlowStep from './AbstractFlowStep';
import ModelListViewSelectMultiple from '../../models/ModelListViewSelectMultiple';

class SelectEntitiesStep extends AbstractFlowStep {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      selections: props.prevSuccessResult
    };
    this.doNext = this.doNext.bind(this);
    this.onItemsUpdated = this.onItemsUpdated.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.prevSuccessResult !== prevProps.prevSuccessResult) {
      this.setState({
        selections: this.props.prevSuccessResult
      });
    }
  }

  doNext() {
    if (this.state.selections && this.state.selections.length) {
      this.props.onNextSuccess(this.state.selections);
    } else if (!this.props.required) {
      this.props.onNextSuccess([]);
    }
  }

  onItemsUpdated(items) {
    this.setState({
      selections: items
    });
  }

  render() {
    const {
      title,
      selectedTitle,
      apiUrl,
      required,
      listItemRenderer,
      prevSuccessResult,
      allSuccessResults,
      onNextSuccess,
      ...rest
    } = this.props;
    return (
      <>
        {title && <h3>{title}</h3>}
        {!listItemRenderer && (
          <ModelListViewSelectMultiple
            apiUrl={apiUrl}
            initialSelection={this.state.selections}
            onItemsUpdated={this.onItemsUpdated}
            title={selectedTitle}
            {...rest}
          />
        )}
        {listItemRenderer && (
          <ModelListViewSelectMultiple
            apiUrl={apiUrl}
            initialSelection={this.state.selections}
            onItemsUpdated={this.onItemsUpdated}
            title={selectedTitle}
            listItemRenderer={listItemRenderer}
            {...rest}
          />
        )}
      </>
    );
  }
}

SelectEntitiesStep.propTypes = {
  ...AbstractFlowStep.propTypes,
  title: PropTypes.string,
  selectedTitle: PropTypes.string,
  apiUrl: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  listItemRenderer: PropTypes.func
};

export default SelectEntitiesStep;
