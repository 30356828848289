import { CaseConfiguration, TaskConfiguration } from './Config';
import { upperCaseFirst } from 'text-case';
import * as _ from 'lodash';

/**
 * An enumeration of available message keys...
 * corresponds to the message keys supplied within the case/task configurations at the server
 */
export enum UiMessageKey {
  SearchTitle = 'title.search',
  TitlePlural = 'title.plural',
  TitlePluralShort = 'title.plural.short',
  TitleSingular = 'title.singular',
  TitleSingularShort = 'title.singular.short',
  TitleAcronym = 'title.acronym',
  Verb = 'verb',
  InfoNext = 'info.next',
}

/**
 * Take a task or case configuration and retrieve a specific label value from the config.  If not present,
 * return '!undefined!'
 * @param config
 * @param key
 * @param capsFunc
 */
export const caseOrTaskMessage = (
  config: CaseConfiguration | TaskConfiguration,
  key: UiMessageKey,
  capsFunc: (a: string) => string = upperCaseFirst
) => {
  if (config === undefined || Object.keys(config).length === 0) return '';
  if (_.has(config.meta.messages, key)) {
    return capsFunc(_.get(config.meta.messages, key as string));
  } else {
    return '!undefined!';
  }
};

/**
 * Check whether a specific {@link UiMessageKey} exists within a given configuration
 * @param config
 * @param key
 */
export const haveCaseOrTaskMessage = (
  config: CaseConfiguration | TaskConfiguration,
  key: UiMessageKey
) => {
  if (config === undefined || Object.keys(config).length === 0) return false;
  return _.has(config.meta.messages, key);
};

/**
 * Same as {@link caseOrTaskMessage} but allows for an alternative to be used in the event of the label not existing
 * @param config
 * @param key
 * @param alternative
 * @param capsFunc
 */
export const caseOrTaskMessageElse = (
  config: CaseConfiguration | TaskConfiguration,
  key: UiMessageKey,
  alternative: (config: CaseConfiguration | TaskConfiguration) => string,
  capsFunc: (a: string) => string = upperCaseFirst
) => {
  if (_.has(config.meta.messages, key)) {
    return capsFunc(_.get(config.meta.messages, key as string));
  } else {
    return alternative(config);
  }
};
