import React from 'react';
import { ConditionalFormForSchema } from '@lookinglocal/react-jsonschema-form-extensions';
import {latestCaseConfiguration} from "../shared/types";

const CaseDetailsForm = (props) => {
  const appConfig = props.appConfig;
  const caseRef = props.caseRef;
  const caseConfig = latestCaseConfiguration(appConfig, caseRef);
  return (
    <ConditionalFormForSchema
      schema={caseConfig.form.schema}
      uiSchema={caseConfig.form.uiSchema}
      rules={caseConfig.form.rules}
      {...props}
    />
  );
};

export default CaseDetailsForm;
