import React, { useState } from 'react';
import PropTypes from 'prop-types';

function ListGroupItem ({ children, ...rest }) {
  return <button type="button" {...rest}>{children}</button>;
}

function DefaultListItem ({ model = {}, ...rest }) {
  return <div {...rest}>{model.displayName}</div>;
}

const ModelListViewSelectMultipleItems = ({
  items,
  onItemsUpdated,
  listItemRenderer: ItemComponent = DefaultListItem,
  initialSelection,
  className,
  ...rest
}) => {

  const [selectedItems, setSelectedItems] = useState(initialSelection);

  function findItemIndex (item) {
    const itemJson = JSON.stringify(item);
    return (selectedItems || []).findIndex(existing => JSON.stringify(existing) === itemJson);
  }

  function onClick (item) {
    const existingIndex = findItemIndex(item);
    if (existingIndex < 0) {
      const selected = (selectedItems || []).slice();
      selected.push(item);
      setSelectedItems(selected);
      onItemsUpdated && onItemsUpdated(selected);
    } else {
      unselect(item);
    }
  }

  function unselect (item) {
    const existingIndex = findItemIndex(item);
    if (existingIndex > -1) {
      const selected = (selectedItems || []).slice();
      selected.splice(existingIndex, 1);
      setSelectedItems(selected);
      onItemsUpdated && onItemsUpdated(selected);
    }
  }

  function isActive (item) {
    const existingIndex = findItemIndex(item);
    return existingIndex > -1;
  }

  function listItemClassName (item) {
    return `list-group-item list-group-item-action ${isActive(item) ? 'active' : ''}`;
  }

  function listGroupItemViews () {
    if (items && items.length) {
      return items.map((item, key) =>
        <ListGroupItem key={`item${key}`} data-testid={`item${key}${isActive(item) ? '_selected' : ''}`} onClick={() => onClick(item)} className={listItemClassName(item)}>
          <ItemComponent model={item} {...rest}/>
        </ListGroupItem>);
    }
  }

  return (<div className={className || 'list-group'} data-testid="list">
    {listGroupItemViews()}
  </div>);
};

ModelListViewSelectMultipleItems.propTypes = {
  items: PropTypes.array.isRequired,
  initialSelection: PropTypes.array,
  onItemsUpdated: PropTypes.func,
  listItemRenderer: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
};

export default ModelListViewSelectMultipleItems;
