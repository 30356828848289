import React from 'react';
import PropTypes from 'prop-types';
import {FormForSchema} from "@lookinglocal/react-jsonschema-form-extensions";

const ProfileRolesForm = ({ flavourRoles, ...rest }) => {

  const flavourRolesSchema = {
    title: 'Profile roles',
    description: 'Each role might have a specific set of permissions. Also helps to differentiate among people when assigning tasks',
    type: 'array',
    items: {
      type: 'string',
      enum: flavourRoles.map(role => role.authority),
      enumNames: flavourRoles.map(role => `${role.label} - ${role.description}`)
    },
    uniqueItems: true
  };

  const schema = {
    type: 'object',
    title: 'Update roles',
    description: 'Profile roles are used to gain access to parts of this application',
    properties: {}
  };

  if (flavourRoles && flavourRoles.length) {
    schema.properties.flavourRoles = flavourRolesSchema;
  }

  const uiSchema = {
    flavourRoles: {
      'ui:widget': 'checkboxes'
    }
  };

  return (<FormForSchema schema={schema} uiSchema={uiSchema} {...rest}/>);
};

ProfileRolesForm.propTypes = {
  flavourRoles: PropTypes.arrayOf(PropTypes.shape({
    authority: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
  })).isRequired
};

export default ProfileRolesForm;
