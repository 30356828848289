import React, { useEffect, useState } from 'react';
import { getURLSearchParams, getURLSearchParamsWithoutPagination, RouterHistory } from '../main/RouterHistory';
import PropTypes from 'prop-types';
import { QueryParameter } from '../models/QueryParameter';
import { InputSize, sizeToInputGroupClassName } from './InputSize';
import axios from 'axios';
import { parseAxiosError } from '../shared/AxiosResponseErrorParser';
import Spinner from 'react-bootstrap/Spinner';

/**
 * Is functional as standalone element which changes
 * query parameter ("profileRole") in URL.
 */
export const SelectProfileRole = ({ className, size, useRouter, onChange, value }) => {

  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [availableRoles, setAvailableRoles] = useState({});

  const sizeClassName = sizeToInputGroupClassName(size);
  let selection;
  if (useRouter) {
    const params = getURLSearchParams();
    selection = params.get(QueryParameter.profileRole.key) || '';
  } else {
    selection = value || '';
  }

  useEffect(() => {
    axios.get(`/api/auth/roles`)
      .then(availableRolesResponse => {
        //insert no roles filter to the list of roles;
        const roles = availableRolesResponse.data.data || {};
        const anyRole = {authority: "ANY-ROLE", description: null, label: "-- At least one role --", permissions: []};
        const noAssignedRoles = {authority: "NONE", description: null, label: "-- No roles --", permissions: []};
        roles.flavourRoles.unshift(anyRole);
        roles.flavourRoles.push(noAssignedRoles);

        setAvailableRoles(roles);
        setLoading(false);
      })
      .catch(e => {
        setErrorMessage(parseAxiosError(e).getMessage());
        setLoading(false);
      });
  }, []);

  const onValueChange = (event) => {
    const val = event.target.value;
    if (useRouter) {
      const urlQueryParams = getURLSearchParamsWithoutPagination();
      urlQueryParams.set(QueryParameter.profileRole.key, val);
      RouterHistory.push({
        pathname: RouterHistory.location.path,
        search: urlQueryParams.toString()
      });
    } else {
      onChange && onChange(val);
    }
  };

  return (<div className={`form-group ${className}`}>
    <label htmlFor="profileRoleSelector" className="sr-only">{QueryParameter.profileRole.label}</label>
    {!loading && availableRoles.flavourRoles && <>
      <div className={`input-group ${sizeClassName} mr-sm-3`}>
        <div className="input-group-prepend">
          <label className="input-group-text" htmlFor={QueryParameter.profileRole.key}>{QueryParameter.profileRole.label}</label>
        </div>
        <select value={selection} onChange={onValueChange} className="custom-select" id="profileRoleSelector">
          <option value="">-- Unfiltered --</option>
          { availableRoles.flavourRoles.map(r => <option key={r.authority} value={r.authority}>{r.label}{r.description && ` - ${r.description}`}</option>) }
        </select>
      </div> </>}
    {loading && <Spinner animation={'border'} variant={'secondary'} size={'sm'}/> }
    {errorMessage && <span className="text-danger">{errorMessage}</span>}
  </div>);
};

SelectProfileRole.propTypes = {
  size: PropTypes.oneOf(Object.keys(InputSize)),
  className: PropTypes.string,
  useRouter: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.any,
};
