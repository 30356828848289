import React, { useCallback, useEffect, useState } from 'react';
import ModelInstanceView from '../models/ModelInstanceView';
import { Page, PageNav } from '../shared/Page';
import { TaskStatus, TaskStatusBadge } from './TaskStatusBadge';
import { Link } from 'react-router-dom';
import { CommonDateTime } from '../shared/Dates';
import { FormFromTaskConfiguration } from '../form/FormFromTaskRef';
import RouterHistory from '../main/RouterHistory';
import { useApiGet } from '../models/useApi';
import axios from 'axios';
import { ProfileLink } from '../profile/Profile';
import { useFormDiffs } from '../form/FormHooks';
import { TaskDiffSummary } from './TaskDiffSummary';

const TaskSnapshot = ({ model: activity }) => {
  const previousVersionNumber =
    activity.entityVersion < 2 ? 1 : activity.entityVersion - 1;
  const [hasNextHistory, setHasNextHistory] = useState(false);
  const [metaChanges, setMetaChanges] = useState({});

  // this needs to be looked at, as it gets called repeatedly and needs memoisation
  const { data: previous, loading: previousLoading } = useApiGet(
    `/api/task/${activity.entityId}/history/version/${previousVersionNumber}`,
    {}
  );

  // this callback just calculates changes to the meta-data (status etc...) associated with the
  // form version, and does not inspect the internal structure of the form (this is done through a more
  // idiomatic hook)
  const calculateMetaChanges = useCallback(function(
    previousVersion,
    currentVersion
  ) {
    const currentEvent = currentVersion.event;
    const previousEvent = previousVersion.event;
    let result = {};
    if (currentEvent.assignee.id !== previousEvent.assignee.id)
      result.event_assignee = currentEvent.assignee.displayName;
    if (currentEvent.status !== previousEvent.status)
      result.event_status = currentEvent.status;
    if (currentEvent.updatedAt !== previousEvent.updatedAt)
      result.event_update = currentEvent.updatedAt;
    return result;
  }, []);

  useEffect(() => {
    axios
      .get(
        `/api/task/${activity.entityId}/history/version/${activity.entityVersion + 1
        }`
      )
      .then(() => {
        setHasNextHistory(true);
      })
      .catch(() => {
        setHasNextHistory(false);
      });
  }, [activity.entityId, activity.entityVersion]);

  useEffect(() => {
    if (!previousLoading && previous) {
      setMetaChanges(calculateMetaChanges(activity, previous));
    }
  }, [calculateMetaChanges, activity, previousLoading, previous]);

  // form differences are now computed using a hook, which summarises the
  // changes by way of RFC6902 compliant differences
  const formDiffSummary = useFormDiffs(previous, activity);

  const onShowPreviousVersion = () => {
    if (activity.entityVersion !== 1)
      RouterHistory.push(
        `/tasks/show/${activity.event.id}/history/${activity.entityVersion - 1}`
      );
    else RouterHistory.push(`/tasks/show/${activity.event.id}/history/1`);
  };

  const onShowNextVersion = () => {
    setHasNextHistory(false);
    RouterHistory.push(
      `/tasks/show/${activity.event.id}/history/${activity.entityVersion + 1}`
    );
  };

  const getFormForRefModel = () => {
    const {
      formSchema,
      formUiSchema,
      formRules,
      formActions = ''
    } = activity.event;

    let schema = {},
      uiSchema = {},
      rules = [],
      actions = window.eval(formActions) || {};
    if (typeof formSchema === 'string') {
      schema = JSON.parse(formSchema);
    } else if (typeof formSchema === 'object') {
      schema = formSchema;
    }
    if (typeof formUiSchema === 'string') {
      uiSchema = JSON.parse(formUiSchema);
    } else if (typeof formUiSchema === 'object') {
      uiSchema = formUiSchema;
    }
    if (!rules) {
      rules = [];
    } else if (typeof formRules === 'string') {
      if (formRules.length > 0) {
        rules = JSON.parse(formRules);
      } else {
        rules = [];
      }
    } else if (Array.isArray(formRules)) {
      rules = formRules;
    }
    return {
      form: {
        schema,
        uiSchema,
        rules,
        actions
      }
    };
  };

  return (
    <Page>
      <PageNav title="Task History Snapshot" translate={false}>
        <span className="ml-auto" />
        <Link
          to={`/tasks/show/${activity.event.id}/history`}
          className="btn btn-sm btn-outline-primary mr-1"
        >
          History
        </Link>
        <button
          disabled={activity.entityVersion <= 1}
          onClick={() => onShowPreviousVersion()}
          className="btn btn-sm btn-outline-primary mr-1"
        >
          Previous version
        </button>
        <button
          disabled={!hasNextHistory}
          onClick={() => onShowNextVersion()}
          className="btn btn-sm btn-outline-primary mr-1"
        >
          Next version
        </button>
        <Link
          to={`/tasks/show/${activity.event.id}`}
          className="btn btn-sm btn-outline-primary mr-1"
        >
          Most recent version
        </Link>
      </PageNav>

      <div className="row">
        <div className="col-xl-8 order-1 order-xl-0">
          <div className="card mb-4">
            <TaskDiffSummary
              summary={formDiffSummary}
              version={activity.entityVersion}
            />
            <div className="card-body">
              <FormFromTaskConfiguration
                model={getFormForRefModel()}
                values={activity.event.formModelJson}
                isReadOnly={true}
                previousChanges={formDiffSummary.changeDetails}
              />
            </div>
          </div>
        </div>
        <div className="col-xl-4 order-0 order-xl-1">
          <div className="card mb-4">
            <div className="card-body">
              <dl className="row">
                <dt className="col-lg-4 col-xl-12">Version</dt>
                <dd className="col-lg-8 col-xl-12">{activity.entityVersion}</dd>
                <dt className="col-lg-4 col-xl-12">Snapshot time</dt>
                <dd className="col-lg-8 col-xl-12">
                  <CommonDateTime date={activity.event.updatedAt} />
                  {metaChanges.event_update && (
                    <div className="version-diff-block mt-2 p-2">
                      <span className="diff-title">Previously</span>
                      <br />
                      <CommonDateTime date={metaChanges.event_update} />
                    </div>
                  )}
                </dd>
                <dt className="col-lg-4 col-xl-12">Assignee (at the time)</dt>
                <dd className="col-lg-8 col-xl-12">
                  <ProfileLink
                    id={activity.event.assignee.id}
                    label={activity.event.assignee.displayName}
                  />
                  ;
                  {metaChanges.event_assignee && (
                    <div className="version-diff-block mt-2 p-2">
                      <span className="diff-title">Previously</span>
                      <br />
                      {metaChanges.event_assignee}
                    </div>
                  )}
                </dd>
                <dt className="col-lg-4 col-xl-12">Status (at the time)</dt>
                <dd className="col-lg-8 col-xl-12">
                  <TaskStatusBadge status={activity.event.status} /> -{' '}
                  {TaskStatus[activity.event.status].description}
                  {metaChanges.event_status && (
                    <div className="version-diff-block mt-2 p-2">
                      <span className="diff-title">Previously</span>
                      <br />
                      <TaskStatusBadge
                        status={metaChanges.event_status}
                      /> - {TaskStatus[metaChanges.event_status].description}
                    </div>
                  )}
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};

const TaskShowHistorySnapshotPage = (props) => {
  const { taskId, versionNumber } = props.match.params;
  return (
    <ModelInstanceView
      apiUrl={`/api/task/${taskId}/history/version/${versionNumber}`}
      viewItem={TaskSnapshot}
      {...props}
    />
  );
};

export default TaskShowHistorySnapshotPage;
