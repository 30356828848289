import { FC } from 'react';
import { Activity } from '../shared/types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export interface ActivityEventDescriptionProps {
  activity: Activity;
}

export const ActivityEventDescription: FC<ActivityEventDescriptionProps> = ({
  activity
}) => {
  let title: string = '';
  let badgeClass = 'badge activity-badge-info';
  let tooltipText = '';
  switch (activity.eventType) {
    case 'ASSIGNEE_CHANGE':
      title = 'Assignee Changed';
      badgeClass = 'badge activity-badge-info activity-badge';
      tooltipText = 'The assignee of a task has been changed';
      break;
    case 'COMMENT_ADDED':
      title = 'Comment Added';
      badgeClass = 'badge activity-badge-info activity-badge';
      tooltipText = 'A comment has been added to a case or task';
      break;
    case 'COMMENT_REMOVED':
      title = 'Comment Removed';
      badgeClass = 'badge activity-badge-info activity-badge';
      tooltipText = 'A comment has been removed from a case or task';
      break;
    case 'CREATE':
      title = 'Created';
      badgeClass = 'badge activity-badge-primary activity-badge';
      tooltipText = 'A new case or task has been created';
      break;
    case 'LOCKED':
      title = 'Locked';
      badgeClass = 'badge badge-danger activity-badge';
      tooltipText = 'A case has been locked';
      break;
    case 'NEW_COMMENT':
      title = 'Comment Added';
      badgeClass = 'badge activity-badge-info activity-badge';
      tooltipText = 'A comment has been added to a case or task';
      break;
    case 'UNLOCKED':
      title = 'Unlocked';
      badgeClass = 'badge badge-danger activity-badge';
      tooltipText = 'A case has been unlocked';
      break;
    case 'SAVE':
      title = 'Saved';
      badgeClass = 'badge activity-badge-success activity-badge';
      tooltipText = 'A case or task has been saved';
      break;
    case 'STATUS_CHANGE':
      title = 'Status Changed';
      badgeClass = 'badge badge-warning activity-badge';
      tooltipText = 'The status of a case or task has changed';
      break;
    case 'WATCHER_ADDED':
      title = 'Watcher Added';
      badgeClass = 'badge activity-badge-info activity-badge';
      tooltipText = 'A watched has been added';
      break;
    case 'WATCHER_REMOVED':
      title = 'Watcher Removed';
      badgeClass = 'badge activity-badge-info activity-badge';
      tooltipText = 'A watched has been removed';
      break;
    default:
      title = 'Unknown Event';
      badgeClass = 'badge badge-danger activity-badge';
      tooltipText = 'A unknown event has occurred';
      break;
  }
  return (
    <>
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id={`tt-${activity.id}`}>{tooltipText}</Tooltip>}
      >
        <span className={badgeClass}>{title}</span>
      </OverlayTrigger>
    </>
  );
};
