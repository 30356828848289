import {
  FaCheck,
  FaBriefcase,
  FaListCheck,
  FaTriangleExclamation,
  FaFileCsv,
  FaXmark,
  FaLink,
  FaSquareCaretUp,
  FaSquareCaretDown,
  FaEllipsis,
  FaArrowUpRightFromSquare,
  FaCaretRight,
  FaSquarePlus,
  FaSquareMinus,
  FaCirclePlus,
  FaCircleMinus,
  FaFloppyDisk,
  FaTableCells,
  FaFilter,
  FaGear,
  FaPenToSquare,
  FaPlay,
  FaCircle,
  FaCircleCheck,
  FaCircleXmark
} from 'react-icons/fa6';

import { VscJson } from 'react-icons/vsc';
import { collapseClassnames } from '../functions/ClassNameUtils';
import { FaSearch } from 'react-icons/fa';

const SquarePlus = (classNames: string | string[] | undefined) => {
  if (classNames) {
    return <FaSquarePlus className={collapseClassnames(classNames)} />;
  } else {
    return <FaSquarePlus />;
  }
};

const Circle = (classNames: string | string[] | undefined) => {
  if (classNames) {
    return <FaCircle className={collapseClassnames(classNames)} />;
  } else {
    return <FaCircle />;
  }
};

const CirclePlus = (classNames: string | string[] | undefined) => {
  if (classNames) {
    return <FaCirclePlus className={collapseClassnames(classNames)} />;
  } else {
    return <FaCirclePlus />;
  }
};

const Play = (classNames: string | string[] | undefined) => {
  if (classNames) {
    return <FaPlay className={collapseClassnames(classNames)} />;
  } else {
    return <FaPlay />;
  }
};

const SquareMinus = (classNames: string | string[] | undefined) => {
  if (classNames) {
    return <FaSquareMinus className={collapseClassnames(classNames)} />;
  } else {
    return <FaSquareMinus />;
  }
};

const CircleMinus = (classNames: string | string[] | undefined) => {
  if (classNames) {
    return <FaCircleMinus className={collapseClassnames(classNames)} />;
  } else {
    return <FaCircleMinus />;
  }
};

const CircleTick = (classNames: string | string[] | undefined) => {
  if (classNames) {
    return <FaCircleCheck className={collapseClassnames(classNames)} />;
  } else {
    return <FaCircleCheck />;
  }
};

const CircleCross = (classNames: string | string[] | undefined) => {
  if (classNames) {
    return <FaCircleXmark className={collapseClassnames(classNames)} />;
  } else {
    return <FaCircleXmark />;
  }
};

const SquareCaretUp = (classNames: string | string[] | undefined) => {
  if (classNames) {
    return <FaSquareCaretUp className={collapseClassnames(classNames)} />;
  } else {
    return <FaSquareCaretUp style={{color: "#707070"}} />;
  }
};

const CaretRight = (classNames: string | string[] | undefined) => {
  if (classNames) {
    return <FaCaretRight className={collapseClassnames(classNames)} />;
  } else {
    return <FaCaretRight />;
  }
};

const SquareCaretDown = (classNames: string | string[] | undefined) => {
  if (classNames) {
    return <FaSquareCaretDown className={collapseClassnames(classNames)} />;
  } else {
    return <FaSquareCaretDown style={{color: "#707070"}} />;
  }
};

/**
 * Triangular exclamation mark warning icon
 * @param classNames list of optional classnames
 * @constructor
 */
const Warning = (classNames: string | string[] | undefined) => {
  if (classNames) {
    return <FaTriangleExclamation className={collapseClassnames(classNames)} />;
  } else {
    return <FaTriangleExclamation />;
  }
};
/**
 * General ellipsis, centered in the vertical
 * @param classNames
 * @constructor
 */
const Ellipsis = (classNames: string | string[] | undefined) => {
  if (classNames) {
    return <FaEllipsis className={collapseClassnames(classNames)} />;
  } else {
    return <FaEllipsis />;
  }
};
/**
 * Generic search icon
 * @param classNames
 * @constructor
 */
const Search = (classNames: string | string[] | undefined) => {
  if (classNames) {
    return <FaSearch className={collapseClassnames(classNames)} />;
  } else {
    return <FaSearch />;
  }
};

const Filter = (classNames: string | string[] | undefined) => {
  if (classNames) {
    return <FaFilter className={collapseClassnames(classNames)} />;
  } else {
    return <FaFilter />;
  }
};

const Settings = (classNames: string | string[] | undefined) => {
  if (classNames) {
    return <FaGear className={collapseClassnames(classNames)} />;
  } else {
    return <FaGear />;
  }
};

const Edit = (classNames: string | string[] | undefined) => {
  if (classNames) {
    return <FaPenToSquare className={collapseClassnames(classNames)} />;
  } else {
    return <FaPenToSquare />;
  }
};

/**
 * Icon for representing opening a link in a new tab
 * @param classNames
 * @constructor
 */
const OpenInNew = (classNames: string | string[] | undefined) => {
  if (classNames) {
    return (
      <FaArrowUpRightFromSquare className={collapseClassnames(classNames)} />
    );
  } else {
    return <FaArrowUpRightFromSquare />;
  }
};

const Link = (classNames: string | string[] | undefined) => {
  if (classNames) {
    return <FaLink className={collapseClassnames(classNames)} />;
  } else {
    return <FaLink />;
  }
};

const Disk = (classNames: string | string[] | undefined) => {
  if (classNames) {
    return <FaFloppyDisk className={collapseClassnames(classNames)} />;
  } else {
    return <FaFloppyDisk />;
  }
};

/**
 * General purpose cross mark (to indicate something that hasn't been done,
 * isn't allowed etc...)
 * @param classNames list of optional classnames
 * @constructor
 */
const Cross = (classNames: string | string[] | undefined) => {
  if (classNames) {
    return <FaXmark className={collapseClassnames(classNames)} />;
  } else {
    return <FaXmark />;
  }
};

/**
 * General purpose check (tick) icon
 * @param classNames list of optional classnames
 * @constructor
 */
const Tick = (classNames: string | string[] | undefined) => {
  if (classNames) {
    return <FaCheck className={collapseClassnames(classNames)} />;
  } else {
    return <FaCheck />;
  }
};

/**
 * Common case icon
 * @param classNames
 * @constructor
 */
const Case = (classNames: string | string[] | undefined) => {
  if (classNames) {
    return <FaBriefcase className={collapseClassnames(classNames)} />;
  } else {
    return <FaBriefcase />;
  }
};

const Table = (classNames: string | string[] | undefined) => {
  if (classNames) {
    return <FaTableCells className={collapseClassnames(classNames)} />;
  } else {
    return <FaTableCells />;
  }
};

/**
 * Common task icon
 * @param classNames
 * @constructor
 */
const Task = (classNames: string | string[] | undefined) => {
  if (classNames) {
    return <FaListCheck className={collapseClassnames(classNames)} />;
  } else {
    return <FaListCheck />;
  }
};

const Csv = (classNames: string | string[] | undefined) => {
  if (classNames) {
    return <FaFileCsv className={collapseClassnames(classNames)} />;
  } else {
    return <FaFileCsv />;
  }
};

const Json = (classNames: string | string[] | undefined) => {
  if (classNames) {
    return <VscJson className={collapseClassnames(classNames)} />;
  } else {
    return <VscJson />;
  }
};

/**
 * General icons
 */
export const GeneralIcons = {
  Warning: Warning,
  Tick: Tick,
  Cross: Cross,
  Link: Link,
  SquareCaretUp: SquareCaretUp,
  SquareCaretDown: SquareCaretDown,
  SquarePlus: SquarePlus,
  SquareMinus: SquareMinus,
  Circle: Circle,
  CirclePlus: CirclePlus,
  CircleMinus: CircleMinus,
  CircleTick: CircleTick,
  CircleCross: CircleCross,
  Ellipsis: Ellipsis,
  Search: Search,
  OpenInNew: OpenInNew,
  Csv: Csv,
  CaretRight: CaretRight,
  Disk: Disk,
  Filter: Filter,
  Edit: Edit,
  Settings: Settings,
  Play: Play
};

/**
 * Icons relating to specific entities
 */
export const EntityIcons = {
  Case: Case,
  Task: Task,
  Table: Table
};

/**
 * File type icons
 */
export const FileTypeIcons = {
  Csv: Csv,
  Json: Json
};
