import React, { FC, useEffect, useState } from 'react';
import { Case, CaseConfiguration, caseConfigurationVersion, mapDisplaySpecifications } from '../types';
import { useAppConfiguration } from '../contexts/AppConfiguration';
import { CaseDetailsRow } from '../../case/CaseDetailsRow';
import { newUUID } from '../functions';

export interface CaseLinkSummaryProps {
  /**
   * The case to display a link summary for
   */
  case: Case;
}

export const CaseLinkSummary: FC<CaseLinkSummaryProps> = (props) => {
  const appConfig = useAppConfiguration();
  const [caseConfig, setCaseConfig] = useState<CaseConfiguration>();
  const [details, setDetails] = useState<JSX.Element[]>([]);

  useEffect(() => {
    if (appConfig.loaded) {
      const caseConfig = caseConfigurationVersion(
        appConfig,
        props.case.caseRef,
        props.case.caseVersion
      );
      setCaseConfig(caseConfig);
      if (caseConfig.meta.searchDisplaySpecifications !== undefined) {
        setDetails(
          mapDisplaySpecifications(
            caseConfig,
            caseConfig.meta.searchDisplaySpecifications,
            (label, f) => {
              return (
                <CaseDetailsRow
                  className={'case-link-details-row'}
                  key={`case-link-details-${newUUID()}`}
                  value={f(props.case)}
                  label={label}
                />
              );
            }
          )
        );
      }
    }
  }, [appConfig, props.case, props.case.caseRef, props.case.caseVersion]);

  return (
    <>{caseConfig && details}</>
  );
};
