import React, { FC, useState } from 'react';
import axios from 'axios';
import { PromptModal } from '../../shared/Modal';
import { Close } from '../../main/icons';
import Spinner from 'react-bootstrap/Spinner';

export interface DeleteRelatedButtonProps {
  parentCaseId: number;
  linkedCaseId: number;
  onSuccess: (id : number) => any;
}

/**
 * Constructs a link to remove a related (linked) case from a given parent case
 * @param parentCaseId
 * @param linkedCaseId
 * @param onSuccess
 * @constructor
 */
export const DeleteRelatedButton: FC<DeleteRelatedButtonProps> = ({
  parentCaseId,
  linkedCaseId,
  onSuccess
}) => {
  const [loading, setLoading] = useState(false);
  const [isConfirmVisible, setIsConfirmVisible] = useState(false);

  const className = 'border-0 m-0 p-0 btn btn-sm btn-outline-secondary';

  const onDeleteClick = () => {
    setLoading(true);
    setIsConfirmVisible(true);
  };

  const onDeleteCancel = () => {
    setLoading(false);
    setIsConfirmVisible(false);
  };

  const onDeleteConfirm = () => {
    setLoading(true);
    setIsConfirmVisible(false);
    axios
      .delete(`api/links/case/${parentCaseId}/cases/${linkedCaseId}`)
      .then(() => {
        setLoading(false);
        onSuccess && onSuccess(linkedCaseId);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {isConfirmVisible && (
        <PromptModal
          title={`Delete link between #${parentCaseId} and #${linkedCaseId}`}
          text="Are you sure you want to delete this link?"
          onCancel={onDeleteCancel}
          onSuccess={onDeleteConfirm}
        />
      )}
      <button
        disabled={loading}
        className={className}
        aria-label="Delete link"
        onClick={onDeleteClick}
      >
        {!loading && <Close height={20} style={{ fill: 'currentColor' }} />}
        {loading && (
          <Spinner animation={'border'} variant={'secondary'} size={'sm'} />
        )}
      </button>
    </>
  );
};
