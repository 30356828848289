import React, { FC } from 'react';
import { SidebarLink } from './SidebarLink';
import { Collapsible } from '../shared/components/Collapsible';

/**
 * Props for the sidebar reporting links component
 */
export interface SidebarReportingLinksProps {}

/**
 * Aggregate component that contains links to the various reporting and export
 * functions
 * @constructor
 */
export const SidebarReportingLinks: FC<SidebarReportingLinksProps> = () => {
  return (
    <Collapsible
      index={0}
      expandedTitle={'Reporting'}
      collapsedTitle={'Reporting'}
      expanded={true}
    >
      <SidebarLink
        to={'/reports'}
        label={'Report Generation'}
        translate={false}
      />
      <SidebarLink to={'/export'} label={'Raw Exports'} translate={false} />
    </Collapsible>
  );
};
