import { FC, useCallback } from 'react';
import { Collapsible } from '../shared/components/Collapsible';
import { SidebarLink } from './SidebarLink';
import {
  appFeatureFlag,
  CaseConfiguration,
  caseOrTaskMessage,
  caseOrTaskMessageElse,
  sortCaseConfigurations,
  UiMessageKey
} from '../shared/types';
import { useAppConfiguration } from '../shared/contexts/AppConfiguration';

export interface SidebarSearchLinksProps {
  /**
   * The title of link section
   */
  title: string;

  /**
   * Are we expanded on first render?
   */
  expanded?: boolean;
}

/**
 * Displays a series of search links - one for each {@link CaseConfiguration} type
 * @param title
 * @param expanded
 * @param rest
 * @constructor
 */
export const SidebarSearchLinks: FC<SidebarSearchLinksProps> = ({
  title,
  expanded
}) => {
  const appConfig = useAppConfiguration();

  // if we're feature flagged as dev, then grab the category for a  *fancy* badge
  const  caseCategoryLabel = useCallback((caseConfig: CaseConfiguration) => {
    if (appConfig.loaded) {
      if (appFeatureFlag(appConfig, 'dev')) {
        return caseConfig.meta.category ? caseConfig.meta.category : undefined;
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  }, [appConfig]);

  return (
    <Collapsible
      index={0}
      expandedTitle={title}
      collapsedTitle={title}
      expanded={expanded}
    >
      {appConfig && sortCaseConfigurations(appConfig.caseConfigurations).map((config) => {
        const caseRef = config.ref;
        const relativeLink = `/cases/search?caseRef=${caseRef}`;
        const label = caseOrTaskMessageElse(
          config,
          UiMessageKey.SearchTitle,
          (config) => caseOrTaskMessage(config, UiMessageKey.TitlePlural)
        );
        return (
          <SidebarLink
            key={caseRef}
            to={relativeLink}
            label={label}
            translate={false}
            badge={caseCategoryLabel(config)}
          />
        );
      })}
    </Collapsible>
  );
};
