import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import { BasePie } from './basePie';
import { useAuthContext } from '../auth/AuthContextProvider';
import { FormattedMessage, useIntl } from 'react-intl';
import { CardWidget } from '../shared/CardWidget';
import {Link} from "react-router-dom";
import {AngleRight} from "../main/icons";

export const LoginStatsCard = () => {
  const intl = useIntl();
  return (<CardWidget
    title={intl.formatMessage({id: "stats.profiles.logins"})}
    navItems={<Link aria-label={intl.formatMessage({id: "stats.profiles.logins"}) + " " + intl.formatMessage({id: "stats.profiles"})} className="btn btn-sm btn-outline-secondary" to={"/profiles/list"}>
      <FormattedMessage id="nav.profiles.all" /> <AngleRight className="svg-gradient-primary" height={15} />
    </Link>}>
    <LoginAccountLinksChart />
  </CardWidget>);
};

export const LoginAccountLinksChart = () => {

  const { loading, isAuthenticated } = useAuthContext();
  const [analyticsData, setAnalyticsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (!loading && isAuthenticated) {
      axios.get('/api/stats/profiles/login-account-links')
        .then(response => response.data.data)
        .then(results => {
          setAnalyticsData(results.map(result => ({
            id: result.linked ? 'Did log in': 'Did not log in yet',
            label: result.linked ? 'Did log in': 'Did not log in yet',
            value: result.count
          })));
        })
        .catch(() => setAnalyticsData([]))
        .finally(() => setIsLoading(false));
    }
  }, [isAuthenticated, loading]);

  return (<div style={{ height: 300 }} className="d-flex align-items-center justify-content-center">
    {isLoading && <Spinner animation={'border'} variant={'primary'}/>}
    {!isLoading && analyticsData.length === 0 && <p className="text-muted text-center">
      <FormattedMessage id="stats.profiles.logins.notfound"/>
    </p>}
    {!isLoading && analyticsData.length > 0 && <BasePie analyticsData={analyticsData}/>}
  </div>);
};
