import { FC } from 'react';
import { AddOperation, RemoveOperation, ReplaceOperation } from './FormHooks';
import { GeneralIcons } from '../shared/components/Icons';
import { newUUID } from '../shared/functions';

/**
 * Props structure
 */
export interface FormFieldDifferencesProps {
  /**
   * The previous value of the field.
   */
  previousValue: any;

  /**
   * The current value of the field.
   */
  currentValue: any;

  /**
   * The complete change details for the parent form
   */
  changeDetails:
  | AddOperation<any>
  | RemoveOperation<any>
  | ReplaceOperation<any>;
}

// work out what has been removed from the current array of values
function calculateArrayRemovedValues(
  previousValues: any[],
  currentValues: any[]
): any[] {
  const removedValues: any[] = [];

  for (let previous of previousValues) {
    if (currentValues.indexOf(previous) === -1) {
      removedValues.push(previous);
    }
  }
  return removedValues;
}

// calculate what's been added to the current array of values
function calculateArrayAddedValues(
  previousValues: any[],
  currentValues: any[]
): any[] {
  const addedValues: any[] = [];

  for (let current of currentValues) {
    if (previousValues.indexOf(current) === -1) {
      addedValues.push(current);
    }
  }
  return addedValues;
}

/**
 * Component that renders a simple addition
 * @param props
 * @constructor
 */
export const Addition: FC<FormFieldDifferencesProps> = (props) => {
  if (typeof props.changeDetails.value === 'boolean') {
    return (
      <div className={'list-additions'}>
        <div className={'sidebar'}>{GeneralIcons.CirclePlus('')}</div>
        <div className={'add'}>
          {`${props.changeDetails.value ? 'Yes' : 'No'}`}
        </div>
      </div>
    );
  } else {
    return (
      <div className={'list-additions'}>
        <div className={'sidebar'}>{GeneralIcons.CirclePlus('')}</div>
        <div className={'add'}>{`${props.changeDetails.value}`}</div>
      </div>
    );
  }
};

/**
 * Component that renders an array addition
 * @param props
 * @constructor
 */
export const ArrayAddition: FC<FormFieldDifferencesProps> = (props) => {
  const addedValues = props.changeDetails.value as any[];

  return (
    <>
      {addedValues.map((value, index) => (
        <>
          <div key={newUUID()} className={'list-additions'}>
            <div
              key={newUUID()}
              className={index === 0 ? 'sidebar' : 'sidebar inner'}
            >
              {GeneralIcons.CirclePlus('')}
            </div>
            <div key={props.changeDetails.path + value} className={'add'}>
              {`${value}`}
            </div>
          </div>
        </>
      ))}
    </>
  );
};

/**
 * Component that renders an array addition
 * @param props
 * @constructor
 */
export const ArrayDeletion: FC<FormFieldDifferencesProps> = (props) => {
  const removedValues = calculateArrayRemovedValues(
    props.previousValue,
    props.currentValue
  );
  const addedValues = calculateArrayAddedValues(
    props.previousValue,
    props.currentValue
  );

  return (
    <>
      {addedValues.map((value, index) => (
        <>
          <div key={newUUID()} className={'list-additions'}>
            <div
              key={newUUID()}
              className={index === 0 ? 'sidebar' : 'sidebar inner'}
            >
              {GeneralIcons.CirclePlus('')}
            </div>
            <div key={props.changeDetails.path + value} className={'add'}>
              {`${value}`}
            </div>
          </div>
        </>
      ))}
      {removedValues.map((value, index) => (
        <>
          <div key={newUUID()} className={'list-removals'}>
            <div
              key={newUUID()}
              className={index === 0 ? 'sidebar' : 'sidebar inner'}
            >
              {GeneralIcons.CircleMinus('')}
            </div>
            <div key={props.changeDetails.path + value} className={'remove'}>
              {`${value}`}
            </div>
          </div>
        </>
      ))}
    </>
  );
};

/**
 * Component that renders a simple deletion
 * @param props
 * @constructor
 */
export const Deletion: FC<FormFieldDifferencesProps> = (props) => {
  if (typeof props.previousValue === 'boolean') {
    return (
      <div className={'list-removals'}>
        <div className={'sidebar'}>{GeneralIcons.CircleMinus('')}</div>
        <div className={'remove'}>
          + {`${props.previousValue ? 'Yes' : 'No'}`}
        </div>
      </div>
    );
  } else {
    return (
      <div className={'list-removals'}>
        <div className={'sidebar'}>{GeneralIcons.CircleMinus('')}</div>
        <div className={'remove'}>{`${props.previousValue}`}</div>
      </div>
    );
  }
};

/**
 * Component that renders a simple replacement
 * @param props
 * @constructor
 */
export const Replace: FC<FormFieldDifferencesProps> = (props) => {
  if (typeof props.currentValue === 'boolean') {
    return (
      <>
        <div className={'list-additions'}>
          <div className={'sidebar'}>{GeneralIcons.CirclePlus('')}</div>
          <div className={'add'}>{`${props.currentValue ? 'Yes' : 'No'}`}</div>
        </div>
        <div className={'list-removals'}>
          <div className={'sidebar'}>{GeneralIcons.CircleMinus('')}</div>
          <div className={'remove'}>
            {`${props.previousValue ? 'Yes' : 'No'}`}
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className={'list-additions'}>
          <div className={'sidebar'}>{GeneralIcons.CirclePlus('')}</div>
          <div className={'add'}>{`${props.currentValue}`}</div>
        </div>

        <div className={'list-removals'}>
          <div className={'sidebar'}>{GeneralIcons.CircleMinus('')}</div>
          <div className={'remove'}>{`${props.previousValue}`}</div>
        </div>
      </>
    );
  }
};

/**
 * Generic component for displaying differences between versions of a given form field. We
 * handle changes to "simple" values differently to changes applied to arrays.
 * @param props
 * @constructor
 */
export const FormFieldDifferences: FC<FormFieldDifferencesProps> = (props) => {
  const operation = props.changeDetails.op;
  const isSimple = !Array.isArray(props.currentValue);

  if (isSimple) {
    return (
      <>
        <div className={'field-diff-container'}>
          {operation === 'add' && (
            <Addition
              previousValue={props.previousValue}
              currentValue={props.currentValue}
              changeDetails={props.changeDetails}
            />
          )}
          {operation === 'remove' && (
            <Deletion
              previousValue={props.previousValue}
              currentValue={props.currentValue}
              changeDetails={props.changeDetails}
            />
          )}
          {operation === 'replace' && (
            <Replace
              previousValue={props.previousValue}
              currentValue={props.currentValue}
              changeDetails={props.changeDetails}
            />
          )}
        </div>
      </>
    );
  } else {
    return (
      <div className={'field-diff-container'}>
        {operation === 'add' && (
          <ArrayAddition
            previousValue={props.previousValue}
            currentValue={props.currentValue}
            changeDetails={props.changeDetails}
          />
        )}
        {operation === 'remove' && (
          <ArrayDeletion
            previousValue={props.previousValue}
            currentValue={props.currentValue}
            changeDetails={props.changeDetails}
          />
        )}
      </div>
    );
  }
};
