import React, {FC, useEffect, useState} from 'react';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import {FormattedMessage} from 'react-intl';
import {ResponsiveLine} from '@nivo/line';
import {useAppConfiguration} from '../shared/contexts/AppConfiguration';
import {CaseConfiguration, caseOrTaskMessage, latestCaseConfiguration, UiMessageKey} from '../shared/types';

/**
 * Only here to make things more readable
 */
type Datum = Array<{
  id: string | number;
  data: Array<{ x: number | string | Date; y: number | string | Date }>;
}>;

/**
 * Interface defining the expected prop structure for this component
 */
export interface CaseCreatedPerDayLineChartProps {
  caseRef: string;
  refreshCookie: string;
}

/**
 * Generic line chart component that can display the number of case creations per day, either in totality (across all
 * case types) or individually for a given case type
 * @param caseRef
 * @param refreshCookie
 * @constructor
 */
export const CasesCreatedPerDayLineChart: FC<
  CaseCreatedPerDayLineChartProps
> = ({ caseRef, refreshCookie }) => {
  const appConfig = useAppConfiguration();
  const [caseConfig, setCaseConfig] = useState<CaseConfiguration>();
  const [analyticsData, setAnalyticsData] = useState<Datum>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<string>(refreshCookie);

  useEffect(() => {
    if (!loaded && appConfig.loaded) {

      // grab the case configuration
      setCaseConfig(latestCaseConfiguration(appConfig, caseRef));

      // query the data and map
      const apiUrl = `/api/stats/cases/per-day/${caseRef}`;
      axios
        .get(apiUrl)
        .then((response) => response.data.data)
        .then((results) => {
          const allCases = {
            id: 'all',
            data: results.map((result: { date: Date; count: number }) => ({
              x: result.date,
              y: result.count
            }))
          };
          setAnalyticsData([allCases]);
        })
        .catch(() => setAnalyticsData([]))
        .finally(() => setIsLoading(false));
    }
    if (refreshCookie !== refresh) {
      setLoaded(false);
      setRefresh(refreshCookie);
    }
  }, [appConfig, appConfig.loaded, caseRef, loaded, refresh, refreshCookie]);

  return (
    <div
      style={{ height: 300 }}
      className="d-flex align-items-center justify-content-center"
    >
      {isLoading && <Spinner animation={'border'} variant={'primary'} />}
      {!isLoading && analyticsData.length === 0 && (
        <p className="text-muted text-center">
          <FormattedMessage id="stats.cases.daily.notfound" />
        </p>
      )}
      {!isLoading && analyticsData.length > 0 && caseConfig && (
        <ResponsiveLine
          data={analyticsData}
          margin={{ top: 20, right: 5, bottom: 50, left: 50 }}
          xScale={{ type: 'time', format: '%Y-%m-%d' }}
          xFormat="time:%Y-%m-%d"
          yScale={{
            type: 'linear',
            min: 0,
            max: 'auto',
            stacked: false,
            reverse: false
          }}
          axisBottom={{
            format: '%b %d',
            tickValues: 'every 7 days',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: -45,
            legend: 'Date created',
            legendOffset: 70,
            legendPosition: 'middle'
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            format: (fx) => Math.floor(fx) === fx && fx,
            legend: `Number of ${caseOrTaskMessage(caseConfig,  UiMessageKey.TitlePluralShort)}`,
            legendOffset: -40,
            legendPosition: 'middle'
          }}
          colors={{ scheme: 'dark2' }}
          lineWidth={5}
          enablePoints={false}
          pointSize={10}
          pointColor={{ theme: 'background' }}
          pointBorderWidth={2}
          pointBorderColor={{ from: 'serieColor' }}
          enablePointLabel={true}
          pointLabel="y"
          pointLabelYOffset={-12}
          useMesh={true}
        />
      )}
    </div>
  );
};
