// @ts-ignore
import * as jiff from 'jiff';
import React, { MutableRefObject, useEffect, useRef } from 'react';
import { jsonPointerFromReference, prefixedPointersForObject } from '../shared/functions';
import { FormFieldDifferences } from './FormFieldDifferences';

// a basic typedef for JSON patch operations (based on RFC6902)
export type DiffOperation =
  | AddOperation<any>
  | RemoveOperation<any>
  | ReplaceOperation<any>
  | MoveOperation
  | CopyOperation
  | TestOperation<any>;

// fer type checking porpoises
export type OperationCode =
  | 'add'
  | 'remove'
  | 'replace'
  | 'move'
  | 'copy'
  | 'test';

// common to all is a JSON pointer
export interface BaseOperation {
  op: OperationCode;
  path: string;
}

// add operation
export interface AddOperation<T> extends BaseOperation {
  op: 'add';
  value: T;
}

// remove operation
export interface RemoveOperation<T> extends BaseOperation {
  op: 'remove';
  value: T;
}

// replace operation
export interface ReplaceOperation<T> extends BaseOperation {
  op: 'replace';
  value: T;
}

// move operation
export interface MoveOperation extends BaseOperation {
  op: 'move';
  from: string;
}

// copy operation
export interface CopyOperation extends BaseOperation {
  op: 'copy';
  from: string;
}

// test operation
export interface TestOperation<T> extends BaseOperation {
  op: 'test';
  value: T;
}

/**
 * Typed filter function for diff operations
 */
type DiffOperationFilter = (op: DiffOperation) => boolean;

/**
 * Type for grouping operations based on their operation type
 */
export type GroupedDiffs = {
  [key in OperationCode]?: DiffOperation[];
};

/**
 * Entry for the change detail map, which is passed through to the
 * rendering routines within the form extensions common library. This
 * type aligns with the prop types defined within that library
 */
export type ChangeDetailMapEntry = {
  /**
   * The field reference for the change (relative JSON pointer)
   */
  fieldRef: string;

  /**
   * Value from the current version of the form
   */
  currentValue: any;

  /**
   * Value from the previous version of the form
   */
  previousValue: any;

  /**
   * An optional component which will be used to render
   * the history elements within the form snapshot
   */
  renderComponent?: React.ElementType;

  /**
   * Optional details that will be passed through to
   * the rendering component (can contain anything needed
   * to render the history in the way you want to present
   * it)
   */
  changeDetails?: any;
};

/**
 * Typed version of the legacy change details structure
 */
export type ChangeDetailMap = {
  [key: string]: ChangeDetailMapEntry;
};

/**
 * Type containing summarised (canonical) differences between two form versions
 */
export type FormDiffSummarisation = {
  /**
   * Differences between the forms, grouped by operation type
   */
  groupedDiffs: GroupedDiffs;

  /**
   * The total number of differences between versions
   */
  totalCount: number;

  /**
   * Number of strict deletions
   */
  deletionCount: number;

  /**
   * Number of strict additions
   */
  additionCount: number;

  /**
   * Number of strict mods
   */
  modificationCount: number;

  /**
   * Representation of the changes, suitable for consumption
   * by the form rendering library
   */
  changeDetails: ChangeDetailMap;
};

/**
 * Given an array of diff operations, recurse into the individual diffs and
 * expand each one until we hit the canonical state where each operation has
 * a primitive value.  This ensures that operations involving objects, arrays
 * and the like are split into correctly addressed sub-operations. This bit
 * of logic could be condensed using something like fp-ts
 * @param diffs the array of operations to expand
 */
function expandDiffs(diffs: DiffOperation[]): DiffOperation[] {
  let accum: DiffOperation[] = [];

  // tail-recursive expansion op
  function expandDiff(
    accum: DiffOperation[],
    diff: DiffOperation
  ): DiffOperation[] {
    if (diff.op === 'add' || diff.op === 'replace') {
      const narrowed = diff as AddOperation<any> | ReplaceOperation<any>;
      if (typeof narrowed.value === 'object' && narrowed.value !== null) {
        const pathPtrs = prefixedPointersForObject(
          narrowed.path,
          narrowed.value
        );
        const valuePtrs = prefixedPointersForObject(undefined, narrowed.value);
        const zipped = pathPtrs.map((value, index) => [
          value,
          valuePtrs[index]
        ]);
        for (const pair of zipped) {
          accum = accum.concat(
            expandDiff(accum, {
              ...narrowed,
              path: pair[0].toString(),
              value: pair[1].get(narrowed.value)
            })
          );
        }
        return accum;
      } else {
        return narrowed.value === null ? [] : [diff];
      }
    }
    return [diff];
  }

  for (let diff of diffs) {
    accum = accum.concat(expandDiff([], diff));
  }

  return accum;
}

/**
 * Compute the RFC6902 diff array between two json structures, then perform expansion on the
 * diffs so that rather than having a single diff for object arrays, objects, we have
 * discrete individual changes (and fully qualified path references).
 * @param previous the previous model object
 * @param current the current model object
 */
function canonicalDiffs(previous: any, current: any): DiffOperation[] {
  return expandDiffs(
    filterDiffs(jiff.diff(previous, current), defaultOperationFilter)
  );
}

/**
 * Checks whether an addition operation just adds an empty object. We need to do some
 * 'orrible type checking here, this could be cleaned up if it causes any real pain
 * @param operation the DiffOperation to check
 */
function isNullAddOperation(operation: DiffOperation): boolean {
  if (operation.op !== 'add') return false;
  if (operation.value) {
    if (typeof operation.value === 'object') {
      return (
        Object.keys(operation.value).length === 0 &&
        Object.getPrototypeOf(operation.value) === Object.prototype
      );
    }
    return false;
  }
  return true;
}

/**
 * Spot of currying here to avoid having to write multiple op tests
 * @param opCode
 */
const hasOpCode = (opCode: OperationCode) => (operation: DiffOperation) => {
  return operation.op === opCode;
};

/**
 * This is a composed filter which is applied to the raw diffs, so that we can
 * arrive at a canonical set of changes for a given form.
 * @param op the difference operation to check
 * @returns `true` if the operation can be kept, `false` if the operation should be binned
 */
function defaultOperationFilter(op: DiffOperation): boolean {
  return (
    !hasOpCode('test')(op) && !hasOpCode('copy')(op) && !isNullAddOperation(op)
  );
}

/**
 * General filtering function for difference operations
 * @param diffs An array of operations to filter
 * @param filter A filter function
 */
function filterDiffs(
  diffs: DiffOperation[],
  filter: DiffOperationFilter
): DiffOperation[] {
  return diffs.filter((v) => filter(v));
}

/**
 * Aggregate an array of diffs, based on operation type
 * @param diffs the list of diffs to aggregate
 */
function aggregateDiffs(diffs: DiffOperation[]): GroupedDiffs {
  let grouped: GroupedDiffs = {};
  for (const diff of diffs) {
    if (grouped[diff.op] === undefined) {
      grouped[diff.op] = [];
      grouped[diff.op]!.push(diff);
    } else {
      grouped[diff.op]!.push(diff);
    }
  }
  return grouped;
}

/**
 * Given a path into a structure, probe the give model and get
 * the value
 * @param path the (JSON pointer compliant) path into the structure
 * @param model the model to probe
 */
function valueByPath(path: string, model: any): any {
  const ptr = jsonPointerFromReference(path);
  return ptr.get(model);
}

/**
 * Take an array of {@link DiffOperation}s, and return a reduced array where
 * individual diffs relating to arrays are merged together, with array values,
 * rather than individual type values (e.g. string, number, boolean)
 * @param diffs the diffs to operate on
 */
function mergeArrayDiffs(diffs: DiffOperation[]): DiffOperation[] {
  let merged: DiffOperation[] = [];
  let addCache = new Map();
  let removeCache = new Map();

  // merge
  for (let diff of diffs) {
    if (diff.op === 'replace') merged.push(diff);
    if (diff.op === 'add' || diff.op === 'remove') {
      // select the correct cache
      let cache = undefined;
      if (diff.op === 'add') {
        cache = addCache;
      }
      if (diff.op === 'remove') {
        cache = removeCache;
      }

      // look for an array diff
      let last = diff.path.split('/').pop()!;
      if (!Number.isNaN(Number(last))) {
        let newPath = diff.path.substring(0, diff.path.lastIndexOf('/'));
        if (cache!.has(newPath)) {
          cache!.get(newPath).value.push(diff.value);
        } else {
          cache!.set(newPath, {
            op: diff.op,
            path: newPath,
            value: [diff.value]
          });
        }
      } else {
        // just add ordinary diffs
        merged.push(diff);
      }
    }
  }

  // reduce
  addCache.forEach((value, key) => {
    merged.push(value);
  });
  removeCache.forEach((value, key) => {
    merged.push(value);
  });

  return merged;
}

/**
 * For each individual diff between two versions of a form, compute the relative changes which will
 * then be used by the form rendering logic to display current/previous value comparisons.
 *
 * Because the form implementation used (implemented within react-jsonschema-form-extensions) doesn't
 * currently handle arrays or nested arrays very well, we need to do a little bit of work here in order
 * to "flatten" out multiple array changes into a single diff with array values, rather than having
 * multiple diffs, with *one for each changed array value*.
 *
 * @param previousModel the previous version of the form
 * @param currentModel the current version of the form
 * @param diffs a list of discrete diff operations.  The resultant change details map should have one
 * key (entry) per diff operation
 */
function calculateRelativeChanges(
  previousModel: any,
  currentModel: any,
  diffs: DiffOperation[]
): ChangeDetailMap {
  const changes: ChangeDetailMap = {};
  for (let diff of mergeArrayDiffs(diffs)) {
    switch (diff.op) {
      case 'add':
        changes[diff.path] = {
          fieldRef: diff.path,
          currentValue: diff.value,
          previousValue: valueByPath(diff.path, previousModel),
          changeDetails: diff,
          renderComponent: FormFieldDifferences
        };
        break;
      case 'replace':
        changes[diff.path] = {
          fieldRef: diff.path,
          currentValue: diff.value,
          previousValue: valueByPath(diff.path, previousModel),
          changeDetails: diff,
          renderComponent: FormFieldDifferences
        };
        break;
      case 'remove':
        changes[diff.path] = {
          fieldRef: diff.path,
          currentValue: valueByPath(diff.path, currentModel),
          previousValue: valueByPath(diff.path, previousModel),
          changeDetails: diff,
          renderComponent: FormFieldDifferences
        };
        break;
      default:
    }
  }
  return changes;
}

/**
 * Construct a canonical summary of the differences between the previous and current versions
 * of a form
 * @param previousModel
 * @param currentModel
 */
function generateDiffSummarisation(
  previousModel: any,
  currentModel: any
): FormDiffSummarisation {
  const canonicals = canonicalDiffs(previousModel, currentModel);
  const grouped = aggregateDiffs(canonicals);
  const changes = calculateRelativeChanges(
    previousModel,
    currentModel,
    canonicals
  );
  return {
    groupedDiffs: grouped,
    additionCount: grouped['add'] !== undefined ? grouped['add'].length : 0,
    deletionCount:
      grouped['remove'] !== undefined ? grouped['remove'].length : 0,
    modificationCount:
      grouped['replace'] !== undefined ? grouped['replace'].length : 0,
    totalCount: canonicals.length,
    changeDetails: changes
  };
}

/**
 * A hook for retrieving diff information between two versions of a given form model.  This
 * hook assumes the that form model json can be found within an `event` property of the source
 * and target parameters.  If the source and target do not contain an `event` property, then
 * an empty object is returned.
 * @param source A source object which should contain an `event` property for form model retrieval
 * @param target A target object which should contain an `event` property for form model retrieval
 *
 * @returns A structure containing the differences required to take [source] to [target]
 */
export const useFormDiffs = (source: any, target: any) => {
  let diffs: MutableRefObject<FormDiffSummarisation> = useRef({
    groupedDiffs: {},
    totalCount: 0,
    additionCount: 0,
    deletionCount: 0,
    modificationCount: 0,
    changeDetails: {}
  });
  useEffect(() => {
    if (source && target) {
      if (source.event !== undefined && target.event !== undefined) {
        const sourceFormModel = source.event.formModelJson;
        const targetFormModel = target.event.formModelJson;
        diffs.current = generateDiffSummarisation(
          sourceFormModel,
          targetFormModel
        );
      }
    }
  }, [source, target]);
  return diffs.current;
};
