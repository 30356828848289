import React from "react";
import {FormDiffSummarisation} from "../form/FormHooks";

export function TaskDiffSummary(props: { version: number, summary: FormDiffSummarisation }): JSX.Element {

  function initialVersionHeader() : JSX.Element {
    return <span key={'info'} className="initial-badge">Values after first submission</span>;
  }

  function laterVersionHeader(summary: FormDiffSummarisation): Array<JSX.Element> {
    const badges: Array<JSX.Element> = [];
    if (summary.additionCount > 0) {
      badges.push(additionsBadge('addition', summary.additionCount));
    }
    if (summary.deletionCount > 0) {
      badges.push(deletionsBadge('deletion', summary.deletionCount));
    }
    if (summary.modificationCount > 0) {
      badges.push(modificationsBadge('modification', summary.modificationCount));
    }
    if (summary.totalCount === 0) {
      badges.push(infoBadge());
    }
    return badges;
  }

  function infoBadge(): JSX.Element {
    return <span key={'info'} className="initial-badge">No changes in this version</span>;
  }

  function additionsBadge(suffix: string, count: number): JSX.Element {
    return <span key={'additions'} className="addition-badge">Additions : {count}</span>;
  }

  function deletionsBadge(suffix: string, count: number): JSX.Element {
    return <span key={'deletions'} className="deletion-badge">Deletions: {count}</span>;
  }

  function modificationsBadge(suffix: string, count: number): JSX.Element {
    return <span key={'modifications'} className="modification-badge">Modifications: {count}</span>;
  }

  return <div className="card-header">
    {props.version === 1 ? initialVersionHeader() : laterVersionHeader(props.summary)}
  </div>;
}
