import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import moment from 'moment';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export const CasualTime = ({date, ...rest}) => {
  if (!date) return null;
  return (<OverlayTrigger placement="top" overlay={<Tooltip id={date}><CommonDateTime date={date} /></Tooltip>}>
    <span {...rest}>
      <Moment fromNow utc={true} local={true} date={date} />
    </span>
  </OverlayTrigger>);
};
CasualTime.propTypes = {
  date: PropTypes.any
};

export const CommonDateTime = ({ date, withCasual = false, fallback = null }) => {
  if (!date) return fallback;
  const formal = <Moment utc={true} local={true} format="D MMM YYYY [at] HH:mm:ss" date={date} />;
  if (!withCasual)
    return formal;

  return <>{formal} (<CasualTime date={date} />)</>;
};
CommonDateTime.propTypes = {
  date: PropTypes.any,
  withCasual: PropTypes.bool
};

export const CommonDate = ({ date, fallback = null }) => {
  if (!date) return fallback;
  return <Moment utc={true} local={true} format="D MMM YYYY" date={date} />;
};
CommonDate.propTypes = {
  date: PropTypes.any
};

export const IfToday = ({ date, children }) => {
  if (!date) return null;
  const isToday = moment(date).isSame(moment(), 'day');
  return isToday ? children : null;
};
IfToday.propTypes = {
  date: PropTypes.any
};

export const IfBeforeToday = ({ date, children }) => {
  if (!date) return null;
  const isPast = moment(date).isBefore(moment(), 'day');
  return isPast ? children : null;
};
IfBeforeToday.propTypes = {
  date: PropTypes.any
};
