import React, { useCallback, useContext, useEffect, useState } from 'react';
import jwt_decode from 'jwt-decode';
import createAuth0Client from '@auth0/auth0-spa-js';
import axios from 'axios';
import { sendError } from '../Airbrake';
import { ApiContextErrorPage, Auth0ContextErrorPage } from './ContextErrorPage';
import SessionExpiryMonitor from '../main/SessionExpiryMonitor';

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname);
const redirectUri = window.location.origin;
const fallbackLogoutUrl = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/v2/logout?client_id=${process.env.REACT_APP_AUTH0_CLIENT_ID}&returnTo=${redirectUri}`;
/**
 * Either auth0 security context cannot initialize or API request cannot be authorized
 */
const InitializationErrorSource = Object.freeze({
  AUTH0: 'AUTH0',
  API: 'API',
  UNAUTH: 'UNAUTH'
});
const buildContextInitializationError = (errorSource, err, auth0User) => ({
  source: InitializationErrorSource[errorSource],
  error: err,
  auth0User: auth0User
});

export const Auth0Context = React.createContext({});
export const useAuthContext = () => useContext(Auth0Context);

export const AuthContextProvider = ({
  children,
  redirect_uri,
  onRedirectCallback
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [profile, setProfile] = useState(null);
  const [auth0Client, setAuth0] = useState(null);
  const [loading, setLoading] = useState(true);
  const [initializationError, setInitializationError] = useState(null);
  const [sessionExpired, setSessionExpired] = useState(false);

  if (typeof onRedirectCallback !== 'function') {
    onRedirectCallback = DEFAULT_REDIRECT_CALLBACK;
  }

  /**
   * Should always return profile, will create new one if one does not exist.
   * Can fail if server is down or token is invalid or email is not verified
   * @param accessToken
   * @return {Promise<Object>}
   */
  const getProfile = async (accessToken) => {
    return axios
      .get(`${process.env.REACT_APP_SERVER}/api/profile/me`, {
        headers: { Authorization: `Bearer ${accessToken}` }
      })
      .then((response) => response.data.data);
  };

  /**
   * Should run only once as options do not change
   */
  useEffect(() => {
    const initAuth0AndGetAccessToken = async () => {
      const auth0FromHook = await createAuth0Client({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        domain: process.env.REACT_APP_AUTH0_DOMAIN,
        client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
        scope: process.env.REACT_APP_AUTH0_SCOPE,
        redirect_uri: redirect_uri,
        cacheLocation: 'localstorage', // DOL-648 + required in tests
        useRefreshTokens: true // DOL-648
      });
      setAuth0(auth0FromHook);

      if (window.location.search.includes('code=')) {
        const { appState } = await auth0FromHook.handleRedirectCallback();
        SessionExpiryMonitor.onLogin();
        onRedirectCallback(appState);
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated();
      setIsAuthenticated(isAuthenticated);

      if (isAuthenticated) {
        const token = await auth0FromHook.getTokenSilently();
        const user = await auth0FromHook.getUser();
        return {
          token: token,
          user: user
        };
      }

      return {
        token: null,
        user: null
      };
    };

    const getSetProfile = async (accessToken) => {
      if (accessToken) {
        const apiProfile = await getProfile(accessToken);
        setProfile(apiProfile);
      } else {
        setProfile(null);
      }
    };

    initAuth0AndGetAccessToken()
      .then(({ token, user }) => {
        return getSetProfile(token).catch((e) => {
          setInitializationError(
            buildContextInitializationError(
              InitializationErrorSource.API,
              e,
              user
            )
          );
          // Ignore 403 as it indicates email is unverified
          if (!e || !e.response || e.response.status !== 403) {
            if (e.response.status === 401) {
              setInitializationError(
                buildContextInitializationError(
                  InitializationErrorSource.UNAUTH,
                  e,
                  user
                )
              );
              console.error('Unauthorized', e);
              sendError(e);
            } else {
              console.error('getSetProfile failure', e);
              sendError(e);
            }
          }
        });
      })
      .then(() => setLoading(false))
      .catch((e) => {
        console.error('initAuth0 failure', e);
        setLoading(false);
        setInitializationError(
          buildContextInitializationError(
            InitializationErrorSource.AUTH0,
            e,
            null
          )
        );
        sendError(e);
      });
  }, [redirect_uri, onRedirectCallback]);

  const getTokenSilently = async () => {
    try {
      const accessToken = await auth0Client.getTokenSilently();
      return { raw: accessToken, decoded: jwt_decode(accessToken) };
    } catch (e) {
      throw new Error('Could not obtain access token');
    }
  };

  const refreshProfile = useCallback(() => {
    setLoading(true);
    return auth0Client
      .getTokenSilently()
      .then((t) => getProfile(t))
      .then((p) => setProfile(p))
      .catch(() => {
        setIsAuthenticated(false);
        setProfile(null);
      })
      .finally(() => setLoading(false));
  }, [auth0Client, setLoading, setProfile]);

  const loginWithRedirect = useCallback(
    (targetUrl) => {
      setLoading(true);
      return auth0Client
        .loginWithRedirect({
          appState: {
            targetUrl: targetUrl || '/dashboard'
          },
          max_age: 0 // make sure user has to enter an email instead of only OTP code
        })
        .then(() => setLoading(false))
        .catch(() => setLoading(false));
    },
    [auth0Client, setLoading]
  );

  const logoutWithRedirect = useCallback(() => {
    if (!auth0Client) {
      window.location.href = fallbackLogoutUrl;
      return;
    }

    return auth0Client.logout({
      returnTo: window.location.origin
    });
  }, [auth0Client]);

  return (
    <Auth0Context.Provider
      value={{
        loading,
        isAuthenticated,
        profile,
        sessionExpired,
        refreshProfile,
        loginWithRedirect,
        logoutWithRedirect,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        getTokenSilently,
        getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        setSessionExpired
      }}
    >
      {initializationError &&
          initializationError.source === InitializationErrorSource.AUTH0 && (
        <Auth0ContextErrorPage onLogoutClick={() => logoutWithRedirect()} />
      )}

      {initializationError &&
          initializationError.source === InitializationErrorSource.API && (
        <ApiContextErrorPage
          error={initializationError.error}
          auth0User={initializationError.auth0User}
          onLogoutClick={() => logoutWithRedirect()}
        />
      )}

      {initializationError &&
          initializationError.source === InitializationErrorSource.UNAUTH && (
        <ApiContextErrorPage
          error={initializationError.error}
          auth0User={initializationError.auth0User}
          onLogoutClick={() => logoutWithRedirect()}
        />
      )}

      {!initializationError && children}
    </Auth0Context.Provider>
  );
};
