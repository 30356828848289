import React from 'react';
import PropTypes from 'prop-types';
import AbstractFlowStep from './AbstractFlowStep';

class FormStep extends AbstractFlowStep {
  constructor(props) {
    super(props);
    this.state = {
      formValues: props.prevSuccessResult
    };
    this.formSubmit = null;
    this.doNext = this.doNext.bind(this);
    this.afterSubmit = this.afterSubmit.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.prevSuccessResult !== prevProps.prevSuccessResult) {
      this.setState({
        formValues: this.props.prevSuccessResult
      });
    }
  }

  doNext() {
    this.formSubmit && this.formSubmit();
  }

  afterSubmit(data) {
    this.props.onNextSuccess(data);
  }

  render() {
    const {
      title,
      schemaFormComponent: Component,
      prevSuccessResult,
      allSuccessResults,
      onNextSuccess,
      ...rest
    } = this.props;
    return (
      <>
        {this.props.title && <h2 data-testid="flow-form-title">{title}</h2>}
        {title.length > 0 && <hr />}
        <Component
          values={this.state.formValues}
          onSubmitExecutorChange={(fn) => (this.formSubmit = fn)}
          onSubmit={this.afterSubmit}
          {...rest}
        />
      </>
    );
  }
}

FormStep.propTypes = {
  ...AbstractFlowStep.propTypes,
  title: PropTypes.string.isRequired,
  schemaFormComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired
};

export default FormStep;
