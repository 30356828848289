import { FC } from 'react';

export interface ColourDotProps {
  /**
   * The dot colour
   */
  colour: string | undefined;
}

/**
 * Used to create a coloured "dot"
 * @param className
 * @param colour
 * @constructor
 */
export const ColourDot: FC<ColourDotProps> = ({ colour = '#888888'}) => {
  return (
    <span
      className={'colour-dot'}
      style={{
        height: 12,
        width: 12,
        borderRadius: '50%',
        backgroundColor: colour,
        marginRight: '4px',
        paddingTop: '4px'
      }}
    />
  );
};
