import React from 'react';
import { Page, PageNav } from '../shared/Page';
import ModelTableWithRouterState from '../models/ModelTableWithRouterState';
import { Link } from 'react-router-dom';
import { ActivitySearchResult } from '../activity/ActivitySearchResult';
import { toUkDateTimeString } from '../search/MemorableDate';
import { useIntl } from 'react-intl';
import { ProfileLink } from '../profile/Profile';

const TaskShowHistoryPage = (props) => {
  const intl = useIntl();
  const { taskId } = props.match.params;

  const columns = [{
    label: 'Task',
    fromModel: (item) => new ActivitySearchResult(item).link()
  }, {
    label: 'Version',
    sortBy: 'entityVersion',
    fromModel: (item) => item.entityVersion
  }, {
    label: 'By',
    sortBy: 'profileName',
    fromModel: (item) => new ActivitySearchResult(item).profile()
  }, {
    label: intl.formatMessage({id:"model.task.status"}),
    fromModel: (item) => new ActivitySearchResult(item).status()
  }, {
    label: 'Case',
    fromModel: (item) => {
      switch (item.eventType) {
        case 'CREATE':
        case 'SAVE':
          return <Link to={`/cases/show/${item.event.case.id}`}>{item.event.case.displayName}</Link>;
        default:
          return 'N/A';
      }
    }
  }, {
    label: intl.formatMessage({id:"model.task.assignee"}),
    fromModel: (item) => {
      switch (item.eventType) {
        case 'CREATE':
        case 'SAVE':
        case 'ASSIGNEE_CHANGE':
          return <ProfileLink id={item.event.assignee.id} label={item.event.assignee.displayName} />;
        default:
          return 'N/A';
      }
    }
  }, {
    label: 'Time',
    sortBy: 'createdAt',
    fromModel: (item) => toUkDateTimeString(item.createdAt)
  }];

  const additionalQueryParams = {
    eventType: ['SAVE', 'CREATE'].join(",")
  };

  return (<Page>
    <PageNav title="Task History" translate={false}/>
    <ModelTableWithRouterState caption="nav.tasks.history" apiEndpoint={`/api/task/${taskId}/history`} additionalQueryParams={additionalQueryParams} columns={columns} {...props}/>
  </Page>);
};

export default TaskShowHistoryPage;
