import React, { FC, useCallback, useReducer } from 'react';
import { useAppConfiguration } from '../shared/contexts/AppConfiguration';
import {
  CaseConfiguration,
  CaseStatus,
  caseStatusesByCaseVersion,
  displaySpecificationLabel
} from '../shared/types';
import { DropdownWidget } from '../shared/components/widgets/DropdownWidget';
import {
  atomicFilterClause,
  compoundFilterClause,
  findCoreCaseFormFilterClause,
  findCoreTableFilterClause,
  ReportFilter,
  ReportFilterAtomicClause,
  ReportFilterClause,
  ReportFilterClauseType,
  ReportFilterCompoundClause
} from '../shared/types/Reports';
import {
  DateRange,
  matchStandardDateRange,
  StandardDateRange,
  StandardDateRanges
} from '../shared/functions/DateRange';
import { DateSelectionWidget } from '../shared/components/widgets/DateSelectionWidget';
import moment from 'moment';
import { newUUID } from '../shared/functions';
import _ from 'lodash';
import { LabelledTextWidget } from '../shared/components/widgets/LabelledTextWidget';

export interface ReportFilterEditorProps {
  /**
   * The currently active case configuration - used for status enumerations etc...
   */
  caseConfig?: CaseConfiguration;

  /**
   * The currently active filter
   */
  filter: ReportFilter;

  /**
   * CB fired when a change to the filter is needed. All mutations need to be grouped into a single atomic transaction
   * effectively, otherwise the event handling logic will screw with the order of any state reductions performed by
   * the React framework
   * @param addtions a list of clauses to add to the filter
   * @param removals a list of clauses (by id) to remove from the filter
   */
  onFilterUpdate: (
    additions: ReportFilterClause[],
    removals: { clauseType: ReportFilterClauseType; id: string }[]
  ) => void;
}

interface ReportFilterEditorState {
  /**
   * Whether the case creation date range controls are currently enabled
   */
  caseDateRangeEnabled: boolean;

  /**
   * Temporary stash of before create date
   */
  beforeDate?: Date;

  /**
   * Temporary stash of after create date
   */
  afterDate?: Date;
}

export const ReportFilterEditor: FC<ReportFilterEditorProps> = (props) => {
  const appConfig = useAppConfiguration();
  const [state, dispatch] = useReducer(reducer, {
    caseDateRangeEnabled: true
  });

  /**
   * Update the (possibly compound) filter associated with the creation date
   * @param beforeDate
   * @param afterDate
   */
  const updateCreateDateFilter = useCallback(
    (beforeDate?: string, afterDate?: string) => {
      let beforeClause: ReportFilterClause | undefined;
      let afterClause: ReportFilterClause | undefined;

      // set up the individual clauses
      if (beforeDate) {
        beforeClause = atomicFilterClause(
          'table',
          'cases.created_before',
          'createdAt',
          moment(beforeDate).format(),
          'lte'
        );
      }

      // need to check local component state as well
      if (!beforeDate && state.beforeDate) {
        beforeClause = atomicFilterClause(
          'table',
          'cases.created_before',
          'createdAt',
          moment(state.beforeDate).format(),
          'lte'
        );
      }

      if (afterDate) {
        afterClause = atomicFilterClause(
          'table',
          'cases.created_after',
          'createdAt',
          moment(afterDate).format(),
          'gte'
        );
      }

      if (!afterDate && state.afterDate) {
        afterClause = atomicFilterClause(
          'table',
          'cases.created_after',
          'createdAt',
          moment(state.afterDate).format(),
          'gte'
        );
      }

      if (beforeClause && afterClause) {
        const compoundClause = compoundFilterClause(
          'table',
          'cases.creation_range',
          afterClause,
          beforeClause,
          'and'
        );
        props.onFilterUpdate(
          [compoundClause],
          [
            { clauseType: 'table', id: 'cases.created_before' },
            { clauseType: 'table', id: 'cases.created_after' }
          ]
        );
      } else {
        if (beforeClause && !afterClause) {
          props.onFilterUpdate(
            [beforeClause],
            [
              { clauseType: 'table', id: 'cases.created_after' },
              { clauseType: 'table', id: 'cases.creation_range' }
            ]
          );
        }
        if (afterClause && !beforeClause) {
          props.onFilterUpdate(
            [afterClause],
            [
              { clauseType: 'table', id: 'cases.created_before' },
              { clauseType: 'table', id: 'cases.creation_range' }
            ]
          );
        }
        if (!beforeClause && !afterClause) {
          props.onFilterUpdate(
            [],
            [
              { clauseType: 'table', id: 'cases.created_after' },
              { clauseType: 'table', id: 'cases.created_before' },
              { clauseType: 'table', id: 'cases.creation_range' }
            ]
          );
        }
      }
    },
    [props, state.afterDate, state.beforeDate]
  );

  // cb for grabbing the current case status filter value
  const caseStatusInitialValue = useCallback(() => {
    if (props.filter) {
      const clause = findCoreTableFilterClause(
        'cases',
        'cases.status',
        props.filter
      );
      if (clause) {
        return (clause as ReportFilterAtomicClause).value;
      }
    }
  }, [props.filter]);

  // cb for grabbing the initial value for a case form field filter value
  const caseFormFieldInitialValue = useCallback(
    (id: string) => {
      if (props.filter) {
        const clause = findCoreCaseFormFilterClause(id, props.filter);
        if (clause) {
          return (clause as ReportFilterAtomicClause).value;
        }
      }
    },
    [props.filter]
  );

  // cb for grabbing the current create date range value, we need to look at the
  // currently specified (potentially compound) filter to work out whether a preset
  // or a custom range is currently being used
  const createDateRangeInitialValues = useCallback(() => {
    if (props.filter) {
      const rangeClause = findCoreTableFilterClause(
        'cases',
        'cases.creation_range',
        props.filter
      );
      if (rangeClause) {
        // we have a compound date range clause
        const left = (rangeClause as ReportFilterCompoundClause)
          .left as ReportFilterAtomicClause;
        const right = (rangeClause as ReportFilterCompoundClause)
          .right as ReportFilterAtomicClause;
        const match = matchStandardDateRange(left.value, right.value);
        if (match) {
          // standard date range
          state.caseDateRangeEnabled = false;
          state.beforeDate = moment(right.value).toDate();
          state.afterDate = moment(left.value).toDate();
          return JSON.stringify(match);
        } else {
          // custom date range
          state.caseDateRangeEnabled = true;
          state.beforeDate = moment(right.value).toDate();
          state.afterDate = moment(left.value).toDate();
          return '';
        }
      } else {
        // single custom before or after date
        if (
          findCoreTableFilterClause(
            'cases',
            'cases.created_after',
            props.filter
          )
        ) {
          const afterClause = findCoreTableFilterClause(
            'cases',
            'cases.created_after',
            props.filter
          ) as ReportFilterAtomicClause;
          state.afterDate = moment(afterClause.value).toDate();
        } else {
          state.afterDate = undefined;
        }
        if (
          findCoreTableFilterClause(
            'cases',
            'cases.created_before',
            props.filter
          )
        ) {
          const beforeClause = findCoreTableFilterClause(
            'cases',
            'cases.created_before',
            props.filter
          ) as ReportFilterAtomicClause;
          state.beforeDate = moment(beforeClause.value).toDate();
        } else {
          state.beforeDate = undefined;
        }
        state.caseDateRangeEnabled = true;
        return '';
      }
    }
    return '';
  }, [props.filter, state]);

  // all reduction of local state (not filter) happens here
  function reducer(
    state: ReportFilterEditorState,
    action: any
  ): ReportFilterEditorState {
    const { type, payload } = action;
    switch (type) {
      case 'setCaseDateRangeEnabled':
        return {
          ...state,
          caseDateRangeEnabled: payload
        };
    }
    return state;
  }

  // hook widget events here and translate to state changes
  const onWidgetValueChange = useCallback(
    (tag: string, value: any) => {
      switch (tag) {
        case 'caseStatus':
          if (value !== '') {
            const clause = atomicFilterClause(
              'table',
              'cases.status',
              'status',
              value,
              'eq'
            );
            props.onFilterUpdate([clause], []);
          } else {
            props.onFilterUpdate(
              [],
              [{ clauseType: 'table', id: 'cases.status' }]
            );
          }
          return;
        case 'creationDateRange':
          if (value !== '') {
            const range: DateRange = JSON.parse(value);
            updateCreateDateFilter(
              moment(range.end).format(),
              moment(range.start).format()
            );
            dispatch({
              type: 'setCaseDateRangeEnabled',
              payload: false
            });
          } else {
            props.onFilterUpdate(
              [],
              [
                { clauseType: 'table', id: 'cases.creation_range' },
                { clauseType: 'table', id: 'cases.created_after' },
                { clauseType: 'table', id: 'cases.created_before' }
              ]
            );
            dispatch({
              type: 'setCaseDateRangeEnabled',
              payload: true
            });
          }
          return;
        case 'createdAfter':
          state.afterDate = value;
          updateCreateDateFilter(undefined, value);
          return;
        case 'createdBefore':
          state.beforeDate = value;
          updateCreateDateFilter(value, undefined);
          return;
        default:
          // deal with arbitrary form fields here
          const components = tag.split('.');
          if (components.length > 1) {
            switch (components[0]) {
              case 'case':
                console.log(`caseForm change detected: ${value}`);
                const id = components[1];
                if (value !== '') {
                  props.onFilterUpdate(
                    [
                      atomicFilterClause(
                        'case',
                        id,
                        `${props.caseConfig?.ref}.${id}`,
                        value,
                        'contains'
                      )
                    ],
                    []
                  );
                } else {
                  props.onFilterUpdate(
                    [],
                    [{ clauseType: 'case', id: id }]
                  );
                }
                break;
            }
          }
      }
    },
    [props, state, updateCreateDateFilter]
  );

  // debounced version of the widget change handler for use within text fields
  const debouncedChange = _.debounce(onWidgetValueChange, 500, {
    trailing: true,
    maxWait: 1000
  });

  return (
    <div className={'report-form'}>
      <div className={'report-form-row'}>
        <label className={'heading'}>General</label>
      </div>
      <div className={'report-form-row'}>
        {/* filtration based on case type status*/}
        {props.caseConfig && (
          <>
            <DropdownWidget
              key={newUUID()}
              tag="caseStatus"
              onValueChange={onWidgetValueChange}
              initialSelection={caseStatusInitialValue()}
              allowBlank={true}
              blankLabel={'Any'}
              label={'Case Status'}
              items={caseStatusesByCaseVersion(
                appConfig,
                props.caseConfig.ref,
                props.caseConfig.version
              )}
              labelCallback={(status) => (status as CaseStatus).label}
              valueCallback={(status) => (status as CaseStatus).id}
              disabled={false}
            />
          </>
        )}

        {/* createdAt filtration of the cases core table*/}
        {props.filter && (
          <>
            <DropdownWidget
              key={newUUID()}
              tag="creationDateRange"
              onValueChange={onWidgetValueChange}
              initialSelection={createDateRangeInitialValues()}
              allowBlank={true}
              blankLabel={'Custom'}
              label={'Date Range'}
              items={StandardDateRanges}
              labelCallback={(range) => (range as StandardDateRange).label}
              valueCallback={(range) =>
                JSON.stringify((range as StandardDateRange).func())
              }
              disabled={false}
            />

            <DateSelectionWidget
              key={newUUID()}
              selectedDate={
                state.afterDate ? moment(state.afterDate).toDate() : undefined
              }
              tag={'createdAfter'}
              label={'After'}
              disabled={!state.caseDateRangeEnabled}
              onChange={(tag, date) => onWidgetValueChange(tag, date)}
            />

            <div className={'report-form-row-spacer'}>&rArr;</div>
            <DateSelectionWidget
              key={newUUID()}
              selectedDate={
                state.beforeDate ? moment(state.beforeDate).toDate() : undefined
              }
              tag={'createdBefore'}
              label={'Before'}
              disabled={!state.caseDateRangeEnabled}
              onChange={(tag, date) => onWidgetValueChange(tag, date)}
            />
          </>
        )}
      </div>

      <hr className={'report-form-divider'} />

      {/* case task form fields for the current case configuration, linked to search configuration */}
      <div className={'report-form-row'}>
        <label className={'heading'}>Case Specific Fields</label>
      </div>
      <div className={'report-form-row report-form-row-grid'}>
        {props.caseConfig &&
          props.caseConfig.meta.searchDisplaySpecifications!!.map((spec, k) => {
            return (
              <LabelledTextWidget
                key={`case.${spec.valuePointer}_${k}`}
                label={displaySpecificationLabel(props.caseConfig!!, spec)}
                prompt={'Contains...'}
                tag={`case.${spec.valuePointer}`}
                initialValue={caseFormFieldInitialValue(spec.valuePointer)}
                onValueChange={debouncedChange}
                disabled={false}
                labelClassName={'min-width-3'}
              />
            );
          })}
      </div>
    </div>
  );
};
