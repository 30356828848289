import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import axios from 'axios';
import { useApiList } from '../models/useApi';
import Spinner from 'react-bootstrap/Spinner';

// TODO this needs a bit more thought, seems a bit weird
const CaseLabelComponent = ({ caseId, caseConfig }) => {

  const { loading, error, items } = useApiList(`/api/case/${caseId}/label`);
  const [managedLabels, setManagedLabels] = useState([]);

  function convertServerLabels(serverLabels) {
    return serverLabels.map(item => ({
      label: item.text,
      value: item.text,
      ident: item.id
    }));
  }

  useEffect(() => {
    if (!loading && items && items.length)
      setManagedLabels(convertServerLabels(items));
  }, [loading, items]);

  if (loading || !items) return <Spinner animation={'border'} variant={'primary'} size={'sm'}/>;
  if (error) return <p className="alert alert-warning">{error}</p>;

  const style = {
    control: base => ({
      ...base,
      top: -10,
      maxWidth: 250
    }),
    menu: base => ({
      ...base,
      marginTop: 0,
      maxWidth: 250
    }),
    valueContainer: provided => ({
      ...provided,
      margin: 0,
      padding: 4
    })
  };

  function addLabel (label) {
    axios.post(`/api/case/${caseId}/label`, { text: label })
      .then((response) => {
        const data = response.data.data;
        setManagedLabels(convertServerLabels(data));
      });
  }

  function removeLabel (id) {
    axios.delete(`/api/case/${caseId}/label/${id}`);
  }

  function onLabelChangeHandler (value, action) {
    if (action.action === 'select-option') {
      addLabel(action.option.label);
    } else if (action.action === 'remove-value') {
      if (action.removedValue.ident) removeLabel(action.removedValue.ident);
      else {
        const item = managedLabels.find((item) => item.value === action.removedValue.value);
        removeLabel(item.ident);
      }
      setManagedLabels(value);
    }
  }

  return (<Select
    value={managedLabels}
    isSearchable={false}
    isClearable={false}
    isMulti
    options={caseConfig.meta.labels}
    styles={style}
    placeholder="N/A"
    onChange={onLabelChangeHandler}
  />);
};

CaseLabelComponent.propTypes = {
  caseId: PropTypes.number.isRequired
};

export default CaseLabelComponent;
