import React, {useEffect, useState} from 'react';
import {
  buildMemoryHistory,
  getURLSearchParamsWithoutPagination
} from '../main/RouterHistory';
import PropTypes from 'prop-types';
import { InputSize, sizeToInputGroupClassName } from './InputSize';

/**
 * Abstracted search filter input operating on search query params, implementation
 * needs to implement query parameter change listeners.
 * @param parameterKey {string} query parameter name/key
 * @param parameterLabel {string} label
 * @param labels {Array<String>}
 * @param values {Array<*>}
 * @param addEmptyValue {boolean}
 * @param params {URLSearchParams}
 * @param className {string} - additional classes next to input-group
 * @param size {InputSize}
 * @return {*}
 */
const SelectFromList = ({
  parameterKey,
  parameterLabel,
  labels,
  values,
  addEmptyValue,
  params,
  className = '',
  size = InputSize.md
}) => {

  const sizeClassName = sizeToInputGroupClassName(size);
  const [value, setValue] = useState(params.get(parameterKey) || '');

  const onChange = (event) => {
    const val = event.target.value;
    setValue(val);
  };

  // Clone to avoid reference issue where values point to the same array as labels
  const options = values.slice();
  const optionLabels = labels.slice();
  if (addEmptyValue) {
    options.unshift("");
    optionLabels.unshift("-- Unfiltered --");
  }

  return (<div className={`input-group ${sizeClassName} ${className}`}>
    <div className="input-group-prepend">
      <label className="input-group-text" htmlFor={parameterKey}>{parameterLabel}</label>
    </div>
    <select value={value} name={parameterKey} onChange={onChange} className="custom-select" id={parameterKey}>
      {options.map((val, idx) => <option key={val} value={val}>{optionLabels[idx]}</option>)}
    </select>
  </div>);
};

SelectFromList.propTypes = {
  parameterKey: PropTypes.string.isRequired,
  parameterLabel: PropTypes.string.isRequired,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  values: PropTypes.arrayOf(PropTypes.string).isRequired,
  addEmptyValue: PropTypes.bool.isRequired,
  params: PropTypes.object.isRequired,
  size: PropTypes.oneOf(Object.keys(InputSize)),
  className: PropTypes.string
};

/**
 * Is functional as standalone element which changes
 * query parameter ($parameterKey) in URL.
 */
export const SelectFromListForUrl = ({ parameterKey, parameterLabel, labels, values, addEmptyValue, className, size }) => {
  return (<SelectFromList
    parameterKey={parameterKey}
    parameterLabel={parameterLabel}
    labels={labels}
    values={values}
    addEmptyValue={addEmptyValue}
    params={getURLSearchParamsWithoutPagination()}
    className={className}
    size={size}
  />);
};

export const SelectFromListWithCallback = ({
  onSearch,
  parameterKey,
  parameterLabel,
  labels,
  values,
  addEmptyValue,
  className,
  size
}) => {
  const [history, setHistory] = useState(null);
  useEffect(() => {
    const inMemoryHistory = buildMemoryHistory();
    setHistory(inMemoryHistory);
    return inMemoryHistory.listen((location, _) => {
      onSearch(new URLSearchParams(location.search).get(parameterKey));
    });
  }, [onSearch, parameterKey]);
  if (!history) return null;
  return <SelectFromList
    parameterKey={parameterKey}
    parameterLabel={parameterLabel}
    labels={labels}
    values={values}
    addEmptyValue={addEmptyValue}
    params={new URLSearchParams(history.location.search)}
    className={className}
    size={size}
  />;
};
