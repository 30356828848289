import PropTypes from 'prop-types';
import Pagination from 'react-bootstrap/Pagination';
import React from 'react';

const PaginationWrapper = ({ total, max, offset, onChange, ...rest }) => {

  const resultsOffset = offset > -1 ? offset : 0;
  const maxPerPage = max > 0 ? max : 1;
  const totalPages = Math.ceil(total / maxPerPage);
  const currentPage = Math.floor(resultsOffset / maxPerPage) + 1;
  const showFirstPage = currentPage > 2; // because previous page will be shown when on page 2
  const previousPage = currentPage > 1 ? currentPage - 1 : null;
  const nextPage = currentPage < totalPages ? currentPage + 1 : null;
  const showLastPage = totalPages > currentPage + 1; // because next page will be shown when on penultimate page
  const showPrevElipsis = currentPage > 3;
  const showNextElipsis = totalPages - currentPage > 2;

  function goFirst () {
    onChange({ max, offset: 0 });
  }

  function goPrev () {
    let newOffset = resultsOffset - maxPerPage;
    if (newOffset < 0) {
      newOffset = 0;
    }
    onChange({ max, offset: newOffset });
  }

  function goNext () {
    let newOffset = resultsOffset + maxPerPage;
    if (newOffset >= total) {
      newOffset = 0;
    }
    onChange({ max, offset: newOffset });
  }

  function goLast () {
    let newOffset = (totalPages - 1) * maxPerPage;
    if (newOffset < 0 || newOffset >= total) {
      newOffset = 0;
    }
    onChange({ max, offset: newOffset });
  }

  function paginationItems () {
    return (<>
      <Pagination.Prev disabled={previousPage === null && offset < 1} onClick={goPrev}/>
      {showFirstPage && <Pagination.Item onClick={goFirst}>1</Pagination.Item>}
      {showPrevElipsis && <Pagination.Ellipsis disabled={true} />}
      {previousPage != null && <Pagination.Item onClick={goPrev}>{previousPage}</Pagination.Item>}
      <Pagination.Item active={true}>{currentPage}</Pagination.Item>
      {nextPage != null && <Pagination.Item onClick={goNext}>{nextPage}</Pagination.Item>}
      {showNextElipsis && <Pagination.Ellipsis disabled={true} />}
      {showLastPage && <Pagination.Item onClick={goLast}>{totalPages}</Pagination.Item>}
      <Pagination.Next disabled={nextPage === null} onClick={goNext}/>
      {rest.children}
    </>);
  }

  return (<>
    {(totalPages > 1 || offset > 1) && <Pagination {...rest}>{paginationItems()}</Pagination>}
    {(totalPages === 1) && <Pagination {...rest}>{rest.children}</Pagination>}
  </>);
};

PaginationWrapper.propTypes = {
  total: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  offset: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired
};

export default PaginationWrapper;
