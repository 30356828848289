import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ModelListView from '../models/ModelListView';
import ContentTypeIcon from '../files/ContentTypeIcon';
import { parseAxiosError } from '../shared/AxiosResponseErrorParser';
import axios from 'axios';
import fileSaver from 'file-saver';
import { Download } from '../main/icons';
import UserPicture from '../shared/UserPicture';
import { WithPermission } from '../auth/AccessConditionals';
import { PromptModal } from '../shared/Modal';
import Spinner from 'react-bootstrap/Spinner';
import { PusherTaskChannelListener } from '../pusher/PusherChannelListeners';
import { toast } from 'react-toastify';
import { CasualTime } from '../shared/Dates';
import { Refresh, Close } from '../main/icons';
import { useIntl } from 'react-intl';

const DeleteCommentButton = ({ comment, className, onSuccess }) => {
  const [loading, setLoading] = useState(false);
  const [isConfirmVisible, setIsConfirmVisible] = useState(false);

  if (!className) {
    className = 'border-0 m-0 p-0 btn btn-sm btn-outline-secondary';
  }

  const onDeleteClick = () => {
    setLoading(true);
    setIsConfirmVisible(true);
  };

  const onDeleteCancel = () => {
    setLoading(false);
    setIsConfirmVisible(false);
  };

  const onDeleteConfirm = () => {
    setLoading(true);
    setIsConfirmVisible(false);
    axios
      .delete(`api/task/${comment.entityId}/comment/${comment.id}`)
      .then(() => {
        setLoading(false);
        onSuccess && onSuccess();
      })
      .catch((e) => {
        setLoading(false);
        const msg = e.response?.data?.errors[0]?.error || null;
        if (msg) {
          toast.error(msg, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2500
          });
        }
      });
  };

  return (
    <WithPermission name="comment:delete" instance={comment}>
      {isConfirmVisible && (
        <PromptModal
          title="Are you sure?"
          text="Comment along with attachments (if any) will be removed! Operation cannot be undone."
          onCancel={onDeleteCancel}
          onSuccess={onDeleteConfirm}
        />
      )}
      <button
        disabled={loading}
        className={className}
        aria-label="Delete comment"
        onClick={onDeleteClick}
      >
        {!loading && <Close height={16} style={{ fill: 'currentColor' }} />}
        {loading && (
          <Spinner animation={'border'} variant={'secondary'} size={'sm'} />
        )}
      </button>
    </WithPermission>
  );
};
DeleteCommentButton.propTypes = {
  comment: PropTypes.object.isRequired,
  onSuccess: PropTypes.func.isRequired,
  className: PropTypes.string
};

const DownloadAttachmentButton = ({
  taskId,
  commentId,
  attachmentId,
  label,
  contentType
}) => {
  const [loading, setLoading] = useState(false);

  const attachmentDownload = (event) => {
    setLoading(true);
    event.preventDefault();
    const url = `api/task/${taskId}/comment/${commentId}/attachment/${attachmentId}`;
    axios
      .get(url, { responseType: 'blob' })
      .then((response) => {
        setLoading(false);
        fileSaver.saveAs(new Blob([response.data]), label);
      })
      .catch((e) => {
        setLoading(false);
        // TODO: exception handling, download progress?
        console.error(parseAxiosError(e).getMessage());
      });
  };

  return (
    <button
      key={commentId + '-' + attachmentId}
      disabled={loading}
      className="btn btn-sm btn-outline-secondary mr-1 mb-1"
      title={`Download ${label}`}
      onClick={attachmentDownload}
    >
      <ContentTypeIcon contentType={contentType} height={14} />
      <span className="mx-2">{label}</span>
      <Download height={14} className="svg-gradient-secondary" />
    </button>
  );
};

DownloadAttachmentButton.propTypes = {
  taskId: PropTypes.number.isRequired,
  commentId: PropTypes.number.isRequired,
  attachmentId: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  contentType: PropTypes.string.isRequired
};

const TaskComment = ({ model }) => {
  const [wasRemoved, setWasRemoved] = useState(false);

  if (wasRemoved) {
    return <div className="p-2 text-center text-muted">Comment removed</div>;
  }

  if (!model) {
    model = {};
  }

  const commenter = (model.owner && model.owner.displayName) || 'anonymous';

  // TODO: chanage API to include name and email as separate properties
  const nameParts = commenter.split(/[<>]/);
  const name = nameParts[0].trim();
  const email = nameParts[1] || name;

  return (
    <div className="d-flex flex-row p-2">
      <div className="flex-grow-1 pl-2 small overflow-hidden">
        <div className="d-flex flex-row align-items-center mb-2">
          <table className="comments-table">
            <tbody>
              <tr>
                <td className="avatar-column">
                  <UserPicture
                    className="avatar comments-avatar"
                    email={email}
                    title={commenter}
                  />
                </td>
                <td className="ellipsis">
                  <span className="mx-2 comment-wrap" title={commenter}>
                    {name}
                  </span>
                </td>
                <td className="ellipsis time-column">
                  <CasualTime
                    className="text-muted mr-auto"
                    date={model.createdAt}
                  />
                </td>
                <td className="remove-column">
                  <DeleteCommentButton
                    comment={model}
                    onSuccess={() => setWasRemoved(true)}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <p style={{ whiteSpace: 'pre-wrap' }}>{model.text}</p>
        {model.attachments &&
          model.attachments.length > 0 &&
          model.attachments.map((attachment) => (
            <DownloadAttachmentButton
              taskId={model.entityId}
              key={attachment.id}
              attachmentId={attachment.id}
              commentId={model.id}
              contentType={attachment.contentType}
              label={attachment.name}
            />
          ))}
      </div>
    </div>
  );
};

function TaskComments({ taskId, reload }) {
  const intl = useIntl();
  const [lastCommentId, setLastCommentId] = useState(null);

  useEffect(() => {
    refreshComments();
  }, [reload]);

  const onAssigneeChange = useCallback(
    (data) => {
      if (data && data.comment && data.comment.id) {
        setLastCommentId(data.comment.id);
      }
    },
    [setLastCommentId]
  );

  const onNewComment = useCallback(
    (data) => {
      if (data && data.comment && data.comment.id) {
        setLastCommentId(data.comment.id);
      }
    },
    [setLastCommentId]
  );

  const refreshComments = () => {
    setLastCommentId(new Date().getTime());
  };

  return (
    <div className="card mb-3" key={`comment_${taskId}`}>
      <PusherTaskChannelListener
        taskId={taskId}
        afterAssigneeChange={onAssigneeChange}
        afterComment={onNewComment}
      />
      <div className="card-header pl-3 pr-2">
        <h2 className="float-left">Comments</h2>
        <div className="float-right">
          <button
            onClick={() => refreshComments()}
            title={intl.formatMessage({ id: 'nav.tasks.comment-refresh' })}
            aria-label={intl.formatMessage({ id: 'nav.tasks.comment-refresh' })}
            className="border-0 p-0 m-0 btn btn-sm btn-outline-secondary"
          >
            <Refresh height={20} style={{ fill: 'currentColor' }} />
          </button>
        </div>
      </div>

      <ModelListView
        className={'list-group list-group-flush'}
        itemClassName={'list-group-item p-0'}
        apiUrl={`/api/task/${taskId}/comment`}
        listItemRenderer={TaskComment}
        notFoundMessage="There are no comments yet on this task."
        key={lastCommentId} // re-fetch comments when a new comment added
      />
    </div>
  );
}

TaskComments.propTypes = {
  taskId: PropTypes.number.isRequired
};

export { TaskComments, TaskComment };
