import React, { FC } from 'react';
import * as AppWidgets from './widgets/AppWidgets';
// @ts-ignore
import { ConditionalFormForSchema } from '@lookinglocal/react-jsonschema-form-extensions';

/**
 * Props used to display a read-only view of the form associated with a given
 * case
 */
export interface ReadonlyFormDisplayProps {
  /**
   * The actual form data
   */
  values: any;

  /**
   * The schema for the form
   */
  schema: any;

  /**
   * The UI schema associated with the form
   */
  uiSchema: any;

  /**
   * The rules associated with the form
   */
  rules: any;
}

/**
 * Simple component that will display the form associated with a case in a
 * read-only fashion (for use within external case search etc...)
 * @param props
 * @constructor
 */
export const ReadOnlyFormDisplay: FC<ReadonlyFormDisplayProps> = (props) => {
  return (
    <ConditionalFormForSchema
      isReadOnly={true}
      appWidgets={AppWidgets.appWidgets}
      values={props.values}
      schema={props.schema}
      uiSchema={props.uiSchema}
      rules={props.rules ? props.rules : []}
    />
  );
};
