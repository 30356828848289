import { getURLSearchParams } from '../main/RouterHistory';
import PropTypes from 'prop-types';
import { MemorableDate, toIsoDateString } from './MemorableDate';
import { InputSize } from './InputSize';

export const SelectDateRangeForUrl = ({ beforeKey, beforeLabel, afterKey, afterLabel, minDate, maxDate, className, size }) => {
  const params = getURLSearchParams();
  const urlAfterParam = params.get(afterKey) || '';
  const urlBeforeParam = params.get(beforeKey) || '';

  const defaultMinDate = toIsoDateString(minDate) || '1800-01-01';
  const defaultMaxDate = toIsoDateString(maxDate) || '2100-01-01';
  const afterMaxDate = urlBeforeParam || defaultMaxDate;
  const beforeMinDate = urlAfterParam || defaultMinDate;

  const onAfterMemorableChange = (isoDateString) => {
    if (urlAfterParam === isoDateString) return;
    const elAfter = document.getElementById(afterKey);
    if (elAfter) elAfter.dispatchEvent(new Event("input", {"bubbles": true}));
  };

  const onBeforeMemorableChange = (isoDateString) => {
    if (urlBeforeParam === isoDateString) return;
    const elBefore = document.getElementById(beforeKey);
    if (elBefore) elBefore.dispatchEvent(new Event("input", {"bubbles": true}));
  };

  return (<>
    <MemorableDate className={className}
      size={size}
      label={afterLabel}
      paramKey={afterKey}
      initialValue={urlAfterParam}
      minDateIso={defaultMinDate}
      maxDateIso={afterMaxDate}
      onChange={onAfterMemorableChange} />
    <MemorableDate className={className}
      size={size}
      label={beforeLabel}
      paramKey={beforeKey}
      initialValue={urlBeforeParam}
      minDateIso={beforeMinDate}
      maxDateIso={defaultMaxDate}
      onChange={onBeforeMemorableChange} />
  </>);
};

SelectDateRangeForUrl.propTypes = {
  beforeKey: PropTypes.string.isRequired,
  beforeLabel: PropTypes.string.isRequired,
  afterKey: PropTypes.string.isRequired,
  afterLabel: PropTypes.string.isRequired,
  minDate: PropTypes.instanceOf(Date).isRequired,
  maxDate: PropTypes.instanceOf(Date).isRequired,
  size: PropTypes.oneOf(Object.keys(InputSize)),
  className: PropTypes.string
};
