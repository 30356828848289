import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import FlowContainer, { CssColClassesFlow } from '../flows/core/FlowContainer';
import FormStep from '../flows/core/FormStep';
import ListLoader from '../loaders/ListLoader';
import { parseAxiosError } from '../shared/AxiosResponseErrorParser';
import RouterHistory from '../main/RouterHistory';

const ModelInstanceEditView = (props) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [existing, setExisting] = useState(null);
  const [locked, setLocked] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios.get(props.apiUrl)
      .then(response => {
        setExisting(response.data.data);
        const state = response.data.state || { editable: true };
        setLocked(!state.editable);
        setErrorMessage(null);
      })
      .catch(e => {
        const is404 = e.response && e.response.status === 404;
        setExisting(is404 ? props.defaultValuesIfNotFound : null);
        setErrorMessage(is404 ? null : parseAxiosError(e, 'Failed to retrieve results from server').getMessage());
      })
      .finally(() => setLoading(false));
  }, [props.apiUrl, props.defaultValuesIfNotFound]);

  function onSubmit (data) {
    const {apiUrl, onAfterSubmit, pathAfterSuccess} = props;
    setLoading(true);
    return axios.put(apiUrl, data[0])
      .then(() => {
        setLoading(false);
        props.onAfterSubmit && onAfterSubmit();
        RouterHistory.push(pathAfterSuccess);
      })
      .catch(e => {
        setExisting(data[0]); // make sure we do not loose data in the form
        setLoading(false);
        setErrorMessage(parseAxiosError(e, 'Failed to save').getMessage());
      });
  }

  function onCancel () {
    RouterHistory.goBack();
  }

  function notFoundErrorIfAny () {
    const shouldShow = !loading && !errorMessage && !existing && !props.ignoreNotFound;
    return shouldShow ? <div className="alert alert-warning">Nothing found</div> : null;
  }

  const SchemaFormComponent = props.schemaFormComponent;

  return (<div className="row">
    <div className={CssColClassesFlow}>
      {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
      {loading && <ListLoader/>}
      {notFoundErrorIfAny()}
    </div>
    {!loading && (existing || !!props.ignoreNotFound) &&
    <FlowContainer onFinalSubmit={onSubmit} primaryValues={[existing]} onCancel={onCancel}>
      <FormStep key={'one'} title={props.viewTitle} isReadOnly={locked} schemaFormComponent={SchemaFormComponent}/>
    </FlowContainer>}
  </div>);
};

ModelInstanceEditView.propTypes = {
  ignoreNotFound: PropTypes.bool,
  defaultValuesIfNotFound: PropTypes.object,
  viewTitle: PropTypes.string.isRequired,
  apiUrl: PropTypes.string.isRequired,
  pathAfterSuccess: PropTypes.string.isRequired,
  onAfterSubmit: PropTypes.func,
  schemaFormComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired
};

export default ModelInstanceEditView;
