import React, {useContext, useEffect, useState} from 'react';
import Pusher from 'pusher-js';
import {useAuthContext} from '../auth/AuthContextProvider';

export const PusherContext = React.createContext({});
export const usePusher = () => useContext(PusherContext);
export const PusherProvider = ({
  children
}) => {

  const [pusher, setPusher] = useState(null);
  const [loading, setLoading] = useState(true);
  const {getTokenSilently, loading: auth0Loading} = useAuthContext();

  /**
   * Should run only once as options do not change
   */
  useEffect(() => {
    if (!auth0Loading) getTokenSilently()
      .then((token) => {
        console.info(` process.env.REACT_APP_SERVER: ${process.env.REACT_APP_SERVER}`);
        const authUrl = new URL('/api/pusher/auth', process.env.REACT_APP_SERVER);
        console.info(`authEndpoint URL: ${authUrl}`);
        try {
          const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
            cluster: process.env.REACT_APP_PUSHER_CLUSTER || 'eu',
            forceTLS: true,
            authEndpoint: authUrl.href,
            auth: {
              headers: {
                'Authorization': `Bearer ${token.raw}`
              }
            }
          });
          setPusher(pusher);
          setLoading(false);
        } catch(err) {
          console.info(`token: ${JSON.stringify(token)}`);
          console.error("Failure in Pusher initialisation", err);
          setLoading(false);
        }
      })
      .catch(() => {
        console.info("Cannot set up Pusher until auth token is available");
        setLoading(false);
      });

  }, [auth0Loading, getTokenSilently]);

  return (
    <PusherContext.Provider
      value={{
        loading,
        pusher
      }}
    >
      {children}
    </PusherContext.Provider>
  );
};
