import React, { useEffect, useState } from 'react';
import { ReactComponent as Logo } from '../images/logo.svg';
import { FormattedMessage } from 'react-intl';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import { parseAxiosError } from '../shared/AxiosResponseErrorParser';
import {
  useAppConfiguration
} from '../shared/contexts/AppConfiguration';

const redirectUri = window.location.origin;
const loginUrl = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/authorize?response_type=token&client_id=${process.env.REACT_APP_AUTH0_CLIENT_ID}&redirect_uri=${redirectUri}`;

const refreshPage = () => window.location.reload();

const ContextErrorPageTemplate = ({ children }) => {
  return (
    <div className="min-vh-100 d-flex flex-column">
      <nav className="navbar app-navbar flex-grow-0 flex-fill navbar-light bg-light justify-content-start px-xl-5">
        <span className="navbar-brand mr-3">
          <Logo height="30px" title="Logo" className="customer-logo mr-2" />
          <FormattedMessage id="general.brand" />
        </span>
      </nav>
      <div className="container p-3 d-flex flex-grow-1 justify-content-center flex-column">
        <div className="col-lg-8 offset-lg-2">{children}</div>
      </div>
    </div>
  );
};

/**
 * In some cases our API might fail and authentication context will not be able to
 * retrieve required profile information.
 * @param error {Object} Auth0 error
 * @param [auth0User] {Object} Auth0 user
 * @param onLogoutClick {Function}
 */
export const ApiContextErrorPage = ({
  error,
  auth0User,
  onLogoutClick = () => {}
}) => {
  if (error && error.response && error.response.status === 403 && auth0User) {
    return (
      <EmailUnverifiedErrorPage
        message={error.response.data.message}
        auth0User={auth0User}
        onLogoutClick={onLogoutClick}
      />
    );
  } else if (
    error &&
    error.response &&
    error.response.status === 401 &&
    auth0User
  ) {
    return <UnauthorizedErrorPage onLogoutClick={onLogoutClick} />;
  }
  return <ApiAuthFailureErrorPage onLogoutClick={onLogoutClick} />;
};

/**
 * @param error {Object} Auth0 error
 * @param [auth0User] {Object} Auth0 user
 * @param onLogoutClick {Function}
 */
const EmailUnverifiedErrorPage = ({ message, auth0User, onLogoutClick }) => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [verificationSent, setVerificationSent] = useState(false);

  const appConfig = useAppConfiguration();
  const [maintenanceMode, setMaintenanceMode] = useState(false);

  useEffect(() => {
    if (appConfig.loaded) {
      console.log('config loaded');
      setMaintenanceMode(appConfig.appMetaData.maintenanceMode);
    } else {
      console.log('config not loaded');
    }
  }, [appConfig.appMetaData.maintenanceMode, appConfig.loaded]);

  const sendVerificationEmail = () => {
    setLoading(true);
    axios
      .post(`/api/auth/verification/send/${encodeURIComponent(auth0User.sub)}`)
      .then(() => setVerificationSent(true))
      .catch((e) => setErrorMessage(parseAxiosError(e).getMessage()))
      .finally(() => setLoading(false));
  };

  return (
    <ContextErrorPageTemplate>
      <h1 className="text-center">
        <span role="img" aria-label="Robot">
          🤖
        </span>
      </h1>
      {maintenanceMode && (
        <h4 className="text-center">
          The system is currently in maintenance mode, please check back later!
        </h4>
      )}
      {!maintenanceMode && (
        <h4 className="text-center">Cannot continue for now</h4>
      )}
      <div className="alert alert-warning">{message}</div>
      {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
      {verificationSent && (
        <div className="alert alert-success">
          <span role="img" aria-label="Done">
            ✅
          </span>{' '}
          Verification email should have been sent
        </div>
      )}
      {!maintenanceMode && (
        <div className="text-center">
          {loading && (
            <spinner animation={'border'} variant={'primary'} size={'sm'} />
          )}
          {!loading && (
            <button
              type="button"
              className="btn btn-primary mr-2"
              onClick={() => sendVerificationEmail()}
            >
              Send verification email
            </button>
          )}
          <button
            type="button"
            className="btn btn-outline-secondary mr-2"
            onClick={() => onLogoutClick()}
          >
            Logout
          </button>
          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={() => refreshPage()}
          >
            Refresh page
          </button>
        </div>
      )}
    </ContextErrorPageTemplate>
  );
};

/**
 * @param onLogoutClick {Function}
 */
const UnauthorizedErrorPage = ({ onLogoutClick }) => {
  return (
    <ContextErrorPageTemplate>
      <h1 className="text-center">
        <span role="img" aria-label="No entry">
          ⛔
        </span>
      </h1>
      <h4 className="text-center">Authorisation Failed</h4>
      <div className="alert alert-danger text-center">
        Your account may be locked, please contact your organisation's{' '}
        <FormattedMessage id="general.brand" /> administrator or a member of the
        Coalescent team for support
      </div>
      <div className="text-center">
        <button
          type="button"
          className="btn btn-outline-secondary mr-2"
          onClick={() => onLogoutClick()}
        >
          Logout
        </button>
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={() => refreshPage()}
        >
          Refresh page
        </button>
      </div>
    </ContextErrorPageTemplate>
  );
};

/**
 * @param onLogoutClick {Function}
 */
const ApiAuthFailureErrorPage = ({ onLogoutClick }) => {
  const [apiCheckTime, setApiCheckTime] = useState(new Date());
  const [apiLoading, setApiLoading] = useState(true);
  const [apiIsUp, setApiIsUp] = useState(false);

  /**
   * Check API is up
   */
  useEffect(() => {
    setApiLoading(true);
    axios
      .get(process.env.REACT_APP_SERVER)
      .then(() => {
        setApiLoading(false);
        setApiIsUp(true);
      })
      .catch(() => {
        setApiLoading(false);
        setApiIsUp(false);
      });
  }, [apiCheckTime]);

  const checkAgain = () => setApiCheckTime(new Date());

  return (
    <ContextErrorPageTemplate>
      <h1 className="text-center">
        <span role="img" aria-label="Robot">
          🤖
        </span>
      </h1>
      <h4 className="text-center">
        Could not start due to authentication failure
      </h4>
      <div className="alert alert-warning">
        <ul className="mb-0">
          <li>
            {apiLoading && (
              <div>
                <Spinner animation={'border'} variant={'primary'} size={'sm'} />{' '}
                Checking if server is up...{' '}
              </div>
            )}
            {!apiLoading && apiIsUp && (
              <span className="text-success">
                Connection to the API server is healthy
              </span>
            )}
            {!apiLoading && !apiIsUp && (
              <span className="text-danger">
                Connection to the API server failed!
              </span>
            )}

            {!apiLoading && (
              <button
                type="button"
                className="btn btn-sm btn-link"
                onClick={() => checkAgain()}
              >
                Check again
              </button>
            )}
          </li>
          <li>
            You could try to{' '}
            <a href={loginUrl} className="btn btn-outline-primary btn-sm">
              Login
            </a>{' '}
            or{' '}
            <button
              type="button"
              className="btn btn-outline-primary btn-sm"
              onClick={() => onLogoutClick()}
            >
              Logout
            </button>{' '}
            or{' '}
            <button
              type="button"
              className="btn btn-outline-primary btn-sm"
              onClick={() => refreshPage()}
            >
              Refresh page
            </button>
          </li>
          {!apiLoading && !apiIsUp && (
            <li>Please also make sure you are connected to the internet.</li>
          )}
        </ul>
      </div>
    </ContextErrorPageTemplate>
  );
};

/**
 * In some edge cases `consent_required` failure might be thrown when initializing
 * the Auth0 client. It can end up in infinite loops as this app tries to initialize client
 * every time it loads. Automatic redirect will not help here.
 * Details of an error are opaque and there are at least 2 choices user can make:
 * - redirect to login page
 * - logout invalid user
 * @param onLogoutClick {Function}
 */
export const Auth0ContextErrorPage = ({ onLogoutClick = () => {} }) => {
  return (
    <ContextErrorPageTemplate>
      <h1 className="text-center">
        <span role="img" aria-label="Robot">
          🤖
        </span>
      </h1>
      <h4 className="text-center">
        Could not start due to authentication failure
      </h4>

      <div className="alert alert-warning">
        <ul className="mb-0">
          <li>
            You could try to{' '}
            <a href={loginUrl} className="btn btn-outline-primary btn-sm">
              Login
            </a>{' '}
            or{' '}
            <button
              type="button"
              className="btn btn-outline-primary btn-sm"
              onClick={() => onLogoutClick()}
            >
              Logout
            </button>{' '}
            or{' '}
            <button
              type="button"
              className="btn btn-outline-primary btn-sm"
              onClick={() => refreshPage()}
            >
              Refresh page
            </button>
          </li>
          <li>Please also make sure you are connected to the internet.</li>
          <li>
            If the error persists, try clearing your browser cache, removing
            cookies or deleting local/session storage.
          </li>
        </ul>
      </div>

      <p className="text-muted">
        Please also consider trying to load this page on other available
        browsers in your disposal or from another device.
      </p>
    </ContextErrorPageTemplate>
  );
};
