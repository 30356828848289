import React, { useState } from 'react';
import { generatePath } from 'react-router';
import ModelTableWithRouterState from '../models/ModelTableWithRouterState';
import { Page, PageNav } from '../shared/Page';
import {useDocumentTitle} from "../shared/hooks/DocumentTitleHooks";

const FormsListPage = (props) => {
  const [timestamp] = useState(new Date().getTime());
  const additionalQueryParams = { t: timestamp };
  const apiEndpoint = '/api/form';
  const columns = [
    {
      label: 'Reference',
      fromModel: (item) => item.ref
    },
    {
      label: 'Schema Version',
      fromModel: (item) => item.version
    },
    {
      label: 'Title',
      fromModel: (item) => item.form.schema.title
    }
  ];


  useDocumentTitle('Available task forms');

  return (
    <Page>
      <PageNav title="Available task forms" translate={false} />
      <ModelTableWithRouterState
        caption="nav.tasks.forms"
        apiEndpoint={apiEndpoint}
        additionalQueryParams={additionalQueryParams}
        columns={columns}
        itemHref={(item) => generatePath(`/formPreview/${item.ref}/${item.version}`)}
        {...props}
      />
    </Page>
  );
};

export default FormsListPage;
