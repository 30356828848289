import { FC, useCallback } from 'react';
import { Collapsible } from '../shared/components/Collapsible';
import { SidebarLink } from './SidebarLink';
import {
  appFeatureFlag,
  CaseConfiguration,
  caseOrTaskMessage,
  sortCaseConfigurations,
  UiMessageKey
} from '../shared/types';
import { useAppConfiguration } from '../shared/contexts/AppConfiguration';

export interface SidebarUserCaseLinksProps {
  /**
   * The title of link section
   */
  title: string;

  /**
   * How should the "all" link be labelled?
   */
  allLabel?: string;

  /**
   * Are we expanded on first render?
   */
  expanded?: boolean;
}

/**
 *
 * @param title
 * @param expanded
 * @param rest
 * @constructor
 */
export const SidebarUserCaseLinks: FC<SidebarUserCaseLinksProps> = ({
  title,
  expanded,
  allLabel
}) => {
  const appConfig = useAppConfiguration();

  // if we're feature flagged as dev, then grab the category for a  *fancy* badge
  const  caseCategoryLabel = useCallback((caseConfig: CaseConfiguration) => {
    if (appConfig.loaded) {
      if (appFeatureFlag(appConfig, 'dev')) {
        return caseConfig.meta.category ? caseConfig.meta.category : undefined;
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  }, [appConfig]);

  return (
    <Collapsible
      index={0}
      expandedTitle={title}
      collapsedTitle={title}
      expanded={expanded}
    >
      {appConfig.loaded && sortCaseConfigurations(appConfig.caseConfigurations)
        .map((config) => {
          const relativeLink = `/cases/created-by-you/${config.ref}`;
          return (
            <SidebarLink
              key={config.ref}
              to={relativeLink}
              label={caseOrTaskMessage(config, UiMessageKey.TitlePlural)}
              translate={false}
              badge={caseCategoryLabel(config)}
            />
          );
        })}
    </Collapsible>
  );
};
