import React from 'react';

export default function ListLoader () {
  const items = [0, 1, 2].map((item, key) =>
    <div className="list-group-item" key={key}>
      <span className="loading-text-filler"/>
    </div>
  );

  return <div className="list-group" data-testid="list-loader">{items}</div>;
}
