import { FC } from 'react';
import { useCaseConfigurations } from '../shared/hooks/AppConfigurationHooks';
import { Collapsible } from '../shared/components/Collapsible';
import { SidebarLink } from './SidebarLink';
import {
  caseOrTaskMessage,
  caseOrTaskMessageElse,
  sortCaseConfigurations,
  UiMessageKey
} from '../shared/types';

export interface SidebarExternalSearchLinksProps {
  /**
   * The title of link section
   */
  title: string;

  /**
   * Are we expanded on first render?
   */
  expanded?: boolean;
}

/**
 * Displays a series of search links - one for each {@link CaseConfiguration} type
 * @param title
 * @param expanded
 * @param rest
 * @constructor
 */
export const SidebarExternalSearchLinks: FC<
  SidebarExternalSearchLinksProps
> = ({ title, expanded }) => {
  // The current case configurations
  const caseConfigurations = useCaseConfigurations();

  return (
    <Collapsible
      index={0}
      expandedTitle={title}
      collapsedTitle={title}
      expanded={expanded}
    >
      {sortCaseConfigurations(caseConfigurations).map((config) => {
        const caseRef = config.ref;
        const relativeLink = `/cases/external-search?caseRef=${caseRef}`;
        const label = caseOrTaskMessageElse(
          config,
          UiMessageKey.SearchTitle,
          (config) => caseOrTaskMessage(config, UiMessageKey.TitlePlural)
        );
        return (
          <SidebarLink
            key={caseRef}
            to={relativeLink}
            label={label}
            translate={false}
          />
        );
      })}
    </Collapsible>
  );
};
