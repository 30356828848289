import { isValid } from '../form/utils';

const QueryParameterSchema = {
  type: 'object',
  additionalProperties: {
    type: 'object',
    properties: {
      key: {
        type: 'string'
      },
      label: {
        type: 'string'
      },
      isSort: {
        type: 'boolean'
      },
      isPagination: {
        type: 'boolean'
      }
    },
    required: ['key', 'label']
  }
};

export const QueryParameter = {
  query: {
    key: 'query',
    label: 'Search query',
  },
  caseStatus: {
    key: 'caseStatus',
    label: 'Status',
  },
  caseCreatorId: {
    key: 'caseCreatorId',
    label: 'Created by',
  },
  dueForReview: {
    key: 'dueForReview',
    label: 'Due for review',
  },
  taskRef: {
    key: 'taskRef',
    label: 'Task',
  },
  taskStatus: {
    key: 'taskStatus',
    label: 'Status',
  },
  taskQuery: {
    key: 'taskQuery',
    label: 'Form contains ...',
  },
  taskChangedAfter: {
    key: 'taskChangedAfter',
    label: 'Last change after',
  },
  taskChangedBefore: {
    key: 'taskChangedBefore',
    label: 'Last change before',
  },
  profileRole: {
    key: 'role',
    label: 'Role(s)',
  },
  assigneeId: {
    key: 'assigneeId',
    label: 'Assignee',
  },
  assigneeName: {
    key: 'assigneeName',
    label: 'Assignee contains ...',
  },
  afterSuccess: {
    key: 'afterSuccess',
    label: 'After success',
  },
  sort: {
    key: 'sort',
    label: 'Sort by',
    isSort: true
  },
  order: {
    key: 'order',
    label: 'Order',
    isSort: true
  },
  max: {
    key: 'max',
    label: 'Per page',
    isPagination: true
  },
  offset: {
    key: 'offset',
    label: 'Offset',
    isPagination: true
  }
};

if (!isValid(QueryParameterSchema, QueryParameter)) {
  throw new Error('QueryParameter does not validate against QueryParameterSchema');
}

export const queryParamFromKey = (key) => {
  const unrecognisedParam = {
    key: key,
    label: key
  };
  return QueryParameter[key] || unrecognisedParam;
};
