import {dateFromISODateString, toUkDateString} from '../search/MemorableDate';
import {SelectFromListForUrl} from '../search/SelectFromList';
import {InputSize} from '../search/InputSize';
import {SelectDateRangeForUrl} from '../search/SelectDateRange';
import {InputQueryWithCallback, InputTextForUrlParam} from '../search/InputText';
import {flavour} from '../config';
import React, {useCallback} from 'react';

const EnumSearchFieldForUrl = ({definition}) => {
  return <SelectFromListForUrl
    key={definition.key}
    size={InputSize.sm}
    className="mr-sm-3 mb-1"
    labels={definition.schema.enumNames || definition.schema.enum}
    values={definition.schema.enum}
    addEmptyValue={true}
    parameterKey={definition.key}
    parameterLabel={definition.label}/>;
};

const DateRangeSearchFieldForUrl = ({definition}) => {
  // TODO replace with date or time selector based on "ui:widget" and "ui:options"
  const uiOptions = definition.uiSchema['ui:options'] || {};
  const [fromYear, toYear] = uiOptions.yearsRange || [1900, new Date().getFullYear()];
  const beforeKey = `${definition.key}before`;
  const afterKey = `${definition.key}after`;
  return <SelectDateRangeForUrl
    size={InputSize.sm}
    className="mr-sm-3 mb-1"
    minDate={new Date(fromYear, 0, 1)}
    maxDate={new Date(toYear, 11, 31)}
    afterKey={afterKey}
    afterLabel={`${definition.label} (after)`}
    beforeKey={beforeKey}
    beforeLabel={`${definition.label} (before)`}
  />;
};

const TextSearchFieldForUrl = ({definition}) => {
  return <InputTextForUrlParam
    key={definition.key}
    size={InputSize.sm}
    placeholder={definition.label}
    parameterKey={definition.key}
    className="narrow mr-sm-3 mb-1"
  />;
};

export const TextSearchFieldWithCallback = ({definition, callback}) => {
  const onSearch = useCallback((val) => {
    callback({[definition.key]: val});
  }, [definition, callback]);
  return <InputQueryWithCallback
    key={definition.key}
    parameterKey={definition.key}
    size={InputSize.sm}
    placeholder={definition.label}
    onSearch={onSearch}
    className="narrow mr-sm-3 mb-1"
  />;
};

class SchemaSearchFieldDefinition {
  constructor(key, schema, uiSchema) {
    this.key = key;
    this.label = schema.title || key;
    this.schema = schema;
    this.uiSchema = uiSchema;
  }

  getFormFieldForUrl() {
    if (this.schema.enum) {
      return <EnumSearchFieldForUrl key={this.key} definition={this}/>;
    }
    if (this.schema.format === 'date') {
      return <DateRangeSearchFieldForUrl key={this.key} definition={this}/>;
    }
    return <TextSearchFieldForUrl key={this.key} definition={this}/>;
  }

  getFormFieldWithCallback(callbackFn) {
    // TODO provide support for date and enum fields when necessary
    return <TextSearchFieldWithCallback key={this.key} definition={this} callback={callbackFn}/>;
  }
}

export class ListColumnDefinition {
  constructor(label, renderFunction) {
    this.label = label;
    this.fromModel = renderFunction;
  }
}

export class CaseDetailsSearchFields {

  static SUPPORTED_SEARCH_FIELD_TYPES = ['integer', 'number', 'string', 'array'];

  constructor(caseSchema, caseUiSchema) {
    this.caseSchema = caseSchema || {};
    this.caseUiSchema = caseUiSchema || {};

    const schemaProps = this.caseSchema.properties || {};
    this.fields = Object.keys(schemaProps)
      .filter(k => schemaProps[k] && CaseDetailsSearchFields.SUPPORTED_SEARCH_FIELD_TYPES.indexOf(schemaProps[k].type) > -1)
      .map(k => (new SchemaSearchFieldDefinition(k, schemaProps[k] || {}, this.caseUiSchema[k] || {})));
  }

  getQueryParamNames() {
    return this.fields.reduce((memo, field) => {
      if (field.schema.format === 'date') {
        memo.push(`${field.key}before`);
        memo.push(`${field.key}after`);
      } else {
        memo.push(field.key);
      }
      return memo;
    }, []);
  }

  /**
   * TODO - only supports top level elements in the form at this point in time, should be recursive or based on
   * JSON Path
   * @param [limitTo] {Array} filter by given field keys
   * @returns {ListColumnDefinition[]}
   */
  getListColumnConfig(limitTo = []) {
    return this.fields
      .filter(field => (!limitTo || !limitTo.length || limitTo.indexOf(field.key) > -1))
      .map(field => {
        let renderFunction;
        if (field.schema.format === 'date') {
          renderFunction = (item) => item.details[field.key] ? toUkDateString(dateFromISODateString(item.details[field.key])) : '';
        } else {
          if (field.schema.type === 'array') {
            const arrayDisplayFunction = flavour.arrayDisplayFunctions && flavour.arrayDisplayFunctions.find((value) => value[field.key]);
            if (arrayDisplayFunction) {
              renderFunction = (item) => {
                if (item.details[field.key]) return arrayDisplayFunction[field.key](item.details[field.key]);
                else return null;
              };
            }
            else renderFunction = () => null;
          }
          else renderFunction = (item) => item.details[field.key];
        }

        return new ListColumnDefinition(field.label, renderFunction);
      });
  }

  getFormFieldsForUrl() {
    return this.fields.map(f => f.getFormFieldForUrl());
  }

  getFormFields(limitTo = [], searchCallback) {
    return this.fields
      .filter(field => (!limitTo || !limitTo.length || limitTo.indexOf(field.key) > -1))
      .map(f => f.getFormFieldWithCallback(searchCallback));
  }
}
