import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { usePusher } from './PusherContext';
import UserPicture from '../shared/UserPicture';

/**
 * Render a single user avatar
 * @param member.id {string} Of channel.members.members (where channel is a Pusher channel object)
 * @param member.info {object}
 * @param member.info.name {string}
 * @param member.info.email {string}
 * @return {UserPicture}
 */
const Member = ({ member }) => {
  return (<UserPicture className="avatar" email={member.info.email} title={`${member.info.name} is also viewing this page`}/>);
};

Member.propTypes = {
  member: PropTypes.object.isRequired
};

/**
 * Render a list of other user avatars (excluding the current user)
 * @param members channel.members (where channel is a Pusher channel object)
 * @return {React.Fragment}
 */
const Members = ({ members }) => {
  // Convert members object into a list with "me" first
  const list = [];
  Object.entries(members.members).forEach(([id, info]) => {
    if (id !== members.me.id.toString())
      list.push({ id: id, info: info });
  });
  return <>{list.map(item => <Member key={item.id} member={item}/>)}</>;
};

Members.propTypes = {
  members: PropTypes.object.isRequired
};

/**
 * Render list of other users subscribed to the same channel, and adds the current user to the list
 *
 * @param channelName {string} Unprefixed channel name, e.g. 'task-10'
 * @param props To be added to the Members component
 * @return {Members}
 */
const PresenceList = ({ channelName, ...props }) => {

  const { loading: pusherLoading, pusher } = usePusher();
  const [members, setMembers] = useState([]);
  const channel = useRef(null);

  useEffect(() => {
    if (!pusher || !channel) {
      return;
    }
    const anyEvent = () => {
      setMembers(channel && channel.current && channel.current.members);
    };
    if (channel && channelName && !pusherLoading) {
      const prefixedChannelName = `presence-${channelName}`;
      channel.current = pusher.subscribe(prefixedChannelName);
      channel.current.bind('pusher:subscription_succeeded', anyEvent);
      channel.current.bind('pusher:member_added', anyEvent);
      channel.current.bind('pusher:member_removed', anyEvent);
    }
    return () => {
      // Unbind and unsubscribe channel to prevent state changes on unmounted component
      if (channel && channel.current && channel.current.subscribed) {
        channel.current.unbind();
        channel.current.unsubscribe();
      }
    };
  }, [channelName, pusher, pusherLoading]);

  return !!members && members.count > 0 && <Members members={members} {...props} />;
};

PresenceList.propTypes = {
  channelName: PropTypes.string.isRequired
};

export default PresenceList;
