import React, {useLayoutEffect, useState} from "react";

export function CookiePopup() {

  const [isActive, setIsActive] = useState(false);

  useLayoutEffect(() => {
    if (!isAgreed()) {
      setTimeout(() => {
        setIsActive(true);
      }, 1000);
    }
  }, []);

  const isAgreed = () => document.cookie.indexOf("cookieConsentPresent=agreed") > -1;

  const agree = () => {
    const cookieExpiry = new Date((new Date().getTime()) + (3600 * 24 * 1000 * 365));
    document.cookie = 'cookieConsentPresent=agreed; Path=/; expires=' + cookieExpiry.toUTCString();
    setIsActive(false);
  };

  return (<>
    {isActive && <div className="cookie-popup bg-primary text-white">
      <small>We use cookies to ensure that we give you the best experience on our website. If you continue, we'll assume that you are happy to receive all cookies on our website.</small>
      <div className="d-flex justify-content-start mt-2">
        <button type="button" className="btn btn-light btn-sm cookies-accept mr-2" onClick={() => agree()}>
          Accept cookies
        </button>
        <button type="button" className="btn btn-outline-light btn-sm cookies-close" onClick={() => setIsActive(false)}>
          Close
        </button>
      </div>
    </div>}
  </>);
}
