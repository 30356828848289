import React, { useState } from 'react';
import {generatePath} from "react-router";
import {Link} from 'react-router-dom';
import ModelTableWithRouterState from '../models/ModelTableWithRouterState';
import {TaskStatusBadge} from './TaskStatusBadge';
import { Page, PageNav } from '../shared/Page';
import { toUkDateTimeString } from '../search/MemorableDate';
import { useIntl } from 'react-intl';
import {CaseSummarySpan} from "../case/summary/CaseSummarySpan";

const sp = e => e.stopPropagation();

export const MyAssignedTasksListPage = (props) => {
  const intl = useIntl();
  const apiEndpoint = '/api/profile/me/tasks-assigned';
  const [timestamp] = useState(new Date().getTime());
  const additionalQueryParams = { t: timestamp };
  const columns = [{
    label: "Task",
    sortBy: 'title',
    fromModel: (item) => item.title
  }, {
    label: intl.formatMessage({id: 'model.task.status'}),
    sortBy: 'status',
    fromModel: (item) => <TaskStatusBadge status={item.status}/>
  }, {
    label: "Case",
    sortBy: 'case',
    fromModel: (item) => <Link tabIndex={-1} to={`/cases/show/${item.case.id}`} onClick={sp}><CaseSummarySpan model={item.case}/></Link>
  }, {
    label: intl.formatMessage({id: 'model.task.updatedAt'}),
    sortBy: 'updatedAt',
    fromModel: (item) => toUkDateTimeString(item.updatedAt)
  }];

  return (<Page>
    <PageNav title="Tasks assigned to you" translate={false} />
    <ModelTableWithRouterState
      caption="Tasks assigned to you"
      apiEndpoint={apiEndpoint}
      notFoundMessage={"No tasks found"}
      columns={columns}
      additionalQueryParams={additionalQueryParams}
      itemHref={(item) => generatePath(`/tasks/show/${item.id}`)}
      {...props}/>
  </Page>);
};

export const MyWatchedTasksListPage = (props) => {
  const intl = useIntl();
  const apiEndpoint = '/api/profile/me/tasks-watched';
  const [timestamp] = useState(new Date().getTime());
  const additionalQueryParams = { t: timestamp };
  const columns = [{
    label: "Task",
    sortBy: 'title',
    fromModel: (item) => item.title
  }, {
    label: intl.formatMessage({id: 'model.task.status'}),
    sortBy: 'status',
    fromModel: (item) => <TaskStatusBadge status={item.status}/>
  }, {
    label: "Case",
    sortBy: 'case',
    fromModel: (item) => <Link tabIndex={-1} to={`/cases/show/${item.case.id}`} onClick={sp}><CaseSummarySpan model={item.case}/></Link>
  }, {
    label: intl.formatMessage({id: 'model.task.updatedAt'}),
    sortBy: 'updatedAt',
    fromModel: (item) => toUkDateTimeString(item.updatedAt)
  }];

  return (<Page>
    <PageNav title="Tasks watched by you" translate={false}/>
    <ModelTableWithRouterState
      caption="Tasks watched by you"
      apiEndpoint={apiEndpoint}
      notFoundMessage={'No tasks found'}
      columns={columns}
      additionalQueryParams={additionalQueryParams}
      itemHref={(item) => generatePath(`/tasks/show/${item.id}`)}
      {...props}/>
  </Page>);
};
