import { generateAndSavePdf } from '../docs/SchemaDocumentPdf';
import { toast } from 'react-toastify';
import { Download, FilePdf } from '../main/icons';
import { useCallback, useState } from 'react';
import axios from 'axios';
import { rulesRunner } from 'rjsf-conditionals';
import Engine from 'json-rules-engine-simplified';
import { parseAxiosError } from '../shared/AxiosResponseErrorParser';
import { toUkDateString, toUkDateTimeString } from '../search/MemorableDate';
import { TaskStatus } from '../task/TaskStatusBadge';
import PropTypes from 'prop-types';
import { useAppConfiguration } from '../shared/contexts/AppConfiguration';
import {
  caseConfigurationVersion,
  caseOrTaskMessage,
  caseStatusFromId,
  compileFormRunnables,
  UiMessageKey
} from '../shared/types';
import { evaluateFormHandler } from '../shared/functions/FormHandlers';
import _ from 'lodash';

export const DownloadPdfButton = ({
  className = 'btn btn-sm btn-outline-secondary',
  databaseName,
  taskRef,
  taskVersion,
  formValues,
  caseId,
  taskId
}) => {
  const appConfig = useAppConfiguration();
  const [loading, setLoading] = useState(false);

  // compile any form runnables (such as actions, handlers etc...)
  // and apply them prior to generating the PDF
  const applyFormRunnables = async (taskConfiguration, values) => {
    const runnables = compileFormRunnables(taskConfiguration);
    let schema = taskConfiguration.form.schema
      ? _.cloneDeep(taskConfiguration.form.schema)
      : {};
    let uiSchema = taskConfiguration.form.uiSchema
      ? _.cloneDeep(taskConfiguration.form.uiSchema)
      : {};
    let rules = taskConfiguration.form.rules
      ? _.cloneDeep(taskConfiguration.form.rules)
      : [];

    // if we have a form handler, evaluate it
    if (runnables[1]) {
      const evaluationResult = evaluateFormHandler(
        taskConfiguration,
        schema,
        uiSchema,
        rules,
        values,
        'generatePdf',
        {}
      );

      // if the handler has updated the data in the form, replace the original data passed into
      // the PDF generation component
      if (_.has(evaluationResult, 'formData')) {
        values = evaluationResult.formData;
      }
      if (_.has(evaluationResult, 'schema')) {
        schema = evaluationResult.schema;
      }
      if (_.has(evaluationResult, 'rules')) {
        rules = evaluationResult.rules;
      }
    }

    // deal with the historical rules processing logic
    const extraActions = runnables[0] || {};
    const runRules = rulesRunner(schema, uiSchema, rules, Engine, extraActions);

    // run the old style rules which may result in a change to schema
    if (rules && rules.length > 0) {
      return runRules(values).then(({ schema, formData }) => ({
        schema: schema,
        values: formData
      }));
    } else {
      return Promise.resolve({
        schema: schema,
        values: values
      });
    }
  };

  const clickHandler = useCallback(
    (formData) => {
      if (loading || !taskRef) return;
      setLoading(true);

      const apiUrl = databaseName
        ? `/api/case/external/${databaseName}/form/${taskRef}/${taskVersion}`
        : `/api/form/${taskRef}/${taskVersion}`;

      return axios
        .get(apiUrl)
        .catch((error) => {
          toast.error(
            parseAxiosError(
              error,
              'Failed to get relevant PDF details'
            ).getMessage()
          );
          throw error;
        })
        .then((formResp) => {
          return applyFormRunnables(formResp.data.data, formData).catch(
            (error) => {
              toast.error('Failed to process PDF details');
              throw error;
            }
          );
        })
        .then(({ schema, values }) => {
          if (!taskId) {
            return { schema, values };
          } else {
            return axios
              .get(`/api/task/${taskId}`)
              .then((taskResp) => {
                const taskResponse = taskResp.data.data;
                const parsedTaskStatus = TaskStatus[taskResponse.status] || {
                  title: taskResponse.status
                };
                const taskDetailsForPdf = {
                  taskAssignee: taskResponse.assignee.displayName,
                  taskStatus:
                    parsedTaskStatus.title +
                    ' (' +
                    parsedTaskStatus.description +
                    ')',
                  taskCreatedAt: toUkDateTimeString(taskResponse.createdAt),
                  taskUpdatedAt: toUkDateTimeString(taskResponse.updatedAt)
                };
                const taskDetailsForPdfSchema = {
                  type: 'object',
                  title: 'Task',
                  properties: {
                    taskAssignee: {
                      title: 'Task Assignee',
                      type: 'string'
                    },
                    taskStatus: {
                      title: 'Task Status',
                      type: 'string'
                    },
                    taskCreatedAt: {
                      title: 'Task Date',
                      type: 'string'
                    },
                    taskUpdatedAt: {
                      title: 'Task Last Updated',
                      type: 'string'
                    }
                  }
                };
                return {
                  schema: Object.assign({}, schema, {
                    properties: {
                      taskDetails: taskDetailsForPdfSchema,
                      ...schema.properties
                    }
                  }),
                  values: Object.assign({}, values, {
                    taskDetails: taskDetailsForPdf
                  })
                };
              })
              .catch((error) => {
                toast.error(
                  parseAxiosError(
                    error,
                    'Failed to get additional PDF details'
                  ).getMessage()
                );
                throw error;
              });
          }
        })
        .then(({ schema, values }) => {
          if (!caseId) {
            return { schema, values };
          } else {
            return axios
              .get(`/api/case/${caseId}`)
              .then((caseResp) => {
                const caseData = caseResp.data.data;
                const caseConfig = caseConfigurationVersion(
                  appConfig,
                  caseData.caseRef,
                  caseData.caseVersion
                );
                const caseDetailsForPdf = {
                  caseId: caseData.id,
                  caseDisplayName: caseData.displayName,
                  creator: caseData.creator.displayName,
                  status: caseStatusFromId(
                    caseData.status,
                    caseConfig.meta.statuses
                  ).label,
                  createdAt: toUkDateString(caseData.createdAt),
                  ...caseData.details
                };
                const caseDetailsForPdfSchema = {
                  ...caseConfig.form.schema,
                  properties: {
                    caseId: {
                      title: 'Case Id',
                      type: 'string'
                    },
                    caseDisplayName: {
                      title: 'Case Display Name',
                      type: 'string'
                    },
                    creator: {
                      title: `${caseOrTaskMessage(caseConfig, UiMessageKey.TitleSingular)} Creator`,
                      type: 'string'
                    },
                    status: {
                      title: `${caseOrTaskMessage(caseConfig, UiMessageKey.TitleSingular)} Status`,
                      type: 'string'
                    },
                    createdAt: {
                      title: `${caseOrTaskMessage(caseConfig, UiMessageKey.TitleSingular)} Date`,
                      type: 'string'
                    },
                    ...caseConfig.form.schema.properties
                  }
                };
                return {
                  schema: Object.assign({}, schema, {
                    properties: {
                      caseDetails: caseDetailsForPdfSchema,
                      ...schema.properties
                    }
                  }),
                  values: Object.assign({}, values, {
                    caseDetails: caseDetailsForPdf
                  })
                };
              })
              .catch((error) => {
                toast.error(
                  parseAxiosError(
                    error,
                    'Failed to get additional PDF details'
                  ).getMessage()
                );
                throw error;
              });
          }
        })

        .then(({ schema, values }) => {
          return generateAndSavePdf(schema, values, (message) =>
            toast.error(message)
          );
        })
        .then(() => setLoading(false))
        .catch((error) => {
          toast.error('Failed to create and save PDF');
          setLoading(false);
        });
    },
    [caseId, taskId, databaseName, taskRef, taskVersion, loading, appConfig]
  );

  return (
    <button
      data-testid="download-pdf"
      disabled={loading}
      className={className}
      onClick={() => clickHandler(formValues)}
    >
      <FilePdf height={14} />
      <span className="mx-2">Download PDF</span>
      <Download height={14} className="svg-gradient-secondary" />
    </button>
  );
};

DownloadPdfButton.propTypes = {
  taskRef: PropTypes.string.isRequired,
  taskVersion: PropTypes.number.isRequired,
  formValues: PropTypes.object.isRequired,
  caseId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  taskId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string
};
