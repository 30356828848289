import moment from 'moment';

/**
 * The standard ISO date format
 */
export const STANDARD_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss.sssZ';

/**
 * Short UK-based date format
 */
export const SHORT_DATE_FORMAT = 'dd/MM/yyyy';

/**
 * Simple date range type
 */
export type DateRange = {
  start: Date;
  end: Date;
};

/**
 * Enumeration of standard date ranges
 */
export type StandardDateRangeLabel = 'Last 7 Days' | 'Last Year' | 'Last Month' | 'Last Six Months';

/**
 * Function that generates a date range
 */
export type DateRangeProducerFunction = () => DateRange;

/**
 * Map a standard date range label to a function that can produce the associated date range
 */
export type StandardDateRange = {
  label: StandardDateRangeLabel;
  func: DateRangeProducerFunction;
};

export function computeStandardDateRange(
  standardRange: StandardDateRangeLabel
): DateRange {
  switch (standardRange) {
    case 'Last 7 Days':
      return {
        start: moment().subtract(1, 'weeks').startOf('day').toDate(),
        end: moment().startOf('day').toDate()
      };
    case 'Last Month':
      return {
        start: moment().subtract(1,'months').startOf('day').toDate(),
        end: moment().startOf('day').toDate()
      };
    case 'Last Six Months':
      return {
        start: moment().subtract(6, 'months').startOf('day').toDate(),
        end: moment().startOf('day').toDate()
      };
    case 'Last Year':
      return {
        start: moment().subtract(1, 'years').startOf('day').toDate(),
        end: moment().startOf('day').toDate()
      };
  }
}

/**
 * Given two strings representing a start and end date for a range, compare them (lexicographically) to each standard date
 * range in turn. If we get a match, then return that range
 * @param startDate
 * @param endDate
 */
export function matchStandardDateRange(startDate: string, endDate: string) : DateRange | undefined {
  const range : DateRange = {
    start: moment(startDate).toDate(),
    end: moment(endDate).toDate()
  };
  const rangeRepr = JSON.stringify(range);
  let tmp = JSON.stringify(computeStandardDateRange('Last 7 Days'));
  if (tmp === rangeRepr){
    return computeStandardDateRange('Last 7 Days');
  }
  if (JSON.stringify(computeStandardDateRange('Last Month')) === rangeRepr){
    return computeStandardDateRange('Last Month');
  }
  if (JSON.stringify(computeStandardDateRange('Last Six Months')) === rangeRepr){
    return computeStandardDateRange('Last Six Months');
  }
  if (JSON.stringify(computeStandardDateRange('Last Year')) === rangeRepr){
    return computeStandardDateRange('Last Year');
  }
  return undefined;
}

/**
 * List of standard date ranges
 */
export const StandardDateRanges: StandardDateRange[] = [
  {
    label: 'Last 7 Days',
    func: () => {
      return computeStandardDateRange('Last 7 Days');
    }
  },
  {
    label: 'Last Month',
    func: () => {
      return computeStandardDateRange('Last Month');
    }
  },
  {
    label: 'Last Six Months',
    func: () => {
      return computeStandardDateRange('Last Six Months');
    }
  },
  {
    label: 'Last Year',
    func: () => {
      return computeStandardDateRange('Last Year');
    }
  }
];
