import React, { FC, useEffect, useState } from 'react';
import { Page, PageNav } from '../shared/Page';
import { WithPermission } from '../auth/AccessConditionals';
import { useAppConfiguration } from '../shared/contexts/AppConfiguration';
import { CaseConfiguration, caseOrTaskMessage, TaskConfiguration, UiMessageKey } from '../shared/types';
import { ExportWidget } from './ExportWidget';

/**
 * Props for the export page
 */
export interface ExportsPageProps {}

/**
 * Page component that lists all the available exports
 * @constructor
 */
export const ExportsPage: FC<ExportsPageProps> = () => {
  const appConfig = useAppConfiguration();
  const [caseConfigs, setCaseConfigs] = useState<CaseConfiguration[]>();
  const [taskConfigs, setTaskConfigs] = useState<TaskConfiguration[]>();

  // load the currently configured case and task configurations
  useEffect(() => {
    if (appConfig.loaded) {
      setCaseConfigs(appConfig.caseConfigurations.sort((a, b) => {
        let titleA = caseOrTaskMessage(a, UiMessageKey.TitlePlural);
        let titleB = caseOrTaskMessage(b, UiMessageKey.TitlePlural);
        if (titleA === '!undefined!') titleA = 'zzzz';
        if (titleB === '!undefined!') titleB = 'zzzz';
        return titleA.localeCompare(titleB);
      }));
      setTaskConfigs(appConfig.taskConfigurations.sort((a, b) => {
        let titleA = caseOrTaskMessage(a, UiMessageKey.TitlePlural);
        let titleB = caseOrTaskMessage(b, UiMessageKey.TitlePlural);
        if (titleA === '!undefined!') titleA = 'zzzz';
        if (titleB === '!undefined!') titleB = 'zzzz';
        return titleA.localeCompare(titleB);
      }));
    }
  }, [appConfig]);

  return (
    <Page className={undefined}>
      <PageNav title="Raw Exports" children={undefined} translate={false} />
      <div className="row">
        <WithPermission name="case:read">
          <div className="col-sm-12">
            <div className="card mb-4">
              <div className="card-body">
                <h2>Export Raw Case Data</h2>
                <hr />
                <ExportWidget exportTarget={'Case'} targetConfig={undefined} />
                {caseConfigs &&
                  caseConfigs.map((c) => (
                    <ExportWidget
                      key={c.ref}
                      exportTarget={'Case'}
                      targetConfig={c}
                    />
                  ))}
              </div>
            </div>
          </div>
        </WithPermission>
        <WithPermission name="task:read">
          <div className="col-sm-12">
            <div className="card mb-4">
              <div className="card-body">
                <h2>Export Raw Task Data</h2>
                <hr />
                <ExportWidget exportTarget={'Task'} targetConfig={undefined} />
                {taskConfigs &&
                  taskConfigs.map((c) => (
                    <ExportWidget
                      key={c.ref}
                      exportTarget={'Task'}
                      targetConfig={c}
                    />
                  ))}
              </div>
            </div>
          </div>
        </WithPermission>
      </div>
    </Page>
  );
};
