import React from 'react';
import { NotificationPreferencesForm } from './Profile';
import ModelInstanceEditView from '../models/ModelInstanceEditView';
import { useAuthContext } from '../auth/AuthContextProvider';
import { Page, PageNav } from '../shared/Page';

const EditMyPreferencesPage = (props) => {
  const { loading } = useAuthContext();
  return (<Page>
    <PageNav title="nav.profiles.preferences"/>
    {!loading && <ModelInstanceEditView
      viewTitle={'Notification Preferences'}
      apiUrl={'/api/profile/me/preferences'}
      schemaFormComponent={NotificationPreferencesForm}
      pathAfterSuccess={'/profile'}
      {...props}/>}
  </Page>);
};

export { EditMyPreferencesPage };
