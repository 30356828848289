import {useIntl} from "react-intl";
import React, {useState} from "react";
import {TaskStatusBadge} from "../task/TaskStatusBadge";
import {CasualTime} from "../shared/Dates";
import {ProfileLink} from "../profile/Profile";
import {Link} from "react-router-dom";
import {DownloadPdfButton} from "../form/DownloadPdfButton";
import TaskStatusChangeModal from "../task/TaskStatusChangeModal";
import TaskAssigneeChangeFlowModal from "../task/TaskAssigneeChangeFlowModal";

export const CaseTaskListItem = ({ model: task, caseState: state, reload }) => {
  const intl = useIntl();
  const [showChangeTaskStatusModal, setShowChangeTaskStatusModal] =
    useState(false);
  const [showTaskAssigneeChangeModal, setShowTaskAssigneeChangeModal] =
    useState(false);

  return (
    <div key={task.id}>
      <div className="d-flex justify-content-between">
        <h5>{task.displayName}</h5>
        {task.status && (
          <div>
            <TaskStatusBadge status={task.status} />
          </div>
        )}
      </div>
      <p>
        <span>Started </span>
        <CasualTime date={task.createdAt} />
        <span>, currently assigned to </span>
        <ProfileLink id={task.assignee.id} label={task.assignee.displayName} />
      </p>
      <div>
        <Link
          className="btn btn-sm btn-outline-primary mr-1"
          to={`/tasks/show/${task.id}`}
          key={`task${task.id}`}
        >
          More details
        </Link>
        {state.editable && (
          <>
            <button
              onClick={() => setShowChangeTaskStatusModal(true)}
              className="btn btn-sm btn-outline-secondary mr-1"
            >
              Change status
            </button>
            <button
              onClick={() => setShowTaskAssigneeChangeModal(true)}
              className="btn btn-sm btn-outline-secondary mr-1"
            >
              Reassign
            </button>
            <DownloadPdfButton
              className="btn btn-sm btn-outline-secondary mr-1"
              taskRef={task.taskRef}
              taskVersion={task.taskVersion}
              formValues={task.formModelJson}
              caseId={task.case.id}
              taskId={task.id}
            />
          </>
        )}
      </div>

      {showChangeTaskStatusModal && (
        <TaskStatusChangeModal
          modalTitle={intl.formatMessage({ id: 'nav.tasks.status.edit' })}
          taskId={task.id}
          onModalClose={(value) => {
            if (value) reload(new Date());
            setShowChangeTaskStatusModal(false);
          }}
        />
      )}

      {showTaskAssigneeChangeModal && (
        <TaskAssigneeChangeFlowModal
          modalTitle={intl.formatMessage({ id: 'nav.tasks.assignee.edit' })}
          taskId={task.id}
          onModalClose={(value) => {
            if (value) reload(new Date());
            setShowTaskAssigneeChangeModal(false);
          }}
        />
      )}
    </div>
  );
};
