import React, { useEffect, useState } from 'react';
import { generatePath } from 'react-router';
import { Link } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import ModelTableWithRouterState from '../models/ModelTableWithRouterState';
import { TaskStatus, TaskStatusBadge } from './TaskStatusBadge';
import { Page, PageNav } from '../shared/Page';
import { CasualTime } from '../shared/Dates';
import { getURLSearchParams } from '../main/RouterHistory';
import { QueryParameter } from '../models/QueryParameter';
import { SearchFormHorizontal } from '../search/SearchFormHorizontal';
import { InputSize } from '../search/InputSize';
import { SelectDateRangeForUrl } from '../search/SelectDateRange';
import { InputTextForUrlParam } from '../search/InputText';
import { SelectFromListForUrl } from '../search/SelectFromList';
import { useIntl } from 'react-intl';
import { ProfileLink } from '../profile/Profile';
import { useAppConfiguration } from '../shared/contexts/AppConfiguration';
import { caseOrTaskMessageElse, UiMessageKey } from '../shared/types';
import { capitalCase } from 'text-case';
import {CaseSummarySpan} from "../case/summary/CaseSummarySpan";

const TasksListPage = (props) => {
  const intl = useIntl();
  const apiEndpoint = '/api/task';
  const appConfig = useAppConfiguration();
  const [taskTypeValues, setTaskTypeValues] = useState([]);
  const [taskTypeLabels, setTaskTypeLabels] = useState([]);
  const sp = (e) => e.stopPropagation();

  useEffect(() => {
    if (appConfig.loaded) {
      setTaskTypeValues(
        appConfig.taskConfigurations.map(
          (task) =>
            `${task.ref}|${caseOrTaskMessageElse(
              task,
              UiMessageKey.Verb,
              (config) => config.form.schema.title,
              capitalCase
            )}`
        )
      );
      setTaskTypeLabels(
        appConfig.taskConfigurations.map(
          (task) =>
            `${caseOrTaskMessageElse(
              task,
              UiMessageKey.Verb,
              (config) => config.form.schema.title,
              capitalCase
            )}`
        )
      );
    }
  }, [appConfig]);

  const columns = [
    {
      label: "Task",
      sortBy: 'title',
      fromModel: (item) => item.title
    },
    {
      label: intl.formatMessage({ id: 'model.task.status' }),
      sortBy: 'status',
      fromModel: (item) => <TaskStatusBadge status={item.status} />
    },
    {
      label: "Case",
      sortBy: 'caseId',
      fromModel: (item) => (
        <Link tabIndex={-1} to={`/cases/show/${item.caseId}`} onClick={sp}>
          <CaseSummarySpan model={item} />
        </Link>
      )
    },
    {
      label: intl.formatMessage({ id: 'model.task.assignee' }),
      sortBy: 'assigneeName',
      fromModel: (item) => (
        <ProfileLink id={item.assigneeId} label={item.assigneeName} />
      )
    },
    {
      label: 'Has login?',
      sortBy: 'assigneeLogin',
      fromModel: (item) => (
        <OverlayTrigger
          placement="left"
          overlay={
            <Tooltip id="assigneeLogin">
              {item.assigneeLogin
                ? 'Signed up and can login'
                : 'Is not signed up but could send replies to email notifications'}
            </Tooltip>
          }
        >
          <span>{item.assigneeLogin ? 'Yes' : 'No'}</span>
        </OverlayTrigger>
      )
    },
    {
      label: 'Added',
      sortBy: 'createdAt',
      fromModel: (item) => <CasualTime date={item.createdAt} />
    },
    {
      label: 'Last change',
      sortBy: 'updatedAt',
      fromModel: (item) => <CasualTime date={item.updatedAt} />
    }
  ];

  const urlQueryParams = getURLSearchParams();
  const additionalQueryParams = {};
  [
    QueryParameter.taskQuery.key,
    QueryParameter.assigneeId.key,
    QueryParameter.assigneeName.key,
    QueryParameter.taskRef.key,
    QueryParameter.taskStatus.key,
    QueryParameter.taskChangedBefore.key,
    QueryParameter.taskChangedAfter.key
  ].forEach((k) => (additionalQueryParams[k] = urlQueryParams.get(k)));

  const taskStatusKeys = Object.keys(TaskStatus);

  const taskStatusValues = taskStatusKeys.map(
    (status) => `${status}|${TaskStatus[status].title}`
  );
  const taskStatusLabels = taskStatusKeys.map(
    (status) =>
      `${TaskStatus[status].title} - ${TaskStatus[status].description}`
  );

  return (
    <Page>
      <PageNav title="All tasks" translate={false} />
      <div className="mb-4">
        <SearchFormHorizontal>
          <InputTextForUrlParam
            size={InputSize.sm}
            placeholder={QueryParameter.taskQuery.label}
            parameterKey={QueryParameter.taskQuery.key}
            className="mr-sm-3 mb-1"
          />
          <InputTextForUrlParam
            size={InputSize.sm}
            placeholder={QueryParameter.assigneeName.label}
            parameterKey={QueryParameter.assigneeName.key}
            className="mr-sm-3 mb-1"
          />
          {taskStatusLabels.length > 0  && (
            <SelectFromListForUrl
              key={QueryParameter.taskRef.key}
              size={InputSize.sm}
              className="narrow mr-sm-3 mb-1"
              labels={taskTypeLabels}
              values={taskTypeValues}
              addEmptyValue={true}
              parameterKey={QueryParameter.taskRef.key}
              parameterLabel={QueryParameter.taskRef.label}
            />
          )}
          <SelectFromListForUrl
            key={QueryParameter.taskStatus.key}
            size={InputSize.sm}
            className="narrow mr-sm-3 mb-1"
            labels={taskStatusLabels}
            values={taskStatusValues}
            addEmptyValue={true}
            parameterKey={QueryParameter.taskStatus.key}
            parameterLabel={QueryParameter.taskStatus.label}
          />
          <SelectDateRangeForUrl
            size={InputSize.sm}
            className="mr-sm-3 mb-1"
            minDate={new Date(2000, 0, 1)}
            maxDate={new Date()}
            afterKey={QueryParameter.taskChangedAfter.key}
            afterLabel={QueryParameter.taskChangedAfter.label}
            beforeKey={QueryParameter.taskChangedBefore.key}
            beforeLabel={QueryParameter.taskChangedBefore.label}
          />
        </SearchFormHorizontal>
      </div>
      <ModelTableWithRouterState
        caption="nav.tasks.all"
        apiEndpoint={apiEndpoint}
        notFoundMessage={intl.formatMessage({ id: 'nav.tasks.notfound' })}
        additionalQueryParams={additionalQueryParams}
        columns={columns}
        itemHref={(item) => generatePath(`/tasks/show/${item.id}`)}
        canExportData={true}
        {...props}
      />
    </Page>
  );
};

export default TasksListPage;
