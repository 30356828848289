import React, { useState } from 'react';
import axios from 'axios';
import { parseAxiosError } from '../shared/AxiosResponseErrorParser';
import RouterHistory from '../main/RouterHistory';
import { Page, PageNav } from '../shared/Page';
import ModelInstanceView from '../models/ModelInstanceView';
import FormStep from '../flows/core/FormStep';
import FlowContainer, { CssColClassesFlow } from '../flows/core/FlowContainer';
import SelectItemsStep from '../flows/core/SelectItemsStep';
import { FormForSchema } from '@lookinglocal/react-jsonschema-form-extensions';
import * as AppWidgets from '../form/widgets/AppWidgets';

const CloneCaseFlow = ({ model }) => {
  const SUBMISSION = {
    PENDING: 'PENDING',
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR'
  };
  const [errorMessage, setErrorMessage] = useState(null);
  const [submissionState, setSubmissionState] = useState(SUBMISSION.PENDING);

  function onCancel() {
    RouterHistory.goBack();
  }

  function onSubmit(flowData) {
    return axios
      .post(`/api/case/external/clone`, {
        source: model.source,
        id: model.id,
        caseRef: model.caseRef,
        caseVersion: model.caseVersion,
        details: model.details,
        tasks: flowData[1] || []
      })
      .then((response) => {
        setSubmissionState(SUBMISSION.SUCCESS);
        if (response.data && response.data.data && response.data.data.id) {
          RouterHistory.push(`/cases/show/${response.data.data.id}`);
        } else {
          RouterHistory.goBack();
        }
      })
      .catch((e) => {
        setErrorMessage(
          parseAxiosError(
            e,
            'Failed to retrieve results from server'
          ).getMessage()
        );
        setSubmissionState(SUBMISSION.ERROR);
      });
  }

  const schema = {
    type: 'object',
    properties: {
      creator: {
        title: 'Creator',
        description: `Will be changed to you`,
        type: 'null'
      }
    }
  };

  return (
    <div className="row">
      <div className={CssColClassesFlow}>
        {submissionState === SUBMISSION.SUCCESS && (
          <p className="alert alert-success">Details successfully submitted</p>
        )}
        {submissionState === SUBMISSION.ERROR && (
          <p className="alert alert-danger">{errorMessage}</p>
        )}
      </div>
      {submissionState !== SUBMISSION.SUCCESS && (
        <FlowContainer onFinalSubmit={onSubmit} onCancel={onCancel}>
          <FormStep
            key={'one'}
            title="Details"
            schemaFormComponent={(props) => (
              <FormForSchema
                schema={schema}
                {...props}
                appWidgets={AppWidgets.appWidgets}
              />
            )}
          />
          <SelectItemsStep
            key={'two'}
            title="Tasks to copy"
            items={model.tasks}
            required={false}
          />
        </FlowContainer>
      )}
    </div>
  );
};

export const CaseExternalCloneFlowPage = (props) => {
  const { caseId, databaseName } = props.match.params;
  return (
    <Page>
      <PageNav title="nav.cases.external.clone" />
      <ModelInstanceView
        apiUrl={`/api/case/external/${databaseName}/${caseId}`}
        viewItem={CloneCaseFlow}
        {...props}
      />
    </Page>
  );
};
