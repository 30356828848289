import React, { useState } from 'react';
import { WithPermission } from '../auth/AccessConditionals';
import { Page, PageNav } from '../shared/Page';
import { LoginStatsCard } from '../charts/loginStats';
import {FormattedMessage, useIntl} from 'react-intl';
import RouterHistory from '../main/RouterHistory';
import { CardWidget } from '../shared/CardWidget';
import CreateNewProfileFlowModal from './CreateNewProfileFlowModal';
import {Link} from "react-router-dom";
import {AngleRight} from "../main/icons";

const ProfilesIntro = () => {

  const intl = useIntl();
  const [showNewProfileModal, setShowNewProfileModal] = useState(false);

  function onNewProfileModalClose (value) {
    setShowNewProfileModal(false);
    if (value) RouterHistory.push('/profiles/list');
  }

  return (<Page>
    <PageNav title="nav.profiles" />
    <div className="row">
      <div className="col-md-6">
        <LoginStatsCard />
      </div>
      <div className="col-md-6">
        <CardWidget
          title={intl.formatMessage({id: "nav.profiles.all"})}
          description={intl.formatMessage({id: "nav.profiles.all.description"})}
          navItems={<Link aria-label={intl.formatMessage({id: "nav.profiles.all"})} className="btn btn-sm btn-outline-secondary" to={"/profiles/list"}>
            <FormattedMessage id="nav.profiles.all" /> <AngleRight className="svg-gradient-primary" height={15} />
          </Link>} />

        <WithPermission name="profile:create">
          <CardWidget
            title={intl.formatMessage({id: "nav.profiles.add"})}
            description={intl.formatMessage({id: "nav.profiles.add.description"})}
            navItems={<button onClick={() => setShowNewProfileModal(true)} className="btn btn-sm btn-outline-secondary">
              <FormattedMessage id="nav.profiles.add" /> <AngleRight className="svg-gradient-primary" height={15} />
            </button>} />
        </WithPermission>
      </div>
    </div>

    {showNewProfileModal && <CreateNewProfileFlowModal
      modalTitle={intl.formatMessage({id: "nav.profiles.add"})}
      onModalClose={onNewProfileModalClose}
    />}

  </Page>);
};

export default ProfilesIntro;
