module.exports = {
  caseLabels: [],
  caseSearchStatusesDefault: ['IN_PROGRESS','VOID','REJECTED','PARTIAL','APPROVED','SIGNED_OFF'],
  showCaseDetailsInList: ["department", "projectName", "projectDescription"],
  searchCaseDetailsInModal: ["department", "projectName"],
  components: require('./components'),
  messages: require('./messages'),
  releaseNotes: require('./releaseNotes'),
  userGuide: "https://coalescent4204.zendesk.com/hc/en-us"
};
