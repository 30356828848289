import React from 'react';
import { Page, PageNav } from '../shared/Page';
import ModelTableWithRouterState from '../models/ModelTableWithRouterState';
import { ActivitySearchResult } from '../activity/ActivitySearchResult';
import { toUkDateTimeString } from '../search/MemorableDate';

const CaseShowHistoryPage = (props) => {
  const { caseId } = props.match.params;

  const columns = [{
    label: 'Item',
    fromModel: (item) => new ActivitySearchResult(item).link()
  }, {
    label: 'Version',
    sortBy: 'entityVersion',
    fromModel: (item) => item.entityVersion
  }, {
    label: 'By',
    sortBy: 'profileName',
    fromModel: (item) => new ActivitySearchResult(item).profile()
  }, {
    label: 'Status',
    fromModel: (item) => new ActivitySearchResult(item).status()
  }, {
    label: 'Time',
    sortBy: 'createdAt',
    fromModel: (item) => toUkDateTimeString(item.createdAt)
  }];

  const additionalQueryParams = {
    eventType: ['SAVE', 'CREATE'].join(",")
  };

  return (<Page>
    <PageNav title="Case History" translate={false} />
    <ModelTableWithRouterState caption="nav.cases.history" apiEndpoint={`/api/case/${caseId}/history`} additionalQueryParams={additionalQueryParams} columns={columns} {...props}/>
  </Page>);
};

export default CaseShowHistoryPage;
