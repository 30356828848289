import React, { useEffect, useState } from 'react';
import { WithPermission } from '../auth/AccessConditionals';
import { Page, PageNav } from '../shared/Page';
import { CardWidget } from '../shared/CardWidget';
import { useParams } from 'react-router-dom';
import {
  caseOrTaskMessage,
  haveCaseOrTaskMessage,
  latestCaseConfiguration,
  UiMessageKey
} from '../shared/types';
import { useAppConfiguration } from '../shared/contexts/AppConfiguration';
import Spinner from 'react-bootstrap/Spinner';
import { CasesCreatedDonutChart } from '../charts/CasesCreatedDonutChart';
import { CasesCreatedPerDayLineChart } from '../charts/CasesPerDayLineChart';
import { QueryParameter } from '../models/QueryParameter';
import { useAuthContext } from '../auth/AuthContextProvider';
import { CasesDueForReviewDonutChart } from '../charts/CasesDueForReviewDonutChart';
import { AgeOfUnresolvedCasesStackedBarChart } from '../charts/AgeOfUnresolvedCasesStackedBarChart';
import { newUUID } from '../shared/functions';
import { useDynamicDocumentTitle } from '../shared/hooks/DocumentTitleHooks';
import { LinkButton } from '../shared/components/LinkButton';

export const CasesIntroPage = () => {
  const { profile } = useAuthContext();
  const appConfig = useAppConfiguration();
  const [caseConfig, setCaseConfig] = useState();
  const { caseRef } = useParams();
  const [allCaseLabel, setAllCaseLabel] = useState(undefined);

  useEffect(() => {
    if (appConfig.loaded) {
      const config = latestCaseConfiguration(appConfig, caseRef);
      setCaseConfig(config);
      if (haveCaseOrTaskMessage(config, UiMessageKey.SearchTitle)) {
        setAllCaseLabel(caseOrTaskMessage(config, UiMessageKey.SearchTitle));
      } else {
        setAllCaseLabel(
          `All ${caseOrTaskMessage(config, UiMessageKey.TitlePlural)}`
        );
      }
    }
  }, [appConfig, caseRef]);

  useDynamicDocumentTitle(() => {
    return `Dashboard: ${caseOrTaskMessage(
      caseConfig,
      UiMessageKey.TitlePlural
    )}`;
  });

  if (appConfig.loaded && caseConfig) {
    return (
      <Page>
        <PageNav
          title={`Overview: ${caseOrTaskMessage(
            caseConfig,
            UiMessageKey.TitlePlural
          )}`}
        />
        <div className="row">
          <div className="col-md-6">
            <CardWidget
              title={`Your ${caseOrTaskMessage(
                caseConfig,
                UiMessageKey.TitlePlural
              )}`}
              navItems={
                <LinkButton
                  className="btn btn-sm btn-outline-secondary link-button"
                  label={`Your ${caseOrTaskMessage(
                    caseConfig,
                    UiMessageKey.TitlePluralShort
                  )}`}
                  href={`/cases/created-by-you/${caseConfig.ref}`}
                />
              }
            >
              {caseRef && (
                <CasesCreatedDonutChart
                  caseRef={caseRef}
                  refreshCookie={newUUID()}
                />
              )}
            </CardWidget>
          </div>
          <div className="col-md-6">
            <WithPermission name="case:read">
              <CardWidget
                title={allCaseLabel}
                description={`A list of all ${caseOrTaskMessage(
                  caseConfig,
                  UiMessageKey.TitlePlural
                )}`}
                navItems={
                  <LinkButton
                    className="btn btn-sm btn-outline-secondary link-button"
                    label = {`Search ${allCaseLabel}`}
                    href={`/cases/search?caseRef=${caseRef}`}
                  >
                  </LinkButton>
                }
              />
            </WithPermission>

            <CardWidget
              title={`Add ${caseOrTaskMessage(
                caseConfig,
                UiMessageKey.TitleSingular
              )}`}
              description={`Register a new ${caseOrTaskMessage(
                caseConfig,
                UiMessageKey.TitleSingular
              )}`}
              navItems={
                <LinkButton
                  className="btn btn-sm btn-outline-secondary"
                  label={`Add New ${caseOrTaskMessage(
                    caseConfig,
                    UiMessageKey.TitleSingularShort
                  )}`}
                  href={`/assessment/${caseConfig.ref}`}
                >
                </LinkButton>
              }
            />
          </div>
        </div>

        <WithPermission name="case:read">
          <div className="row align-items-start">
            <div className="col-lg-7">
              <CardWidget
                title={`Daily ${caseOrTaskMessage(
                  caseConfig,
                  UiMessageKey.TitlePlural
                )}`}
                navItems={
                  <LinkButton
                    className="btn btn-sm btn-outline-secondary"
                    href={`/cases/search?caseRef=${caseRef}`}
                    label={`Search All ${caseOrTaskMessage(
                      caseConfig,
                      UiMessageKey.TitlePluralShort
                    )}`}>
                  </LinkButton>
                }
              >
                {caseRef && (
                  <CasesCreatedPerDayLineChart
                    caseRef={caseRef}
                    refreshCookie={newUUID()}
                  />
                )}
              </CardWidget>
            </div>
            <div className="col-lg-5">
              <CardWidget
                title={'All Due For Review'}
                navItems={
                  <>
                    <LinkButton
                      className="btn btn-sm btn-outline-secondary mr-2"
                      href={`/cases/search?caseRef=${caseRef}&${QueryParameter.dueForReview.key}=true&${QueryParameter.caseCreatorId.key}=${profile.id}`}
                      label={`Your Due ${caseOrTaskMessage(
                        caseConfig,
                        UiMessageKey.TitlePluralShort
                      )}`}>
                    </LinkButton>
                    <LinkButton
                      className="btn btn-sm btn-outline-secondary"
                      href={`/cases/search?caseRef=${caseRef}&${QueryParameter.dueForReview.key}=true`}
                      label={`All Due ${caseOrTaskMessage(
                        caseConfig,
                        UiMessageKey.TitlePluralShort
                      )}`}>
                    </LinkButton>
                  </>
                }
              >
                {caseRef && (
                  <CasesDueForReviewDonutChart
                    caseRef={caseRef}
                    refreshCookie={newUUID()}
                  />
                )}
              </CardWidget>
            </div>
          </div>
          <div className="row align-items-start">
            <div className="col">
              <CardWidget
                title={`Age Of Live ${caseOrTaskMessage(
                  caseConfig,
                  UiMessageKey.TitlePlural
                )}`}
                navItems={
                  <LinkButton
                    className="btn btn-sm btn-outline-secondary"
                    href={`/cases/search?caseRef=${caseRef}`}
                    label={`Search All ${caseOrTaskMessage(
                      caseConfig,
                      UiMessageKey.TitlePluralShort
                    )}`}>
                  </LinkButton>
                }
              >
                {caseRef && (
                  <AgeOfUnresolvedCasesStackedBarChart
                    caseRef={caseRef}
                    refreshCookie={newUUID()}
                  />
                )}
              </CardWidget>
            </div>
          </div>
        </WithPermission>
      </Page>
    );
  } else {
    return <Spinner animation={'border'} variant={'primary'} />;
  }
};

export default CasesIntroPage;
