import React, { useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { parseAxiosError } from '../shared/AxiosResponseErrorParser';
import FormForSchemaModal from '../form/FormForSchemaModal';

const CaseReviewChangeModal = ({caseId, modalTitle, onModalClose, currentReviewDate}) => {

  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const onSubmit = (formData) => {
    setLoading(true);
    axios.post(`/api/case/${caseId}/review`, formData)
      .then(() => {
        setLoading(false);
        onModalClose(formData.nextReviewOn);
      })
      .catch(e => {
        setLoading(false);
        setErrorMessage(parseAxiosError(e).getMessage());
      });
  };

  const schema = {
    type: 'object',
    properties: {
      nextReviewOn: {
        title: 'Next review date',
        description: 'Schedule a new date or clear an existing one',
        type: 'string',
        format: 'date-future'
      }
    }
  };

  const uiSchema = {
    nextReviewOn: {
      'ui:widget': 'memorableDate',
      'ui:options': {
        'minDateOffset': 1
      }
    }
  };

  return (<FormForSchemaModal
    modalTitle={modalTitle}
    isLoading={loading}
    onModalClose={onModalClose}
    onSubmit={onSubmit}
    errorMessage={errorMessage}
    showBottomErrorMsg={false}
    schema={schema}
    uiSchema={uiSchema}
    values={{ nextReviewOn: currentReviewDate }}
  />);
};

CaseReviewChangeModal.propTypes = {
  caseId: PropTypes.number.isRequired,
  modalTitle: PropTypes.string.isRequired,
  currentReviewDate: PropTypes.string.isRequired,
  onModalClose: PropTypes.func.isRequired
};

export default CaseReviewChangeModal;
