import React from 'react';
import ModelInstanceView from '../models/ModelInstanceView';
import {CommonDateTime} from '../shared/Dates';
import { Page, PageNav } from '../shared/Page';
import PropTypes from 'prop-types';

const ActivityDetails = ({ model }) => {

  return (<Page>
    <PageNav title="nav.activities.show" />
    <dl className="row">
      <dt className="col-sm-3">Identifier</dt>
      <dd className="col-sm-9">{model.id}</dd>
      <dt className="col-sm-3">Version</dt>
      <dd className="col-sm-9">{model.version}</dd>
      <dt className="col-sm-3">Event type</dt>
      <dd className="col-sm-9">{model.eventType}</dd>
      <dt className="col-sm-3">Object type</dt>
      <dd className="col-sm-9">{model.entityType}</dd>
      <dt className="col-sm-3">Object id</dt>
      <dd className="col-sm-9">{model.entityId}</dd>
      <dt className="col-sm-3">Object content</dt>
      <dd className="col-sm-9">
        <pre>{JSON.stringify(model.event, null, 2)}</pre>
      </dd>

      <dt className="col-sm-3">Created</dt>
      <dd className="col-sm-9"><CommonDateTime date={model.createdAt} withCasual={true}/></dd>

      <dt className="col-sm-3">Last updated</dt>
      <dd className="col-sm-9"><CommonDateTime date={model.updatedAt} withCasual={true}/></dd>
    </dl>
  </Page>);
};

ActivityDetails.propTypes = {
  model: PropTypes.object.isRequired
};

const ActivityShowPage = (props) => {
  const { activityId } = props.match.params;
  return <ModelInstanceView apiUrl={`/api/activity/${activityId}`} viewItem={ActivityDetails} {...props}/>;
};

export default ActivityShowPage;
