import React from 'react';
import { MyProfileForm } from './Profile';
import ModelInstanceEditView from '../models/ModelInstanceEditView';
import { useAuthContext } from '../auth/AuthContextProvider';
import { getURLSearchParams } from '../main/RouterHistory';
import { QueryParameter } from '../models/QueryParameter';
import { Page, PageNav } from '../shared/Page';

const EditMyProfilePage = (props) => {
  const { loading, refreshProfile } = useAuthContext();
  const urlQueryParams = getURLSearchParams();
  let afterSuccess = urlQueryParams.get(QueryParameter.afterSuccess.key);
  if (afterSuccess) {
    afterSuccess = decodeURIComponent(afterSuccess);
  } else {
    afterSuccess = '/profile';
  }
  return (<Page>
    <PageNav title="nav.profiles.edit"/>
    {!loading && <ModelInstanceEditView
      ignoreNotFound={true}
      viewTitle={'Your user profile'}
      apiUrl={'/api/profile/me'}
      schemaFormComponent={MyProfileForm}
      pathAfterSuccess={afterSuccess}
      onAfterSubmit={() => refreshProfile()}
      {...props}/>}
  </Page>);
};

export default EditMyProfilePage;
