import { getURLSearchParams, RouterHistory } from '../../main/RouterHistory';
import { QueryParameter, queryParamFromKey } from '../../models/QueryParameter';

export function removeFilters() {
  const items = [];
  const history = RouterHistory;
  const params = getURLSearchParams();
  params.forEach((paramNameValue, paramKey) => {
    if (paramKey !== 'caseRef') {
      if (paramNameValue != null && paramNameValue !== "") {
        // paramNameValue can be single or multi-valued (comma separated), and may optionally contain value|name pairs
        // Examples:
        // foo: paramValue=foo; paramLabel = foo
        // foo,bar: paramValue=foo,bar; paramLabel = foo, bar
        // foo|bar: paramValue=foo; paramLabel = bar
        // 1|foo,2|bar: paramValue=1,2; paramLabel = foo, bar
        const paramValue = paramNameValue.split(',').map(it => it.split('|')[0]).join(',');
        const paramLabel = paramNameValue.split(',').map(it => it.split('|')[1]).join(', ');
        const parsedQueryParam = queryParamFromKey(paramKey);
        const elementId = paramKey + paramValue;
        if (!parsedQueryParam.isPagination && !parsedQueryParam.isSort) {
          items.push({
            id: elementId,
            key: parsedQueryParam.key,
            label: parsedQueryParam.label,
            value: paramValue,
            valueLabel: paramLabel || paramValue
          });
        }
      }
    }
  });

  if (!items.length) {
    return null;
  }

  items.forEach(parsedQueryParam => params.delete(parsedQueryParam.key));
  params.delete(QueryParameter.max.key);
  params.delete(QueryParameter.offset.key);
  history.push({
    pathname: history.location.path,
    search: params.toString()
  });
}
