/**
 * All of the explicit conditionals matter as Webpack replaces them:
 *   `if (process.env.REACT_APP_FLAVOUR === "lps") { do something }`
 *   becomes
 *   `if(false){}`
 *   when condition does not match
 */
let flavourConfig;

if (process.env.REACT_APP_FLAVOUR === "dols"){
  flavourConfig = require('./dols');
}
if (process.env.REACT_APP_FLAVOUR === "lps") {
  flavourConfig = require('./lps');
}
if (process.env.REACT_APP_FLAVOUR === "dpia") {
  flavourConfig = require('./dpia');
}
if (process.env.REACT_APP_FLAVOUR === "police") {
  flavourConfig = require('./police');
}
if (process.env.REACT_APP_FLAVOUR === "iar") {
  flavourConfig = require('./iar');
}
if (process.env.REACT_APP_FLAVOUR === "kitchenSink") {
  flavourConfig = require('./kitchenSink');
}

if (!flavourConfig) throw new Error('REACT_APP_FLAVOUR is not valid or not set');

// Check if required parts are set
if (!Array.isArray(flavourConfig.caseLabels)) throw new Error('caseLabels is not valid or not set');
if (!Array.isArray(flavourConfig.caseSearchStatusesDefault)) throw new Error('caseSearchStatusesDefault is not valid or not set');
if (typeof flavourConfig.messages !== 'object') throw new Error('messages is not valid or not set');
if (typeof flavourConfig.components !== 'object') throw new Error('components is not valid or not set');
if (!flavourConfig.components.CaseDetails) throw new Error('components.CaseDetails is not valid or not set');
if (!flavourConfig.components.HomePageContent) throw new Error('components.HomePageContent is not valid or not set');
if (!flavourConfig.components.AboutPageContent) throw new Error('components.AboutPageContent is not valid or not set');

module.exports = flavourConfig;
