import React, { FC } from 'react';
import { getActivitySubjectLink } from './ActivityLink';
import { TaskStatusBadge } from '../task/TaskStatusBadge';

export interface ActivityTaskSummaryProps {
  taskId: number;
  taskStatus: 'OPEN' | 'STOPPED' | 'DONE' | undefined;
  displayName: string;
  highlight: boolean;
}

export const ActivityTaskSummary: FC<ActivityTaskSummaryProps> = ({
  taskId,
  taskStatus,
  displayName,
  highlight = false
}) => {
  return (
    <div className={'activity-subject-ident'}>
      <a href={getActivitySubjectLink('Task', taskId)}>
        {highlight && (
          <span className={'activity-task-display-name'}>
            &rArr; {displayName} #{taskId}
          </span>
        )}
        {!highlight && (
          <span className={'activity-task-display-name'}>
            {displayName} #{taskId}
          </span>
        )}
        {taskStatus !== undefined && (
          <TaskStatusBadge
            status={taskStatus}
            className={'activity-task-status-text'}
          />
        )}
      </a>
    </div>
  );
};
