import React, { FC, useEffect, useState } from 'react';
import { Case } from '../../shared/types';
import { DeleteRelatedButton } from './DeleteRelatedButton';
import { CaseStatusBadge } from '../status/CaseStatus';
import useAxios from 'axios-hooks';
import { CaseLink } from '../../shared/components/CaseLink';
import { CaseLinkSummary } from '../../shared/components/CaseLinkSummary';

export interface RelatedCasesProps {
  parentCaseId: number;
  reloadCookie: string;
}

/**
 * Renders a related cases panel.  This is a simplified and improved version of the original
 * variant that utilises the bloody horrible ModelListView component
 * @constructor
 */
export const RelatedCases: FC<RelatedCasesProps> = ({
  parentCaseId,
  reloadCookie
}) => {
  const [relatedCases, setRelatedCases] = useState<Case[]>([]);
  const [reload, setReload] = useState<string>(reloadCookie);

  const [{ data, loading }, fetch] = useAxios({
    url: `/api/links/case/${parentCaseId}/cases`,
    params: {
      max: 5,
      offset: 0,
      query: ''
    }
  });

  // if a case is deleted, use this callback to remove the corresponding item from the related
  // cases collection
  function removeLinkedCase(id: number) {
    if (relatedCases.length > 0) {
      setRelatedCases(relatedCases.filter((value) => value.id !== id));
      fetch();
    }
  }

  // given that we're using a hook to load the related cases, use an effect to just
  // set the related case items
  useEffect(() => {
    if (!loading) {
      setRelatedCases(data.data);
    }
    if (reloadCookie !== reload) {
      fetch();
      setReload(reloadCookie);
    }
  }, [data, loading, fetch, reload, reloadCookie]);

  return (
    <div className="card mb-3 card-case-links" key={`links_${parentCaseId}`}>
      <div className="card-header pl-3 pr-2">
        <h2>Related Cases </h2>
      </div>
      <div
        className={'list-group list-group-flush flex-column'}
        data-testid="list"
      >
        {relatedCases?.map((related, index) => (
          <React.Fragment key={`related-${related.id}`}>
            <div className={'related-case-actions-row'}>
              <span className={'flex-md-column'} style={{ float: 'right' }}>
                <DeleteRelatedButton
                  key={`delete-related-${related.id}`}
                  parentCaseId={parentCaseId}
                  linkedCaseId={related.id}
                  onSuccess={removeLinkedCase}
                />
              </span>
            </div>
            <div className={'flex flex-row related-case-row'}>
              <span className={'flex-lg-column'}>
                <CaseLink
                  newTab={false}
                  key={`related-case-${related.id}`}
                  model={related}
                  truncationLimit={24}
                  showLabel={true}
                  showId={true}
                />
              </span>
            </div>
            <div className={'flex related-case-row'}>
              <CaseLinkSummary case={related}/>
            </div>
            <div className={'related-case-status-row'}>
              <span className={'case-status-container'} style={{ float: 'right' }}>
                <CaseStatusBadge
                  key={`case-status-${related.id}`}
                  status={related.status}
                  withLock={true}
                />
              </span>
            </div>
            {index !== relatedCases.length -1 && <hr /> }
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};
