import { CaseLinkWidget, CaseLinkWidgetName } from './CaseLinkWidget';
import {
  TrafficLightWidget,
  TrafficLightWidgetName
} from './TrafficLightWidget';

/**
 * Registry of app-specific widgets that may be applied to various forms.
 * Note that the application of app-specific widgets must be carried out as part of the
 * server-side configuration of the UI schema for a given form
 */
export const appWidgets = {
  [CaseLinkWidgetName]: CaseLinkWidget,
  [TrafficLightWidgetName]: TrafficLightWidget
};
