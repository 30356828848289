import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { CommonDateTime } from '../shared/Dates';
import FormFromTaskRef from '../form/FormFromTaskRef';
import { TaskComments } from './TaskComments';
import { TaskStatus, TaskStatusBadge } from './TaskStatusBadge';
import PresenceList from '../pusher/PresenceList';
import { WithPermission } from '../auth/AccessConditionals';
import { Page, PageNav, PageSubNav } from '../shared/Page';
import Popover from 'react-bootstrap/esm/Popover';
import OverlayTrigger from 'react-bootstrap/esm/OverlayTrigger';
import ChangeTaskWatchersModal from './ChangeTaskWatchersModal';
import { useApiList } from '../models/useApi';
import { flavour } from '../config';
import { FormattedMessage, useIntl } from 'react-intl';
import { CaseStatusBadge } from '../case/status/CaseStatus';
import { PusherTaskChannelListener } from '../pusher/PusherChannelListeners';
import { DownloadPdfButton } from '../form/DownloadPdfButton';
import TaskStatusChangeModal from './TaskStatusChangeModal';
import TaskCommentAddModal from './TaskCommentAddModal';
import TaskFilesAttachModal from './TaskFilesAttachModal';
import TaskAssigneeChangeFlowModal from './TaskAssigneeChangeFlowModal';
import { ProfileLink } from '../profile/Profile';
import { CaseSummary } from '../case/summary/CaseSummary';
import {
  caseConfigurationVersion,
  caseOrTaskMessage,
  taskConfigurationVersion,
  UiMessageKey
} from '../shared/types';
import { useAppConfiguration } from '../shared/contexts/AppConfiguration';
import { useDynamicDocumentTitle } from '../shared/hooks/DocumentTitleHooks';
import { CaseLink } from '../shared/components/CaseLink';
import { ReferencesCases } from './related/ReferencesCases';
import { newUUID } from '../shared/functions';

const { CaseDetails } = flavour.components;
const TaskDetails = ({ model: _model, state }) => {
  const intl = useIntl();
  const [model, setModel] = useState(_model);
  const appConfig = useAppConfiguration();
  const [showWatchModal, setShowWatchModal] = useState(false);
  const [showChangeTaskStatusModal, setShowChangeTaskStatusModal] =
    useState(false);
  const [showTaskCommentAddModal, setShowTaskCommentAddModal] = useState(false);
  const [showTaskFilesAttachModal, setShowTaskFilesAttachModal] =
    useState(false);
  const [showTaskAssigneeChangeModal, setShowTaskAssigneeChangeModal] =
    useState(false);
  const [timestamp, setTimestamp] = useState(new Date().getTime());
  const { loading: loadingWatchers, total: totalWatchers } = useApiList(
    `/api/task/${model.id}/watcher`,
    { max: 1, offset: 0, cacheBuster: timestamp }
  );
  const caseConfig = caseConfigurationVersion(
    appConfig,
    model.case.caseRef,
    model.case.caseVersion
  );
  const taskConfig = taskConfigurationVersion(
    appConfig,
    model.taskRef,
    model.taskVersion
  );
  const caseData = model.case;

  useDynamicDocumentTitle(() => {
    const prefix = caseOrTaskMessage(taskConfig, UiMessageKey.TitleSingular);
    return prefix !== '!undefined!'
      ? `${prefix} #${model.id}`
      : `Task #${model.id}`;
  });

  function openTaskFilesAttachModal() {
    setShowTaskFilesAttachModal(true);
  }

  function onTaskFilesAttachModalClose() {
    setShowTaskFilesAttachModal(false);
    setTimestamp(new Date().getTime());
  }

  function openTaskCommentAddModal() {
    setShowTaskCommentAddModal(true);
  }

  function onTaskCommentAddModalClose() {
    setShowTaskCommentAddModal(false);
    setTimestamp(new Date().getTime());
  }

  function openChangeTaskStatusModal() {
    setShowChangeTaskStatusModal(true);
  }

  function onChangeTaskStatusModalClose(e) {
    setShowChangeTaskStatusModal(false);
    if (e) {
      model.status = e;
      setModel(Object.assign({}, model));
    }
  }

  function openTaskAssigneeChangeModal() {
    setShowTaskAssigneeChangeModal(true);
  }

  function onTaskAssigneeChangeModalClose(e) {
    setShowTaskAssigneeChangeModal(false);
    if (e) {
      model.assignee = e;
      setModel(Object.assign({}, model));
      setTimestamp(new Date().getTime());
    }
  }

  function onAssigneeChange(data) {
    if (data && data.assignee) {
      if (data.assignee.id !== model.assignee.id) {
        model.assignee = data.assignee;
        setModel(model);
      }
    }
  }

  function onStatusChange(data) {
    if (data && data.task) {
      if (data.task.status !== model.status) {
        setModel(Object.assign({}, model, data.task));
      }
    }
  }

  function openChangeWatchersModal(e) {
    e.preventDefault();
    setShowWatchModal(true);
  }

  function onChangeWatchersModalClose() {
    setShowWatchModal(false);
    setTimestamp(new Date().getTime());
  }

  return (
    <Page>
      <PusherTaskChannelListener
        taskId={model.id}
        afterAssigneeChange={onAssigneeChange}
        afterStatusChange={onStatusChange}
      />

      <PageNav title={`${model.title} #${model.id}`}>
        <span className="mx-3">
          <PresenceList channelName={`task-${model.id}`} />
        </span>
        <span className="ml-auto" />
      </PageNav>
      <PageSubNav>
        {state.editable && (
          <WithPermission name="task:update" instance={model}>
            {TaskStatus[model.status].editable && (
              <Link
                to={`/tasks/edit/${model.id}`}
                className="btn btn-sm btn-outline-primary mr-1"
              >
                <FormattedMessage id="nav.tasks.form.edit" />
              </Link>
            )}
            {!TaskStatus[model.status].editable && (
              <OverlayTrigger
                trigger="click"
                placement="bottom"
                overlay={
                  <Popover id="popover-open-form">
                    <Popover.Content>
                      Current status prevents changes
                    </Popover.Content>
                  </Popover>
                }
              >
                <button className="btn btn-sm btn-outline-primary mr-1">
                  <FormattedMessage id="nav.tasks.form.edit" />
                </button>
              </OverlayTrigger>
            )}
            <button
              onClick={() => openChangeTaskStatusModal()}
              className="btn btn-sm btn-outline-secondary mr-1"
            >
              Change task status
            </button>
            <button
              onClick={() => openTaskAssigneeChangeModal()}
              className="btn btn-sm btn-outline-secondary mr-1"
            >
              <FormattedMessage id="nav.tasks.assignee.edit" />
            </button>
          </WithPermission>
        )}
        <button
          onClick={() => openTaskCommentAddModal()}
          className="btn btn-sm btn-outline-secondary mr-1"
        >
          <FormattedMessage id="nav.tasks.comment.add" />
        </button>
        <button
          onClick={() => openTaskFilesAttachModal()}
          className="btn btn-sm btn-outline-secondary mr-1"
        >
          <FormattedMessage id="nav.tasks.file.add" />
        </button>

        <DownloadPdfButton
          className="btn btn-sm btn-outline-secondary mr-1"
          taskRef={model.taskRef}
          taskVersion={model.taskVersion}
          formValues={model.formModelJson}
          caseId={model.case.id}
          taskId={model.id}
        />
      </PageSubNav>

      {!state.editable && (
        <div className="alert alert-warning mb-3" role="alert">
          <span className="mr-1 badge badge-warning">Locked</span>
          <span className="my-auto">Current task cannot be modified</span>
        </div>
      )}

      <div className="row mb-4">
        <div className="col-md-6">
          <div className="card h-100">
            <div className="card-body">
              {caseData && caseConfig && (
                <dl className="row mb-md-0">
                  <dt className="col-sm-4">
                    {caseConfig &&
                      caseOrTaskMessage(caseConfig, UiMessageKey.TitleSingular)}
                  </dt>
                  <dd className="col-sm-8">
                    <CaseLink
                      model={caseData}
                      truncationLimit={32}
                      newTab={false}
                      showId={true}
                    />
                  </dd>
                  <dt className="col-sm-4">Status</dt>
                  <dd className="col-sm-8">
                    <CaseStatusBadge
                      status={caseData.status}
                      withDescription={true}
                      withLock={true}
                    />
                  </dd>
                  <dt className="col-sm-4">Summary</dt>
                  <div className="col-sm-8">
                    {caseData && caseConfig && (
                      <CaseSummary
                        model={caseData}
                        caseConfig={caseConfig}
                        defaultComponent={
                          <CaseDetails model={caseData.details} />
                        }
                      />
                    )}
                  </div>
                </dl>
              )}
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="card h-100">
            <div className="card-body">
              <dl className="row mb-md-0">
                <dt className="col-sm-4">
                  <FormattedMessage id="model.task.assignee" />
                </dt>
                <dd className="col-sm-8">
                  <ProfileLink
                    id={model.assignee.id}
                    label={model.assignee.displayName}
                  />
                </dd>

                <dt className="col-sm-4">
                  <FormattedMessage id="model.task.status" />
                </dt>
                <dd className="col-sm-8">
                  <TaskStatusBadge status={model.status} /> -{' '}
                  {TaskStatus[model.status].description}
                </dd>

                <dt className="col-sm-4">
                  <FormattedMessage id="model.task.createdAt" />
                </dt>
                <dd className="col-sm-8">
                  <CommonDateTime date={model.createdAt} withCasual={true} />
                </dd>

                <dt className="col-sm-4">
                  <FormattedMessage id="model.task.updatedAt" />
                </dt>
                <dd className="col-sm-8">
                  <CommonDateTime date={model.updatedAt} withCasual={true} />
                </dd>

                <dt className="col-sm-4">Version</dt>
                <dd className="col-sm-8">
                  {model.version} (
                  <Link to={`/tasks/show/${model.id}/history`}>History</Link>)
                </dd>

                <dt className="col-sm-4">
                  <FormattedMessage id="model.task.watchers" />
                </dt>
                <dd className="col-sm-8">
                  {!loadingWatchers && (
                    <Link
                      to="#"
                      role="button"
                      onClick={(e) => openChangeWatchersModal(e)}
                    >
                      {totalWatchers}{' '}
                      {totalWatchers === 1 ? 'Person' : 'People'} watching this
                      task
                    </Link>
                  )}
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-8">
          <div className="card mb-4">
            <div className="card-header">
              <h2>
                <FormattedMessage id="model.task.form" />
              </h2>
            </div>
            <div className="card-body">
              <FormFromTaskRef
                taskRef={model.taskRef}
                taskVersion={model.taskVersion}
                values={model.formModelJson}
                isReadOnly={true}
              />
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <TaskComments taskId={model.id} reload={timestamp} />
          <ReferencesCases parentTaskId={model.id} reloadCookie={newUUID()} />
        </div>
      </div>

      {showWatchModal && (
        <ChangeTaskWatchersModal
          taskId={model.id}
          onModalClose={onChangeWatchersModalClose}
          reload={timestamp}
        />
      )}

      {showChangeTaskStatusModal && (
        <TaskStatusChangeModal
          modalTitle={intl.formatMessage({ id: 'nav.tasks.status.edit' })}
          taskId={model.id}
          onModalClose={onChangeTaskStatusModalClose}
        />
      )}

      {showTaskCommentAddModal && (
        <TaskCommentAddModal
          modalTitle={intl.formatMessage({ id: 'nav.tasks.comment.add' })}
          taskId={model.id}
          onModalClose={onTaskCommentAddModalClose}
          reload={timestamp}
        />
      )}

      {showTaskFilesAttachModal && (
        <TaskFilesAttachModal
          modalTitle={intl.formatMessage({ id: 'nav.tasks.file.add' })}
          taskId={model.id}
          onModalClose={onTaskFilesAttachModalClose}
          reload={timestamp}
        />
      )}

      {showTaskAssigneeChangeModal && (
        <TaskAssigneeChangeFlowModal
          modalTitle={intl.formatMessage({ id: 'nav.tasks.assignee.edit' })}
          taskId={model.id}
          onModalClose={onTaskAssigneeChangeModalClose}
          reload={timestamp}
        />
      )}
    </Page>
  );
};

TaskDetails.propTypes = {
  model: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired
};

export default TaskDetails;
