import { useEffect, useState } from 'react';
import axios from 'axios';
import FlowContainer, { CssColClassesFlow } from './core/FlowContainer';
import FormStep from './core/FormStep';
import { useAuthContext } from '../auth/AuthContextProvider';
import { parseAxiosError } from '../shared/AxiosResponseErrorParser';
import ListLoader from '../loaders/ListLoader';
import ProfileRolesForm from '../profile/ProfileRolesForm';
import PreviewStep from './core/PreviewStep';
import PropTypes from 'prop-types';
import RouterHistory from '../main/RouterHistory';
import { Page, PageNav } from '../shared/Page';

const buildEmptyCombinedRoles = () => ({ flavourRoles: [] });

const EditRolesFlowPage = (props) => {

  const { authLoading } = useAuthContext();
  const { profileId } = props.match.params;
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [availableRoles, setAvailableRoles] = useState(buildEmptyCombinedRoles());
  const [currentSelectionOfRoles, setCurrentSelectionOfRoles] = useState(buildEmptyCombinedRoles());

  function onCancel () {
    RouterHistory.goBack();
  }

  useEffect(() => {
    if (!authLoading) {
      setLoading(true);
      setErrorMessage(null);

      Promise.all([
        axios.get(`/api/profile/${profileId}`),
        axios.get(`/api/auth/roles`),
        axios.get(`/api/auth/roles/${profileId}`)
      ]).then(values => {
        setAvailableRoles(values[1].data.data || buildEmptyCombinedRoles());
        const currentRoles = values[2].data.data || buildEmptyCombinedRoles();
        setCurrentSelectionOfRoles({
          flavourRoles: currentRoles.flavourRoles.map(role => role.authority),
        });

      }).catch(e => {
        setErrorMessage(parseAxiosError(e).getMessage());
      }).finally(() => {
        setLoading(false);
      });
    }
  }, [authLoading, profileId]);

  function onSubmit (flowParts) {
    const flavourRoles = flowParts[0].flavourRoles;
    setLoading(true);
    setErrorMessage(null);
    axios.post(`/api/auth/roles/${profileId}`, {
      flavourRoles: flavourRoles
    }).then(() => {
      setLoading(false);
      RouterHistory.push(`/profiles/show/${profileId}`);
    }).catch(e => {
      setLoading(false);
      setErrorMessage(parseAxiosError(e).getMessage());
    });
  }

  return (<Page>
    <PageNav title="nav.profiles.roles.edit" />
    <div className="row">
      <div className={CssColClassesFlow}>
        {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
        {loading && <ListLoader/>}
      </div>
      {!loading && <FlowContainer onFinalSubmit={onSubmit} onCancel={onCancel} primaryValues={[currentSelectionOfRoles]}>
        <FormStep
          key={'roles'}
          title="Change roles"
          schemaFormComponent={ProfileRolesForm}
          flavourRoles={availableRoles.flavourRoles}/>
        <PreviewStep title={'Preview changes'}
          component={PreviewRoleChanges}
          rolesResultIndex={0}
          flavourRoles={availableRoles.flavourRoles}
          primaryValues={currentSelectionOfRoles}/>
      </FlowContainer>}
    </div>
  </Page>);
};

/**
 * Custom preview step showing changes (if any) in roles
 */
const PreviewRoleChanges = ({ allSuccessResults, rolesResultIndex, flavourRoles, primaryValues }) => {

  const formResults = allSuccessResults[rolesResultIndex] || buildEmptyCombinedRoles();

  const FlavourRoleNames = flavourRoles.reduce((memo, item) => {
    memo[item.authority] = item.label;
    return memo;
  }, {});

  return (<>
    <h5>Profile role changes</h5>
    <RoleChanges names={FlavourRoleNames} before={primaryValues.flavourRoles || []} after={formResults.flavourRoles || []}/>
  </>);
};

PreviewRoleChanges.propTypes = {
  allSuccessResults: PropTypes.arrayOf(PropTypes.object).isRequired,
  rolesResultIndex: PropTypes.number.isRequired,
  flavourRoles: PropTypes.arrayOf(PropTypes.shape({
    authority: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
  })).isRequired
};

const RoleChanges = ({names, before, after}) => {

  const removed = before.filter(id => after.indexOf(id) === -1);
  const added = after.filter(id => before.indexOf(id) === -1);

  if (!before.length && !after.length) {
    return (<p className="alert alert-warning">No changes</p>);
  } else if (before.length && !after.length) {
    return (<p className="alert alert-warning">All roles will be removed. Are you sure?</p>);
  }

  let removedView, addedView, afterView;
  if (removed.length) {
    removedView = (<>
      <p>Following roles are going to be removed:</p>
      <ul>
        {removed.map(key => <li key={key}><span className="badge badge-warning">{names[key]}</span></li>)}
      </ul>
    </>);
  }

  if (added.length) {
    addedView = (<>
      <p>Following roles are going to be added:</p>
      <ul>
        {added.map(key => <li key={key}><span className="badge badge-success">{names[key]}</span></li>)}
      </ul>
    </>);
  }

  if (after.length) {
    afterView = (<>
      <p>User will have only the following roles:</p>
      <ul>
        {after.map(key => <li key={key}><span className="badge badge-profile-role">{names[key]}</span></li>)}
      </ul>
    </>);
  }

  return (<>
    {removedView}
    {addedView}
    {afterView}
  </>);
};

RoleChanges.propTypes = {
  names: PropTypes.objectOf(PropTypes.string).isRequired,
  before: PropTypes.arrayOf(PropTypes.string).isRequired,
  after: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default EditRolesFlowPage;
