import React from 'react';
import {FormForSchema} from "@lookinglocal/react-jsonschema-form-extensions";
import FlowContainerModal from './core/FlowContainerModal';
import FormStep from './core/FormStep';
import SelectProfileStep from './core/SelectProfileStep';
import * as AppWidgets from "../form/widgets/AppWidgets";

export default function ReassignTaskFlow (props) {

  function onSubmit (data) {
    const assigneeId = data[0].id || null;
    const comment = data[1].text || null;
    props.onModalClose(assigneeId, comment);
  }

  function onCancel () {
    props.onModalClose(null);
  }

  const TaskCommentOptionalForm = (props) => {
    const schema = {
      type: 'object',
      properties: {
        text: {
          title: 'New comment',
          type: 'string'
        },
        helpText: {
          title: "Notifications",
          description: "System will attempt to send notification which will include this text",
          type: "null"
        }
      }
    };

    const uiSchema = {
      text: {
        'ui:widget': 'textarea'
      }
    };

    return (<FormForSchema schema={schema} uiSchema={uiSchema} {...props} appWidgets={AppWidgets.appWidgets} />);
  };

  return (
    <FlowContainerModal errorMessage={null} onFinalSubmit={onSubmit} onCancel={onCancel} {...props}>
      <SelectProfileStep key={'one'} title="Select assignee" apiUrl={`/api/profile`} withSearch={true} defaultRole="ANY-ROLE" notFoundMessage="No available assignees found" />
      <FormStep key={'two'} title="Leave a comment" schemaFormComponent={TaskCommentOptionalForm}/>
    </FlowContainerModal>
  );
}
