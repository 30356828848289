import React, { useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { parseAxiosError } from '../shared/AxiosResponseErrorParser';
import FormForSchemaModal from '../form/FormForSchemaModal';

const TaskCommentAddModal = ({ taskId, modalTitle, onModalClose }) => {

  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const onSubmit = (formData) => {
    setLoading(true);
    axios.post(`/api/task/${taskId}/comment`, formData)
      .then(() => {
        setLoading(false);
        onModalClose();
      })
      .catch(e => {
        setLoading(false);
        setErrorMessage(parseAxiosError(e).getMessage());
      });
  };

  const schema = {
    type: 'object',
    properties: {
      text: {
        title: 'New comment',
        type: 'string'
      },
      helpText: {
        title: "Notifications",
        description: "System will attempt to send notification which will include this text",
        type: "null"
      }
    },
    required: ['text']
  };

  const uiSchema = {
    text: {
      'ui:widget': 'textarea'
    }
  };

  return (<FormForSchemaModal
    modalTitle={modalTitle}
    isLoading={loading}
    onModalClose={onModalClose}
    onSubmit={onSubmit}
    errorMessage={errorMessage}
    schema={schema}
    uiSchema={uiSchema}
  />);
};

TaskCommentAddModal.propTypes = {
  taskId: PropTypes.number.isRequired,
  modalTitle: PropTypes.string.isRequired,
  onModalClose: PropTypes.func.isRequired
};

export default TaskCommentAddModal;
