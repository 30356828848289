import JSONPretty from 'react-json-pretty';
import React, { FC } from 'react';
import {
  ReportFilter,
  ReportSettings,
  ReportTemplate
} from '../shared/types/Reports';
import { ReportGenerationPageState } from './ReportGenerationPage';

export interface ReportStateDebugProps {
  state: Partial<ReportGenerationPageState>;
  settings?: ReportSettings;
  filter?: ReportFilter;
  template?: ReportTemplate;
  refresh: string;
}

export const ReportStateDebug: FC<ReportStateDebugProps> = (props) => {

  return (
    <>
      <div className={'state-debug'}>
        {props.state && (
          <JSONPretty key = {props.refresh} className={'state-debug-col'} json={props.filter} />
        )}
      </div>
      <div className={'state-debug'}>
        {props.state && (
          <JSONPretty className={'state-debug-col'} json={props.settings} />
        )}
      </div>
      <div className={'state-debug'}>
        {props.template && (
          <JSONPretty className={'state-debug-col'} json={props.template} />
        )}
      </div>
    </>
  );
};
