import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { parseAxiosError } from '../shared/AxiosResponseErrorParser';
import { TaskStatus } from './TaskStatusBadge';
import FormForSchemaModal from '../form/FormForSchemaModal';

const TaskStatusChangeModal = ({ taskId, modalTitle, onModalClose }) => {

  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [availableStatuses, setAvailableStatuses] = useState([]);

  useEffect(() => {
    if (taskId) {
      setLoading(true);
      axios.get(`/api/task/${taskId}/status/available`)
        .then(statusesResponse => {
          setLoading(false);
          setErrorMessage(null);
          setAvailableStatuses(statusesResponse.data.data);
        })
        .catch(e => {
          setLoading(false);
          setErrorMessage(parseAxiosError(e).getMessage());
        });
    }
  }, [taskId]);

  const onSubmit = (formData) => {
    setLoading(true);
    axios.put(`/api/task/${taskId}/status/${formData.status}`, {})
      .then(() => {
        setLoading(false);
        onModalClose(formData.status);
      })
      .catch(e => {
        setLoading(false);
        setErrorMessage(parseAxiosError(e).getMessage());
      });
  };

  const schema = {
    type: 'object',
    properties: {
      status: {
        title: 'New status',
        description: 'Select a new status from a list of available ones',
        type: 'string',
        enum: availableStatuses,
        enumNames: availableStatuses.map(status => `${TaskStatus[status].title} - ${TaskStatus[status].description}`)
      }
    },
    required: ['status']
  };

  return (<FormForSchemaModal
    modalTitle={modalTitle}
    isLoading={loading}
    onModalClose={onModalClose}
    onSubmit={onSubmit}
    errorMessage={errorMessage}
    schema={schema}
  />);
};

TaskStatusChangeModal.propTypes = {
  taskId: PropTypes.number.isRequired,
  modalTitle: PropTypes.string.isRequired,
  onModalClose: PropTypes.func.isRequired
};

export default TaskStatusChangeModal;
