import { Activity } from '../shared/types';
import React, { FC } from 'react';
import _ from 'lodash';
import { ActivityCaseSummary } from './ActivityCaseSummary';
import { ActivityTaskSummary } from './ActivityTaskSummary';
/**
 * Information returned by a rendering function (for a specific type of activity) and used
 * to render the activity details
 */
export interface ActivitySubjectProps {
  activity: Activity;
}

/**
 * Use a partial projection for now, because there's still some looseness in the typing between front and back
 * ends when it comes to activities
 */
export type ActivityEventData = Partial<{
  title?: any;
  case?: any;
  task?: any;
  taskId?: any;
  comment?: any;
  assignee?: any;
  profile?: any;
  updateBy?: any;
}>;

export const ActivitySubjectDescription: FC<ActivitySubjectProps> = ({
  activity
}) => {
  const haveCase = _.has(activity.event, 'case');
  const haveTask = _.has(activity.event, 'task');
  switch (activity.entityType) {
    case 'Case':
      return (
        <>
          <ActivityCaseSummary subject={activity.event.case} />
        </>
      );
    case 'Task':
      return (
        <>
          {haveCase && (
            <ActivityCaseSummary subject={activity.event.case} />
          )}
          {haveTask && (
            <ActivityTaskSummary
              taskId={activity.event.task.id}
              taskStatus={activity.event.task.status}
              displayName={activity.event.task.displayName}
              highlight={haveCase}
            />
          )}
          {!haveTask && (
            <ActivityTaskSummary
              taskId={activity.event.taskId}
              taskStatus={undefined}
              displayName={activity.event.title}
              highlight={haveCase}
            />
          )}
        </>
      );
    default:
      return <div></div>;
  }
};
