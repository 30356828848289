import React from 'react';
import ModelInstanceView from '../models/ModelInstanceView';
import { CaseDetailsPage } from './CaseDetailsPage';

const CaseShowPage = (props) => {
  const { caseId } = props.match.params;
  return <ModelInstanceView apiUrl={`/api/case/${caseId}`} viewItem={CaseDetailsPage} {...props}/>;
};

export default CaseShowPage;
