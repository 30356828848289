import { Link } from 'react-router-dom';
import React from 'react';
import { CaseStatusBadge } from '../case/status/CaseStatus';
import { TaskStatusBadge } from '../task/TaskStatusBadge';
import { ProfileLink } from '../profile/Profile';

export function ActivitySearchResult(response) {
  this.item = response || {};
}

// TODO: what? why would you implement this like this? (Should just be a function)
ActivitySearchResult.prototype = {
  displayName: function () {
    switch (this.item.eventType) {
      case 'CREATE':
      case 'SAVE':
        return this.item.event.displayName;
      case 'ASSIGNEE_CHANGE':
      case 'NEW_COMMENT':
      case 'STATUS_CHANGE':
        if (this.item.entityType === 'Case') {
          return this.item.event.case.displayName;
        } else if (this.item.entityType === 'Task') {
          return this.item.event.task.displayName;
        }
        break;
      default:
    }
    return `${this.item.entityType} ${this.item.entityId}`;
  },

  link: function () {
    if (this.item.entityType === 'Case') {
      return (
        <Link to={`/cases/show/${this.item.entityId}`}>
          {this.displayName()}
        </Link>
      );
    } else if (this.item.entityType === 'Task') {
      return (
        <Link
          to={`/tasks/show/${this.item.entityId}/history/${this.item.entityVersion}`}
        >
          {this.displayName()}
        </Link>
      );
    }
  },

  profile: function () {
    if (this.item.profileId) {
      return (
        <ProfileLink id={this.item.profileId} label={this.item.profileName} />
      );
    }
    return 'System';
  },

  status: function () {
    if ('SAVE' === this.item.eventType && 'Case' === this.item.entityType) {
      // additional check here to ensure that if we don't have historical case ref and case version
      // just return an empty fragment
      if (this.item.event.caseRef === undefined || this.item.event.caseVersion === undefined){
        return (
          <></>
        );
      }else {
        return (
          <CaseStatusBadge
            status={this.item.event.status}
            withLock={true}
          />
        );
      }
    }

    if ('SAVE' === this.item.eventType && 'Task' === this.item.entityType) {
      return <TaskStatusBadge status={this.item.event.status} className={''} />;
    }

    if (
      this.item.event.case &&
      this.item.event.case.caseRef &&
      this.item.event.case.caseVersion
    ) {
      return (
        <CaseStatusBadge
          status={this.item.event.case.status}
          withLock={true}
        />
      );
    }

    if (this.item.event.task) {
      return (
        <TaskStatusBadge status={this.item.event.task.status} className={''} />
      );
    }

    return 'N/A';
  }
};
