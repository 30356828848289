import React from 'react';
import PropTypes from 'prop-types';

export const CardWidget = ({title, description, navItems, children}) => {
  return (
    <div data-testid="card-widget" className="card mb-3 p-3">
      <div className="border-bottom mb-3 pb-3 px-2 d-flex justify-content-between">
        <div className="d-flex align-items-center">
          <h2 className="mb-0">{title}</h2>
        </div>
        <div className="d-flex align-items-end">
          {navItems}
        </div>
      </div>
      <div className="card-body">
        {description && <p className="card-text">{description}</p>}
        {children}
      </div>
    </div>
  );
};

CardWidget.propTypes = {
  title: PropTypes.string.isRequired
};
