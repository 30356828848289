import { useEffect, useState } from 'react';
import axios from 'axios';
import ListLoader from '../loaders/ListLoader';
import PropTypes from 'prop-types';
import { parseAxiosError } from '../shared/AxiosResponseErrorParser';
import PaginationWrapper from '../shared/Pagination';
import { RouterHistory, getURLSearchParams } from '../main/RouterHistory';
import { QueryParameter } from './QueryParameter';
import { useIntl } from 'react-intl';
import ExportData from '../shared/ExportData';

const SortOrder = {
  ASC: 'ASC',
  DESC: 'DESC'
};

const inverseSortOrder = (order) => {
  const parsed = SortOrder[`${order}`.toUpperCase()];
  return parsed === SortOrder.DESC ? SortOrder.ASC : SortOrder.DESC;
};

const ModelTablePage = (props) => {
  const intl = useIntl();
  const caption = props.caption;
  const apiEndpoint = props.apiEndpoint;
  const canExportData = props.canExportData || false;
  const urlQueryParams = getURLSearchParams();
  const max = Math.min(urlQueryParams.get(QueryParameter.max.key) || 10, 100);
  const offset = Math.max(
    urlQueryParams.get(QueryParameter.offset.key) || 0,
    0
  );
  const sort = urlQueryParams.get(QueryParameter.sort.key) || '';
  const order = urlQueryParams.get(QueryParameter.order.key) || '';

  const [isFetching, setIsFetching] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [apiResponse, setApiResponse] = useState(null);
  const [apiQueryParams, setApiQueryParams] = useState(undefined);

  useEffect(() => {
    const additionalQueryParams = props.additionalQueryParams || {};
    // Where query param values are expressed as value|label, use only the value
    Object.keys(additionalQueryParams).forEach((k) => {
      if (
        additionalQueryParams[k] != null &&
        additionalQueryParams[k].includes &&
        additionalQueryParams[k].includes('|')
      )
        additionalQueryParams[k] = additionalQueryParams[k].split('|').shift();
    });
    setApiQueryParams({
      ...additionalQueryParams,
      max,
      offset,
      sort,
      order
    });
  }, [props.additionalQueryParams]);

  useEffect(() => {
    if (apiQueryParams && !isFetching) {
      setIsFetching(true);
      axios
        .get(apiEndpoint, { params: apiQueryParams })
        .then((response) => {
          setApiResponse(response.data);
          setErrorMessage(null);
          setIsFetching(false);
        })
        .catch((e) => {
          setApiResponse(null);
          setErrorMessage(
            parseAxiosError(
              e,
              'Failed to retrieve results from server'
            ).getMessage()
          );
          setIsFetching(false);
        });
    }
  }, [apiQueryParams]); // check stringified params aka deepEquals

  function notFoundView() {
    return (
      <div className="alert alert-info" data-testid="list-empty">
        {props.notFoundMessage || 'Nothing found'}
      </div>
    );
  }

  function errorView() {
    return (
      <div className="alert alert-danger" data-testid="list-error">
        {errorMessage}
      </div>
    );
  }

  function hasItems() {
    if (!apiResponse) {
      return false;
    }
    const total = apiResponse.total || 0;
    const items = apiResponse.data || [];
    return items.length > 0 || total > 0;
  }

  function onPagination({ max, offset }) {
    urlQueryParams.set(QueryParameter.max.key, max);
    urlQueryParams.set(QueryParameter.offset.key, offset);
    RouterHistory.push({
      pathname: RouterHistory.location.path,
      search: urlQueryParams.toString()
    });
    props.onPagination && props.onPagination({ max, offset });
  }

  function tableColumns() {
    return props.columns.map((col, colKey) => {
      if (col.sortBy) {
        const isActive = col.sortBy === sort;
        const paramsWithSort = new URLSearchParams(urlQueryParams.toString());
        paramsWithSort.set(QueryParameter.sort.key, col.sortBy);
        const linkOrderParam = isActive
          ? inverseSortOrder(order)
          : order
            ? order
            : SortOrder.DESC;
        paramsWithSort.set(QueryParameter.order.key, linkOrderParam);
        return (
          <th scope="col" key={`th${colKey}`} className={isActive ? 'th-sorted' : ''}>
            <a
              href={'?' + paramsWithSort.toString()}
              className="text-uppercase"
            >
              {col.label}
            </a>
          </th>
        );
      }
      return (
        <th scope="col" className="text-uppercase" key={`th${colKey}`}>
          {col.label}
        </th>
      );
    });
  }

  function tableRows() {
    const items = apiResponse.data || [];
    return items.map((item, itemKey) => {
      const cells = props.columns.map((col, colKey) => {
        const cellValue = col.fromModel(item, itemKey);
        return <td key={`td${colKey}`}>{cellValue}</td>;
      });
      const rowProps = {};
      const dataHref = props.itemHref && props.itemHref(item);
      if (dataHref) {
        rowProps['data-href'] = dataHref;
        rowProps['onClick'] = () => RouterHistory.push(dataHref);
      }
      return (
        <tr data-testid="list-result-row" key={`tr${itemKey}`} {...rowProps}>
          {cells}
        </tr>
      );
    });
  }

  return (
    <>
      {isFetching && <ListLoader />}
      {!isFetching && errorMessage && errorView()}
      {!isFetching && !errorMessage && !hasItems() && notFoundView()}
      {!isFetching && !errorMessage && hasItems() && (
        <>
          <header className="d-flex align-items-center justify-content-between">
            <p className="small mb-1">
              Showing {offset + 1} to{' '}
              {Math.min(apiResponse.total, offset + max)} of{' '}
              {apiResponse.total || 0}
            </p>
            <PaginationWrapper
              className="mb-1"
              size={'sm'}
              max={max}
              offset={offset}
              total={apiResponse.total || 0}
              onChange={onPagination}
            >
              {canExportData && (
                <li className="page-item ml-2">
                  <ExportData
                    apiEndpoint={process.env.REACT_APP_SERVER + apiEndpoint}
                    apiParams={apiQueryParams}
                    pagedDownload={true}
                    showProgress={true}
                  />
                </li>
              )}
            </PaginationWrapper>
          </header>
          <div className="table-responsive table-container mb-3">
            <table
              className="table table-bordered mb-0"
              tabIndex="0"
              data-testid="list-results-table"
            >
              {caption && (
                <caption>{intl.formatMessage({ id: caption })}</caption>
              )}
              <thead className="text-truncate">
                <tr>{tableColumns()}</tr>
              </thead>
              <tbody>{tableRows()}</tbody>
            </table>
          </div>
          <footer className="d-flex align-items-center justify-content-center">
            <PaginationWrapper
              max={max}
              offset={offset}
              total={apiResponse.total || 0}
              onChange={onPagination}
            />
          </footer>
        </>
      )}
    </>
  );
};

ModelTablePage.defaultProps = {
  onPagination: null
};

ModelTablePage.propTypes = {
  apiEndpoint: PropTypes.string.isRequired,
  additionalQueryParams: PropTypes.object,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      sortBy: PropTypes.string,
      fromModel: PropTypes.func.isRequired
    })
  ).isRequired,
  onPagination: PropTypes.func,
  itemHref: PropTypes.func,
  notFoundMessage: PropTypes.string
};

export default ModelTablePage;
