import React, { useCallback, useState, useEffect } from 'react';
import ContentTypeIcon from '../files/ContentTypeIcon';
import PropTypes from 'prop-types';
import ModelListView from '../models/ModelListView';
import UserPicture from '../shared/UserPicture';
import { PusherCaseChannelListener } from '../pusher/PusherChannelListeners';
import { CasualTime } from '../shared/Dates';
import { Refresh } from '../main/icons';
import { useIntl } from 'react-intl';
import { Download } from '../main/icons';
import axios from 'axios';
import { parseAxiosError } from '../shared/AxiosResponseErrorParser';
import fileSaver from 'file-saver';

const DownloadAttachmentButton = ({
  caseId,
  commentId,
  attachmentId,
  label,
  contentType
}) => {
  const [loading, setLoading] = useState(false);

  const attachmentDownload = (event) => {
    setLoading(true);
    event.preventDefault();
    const url = `api/case/${caseId}/comment/${commentId}/attachment/${attachmentId}`;
    axios
      .get(url, { responseType: 'blob' })
      .then((response) => {
        setLoading(false);
        fileSaver.saveAs(new Blob([response.data]), label);
      })
      .catch((e) => {
        setLoading(false);
        console.error(parseAxiosError(e).getMessage());
      });
  };

  return (
    <button
      key={commentId + '-' + attachmentId}
      disabled={loading}
      className="btn btn-sm btn-outline-secondary mr-1 mb-1"
      title={`Download ${label}`}
      onClick={attachmentDownload}
    >
      <ContentTypeIcon contentType={contentType} height={14} />
      <span className="mx-2">{label}</span>
      <Download height={14} className="svg-gradient-secondary" />
    </button>
  );
};

DownloadAttachmentButton.propTypes = {
  caseId: PropTypes.number.isRequired,
  commentId: PropTypes.number.isRequired,
  attachmentId: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  contentType: PropTypes.string.isRequired
};

const CaseComment = ({ model }) => {
  if (!model) model = {};
  const commenter = (model.owner && model.owner.displayName) || 'anonymous';
  const nameParts = commenter.split(/[<>]/);
  const name = nameParts[0].trim();
  const email = nameParts[1] || name;

  return (
    <div className="d-flex flex-row p-2">
      <div className="flex-grow-1 pl-2 small overflow-hidden">
        <div className="d-flex flex-row align-items-center mb-2">
          <table className="comments-table">
            <tbody>
              <tr>
                <td className="avatar-column">
                  <UserPicture
                    className="avatar comments-avatar"
                    email={email}
                    title={commenter}
                  />
                </td>
                <td className="ellipsis">
                  <span className="mx-2 comment-wrap" title={commenter}>
                    {name}
                  </span>
                </td>
                <td className="ellipsis time-column">
                  <CasualTime
                    className="text-muted mr-auto"
                    date={model.createdAt}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <p style={{ whiteSpace: 'pre-wrap' }}>{model.text}</p>
        {model.attachments &&
          model.attachments.length > 0 &&
          model.attachments.map((attachment) => (
            <DownloadAttachmentButton
              caseId={model.entityId}
              key={attachment.id}
              attachmentId={attachment.id}
              commentId={model.id}
              contentType={attachment.contentType}
              label={attachment.name}
            />
          ))}
      </div>
    </div>
  );
};

function CaseComments({ caseId, timestamp }) {
  const intl = useIntl();
  const [lastCommentId, setLastCommentId] = useState(null);

  useEffect(() => {
    refreshComments();
  }, [timestamp]);

  const onNewComment = useCallback(
    (data) => {
      if (data && data.comment && data.comment.id) {
        setLastCommentId(data.comment.id);
      }
    },
    [setLastCommentId]
  );

  const refreshComments = () => {
    setLastCommentId(new Date().getTime());
  };

  return (
    <div className="card mb-3" key={`comment_${caseId}`}>
      <PusherCaseChannelListener
        caseId={caseId}
        afterComment={onNewComment}
        showNotifications={true}
      />
      <div className="card-header pl-3 pr-2">
        <h2 className="float-left">Comments</h2>
        <div className="float-right">
          <button
            onClick={() => refreshComments()}
            title={intl.formatMessage({ id: 'nav.cases.comment-refresh' })}
            aria-label={intl.formatMessage({ id: 'nav.cases.comment-refresh' })}
            className="border-0 p-0 m-0 btn btn-sm btn-outline-secondary"
          >
            <Refresh height={20} style={{ fill: 'currentColor' }} />
          </button>
        </div>
      </div>

      <ModelListView
        className={'list-group list-group-flush'}
        itemClassName={'list-group-item p-0'}
        apiUrl={`/api/case/${caseId}/comment`}
        listItemRenderer={CaseComment}
        notFoundMessage="There are no comments yet on this case."
        key={lastCommentId} // re-fetch comments when a new comment added
      />
    </div>
  );
}

CaseComments.propTypes = {
  caseId: PropTypes.number.isRequired,
  timestamp: PropTypes.any
};

export { CaseComments, CaseComment };
