import { FC, useEffect, useState } from 'react';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import { BasePie } from './basePie';
import {
  allCaseStatuses,
  allCaseStatusesByCaseType,
  CaseConfiguration,
  caseOrTaskMessage,
  caseStatusFromId,
  latestCaseConfiguration,
  UiMessageKey
} from '../shared/types';
import { useAppConfiguration } from '../shared/contexts/AppConfiguration';

/**
 * Interface defining the expected prop structure for this component
 */
export interface CasesCreatedDonutChartProps {
  caseRef?: string;
  refreshCookie: string;
}

/**
 * A donut chart component that shows the counts of cases created grouped by status.  Can be either global (across all
 * case types) or specific to a given case type
 * @param caseRef
 * @param refreshCookie
 * @constructor
 */
export const CasesCreatedDonutChart: FC<CasesCreatedDonutChartProps> = ({
  caseRef,
  refreshCookie
}) => {
  const [analyticsData, setAnalyticsData] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<string>(refreshCookie);
  const appConfig = useAppConfiguration();
  const [caseConfig, setCaseConfig] = useState<CaseConfiguration>();
  const caseStatuses = caseRef
    ? allCaseStatusesByCaseType(appConfig, caseRef)
    : allCaseStatuses(appConfig);

  useEffect(() => {
    if (appConfig.loaded && caseRef) {
      setCaseConfig(latestCaseConfiguration(appConfig, caseRef));
    }
  }, [appConfig, appConfig.loaded, caseRef]);

  useEffect(() => {
    if (!loaded) {
      const apiUrl = caseRef
        ? `/api/stats/cases/created/${caseRef}`
        : `/api/stats/cases/created`;
      axios
        .get(apiUrl)
        .then((response) => response.data.data || [])
        .then((results) => {
          setAnalyticsData(
            results.map((result: { status: string; count: number }) => {
              const caseStatus = caseStatusFromId(result.status, caseStatuses);
              return {
                id: caseStatus.label,
                label: caseStatus.label,
                value: result.count
              };
            })
          );
          setLoaded(true);
        })
        .catch(() => setAnalyticsData([]))
        .finally(() => setIsLoading(false));
    }
    if (refreshCookie !== refresh) {
      setLoaded(false);
      setRefresh(refreshCookie);
    }
  }, [caseRef, caseStatuses, loaded, refresh, refreshCookie]);
  return (
    <div
      style={{ height: 300 }}
      className="d-flex align-items-center justify-content-center"
    >
      {isLoading && <Spinner animation={'border'} variant={'primary'} />}
      {!isLoading && caseConfig && analyticsData.length === 0 && (
        <p className="text-muted text-center">
          {`No ${caseOrTaskMessage(
            caseConfig,
            UiMessageKey.TitlePlural
          )} found`}
        </p>
      )}
      {!isLoading && analyticsData.length > 0 && (
        <BasePie analyticsData={analyticsData} />
      )}
    </div>
  );
};
