import { GeneralIcons } from '../../shared/components/Icons';
import { FC } from 'react';
import { SpinnerDotted } from 'spinners-react';

export interface QuickSearchActionsButtonProps {
  /**
   * Whether the parent quick search is currently loading a case
   */
  loading: boolean;

  /**
   * An optional case identifier
   */
  caseId?: number;

  /**
   * An optional invalid marker (causes display of an invalid state marker)
   */
  invalid?: boolean;

  /**
   * Called when a specific action is selected
   * @param action
   */
  onActionCallback: (action: string) => void;
}

export const QuickSearchActionsButton: FC<QuickSearchActionsButtonProps> = (
  props
) => {
  if (props.invalid) {
    return (
      <div className={'widget action warning'} >
        <label
          aria-disabled={true}
          className={
            'input-group-text widget-label widget-action'
          }
          aria-label={'Advanced search'}
        >
          &nbsp;{GeneralIcons.Cross('warning')}&nbsp;
        </label>
      </div>
    );
  } else {
    return (
      <div className={'widget action'}>
        {props.loading && (
          <label
            aria-disabled={true}
            className={
              'input-group-text widget-label widget-action'
            }
            aria-label={'Loading'}
          >
            &nbsp;
            <SpinnerDotted size={24} />
            &nbsp;
          </label>
        )}
        {!props.loading && props.caseId && (
          <label
            onClick={() => props.onActionCallback('open')}
            className={
              'input-group-text widget-label widget-action'
            }
            aria-label={'Open case in new tab'}
          >
            &nbsp;{GeneralIcons.OpenInNew('action-svg')}&nbsp;
          </label>
        )}
        {!props.loading && !props.caseId && (
          <button
            tabIndex={0}
            onClick={() => props.onActionCallback('advanced')}
            className={
              'input-group-text widget-label no-left-border widget-action'
            }
            aria-label={'Advanced search'}
          >
            &nbsp;{GeneralIcons.Search('action-search-svg')}&nbsp;
          </button>
        )}
      </div>
    );
  }
};
