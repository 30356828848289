import hash from 'object-hash';

/**
 * Deep clone excluding undefined, functions
 * @param o {object}
 * @return {any}
 */
export const cloneSimple = (o) => {
  return JSON.parse(JSON.stringify(o));
};

/**
 * Deep clone excluding undefined, empty objects and arrays ({}, []), functions
 * @param o {object}
 * @return {any}
 */
export const cloneSimpleNoEmpty = (o) => {
  return JSON.parse(JSON.stringify(o), (k, v) => {
    if (!!k && !!v && typeof v === 'object' && !Array.isArray(v) && !Object.keys(v).length) {
      return undefined;
    } else if (!!k && !!v && typeof v === 'object' && Array.isArray(v) && v.every(i => i === undefined)) {
      return undefined;
    }
    return v;
  });
};

/**
 *
 * @param o {*} object to generate hash from
 * @param [skipEmpty] {boolean} excludes empty objects in the graph from hash calculation
 * @return {string}
 */
export const objectHash = (o, skipEmpty) => {
  if (typeof o === 'object') {
    return skipEmpty ? hash(cloneSimpleNoEmpty(o)) : hash(cloneSimple(o));
  }
  if (o === undefined)
    return 'undefined';
  return hash(o);
};

/**
 * Retrieve a value from a path within an object graph
 *
 * @param obj {object} - the object graph
 * @param path {string} - path to object property
 * @returns {*}
 */
export const getPropertyPath = function (obj, path) {
  if (!obj) {
    return;
  }
  if (typeof path !== 'string' || !path.length) {
    return obj;
  }
  let o = obj;
  const parts = path.split('.');
  for (let i = 0; i < parts.length; i++) {
    o = o[parts[i]];
    if (o === undefined) {
      return;
    } else if (o === null) {
      return null;
    }
  }
  return o;
};

/**
 * checks if type is an object
 *
 * @param val {*} type to check
 * @return {boolean}
 */
export const isObject = function(val) {
  return typeof val === 'object' && !Array.isArray(val) && val !== null;
};

/**
 * checks if an object is empty
 *
 * @param val {*} object to check
 * @return {boolean}
 */
export const isObjectEmpty = function(val) {
  return Object.keys(val).length === 0;
};
