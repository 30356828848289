import React from 'react';
import {useAuthContext} from '../auth/AuthContextProvider';
import DashboardContentLoader from '../loaders/DashboardContentLoader';
import DashboardNoRole from './DashboardNoRole';
import DashboardDefault from './DashboardDefault';
import { containsAssignedAuthority } from '../auth/AccessConditionals';

/**
 * @return {null|jsx}
 */
const DashboardEntryPage = () => {
  const { loading, profile } = useAuthContext();

  if (loading) {
    return <DashboardContentLoader />;
  } else if (!profile) {
    return null;
  }

  if (!containsAssignedAuthority(profile)) return <DashboardNoRole />;

  return <DashboardDefault />;
};

export default DashboardEntryPage;
