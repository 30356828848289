import {useCallback, useState} from 'react';
import {SelectProfilesModal} from "../profile/SelectProfilesForm";
import {getURLSearchParamsWithoutPagination, RouterHistory} from "../main/RouterHistory";
import {QueryParameter} from "../models/QueryParameter";

export const SelectProfileForUrl = () => {

  const params = getURLSearchParamsWithoutPagination();

  let urlParamValues = params.get(QueryParameter.caseCreatorId.key) || '';
  if (Array.isArray(urlParamValues)) {
    urlParamValues = urlParamValues.filter(it => !!it);
  } else if (typeof urlParamValues === 'string') {
    urlParamValues = urlParamValues.split(',').filter(it => !!it);
  } else {
    urlParamValues = [];
  }

  const [modalVisible, setModalVisible] = useState(false);
  const [selection, setSelection] = useState(urlParamValues);

  const closeModal = useCallback(() => setModalVisible(false), [setModalVisible]);
  const selectIds = ids => {
    setSelection(ids);
    setModalVisible(false);
    const creatorId = document.getElementById('caseCreatorId');
    creatorId.dispatchEvent(new Event("input", {"bubbles": true}));
  };

  return (<div className="mr-sm-3 mb-1">
    <input type="hidden" id="caseCreatorId" name="caseCreatorId" value={selection} />
    {selection.length === 0 && <button className="btn btn-sm btn-outline-secondary" onClick={() => setModalVisible(!modalVisible)}>
      {QueryParameter.caseCreatorId.label} ({selection.length} selected)
    </button>}

    {selection.length > 0 && <div className="btn-group btn-group-sm" role="group">
      <button className="btn btn-outline-secondary" onClick={() => setModalVisible(!modalVisible)}>
        {QueryParameter.caseCreatorId.label} ({selection.length} selected)
      </button>
      <button className="btn btn-outline-secondary" onClick={() => selectIds([])} aria-label="Clear">
        <span aria-hidden="true">&times;</span>
        <span className="sr-only">Clear</span>
      </button>
    </div>}

    {modalVisible && <SelectProfilesModal onHide={closeModal} initialIds={urlParamValues} onSelectIds={ids => selectIds(ids)} />}
  </div>);
};
