import { FC } from 'react';
import { CaseConfiguration } from '../../shared/types';
import { ValueRenderingFunction } from '../../shared/functions';

/**
 * Props for an individual case summary group item
 */
export interface CaseSummaryGroupItemProps {
  model: any;
  caseConfig: CaseConfiguration;
  label: string;
  valueFunc: ValueRenderingFunction;
}

/**
 * Creates a simple dt/dd pair based on a value extracted from a given case model
 * @constructor
 */
export const CaseSummaryGroupItem: FC<CaseSummaryGroupItemProps> = ({
  model,
  caseConfig,
  label,
  valueFunc
}) => {
  return (
    <>
      <dt className={'label'}>{label}</dt>
      <dt className={'value'}>{valueFunc(model)}</dt>
    </>
  );
};
