import React, { useEffect, useState } from 'react';
import { generatePath } from 'react-router';
import ModelTableWithRouterState from '../models/ModelTableWithRouterState';
import { SearchFormHorizontal } from '../search/SearchFormHorizontal';
import { Page, PageNav } from '../shared/Page';
import { useApiList } from '../models/useApi';
import Spinner from 'react-bootstrap/Spinner';
import { CaseDetailsSearchFields } from './CaseDetailsSearchFields';
import {
  caseOrTaskMessage,
  caseStatusesByCaseVersion,
  latestCaseConfiguration,
  UiMessageKey
} from '../shared/types';
import { QueryParameter } from '../models/QueryParameter';
import * as StatusUtils from '../utils/Status';
import {
  constructSearchAdditionalQueryParams,
  constructSearchDisplayColumns
} from './SearchUtils';
import { useAppConfiguration } from '../shared/contexts/AppConfiguration';
import { useLocation } from 'react-router-dom';
import { useDynamicDocumentTitle } from '../shared/hooks/DocumentTitleHooks';
import { SelectProfileForUrl } from '../search/SelectCreatorProfiles';
import { SelectFromListForUrl } from '../search/SelectFromList';
import { InputSize } from '../search/InputSize';
import { CheckboxForUrl } from '../search/CheckboxForUrl';

const CaseExternalSearchPage = (props) => {
  const {
    loading,
    error,
    items: externalSearchSources
  } = useApiList('/api/config/search/external-sources', {});
  const location = useLocation();
  const appConfig = useAppConfiguration();
  const [timestamp] = useState(new Date().getTime());
  const [caseStatusValues, setCaseStatusValues] = useState(undefined);
  const [caseStatusLabels, setCaseStatusLabels] = useState(undefined);
  const [columns, setDisplayColumns] = useState([]);
  const [caseConfig, setCaseConfig] = useState({});
  const [caseSearchFields, setCaseSearchFields] = useState(undefined);
  const [tableQueryParams, setTableQueryParams] = useState();

  // attempt to grab hold of a case configuration, and if present use this to obtain configuration relating
  // to which columns from within the schema we display in the search results table. If we don't have a valid
  // case configuration, we don't show any case-schema specific columns
  useEffect(() => {
    const caseRef = new URLSearchParams(location.search).get('caseRef');
    if (caseRef && appConfig.loaded) {
      const caseConfig = latestCaseConfiguration(appConfig, caseRef);
      const statuses = caseStatusesByCaseVersion(
        appConfig,
        caseConfig.ref,
        caseConfig.version
      );
      if (caseConfig) {
        setCaseConfig(caseConfig);
        setCaseStatusValues(
          statuses.map((status) => `${status.id}|${status.label}`)
        );
        setCaseStatusLabels(
          statuses.map((status) => StatusUtils.sanitisedStatusLabel(status))
        );
        setDisplayColumns(constructSearchDisplayColumns(caseConfig));
        const searchFields = new CaseDetailsSearchFields(
          caseConfig.form.schema,
          caseConfig.form.uiSchema
        );
        setCaseSearchFields(searchFields);
        setTableQueryParams(
          constructSearchAdditionalQueryParams(
            appConfig,
            caseConfig,
            searchFields,
            location,
            timestamp
          )
        );
      }
    } else {
      setDisplayColumns(constructSearchDisplayColumns(undefined));
    }
  }, [location, appConfig, timestamp]);

  useDynamicDocumentTitle(() => {
    return `Search External ${caseOrTaskMessage(
      caseConfig,
      UiMessageKey.TitlePlural
    )}`;
  });

  if (error) return <p className="alert alert-warning">{error}</p>;
  if (loading || !externalSearchSources)
    return <Spinner animation={'border'} variant={'primary'} size={'sm'} />;

  return (
    <Page>
      <PageNav
        title={`Search ${caseOrTaskMessage(
          caseConfig,
          UiMessageKey.TitlePlural
        )}`}
      >
        {!!externalSearchSources.length && (
          <span>
            Searching in:{' '}
            {externalSearchSources.map((ess) => ess.databaseLabel).join(', ')}
          </span>
        )}
      </PageNav>
      <div className="mb-4">
        <SearchFormHorizontal>
          <SelectProfileForUrl />
          <SelectFromListForUrl
            key={QueryParameter.caseStatus.key}
            size={InputSize.sm}
            className="narrow mr-sm-3 mb-1"
            labels={caseStatusLabels}
            values={caseStatusValues}
            addEmptyValue={true}
            parameterKey={QueryParameter.caseStatus.key}
            parameterLabel={QueryParameter.caseStatus.label}
          />
          <CheckboxForUrl
            parameterKey={QueryParameter.dueForReview.key}
            parameterLabel={QueryParameter.dueForReview.label}
          />
          {caseSearchFields.getFormFieldsForUrl()}
        </SearchFormHorizontal>
      </div>
      <ModelTableWithRouterState
        caption={`Search ${caseOrTaskMessage(
          caseConfig,
          UiMessageKey.TitlePlural
        )}`}
        apiEndpoint={'/api/case/external/search'}
        notFoundMessage={`No ${caseOrTaskMessage(
          caseConfig,
          UiMessageKey.TitlePlural
        )} found`}
        additionalQueryParams={tableQueryParams}
        columns={columns}
        itemHref={(item) =>
          generatePath(`/cases/external-search/${item.db}/${item.id}`)
        }
        canExportData={true}
        {...props}
      />
    </Page>
  );
};

export default CaseExternalSearchPage;
