import React, {  useState } from 'react';
import PropTypes from 'prop-types';
import ModelInstanceView from '../models/ModelInstanceView';
import { FormattedMessage, useIntl } from 'react-intl';
import axios from 'axios';
import { parseAxiosError } from '../shared/AxiosResponseErrorParser';
import FlowContainerModal from '../flows/core/FlowContainerModal';
import SelectEntitiesStep from '../flows/core/SelectEntitiesStep';
import SelectProfileStep from '../flows/core/SelectProfileStep';
import PreviewStep from '../flows/core/PreviewStep';
import { useAuthContext } from '../auth/AuthContextProvider';
import { AvailableTaskItemRenderer } from './AvailableTaskItemRenderer';

const FinalStepContent = () => {
  return (
    <section className='white-space-pre-line'>
      <FormattedMessage id='nav.tasks.add.step.last.description' />
    </section>
  );
};

function CreateTaskFlowModal({ model, onModalClose, modalTitle }) {
  const { profile } = useAuthContext();
  const intl = useIntl();
  const caseId = model.id;
  const [errorMessage, setErrorMessage] = useState(null);

  const addTasks = (task) => {
    return axios.post(`/api/case/${caseId}/task`, task);
  };

  async function onSubmit(data) {
    const promises = [];
    const tasks = data[0];
    const assignee = data[1] || profile;
    tasks.forEach((item) => {
      const cmd = {
        taskRef: item.taskConfig.ref,
        taskVersion: item.taskConfig.version,
        assignee: assignee
      };
      const result = addTasks(cmd);
      promises.push(result);
    });

    await Promise.all(promises)
      .then(() => {
        onModalClose({ reload: true });
      })
      .catch((e) => {
        setErrorMessage(parseAxiosError(e).getMessage());
      });
  }

  return (
    <FlowContainerModal
      modalTitle={modalTitle}
      primaryValues={[null, null, { status: model.status }]}
      onFinalSubmit={onSubmit}
      onCancel={() => onModalClose()}
      errorMessage={errorMessage}
    >
      <SelectEntitiesStep
        key={'one'}
        apiUrl={`/api/case/${caseId}/tasks/selectable?sort=displayName`}
        required={true}
        selectedTitle={
          intl.formatMessage({ id: 'nav.tasks.add.step.selected' }) + ':'
        }
        listItemRenderer={AvailableTaskItemRenderer}
      />
      <SelectProfileStep
        key={'two'}
        title={intl.formatMessage({ id: 'nav.tasks.add.step.assignee' })}
        apiUrl={`/api/profile`}
        withSearch={true}
      />
      <PreviewStep
        key={'finish'}
        title={intl.formatMessage({ id: 'nav.tasks.add.step.last' })}
        component={FinalStepContent}
      />
    </FlowContainerModal>
  );
}

CreateTaskFlowModal.propTypes = {
  model: PropTypes.object.isRequired,
  modalTitle: PropTypes.string.isRequired,
  onModalClose: PropTypes.func.isRequired
};

const AddCaseTaskFlowModal = ({ caseId, ...rest }) => (
  <ModelInstanceView
    floating={true}
    apiUrl={`/api/case/${caseId}`}
    viewItem={CreateTaskFlowModal}
    {...rest}
  />
);

AddCaseTaskFlowModal.propTypes = {
  caseId: PropTypes.number.isRequired,
  modalTitle: PropTypes.string.isRequired,
  onModalClose: PropTypes.func.isRequired
};
export default AddCaseTaskFlowModal;
