import React from 'react';
import FooterComponent from './FooterComponent';
import { useAuthContext } from '../auth/AuthContextProvider';
import { Page, PageNav } from '../shared/Page';
import { ReactComponent as DividerCurve } from "../images/shapes/curves/curve-1.svg";
import { ReactComponent as BottomDivider } from "../images/shapes/waves/wave-1.svg";

export function CookiePolicyPage () {
  const { isAuthenticated } = useAuthContext();

  const PageHeader = () => {
    return <>
      <section className="py-5 bg-primary">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-8 text-center">
              <h1 className="text-white">
                Cookie policy
              </h1>
            </div>
          </div>
        </div>
      </section>
      <div className="position-relative">
        <div className="shape shape-bottom shape-fluid-x svg-shim text-light">
          <DividerCurve/>
        </div>
      </div>
    </>;
  };

  const PageContent = () => {
    return <>
      <div className="bg-light py-3">
        <div className="container">
          <div className="row align-items-start">
            <div className="col-md-10 offset-md-1">
              <div className="card border-white shadow-sm rounded">
                <div className="card-body notes">

                  <p>Protecting your personal information is important to us. We don't use any cookies on our website that contain information which identifies you personally. However, we do use cookies on our website to help make your visit more effective. This policy explains how and why we use cookies.</p>

                  <h1 className="card-title">What cookies are and what information we gather</h1>

                  <p>Cookies are little data files which are sent to your web browser (for example, Internet Explorer or Google Chrome) from a website that you visit. They are stored on your computer's hard drive. When you visit the website again, the cookie allows that particular website to recognise your browser. Cookies may store user preferences and other information.</p>
                  <p>We gather basic information like what web pages you have looked at, your browser settings and when you visited our website.</p>

                  <h1 className="card-title">Control your cookies</h1>
                  <h2 className="card-title">AboutCookies.org</h2>
                  <p>Full instructions on how to manage your cookies in all the main web browsers can be found on the <a href="https://aboutcookies.org">aboutcookies.org</a> website.</p>
                  <h2 className="card-title">How do I disable cookies?</h2>
                  <p>If you want to disable cookies, you need to change your website browser settings. How to do this will depend on the browser you use and the information below shows you how to disable cookies for the most popular browsers:</p>
                  <ul>
                    <li>Google Chrome - Choose the “Chrome” menu then “Settings”. Click on the “Show advanced settings” link, then “Content Settings” under “Privacy”. Select the appropriate “Cookies” setting then close the “Content Settings” panel to commit the setting.</li>
                    <li>Apple Safari - Choose the "Safari" menu then "Preferences". Click on the "Privacy" tab. Select the appropriate setting then close the Preferences panel to commit the setting.</li>
                    <li>Microsoft Internet Explorer - Choose the menu "Tools" then "Internet Options". Click on the "Privacy" tab. Select the appropriate setting.</li>
                    <li>Mozilla Firefox - Choose the menu "Tools" then "Options". Click on the icon "Privacy". Find the menu "Cookie" and select the relevant options.</li>
                    <li>Opera 6.0 and others - Choose the menu "Files" {'>'} "Preferences" {'>'} "Privacy".</li>
                  </ul>
                  <h2 className="card-title">What happens if I disable cookies?</h2>
                  <p>This depends on which cookies you disable, but in general the site may not operate properly if cookies are switched off. If you disable all cookies, you will be unable to complete a transaction on this site.</p>

                  <h1 className="card-title">How we use cookies</h1>
                  <p>When you visit the website, the web pages you see are downloaded to your web browsing device, along with a cookie. Cookies enable us to do useful things like find out whether you have visited our website before.</p>
                  <p>Some online services on our website use cookies for a specific reason. Without cookies, you will not be able to use many of these services. When using these services no personal information is stored inside a cookie.</p>
                  <p>Information supplied by cookies can help us to analyse information about our visitors, which helps us to provide you with a better service, including content that better meets your needs.</p>
                  <h2 className="card-title">Third party cookies</h2>
                  <p>When visiting our website, you may also notice some cookies that are related to content from other websites. When you visit a page with content embedded from another site (for example, YouTube, Facebook, Twitter or Vimeo) you may be presented with cookies from these other websites. We do not control these 'third party' cookies. You should check the other websites for more information about their cookies.</p>
                  <h2 className="card-title">Cookies on this website</h2>
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">Typical content</th>
                          <th scope="col">Level</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td><code>auth0.is.authenticated</code></td>
                          <td><code>true</code></td>
                          <td>Necessary</td>
                        </tr>
                        <tr>
                          <td><code>_legacy_auth0.is.authenticated</code></td>
                          <td><code>true</code></td>
                          <td>Necessary</td>
                        </tr>
                        <tr>
                          <td><code>cookieConsentPresent</code></td>
                          <td><code>agreed</code></td>
                          <td>Necessary</td>
                        </tr>
                        <tr>
                          <td><code>messagesUtk</code></td>
                          <td><code>8fe5e965d07f42c28dd69599619e68b0</code></td>
                          <td>Functional</td>
                        </tr>

                        <tr>
                          <td><code>hs-messages-is-open</code></td>
                          <td><code>true</code></td>
                          <td>Necessary</td>
                        </tr>
                        <tr>
                          <td><code>hs-messages-hide-welcome-message</code></td>
                          <td><code>true</code></td>
                          <td>Necessary</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>;
  };

  return (<>
    {!isAuthenticated && <>
      <PageHeader/>
      <PageContent/>
      <div className="position-relative pb-5 mb-5">
        <div className="shape shape-top shape-fluid-x svg-shim bg-white text-light">
          <BottomDivider/>
        </div>
      </div>
      <FooterComponent/></>
    }

    {isAuthenticated && <>
      <Page>
        <PageNav title="nav.cookies"/>
        <PageContent/>
      </Page></>
    }
  </>);
}
