import { NavLink, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import React, { FC, useEffect, useState } from 'react';

export interface SidebarLinkProps {
  to: string;
  className?: string;
  label: string;
  translate: boolean;
  badge?: string
}

/**
 *
 * @param to
 * @param className
 * @param label
 * @param translate
 * @param badge
 * @param rest
 * @constructor
 */
export const SidebarLink: FC<SidebarLinkProps> = ({
  to,
  className,
  label,
  translate,
  badge,
  ...rest
}) => {
  // we have to do an ignore here because there's no typing on a lot of earlier stuff
  // @ts-ignore
  const [active, setActive] = useState(false);

  // we need the location in order to fully ascertain whether this link is *really* active
  const location = useLocation();

  useEffect(() => {
    setActive(`${location.pathname}${location.search}` === to);
  }, [location, to]);

  return (
    <NavLink
      to={to}
      exact={true}
      isActive={() => active}
      className={`nav-link d-flex`}
      {...rest}
    >
      {translate && <FormattedMessage id={label} />}
      {!translate && `${label}`}
      {badge &&  <span className={'small-badge-no-border shift-up-2 shift-right-4 flex-column'}>({badge})</span>}
    </NavLink>
  );
};
