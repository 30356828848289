import React, { useState } from 'react';
import { generatePath } from 'react-router';
import ModelTableWithRouterState from '../models/ModelTableWithRouterState';
import { getURLSearchParams } from '../main/RouterHistory';
import { WithPermission } from '../auth/AccessConditionals';
import { QueryParameter } from '../models/QueryParameter';
import { InputQueryForUrl } from '../search/InputText';
import { SearchFormHorizontal } from '../search/SearchFormHorizontal';
import { InputSize } from '../search/InputSize';
import { Page, PageNav } from '../shared/Page';
import { SelectProfileRole } from '../search/SelectProfileRole';
import { toUkDateTimeString } from '../search/MemorableDate';
import { FormattedMessage, useIntl } from 'react-intl';
import CreateNewProfileFlowModal from './CreateNewProfileFlowModal';

const ProfilesListPage = (props) => {
  const intl = useIntl();
  const [showNewProfileModal, setShowNewProfileModal] = useState(false);
  const [timestamp, setTimestamp] = useState(new Date().getTime());
  const apiEndpoint = '/api/profile';
  const columns = [
    {
      label: 'Name',
      sortBy: 'name',
      fromModel: (item) => item.name
    },
    {
      label: 'Contact number',
      sortBy: 'mobile',
      fromModel: (item) => item.mobile
    },
    {
      label: 'Email',
      sortBy: 'email',
      fromModel: (item) => item.email
    },
    {
      label: 'First created',
      sortBy: 'createdAt',
      fromModel: (item) => toUkDateTimeString(item.createdAt)
    },
    {
      label: 'Account',
      sortBy: 'username',
      fromModel: (item) => showAccountColumn(item)
    }
  ];

  function showAccountColumn(item) {
    const revoked = item.locked;
    return (
      <>
        {revoked && (
          <span className="badge badge-danger small">Access revoked</span>
        )}
        {!revoked && item.username && (
          <span className="badge badge-success small">Has login account</span>
        )}
        {!revoked && !item.username && (
          <span className="small">Did not sign up yet</span>
        )}
      </>
    );
  }

  function openNewProfileModal() {
    setShowNewProfileModal(true);
  }

  function onNewProfileModalClose(value) {
    if (value) setTimestamp(new Date().getTime());
    setShowNewProfileModal(false);
  }

  const urlQueryParams = getURLSearchParams();
  const query = urlQueryParams.get(QueryParameter.query.key) || '';
  const role = urlQueryParams.get(QueryParameter.profileRole.key) || '';
  const additionalQueryParams = { query, role, t: timestamp };

  return (
    <Page>
      <PageNav title="nav.profiles.all">
        <span className="mx-auto" />
        <WithPermission name="profile:create">
          <button
            onClick={() => openNewProfileModal()}
            className="btn btn-sm btn-outline-secondary"
          >
            <FormattedMessage id="nav.profiles.add" />
          </button>
        </WithPermission>
      </PageNav>
      <div className="mb-4">
        <SearchFormHorizontal>
          <InputQueryForUrl
            size={InputSize.sm}
            placeholder="Name, phone, email"
            className="mr-sm-3 mb-1"
          />
          <SelectProfileRole
            size={InputSize.sm}
            className="narrow mr-sm-3 mb-1"
            useRouter={true}
          />
        </SearchFormHorizontal>
      </div>
      <ModelTableWithRouterState
        caption="nav.profiles.all"
        apiEndpoint={apiEndpoint}
        additionalQueryParams={additionalQueryParams}
        columns={columns}
        itemHref={(item) => generatePath(`/profiles/show/${item.id}`)}
        canExportData={true}
        {...props}
      />

      {showNewProfileModal && (
        <CreateNewProfileFlowModal
          modalTitle={intl.formatMessage({ id: 'nav.profiles.add' })}
          onModalClose={onNewProfileModalClose}
        />
      )}
    </Page>
  );
};

export default ProfilesListPage;
