import { FC } from 'react';
import { CommonWidgetProps } from './types';

export interface ActionButtonWidgetProps extends CommonWidgetProps {
  /**
   * The icon to use for the button
   */
  icon: JSX.Element;

  /**
   * Help text
   */
  description?: string;

  /**
   * CB for when we get clicked
   */
  onClick: (tag: string) => void;
}

/**
 * Simple action button that adopts a common visual style
 * @param props
 * @constructor
 */
export const ActionButtonWidget: FC<ActionButtonWidgetProps> = (props) => {
  return (
    <button disabled={props.disabled} className={'btn widget'} onClick={(event) => props.onClick(props.tag)}>
      {props.label && (
        <div
          className={
            props.disabled
              ? props.disabled
                ? `widget-label widget-button input-group-text disabled ${
                    props.labelClassName ? props.labelClassName : ''
                  }`
                : `widget-label widget-button input-group-text ${
                    props.labelClassName ? props.labelClassName : ''
                  }`
              : `widget-label widget-button input-group-text ${
                  props.labelClassName ? props.labelClassName : ''
                }`
          }
        >
          {props.label}
          {props.icon}
        </div>
      )}
    </button>
  );
};
