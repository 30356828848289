import {useEffect, useState} from 'react';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import {BasePie} from './basePie';
import {TaskStatus} from "../task/TaskStatusBadge";
import { useAuthContext } from '../auth/AuthContextProvider';
import { FormattedMessage } from 'react-intl';
import { CardWidget } from '../shared/CardWidget';
import {Link} from "react-router-dom";
import {AngleRight} from "../main/icons";

export const TasksAssignedCard = () => {
  return (<CardWidget
    title={'Tasks assigned to you'}
    navItems={<Link  className="btn btn-sm btn-outline-secondary" to={"/tasks/assigned"}>
      Tasks assigned to you <AngleRight className="svg-gradient-primary" height={15} />
    </Link>}>
    <TasksAssignedChart/>
  </CardWidget>);
};

export const TasksAssignedChart = () => {

  const { loading, profile } = useAuthContext();
  const [analyticsData, setAnalyticsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (!loading && profile) {
      axios.get('/api/stats/tasks/assigned')
        .then(response => response.data.data)
        .then(results => {
          setAnalyticsData(results.map(result => ({
            id: TaskStatus[result.status].title,
            label: TaskStatus[result.status].title,
            value: result.count
          })));
        })
        .catch(() => setAnalyticsData([]))
        .finally(() => setIsLoading(false));
    }
  }, [profile, loading]);

  return (<div style={{ height: 300 }} className="d-flex align-items-center justify-content-center">
    {isLoading && <Spinner animation={'border'} variant={'primary'}/>}
    {!isLoading && analyticsData.length === 0 && <p className="text-muted text-center">
      <FormattedMessage id="stats.tasks.assigned.user.notfound" />
    </p>}
    {!isLoading && analyticsData.length > 0 && <BasePie analyticsData={analyticsData}/>}
  </div>);
};
