import traverse from 'json-schema-traverse';
import { JsonPointer } from 'json-ptr';
import { toUkDateString, toUkDateTimeString } from '../search/MemorableDate';

/**
 * Format a value as a string, using its JSON schema to provide hints
 * @param value The value
 * @param elementSchema The JSON schema for that value
 * @returns {string|undefined}
 */
function valueToStringUsingSchema(value, elementSchema) {
  if (value === undefined) return '';
  if (elementSchema.format === 'date') {
    return toUkDateString(value);
  } else if (elementSchema.format === 'date-time') {
    return toUkDateTimeString(value);
  } else if (elementSchema.enum && Array.isArray(elementSchema.enum) && elementSchema.enumNames && Array.isArray(elementSchema.enumNames)) {
    const enumName = elementSchema.enumNames[elementSchema.enum.indexOf(value)];
    // If no matching enumName, return the raw value as string
    return enumName === undefined ? value.toString() : enumName;
  } else if (elementSchema.type === 'boolean') {
    return value === true ? 'Yes' : value === false ? 'No' : "";
  } else {
    return value == null ? '' : value + '';
  }
}

/**
 * Walk a schema and a document associated with that schema, firing callbacks as schema elements are encountered
 *
 * @param schema without $ref elements
 * @param values form data
 * @param onTitleElement function called when title element is found (i.e. something with children)
 * @param onFieldElement function called when field element is found (i.e. something without children)
 * @param onError function called on error
 */
function processSchemaForDocument(schema, values, onTitleElement, onFieldElement, onError) {

  function onSchemaElement(elementSchema, jsonPointer, rootSchema, parentPtr) {
    const dataRef = jsonPointer.replace(/\/properties/g, '');
    const value = JsonPointer.get(values, dataRef);
    const level = dataRef.split('/').length - 1;

    if (jsonPointer.includes('oneOf')) return; // ignore any dependencies (i.e. oneOf)  
    if (!jsonPointer.startsWith('/properties')) return;
    if (elementSchema.$ref) {
      // $refs should have been dereferenced before traverse()
      onError && onError('element schema contains $ref');
    } else if (elementSchema.properties) {
      onTitleElement && elementSchema.title && onTitleElement(elementSchema.title, level, dataRef);
    } else if (Array.isArray(value)) {
      if (elementSchema.items.type === 'object') { 
        if (elementSchema.type !== 'array') {
          onTitleElement && onTitleElement(elementSchema.title, level, dataRef);
          value.forEach(item => {
            processSchemaForDocument(elementSchema.items, item, onTitleElement, onFieldElement, onError);
          });
        }
        else {
          if (elementSchema.items && elementSchema.items.dependencies) {
            let displayValues = [];
            value.forEach(item => {
              displayValues.push(Object.values(item).reverse().join(', '));
            });

            onFieldElement && onFieldElement({
              title: elementSchema.title,
              description: elementSchema.description,
              values: displayValues,
              ref: dataRef,
              type: elementSchema.type
            });
          }
          else {
            onTitleElement && onTitleElement(elementSchema.title, level, dataRef);
            value.forEach(item => {
              processSchemaForDocument(elementSchema.items, item, onTitleElement, onFieldElement, onError);
            });
          }          
        }
      } else {
        onFieldElement && onFieldElement({
          title: elementSchema.title,
          description: elementSchema.description,
          values: value.map(v => valueToStringUsingSchema(v,elementSchema.items)),
          ref: dataRef,
          type: elementSchema.type
        });
      }
    } else if (!jsonPointer.endsWith('/items') && !parentPtr.endsWith('/items')) { // array items are processed in parent
      onFieldElement && onFieldElement({
        title: elementSchema.title,
        description: elementSchema.description,
        values: [valueToStringUsingSchema(value, elementSchema)],
        ref: dataRef,
        type: elementSchema.type
      });
    }
  }

  traverse(schema, {
    cb: onSchemaElement
  });

}

export {processSchemaForDocument, valueToStringUsingSchema};
