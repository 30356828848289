import React, { useState } from 'react';
import { ProfileForm } from './Profile';
import PropTypes from 'prop-types';
import axios from 'axios';
import FormStep from '../flows/core/FormStep';
import FlowContainerModal from '../flows/core/FlowContainerModal';
import { parseAxiosError } from '../shared/AxiosResponseErrorParser';

const CreateNewProfileFlowModal = (props) => {
  const [errorMessage, setErrorMessage] = useState(null);

  function onCancel () {
    props.onModalClose();
  }

  function onSubmit (data) {
    return axios.post('/api/profile', data[0])
      .then(() => {
        props.onModalClose({reload: true});
      })
      .catch(e => {
        setErrorMessage(parseAxiosError(e).getMessage());
      });
  }

  return (<>
    <FlowContainerModal errorMessage={errorMessage} onFinalSubmit={onSubmit} onCancel={onCancel} {...props}>
      <FormStep key={'one'} title="New user profile" schemaFormComponent={ProfileForm}/>
    </FlowContainerModal>
  </>);
};

CreateNewProfileFlowModal.propTypes = {
  onModalClose: PropTypes.func.isRequired
};

export default CreateNewProfileFlowModal;
