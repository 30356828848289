import React, { FC, useEffect, useState } from 'react';
import {
  caseOrTaskMessage,
  mapDisplaySpecification,
  Task,
  TaskConfiguration,
  taskConfigurationVersion,
  UiMessageKey
} from '../types';
import { useAppConfiguration } from '../contexts/AppConfiguration';
import { Link } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

/**
 * Props structure
 */
export interface TaskLinkProps {
  /**
   * The actual {@link Task}
   */
  model: Task;

  /**
   * The limiting number characters to display from the title of the case
   */
  truncationLimit: number;

  /**
   * Whether the link should open in a new tab or not
   */
  newTab: boolean;

  /**
   * Whether the label gets displayed
   */
  showLabel: boolean;

  /**
   * Whether the ref badge gets displayed
   */
  showRefBadge: boolean;

  /**
   * Whether the id for the case should be displayed as a badge
   */
  showId: boolean;
}

/**
 * Have a look at the supplied case, along with its config and build a sensible label for it
 * @param model the case model
 * @param config the case configuration
 * @param truncationLimit
 * @param showId
 */
function buildTaskLabel(
  model: Task,
  config: TaskConfiguration,
  truncationLimit: number
): string[] {
  let label: string | undefined = '';

  // map the title of the case (we may need to extract it from the actual form data)
  if (config.meta.titleDisplaySpecification) {
    label = mapDisplaySpecification(
      config,
      config.meta.titleDisplaySpecification,
      (label, f) => {
        return f(model);
      }
    );
  }

  // grab the label (case type) for the case
  if (!label) {
    const message = caseOrTaskMessage(config, UiMessageKey.TitleSingular);
    return [
      message === '!undefined!' ? model.displayName!! : message,
      message === '!undefined!' ? model.displayName!! : message
    ];
  }

  return [
    label.length > truncationLimit
      ? label.slice(0, truncationLimit) + '...'
      : label,
    label
  ];
}

/**
 * Generic component for displaying a one-line link to a given case, with
 * additional information included such as title, id and status
 * @constructor
 */
export const TaskLink: FC<TaskLinkProps> = ({
  model,
  truncationLimit = 24,
  newTab = true,
  showLabel = true,
  showRefBadge = true,
  showId = false
}) => {
  const appConfig = useAppConfiguration();
  const [taskLabel, setTaskLabel] = useState<string[]>([]);

  useEffect(() => {
    if (appConfig.loaded) {
      const config = taskConfigurationVersion(
        appConfig,
        model.taskRef,
        model.taskVersion
      );
      setTaskLabel(buildTaskLabel(model, config, truncationLimit));
    }
  }, [appConfig, model, model.taskRef, model.taskVersion, truncationLimit]);

  return (
    <>
      <div className={'case-link-container flex-row'}>
        {showRefBadge && (
          <span className={'small-badge flex-column'}>{model.taskRef}</span>
        )}
        {showId && (
          <span className={'small-badge-id flex-column'}>{model.id}</span>
        )}
        <span
          className={'case-link-detail flex-column'}
          style={{ maxWidth: 50 }}
        >
          {newTab && showLabel && (
            <Link
              to={`/tasks/show/${model.id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <OverlayTrigger
                overlay={
                  <Tooltip id={`related-case-tooltip-${model.id}`}>
                    {taskLabel[1]}
                  </Tooltip>
                }
                placement={'top'}
              >
                <span className={'related-case-label'}>{taskLabel[0]}</span>
              </OverlayTrigger>
            </Link>
          )}
          {!newTab && showLabel && (
            <Link to={`/cases/show/${model.id}`}>
              <OverlayTrigger
                overlay={
                  <Tooltip id={`related-case-tooltip-${model.id}`}>
                    {taskLabel[1]}
                  </Tooltip>
                }
                placement={'top'}
              >
                <span className={'related-case-label'}>{taskLabel[0]}</span>
              </OverlayTrigger>
            </Link>
          )}
        </span>
      </div>
    </>
  );
};
