import React from 'react';
import { Page, PageNav } from '../shared/Page';
import { useAuthContext } from '../auth/AuthContextProvider';
import ReleaseNotesComponent from './ReleaseNotesComponent';

export function ReleaseNotesPage () {
  const { isAuthenticated } = useAuthContext();

  if (!isAuthenticated) {
    return null;
  }

  return <>
    <Page>
      <PageNav title="nav.whats_new_in_product" />
      <div className="bg-light py-3">
        <div className="container">
          <div className="row align-items-start">
            <div className="col-md-10 offset-md-1">
              <div className="card border-white shadow-sm rounded">
                <div className="card-body pl-0 pr-0">
                  <ReleaseNotesComponent />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  </>;
}
