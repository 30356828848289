import React from 'react';
import { combineClassNames } from '../../functions/ClassNameUtils';
import { newUUID } from '../../functions';
import { CommonWidgetProps } from './types';

/**
 * Props for a search text widget
 */
export interface LabelledTextWidgetProps extends CommonWidgetProps {

  /**
   * A prompt to go in the text input
   */
  prompt?: string;

  /**
   * Classname for the input component (a select element)
   */
  inputClassName?: string;

  /**
   * Initial text value
   */
  initialValue?: any;

  /**
   * Optional input field type
   */
  inputType?: string;

  /**
   * Optional input mode for the input field
   */
  isNumeric?: boolean;

  /**
   * A selection change callback
   * @param parameterKey
   * @param newValue
   */
  onValueChange: (parameterKey: string, newValue?: string) => void;

  /**
   * A callback for when the enter key is pressed
   * @param parameterKey
   * @param newValue
   */
  onEnterPressed?: (parameterKey: string, currentValue?: string) => void;
}

/**
 * Generic search text widget based on a flex layout
 * @param label the label for the component
 * @param labelClassName (optional) label class name
 * @param inputClassName (optional) input class name
 * @param items a list of items to display within the dropdown
 * @constructor
 */
export const LabelledTextWidget = React.forwardRef<
  HTMLInputElement,
  LabelledTextWidgetProps
>((props, ref) => {

  // proxy the key up event so that we can dispatch a separate event for
  // enter key presses
  function onKeyUpProxy(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter') {
      if (props.onEnterPressed){
        props.onEnterPressed(props.tag, event.currentTarget.value);
      }
    }else{
      props.onValueChange(props.tag, event.currentTarget.value);
    }
  }

  return (
    <>
      <div className="widget">
        <label
          htmlFor={`labelled_text_${props.tag}`}
          className={
            props.labelClassName
              ? combineClassNames(
                props.labelClassName,
                'widget-label input-group-text no-right-border'
              )
              : 'widget-label input-group-text no-right-border'
          }
        >
          {props.label}
        </label>
        <input
          id={`labelled_text_${props.tag}`}
          ref={ref}
          type={props.inputType ? props.inputType : 'text'}
          inputMode={props.isNumeric ? 'numeric' : 'text'}
          pattern={props.isNumeric ? '[0-9]*' : undefined}
          placeholder={
            props.prompt ? props.prompt : 'Enter text here...'
          }
          onKeyUp={(event) =>
            onKeyUpProxy(event)
          }
          defaultValue={props.initialValue}
          className={
            props.inputClassName
              ? combineClassNames(props.inputClassName, ['widget-field','no-left-border'])
              : 'widget-field no-left-border'
          }
        ></input>
      </div>
    </>
  );
});
