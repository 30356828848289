import { Case, Task } from '../types';
import { toUkDateString } from '../../search/MemorableDate';
import { valueByJsonPointerPath } from './JSONPointerFunctions';
import * as _ from 'lodash';

/**
 * Bottom type for rendering functions
 */
export type EmptyString = '';

/**
 * Type definition for well-behaved column rendering functions
 */
export type ValueRenderingFunction = (value: any) => string | EmptyString;

export function extractCaseOrTaskValueByPath(
  source: Case | Task,
  path: string
) {
  if (_.has(source, 'caseRef')) {
    return valueByJsonPointerPath(path, (source as Case).details);
  } else if (_.has(source, 'formRef')) {
    return valueByJsonPointerPath(path, (source as Task).formModelJson);
  } else {
    return undefined;
  }
}

/**
 * Renders the raw value (string) of a given value, after extracting it from a given case
 * @param path
 */
export function rawRenderer(path: string) {
  return (item: Case | Task) => {
    const value = extractCaseOrTaskValueByPath(item, path);
    return value ? value : '';
  };
}

/**
 * Takes a value and attempts to render it as a UK date string
 * @param path
 */
export function dateTimeRenderer(path: string) {
  return (item: Case | Task) => {
    const value = extractCaseOrTaskValueByPath(item, path);
    const ukDateValue = toUkDateString(value);
    return ukDateValue ? ukDateValue : '';
  };
}

/**
 * Converts a boolean value into a string from (Yes|No)
 * @param path
 */
export function yesNoBooleanRenderer(path: string) {
  return (item: Case | Task) => {
    const value = extractCaseOrTaskValueByPath(item, path);
    if (value) {
      return (value as boolean) ? 'Yes' : 'No';
    } else {
      return 'No';
    }
  };
}

/**
 * Takes a boolean and renders it to a string from (True|False)
 * @param path
 */
export function trueFalseBooleanRenderer(path: string) {
  return (item: Case | Task) => {
    const value = extractCaseOrTaskValueByPath(item, path);
    if (value) {
      return (value as boolean) ? 'True' : 'False';
    } else {
      return 'False';
    }
  };
}

/**
 * Applies a custom rendering function to an extracted value.  Checked execution
 * is carried out, in case we fault.
 * @param path
 * @param source
 */
export function dynamicRenderer(path: string, source: string) {
  return (item: Case | Task) => {
    try {
      const value = extractCaseOrTaskValueByPath(item, path);
      // eslint-disable-next-line no-new-func
      const f = Function('value', source);
      return f(value);
    }catch (e) {
    }
  };
}
