import {JsonPointer} from "json-ptr";

/**
 * Cache is just a map really
 */
type JsonPointerCache = {
  [key: string]: JsonPointer;
};

/**
 * Cache pointers here
 */
const memoisedPointers: JsonPointerCache = {};

/**
 * Given a json pointer, determine if the pointer references an individual array item (useful for
 * filtering schema elements for example)
 * @param ptr
 */
export function pointsToArrayItem(ptr : JsonPointer) : boolean {
  const segments = ptr.path;
  for (let segment of segments){
    if (!Number.isNaN(Number(segment))){
      return true;
    }
  }
  return false;
}

/**
 * Quick and dirty conversion between a pointer and path format (the server
 * needs things in a path format for queries)
 * @param ptr
 */
export function jsonPointerToJsonPath(ptr : string) : string {
  return `$${ptr.replaceAll("/", ".")}`;
}

/**
 * Given a reference in string form, produce a `JsonPointer`
 * @param ref
 */
export function jsonPointerFromReference(ref: string): JsonPointer {
  if (memoisedPointers[ref] === undefined) {
    memoisedPointers[ref] = JsonPointer.create(ref);
  }
  return memoisedPointers[ref];
}

/**
 * Given a path into a structure, probe the give model and get
 * the value
 * @param path the (JSON pointer compliant) path into the structure
 * @param model the model to probe
 */
export function valueByJsonPointerPath(path: string, model: any): any {
  const ptr = jsonPointerFromReference(path);
  return ptr.get(model);
}


/**
 * Give this function an object, and it will return an array of json pointers,
 * each one for each key in the object, prefixed by the passed in prefix
 * @param prefix the prefix to apply
 * @param source the source object
 */
export function prefixedPointersForObject(
  prefix: string | undefined,
  source: Object
): JsonPointer[] {
  let ptrs: JsonPointer[] = [];
  for (let key in source) {
    if (prefix) {
      ptrs.push(jsonPointerFromReference(`${prefix}/${key}`));
    } else {
      ptrs.push(jsonPointerFromReference(`/${key}`));
    }
  }
  return ptrs;
}

/**
 *
 * @param path
 * @param model
 * @param value
 */
export function addOrReplaceValueByJsonPath(path : string, model: any, value: any) {
  const ptr = jsonPointerFromReference(path);
  return ptr.set(model, value, true);
}

/**
 *
 * @param path
 * @param model
 */
export function unsetValueByJsonPath(path : string, model : any) {
  const ptr = jsonPointerFromReference(path);
  ptr.unset(model);
}
