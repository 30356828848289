import {
  Case,
  CaseConfiguration,
  caseConfigurationVersion,
  mapDisplaySpecifications
} from '../../shared/types';
import React, { FC, useEffect, useState } from 'react';
import { useAppConfiguration } from '../../shared/contexts/AppConfiguration';
import { CaseDetailsRow } from '../../case/CaseDetailsRow';
import { newUUID } from '../../shared/functions';
import { CaseLink } from '../../shared/components/CaseLink';
import { CaseStatusBadge } from '../../case/status/CaseStatus';
import { CommonDateTime } from '../../shared/Dates';

export interface CaseSearchResultItemProps {

  /**
   * The {@link Case} entity to display details for
   */
  result: Case;

  /**
   * Callback for when a result is selected
   * @param result
   */
  onSelected: (result: Case) => void
}

/**
 * Component that displays a summary of a case item (for use in search results etc...)
 * @param model a valid {@link Case} instance
 * @constructor
 */
export const CaseSearchResultItem: FC<CaseSearchResultItemProps> = ({ result , onSelected}) => {
  const appConfig = useAppConfiguration();
  const [caseConfig, setCaseConfig] = useState<CaseConfiguration | undefined>();
  const [details, setDetails] = useState<JSX.Element[]>([]);

  useEffect(() => {
    if (appConfig.loaded && caseConfig === undefined) {
      const caseConfig = caseConfigurationVersion(
        appConfig,
        result.caseRef,
        result.caseVersion
      );
      setCaseConfig(caseConfig);
      if (caseConfig.meta.searchDisplaySpecifications !== undefined) {
        setDetails(
          mapDisplaySpecifications(
            caseConfig,
            caseConfig.meta.searchDisplaySpecifications,
            (label, f) => {
              return (
                <CaseDetailsRow
                  key={`case-details-badge-${newUUID()}`}
                  value={f(result)}
                  label={label}
                />
              );
            }
          )
        );
      }
    }
  }, [appConfig, caseConfig, result]);

  return (
    <>
      <div className={'search-result-item'} onClick={(_) => onSelected(result)}>
        <div data-testid="label" className={'link'}>
          <CaseLink
            model={result}
            truncationLimit={36}
            newTab={true}
            showLabel={true}
            showId={true}
          />
        </div>
        <div className={'status'}>
          <CaseStatusBadge
            status={result.status}
            withLock={true}
          />
        </div>
        <div data-testid="details" className={'details'}>
          {details}
        </div>
        <hr />
        <div className="supplementary">
          <span data-testid={'supplementary-created'}>
            {/* eslint-disable-next-line react/jsx-no-undef */}
            Created on <CommonDateTime date={result.createdAt} />
          </span>
          {result.creator && (
            <span data-testid='supplementary-creator' className={'supplementary'}>{result.creator.displayName}</span>
          )}
        </div>
      </div>
    </>
  );
};
