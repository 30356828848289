import React, {useCallback, useEffect, useReducer, useState} from 'react';
import ModelListViewSelectMultiple from "../models/ModelListViewSelectMultiple";
import {WithPermission} from "../auth/AccessConditionals";
import {useAuthContext} from "../auth/AuthContextProvider";
import {SearchFormHorizontalDefault} from "../search/SearchFormHorizontalDefault";
import {InputQueryWithCallback} from "../search/InputText";
import {SelectProfileRole} from "../search/SelectProfileRole";
import {AssigneeListItem} from "./AssigneeListItem";
import PropTypes from "prop-types";
import {InputSize} from "../search/InputSize";
import {Modal} from "react-bootstrap";
import {useApiList} from "../models/useApi";
import ListLoader from "../loaders/ListLoader";

export const SelectProfilesModal = ({
  initialIds = [],
  onSelectIds,
  onHide
}) => {

  const [initial, setInitial] = useState(null);
  const [selections, setSelections] = useState([]);

  // initialIds may contain comma-separated ids (1,3,9), or comma-separated value|name pairs, (1|foo,3|bar,9|baz)
  const { loading, error, items } = useApiList(`/api/profile/some/${initialIds.map(idOrNameValuePair=>idOrNameValuePair.split('|').shift()).join(',')}`);

  useEffect(() => {
    if (!loading && items !== null) setInitial && setInitial(items);
    if (!loading && error !== null) setInitial && setInitial([]);
  }, [setInitial, loading, items, error]);

  const onChange = useCallback(items => setSelections(items), [setSelections]);
  return <Modal data-testid="modal-profile-selector" onHide={() => onHide && onHide()} centered show animation={false}>
    <Modal.Header closeButton>
      Select profiles
    </Modal.Header>
    <Modal.Body>
      {loading && <ListLoader/>}
      {!loading && initial !== null && <SelectProfilesForm existing={initial} onChange={onChange} />}
    </Modal.Body>
    <Modal.Footer>
      <button type="button" className="btn btn-outline-secondary mr-auto" onClick={() => onSelectIds([])}>Clear</button>
      <button type="button" disabled={selections.length < 1} className="btn btn-primary ml-auto" onClick={() => onSelectIds(selections.map(it => `${it.id}|${it.name}`))}>Select</button>
    </Modal.Footer>
  </Modal>;
};
SelectProfilesModal.propTypes = {
  initialIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSelectIds: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired
};

export const SelectProfilesForm = ({
  existing = [],
  onChange
}) => {

  const { profile } = useAuthContext();

  const [profiles, setProfiles] = useState(existing || []);
  const [additionalApiParams, setAdditionalApiParams] = useReducer(
    (state, newState) => ({ ...state, ...newState }), {
      sort: 'name',
      order: 'asc',
      excludeIds: profile && profile.id
    });

  useEffect(() => {
    if (Array.isArray(existing)) {
      setProfiles && setProfiles([...existing]);
    } else {
      setProfiles && setProfiles([]);
    }
  }, [setProfiles, existing]);

  const onQueryChange = useCallback(query => {
    setAdditionalApiParams({query: query});
  }, [setAdditionalApiParams]);

  const onRoleChange = useCallback(role => {
    setAdditionalApiParams({role: role});
  }, [setAdditionalApiParams]);

  const isSelfSelected = () => profiles.findIndex(it => it.id === profile.id) > -1;

  const onSelfToggle = () => {
    const currentIndex = profiles.findIndex(it => it.id === profile.id);
    let updatedProfiles;
    if (currentIndex > -1) {
      updatedProfiles = [...profiles];
      updatedProfiles.splice(currentIndex, 1);
    } else {
      updatedProfiles = [profile, ...profiles];
    }
    setProfiles(updatedProfiles);
    onChange(updatedProfiles);
  };

  const onProfilesUpdated = items => {
    let updatedProfiles = [...items];
    setProfiles(updatedProfiles);
    onChange(updatedProfiles);
  };

  return (<>
    <div className="mb-3">
      <SearchFormHorizontalDefault>
        <InputQueryWithCallback size={'sm'} placeholder="Name, phone, email" onSearch={onQueryChange} className="mr-sm-3 mb-1" />
        <WithPermission name="profile:read">
          <SelectProfileRole size={InputSize.sm} className="mr-sm-3 mb-1 narrow" useRouter={false} onChange={onRoleChange} value={additionalApiParams.role} />
        </WithPermission>
      </SearchFormHorizontalDefault>
    </div>

    { profile && <div className="list-group mb-3">
      <button type="button" data-testid="assign-me" onClick={() => onSelfToggle()} className={`list-group-item list-group-item-action ${ isSelfSelected() ? 'active' : ''}`}>
        <AssigneeListItem model={profile} />
      </button>
    </div> }

    <ModelListViewSelectMultiple
      apiUrl={'/api/profile'}
      additionalApiParams={additionalApiParams}
      initialSelection={profiles}
      onItemsUpdated={items => onProfilesUpdated(items)}
      listItemRenderer={AssigneeListItem}
    />
  </>);
};

SelectProfilesForm.propTypes = {
  existing: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
    email: PropTypes.string
  })).isRequired,
  onChange: PropTypes.func.isRequired
};
