import React from 'react';
import { Modal } from 'react-bootstrap';
import FlowContainer from './FlowContainer';
import PropTypes from 'prop-types';

class FlowContainerModal extends FlowContainer {

  getNav () {
    const {onCancel} = this.props;
    return (<>
      {!this.isFirstStep() && (
        <button data-testid="flow-btn-previous" type="button" className="btn btn-outline-secondary btn-sm pl-5 pr-5 mr-auto" onClick={() => this.goPrevious()}>Previous</button>)}
      {this.isFirstStep() && onCancel && (
        <button data-testid="flow-btn-cancel" type="button" className="btn btn-outline-secondary btn-sm pl-5 pr-5 mr-auto" onClick={() => this.goPrevious()}>Cancel</button>)}
      <span className="mx-auto"/>
      <button data-testid="flow-btn-next" type="button" className="btn btn-primary btn-sm pl-5 pr-5" onClick={() => this.goNext()}>
        {this.isLastStep() ? 'Submit' : 'Next step'}
      </button>
    </>);
  }

  render () {
    const {modalTitle, onCancel, errorMessage} = this.props;
    this.updateClonedChildren();
    return (<Modal data-testid="flow-dialog" centered show onHide={() => onCancel()} backdrop="static" animation={false}>
      <Modal.Header closeButton>
        <Modal.Title data-testid="flow-dialog-title">
          <h1>{modalTitle}</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="form-modal form-flow-modal-height">
        {errorMessage && errorMessage.length && <div className="alert alert-danger">{errorMessage}</div>}
        {this.getCurrentCard()}
      </Modal.Body>
      <Modal.Footer>
        {this.getNav()}
      </Modal.Footer>
    </Modal>);
  }
}

FlowContainerModal.propTypes = {
  ...FlowContainer.propTypes,
  modalTitle: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  errorMessage: PropTypes.string
};

export default FlowContainerModal;
