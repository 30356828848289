import React from 'react';
import { Link } from 'react-router-dom';
import { WithPermission } from '../../../auth/AccessConditionals';

/**
 * Following ES6 modules work in the context of react-scripts only, that depend on Webpack.
 * If they need to be used in CommonJS environment then refactor to use React.createClass
 */

export const CaseDetails = ({ model, ...rest }) => {
  return <div {...rest}>
    <address>
      Department: {model.department}<br/>
      Project name: {model.projectName}<br/>
      Project description: {model.projectDescription}<br/>
      Project sponsor: {model.projectSponsor}<br/>
      DPIA owner: {model.owner}<br/>
      DPIA Signatory: {model.signatory}<br/>
      IG Lead: {model.lead}
    </address>
    <WithPermission name="case:read">
      <SimilarCasesLink model={model}/>
    </WithPermission>
  </div>;
};

export { HomePageContent } from './HomePageContent';
export { AboutPageContent } from './AboutPageContent';

const SimilarCasesLink = ({ model }) => {
  return <Link to={`/cases/search?caseRef=project_1&projectName=${model.projectName}`} className="btn btn-sm btn-outline-secondary">Find similar</Link>;
};


