import React from 'react';
import PropTypes from 'prop-types';
import ChangeTaskAssigneeFlow from '../flows/ChangeTaskAssigneeFlowComponent';
import ModelInstanceView from '../models/ModelInstanceView';

const TaskAssigneeChangeFlowModal = (props) => {
  return (<ModelInstanceView floating={true} apiUrl={`/api/task/${props.taskId}`} viewItem={ChangeTaskAssigneeFlow} {...props}/>);
};

TaskAssigneeChangeFlowModal.propTypes = {
  taskId: PropTypes.number.isRequired,
  onModalClose: PropTypes.func.isRequired,
};

export default TaskAssigneeChangeFlowModal;
