import Ajv from 'ajv';
import { customFormats } from '@lookinglocal/react-jsonschema-form-extensions';
import Engine from 'json-rules-engine-simplified';
import { rulesRunner } from 'rjsf-conditionals';

function createAjvInstance() {
  const ajv = new Ajv({
    errorDataPath: 'property',
    allErrors: true,
    multipleOfPrecision: 8,
    schemaId: 'auto',
    unknownFormats: 'ignore'
  }); // add custom formats

  ajv.addFormat(
    'data-url',
    /^data:([a-z]+\/[a-z0-9-+.]+)?;(?:name=(.*);)?base64,(.*)$/
  );
  ajv.addFormat(
    'color',
    /^(#?([0-9A-Fa-f]{3}){1,2}\b|aqua|black|blue|fuchsia|gray|green|lime|maroon|navy|olive|orange|purple|red|silver|teal|white|yellow|(rgb\(\s*\b([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])\b\s*,\s*\b([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])\b\s*,\s*\b([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])\b\s*\))|(rgb\(\s*(\d?\d%|100%)+\s*,\s*(\d?\d%|100%)+\s*,\s*(\d?\d%|100%)+\s*\)))$/
  );

  Object.keys(customFormats).forEach((k) => ajv.addFormat(k, customFormats[k]));

  return ajv;
}

const ajv = createAjvInstance();

/**
 * Validates data against a schema, returning true if the data is valid, or
 * false otherwise. If the schema is invalid, then this function will return
 * false.
 */
export function isValid(schema, data) {
  try {
    return ajv.validate(schema, data);
  } catch (e) {
    return false;
  }
}

export function isTaskFormValid(taskConfiguration, formModel) {
  const { schema, uiSchema, rules, actions } = taskConfiguration.form || {};
  const extraActions = window.eval(actions) || {};

  if (rules && rules.length) {
    let runRules = rulesRunner(
      schema,
      uiSchema || {},
      rules,
      Engine,
      extraActions
    );

    return runRules(formModel).then(
      ({ schema: conditionalSchema, formData }) => {
        return isValid(conditionalSchema, formData);
      }
    );
  } else {
    return isValid(schema, formModel);
  }
}
