import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ListLoader from '../loaders/ListLoader';
import { useApiGet } from './useApi';
import { Modal } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';

// TODO add ability to load multiple models to mitigate unnecessary nesting of <ModelInstanceView>
const ModelInstanceView = ({
  floating = false,
  apiUrl,
  viewItem: ViewComponent,
  className,
  notFoundMessage,
  ...rest
}) => {

  const [cacheTime, setCacheTime] = useState(new Date().getTime());
  const [ modalDismissed, setModalDismissed ] = useState(false);
  const { loading, error, data, state } = useApiGet(apiUrl, {cacheBuster: cacheTime});

  const reloadData = (timestamp) => {
    setCacheTime(timestamp);
  };

  function notFoundView (notFoundMessage) {
    return <div className="alert alert-info" data-testid="model-instance-not-found">{notFoundMessage || 'Nothing found'}</div>;
  }

  function errorView () {
    return <div className="alert alert-danger" data-testid="model-instance-error">{error}</div>;
  }

  if (floating === true) {
    return (<>
      {loading && !modalDismissed && <Modal centered show onHide={() => setModalDismissed(true)}><Modal.Header closeButton /><Modal.Body className="text-center p-5"><Spinner animation={'border'} variant={'primary'} /></Modal.Body></Modal>}
      {!loading && !modalDismissed && error && <Modal centered show onHide={() => setModalDismissed(true)}><Modal.Header closeButton /><Modal.Body>{errorView()}</Modal.Body></Modal>}
      {!loading && !modalDismissed && !error && !data && <Modal centered show onHide={() => setModalDismissed(true)}><Modal.Header closeButton /><Modal.Body>{notFoundView(notFoundMessage)}</Modal.Body></Modal>}
      {!loading && !error && data && <ViewComponent model={data} state={state || { editable: true }} reload={reloadData} {...rest} />}
    </>);
  }

  return (<>
    {loading && <ListLoader data-testid="model-instance-loader"/>}
    {!loading && error && errorView()}
    {!loading && !error && !data && notFoundView(notFoundMessage)}
    {!loading && !error && data && <ViewComponent model={data} state={state || { editable: true }} reload={reloadData} {...rest} />}
  </>);
};

ModelInstanceView.propTypes = {
  apiUrl: PropTypes.string.isRequired,
  viewItem: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
  className: PropTypes.string,
  notFoundMessage: PropTypes.string
};

export default React.memo(ModelInstanceView);
