// @ts-ignore
import * as jiff from 'jiff';
import _ from 'lodash';
import {
  addOrReplaceValueByJsonPath,
  unsetValueByJsonPath,
  valueByJsonPointerPath
} from './JSONPointerFunctions';
import { objectHash } from '../ObjectUtils';
import { compileFormRunnables, TaskConfiguration } from '../types';

/**
 * Interface defining the structure of a context passed through to an optional
 * form mutation function. Instances of this context are passed through to
 * form functions, buy may be combined with "outside context" information supplied
 * by the host application.  (Of course, the form functions need to be aware of
 * any application-specific context).
 */
export interface FormHandlerContext {
  /**
   * The original schema of the form, before any changes applied by rsjf-conditionals
   */
  originalSchema: object;

  /**
   * The original UI schema of the form, before any changes applied by rsjf-conditionals.
   * This will typically be defined within the host application as a task form configuration.
   */
  originalUiSchema: object;

  /**
   * The original set of rules defined for the form. If you are fully embracing
   * the use of form functions, then there will likely be no rules, although it's
   * entirely possible to use both.
   */
  originalRules: FormRule[];

  /**
   * Diff between the original (configured) and the current schema. Within a
   * given update call to a function, the schema may have deviated from the
   * originally configured schema. Here's a diff so you can check within the form
   * function.
   */
  schemaDiff: object;

  /**
   * Diff between the original (configured ui schema) and the current ui schema
   */
  uiSchemaDiff: object;

  /**
   * The current form data
   */
  formData: object;

  /**
   * Any current form errors.
   */
  errors: object;

  /**
   * The id schema for the form.  Allows lookup within the DOM of elements
   * making up the form
   */
  idSchema: object;

  /**
   * The current rules for the form. If these are changed, then updates to the
   * form will happen in realtime.  The form function context provides convenience
   * helper functions to assist with the manipulation of rules.
   */
  rules: FormRule[];

  /**
   * A state object. Form functions can maintain state between updates using this
   * container object.
   */
  state: object;
}

/**
 * Used to capture the results of a form mutation
 */
export interface FormHandlerResult {
  /**
   * Updated form data to be applied
   */
  formData?: object;
}

type FormChanges = Partial<{
  schema: object;
  uiSchema: object;
  idSchema: object;
  formData: object;
  errors: object;
}>;

/**
 * Type which assists when dealing with form rules
 */
export type FormRule = Partial<{
  tag: string | undefined;
  conditions: object;
  event: object;
}>;

/**
 * Given a rules array, attempt to locate a rule with a specific tag value
 * @param rules
 */
export const findTaggedRule = (rules: FormRule[]) => {
  return (tag: string) => {
    return rules
      .filter((rule) => rule.tag !== undefined)
      .find((rule) => rule.tag === tag);
  };
};

/**
 * Given a tag and a set of rules, remove the tagged rule
 * @param rules
 */
export const removeTaggedRule = (rules: FormRule[]) => {
  return (tag: string) => {
    const rule = rules
      .filter((rule) => rule.tag !== undefined)
      .find((rule) => rule.tag === tag);
    if (rule) {
      return _.remove(rules, (rule) => rule.tag === tag);
    }
  };
};

/**
 * Add or replace a tagged rule
 * @param rules
 */
export const addOrReplaceTaggedRule = (rules: FormRule[]) => {
  return (tag: string, rule: FormRule) => {
    _.remove(rules, (rule) => rule.tag === tag);
    if (!_.has(rule, 'tag')) {
      rule = {
        tag: tag,
        ...rule
      };
    }
    rules.push(rule);
  };
};

/**
 * Create an "always" true field condition. We can't just omit a condition,
 * or use an always true predicate (they don't exist) so we create one...
 * @param id
 */
const fieldTautology = (id: string) => {
  return JSON.parse(`
      {
        "conditions": {
          "or" : [
            {
                "${id}" : "undef"
            },
            {
              "not" : {
                "${id}" : "undef"
              }
            }
          ]
        }
      }
    `);
};

/**
 * Generate a conditional event which will remove a field from a form
 * @param id
 */
const fieldRemovalEvent = (id: string) => {
  return JSON.parse(
    `
      {
        "event": {
              "type" : "remove",
              "params": {
                "field" : "${id}"
              }
            }
      }
      `
  );
};

/**
 * Add a machine generated rule for the removal of a specific field. Given the
 * crappy way that the rules work, we basically do this by defining a tautology
 * rule which always evaluates to true
 * @param rules
 */
export const addFieldRemovalRule = (rules: FormRule[]) => {
  return (id: string) => {
    const hash = objectHash(id, false);
    const removalTag = `remove_${hash}`;
    _.remove(rules, (rule) => rule.tag === removalTag);
    const conditions = fieldTautology(id);
    const event = fieldRemovalEvent(id);
    rules.push({
      tag: removalTag,
      ...conditions,
      ...event
    });
  };
};

/**
 * Remove any machine generated removal rules for a given form element
 * @param rules
 */
export const deleteFieldRemovalRule = (rules: FormRule[]) => {
  return (id: string) => {
    const hash = objectHash(id, false);
    const removalTag = `remove_${hash}`;
    _.remove(rules, (rule) => rule.tag === removalTag);
  };
};

/**
 * Given a path, grab the value at that location within a form
 * @param form
 */
export const getFormValueByPath = (form: any) => {
  return (path: string) => {
    return valueByJsonPointerPath(path, form);
  };
};

/**
 * Given a path, update the value at that location
 * @param form
 */
export const setFormValueByPath = (form: any) => {
  return (path: string, value: any) => {
    return addOrReplaceValueByJsonPath(path, form, value);
  };
};

/**
 * Given a path, delete the value at that location in the form
 * @param form
 */
export const deleteFormValueByPath = (form: any) => {
  return (path: string) => {
    const existing = valueByJsonPointerPath(path, form);
    if (existing) {
      unsetValueByJsonPath(path, form);
    }
  };
};

/**
 * Build a context to be passed through to invocations of the form function.
 * @param originalSchema
 * @param originalUiSchema
 * @param originalRules
 * @param changes
 * @param rules
 * @param state
 * @param appContext
 */
export function prepareHandlerEvaluationContext(
  originalSchema: object,
  originalUiSchema: object,
  originalRules: FormRule[],
  changes: FormChanges,
  rules: FormRule[],
  state: object,
  appContext?: object
): object {
  const baseContext = {
    originalSchema: originalSchema,
    originalUiSchema: originalUiSchema,
    originalRules: originalRules,
    schemaDiff: jiff.diff(originalSchema, changes.schema),
    uiSchemaDiff: jiff.diff(originalUiSchema, changes.uiSchema),
    formData: changes.formData,
    errors: changes.errors,
    idSchema: changes.idSchema,
    rules: rules,
    state: state,

    // rule related
    findTaggedRule: findTaggedRule(rules),
    removeTaggedRule: removeTaggedRule(rules),
    addOrReplaceTaggedRule: addOrReplaceTaggedRule(rules),
    addFieldRemovalRule: addFieldRemovalRule(rules),
    deleteFieldRemovalRule: deleteFieldRemovalRule(rules),

    // form value related
    getFormValueByPath: getFormValueByPath(changes.formData),
    setFormValueByPath: setFormValueByPath(changes.formData),
    deleteFormValueByPath: deleteFormValueByPath(changes.formData)
  };

  if (appContext) {
    return {
      ...baseContext,
      ...appContext
    };
  } else {
    return baseContext;
  }
}

/**
 * In order to win the fight with the TS compiler
 */
type FormHandlerFunction = (eventType: string, context: object) => object;

/**
 * Given a task configuration, and an event type compile the runnables associated with the
 * task form, and then execute the handler.  We return a structure containing revised rules,
 * schema, uiSchema and an arbitrary object returned by the form handler.  App specific
 * context can be provided through the appContext parameter
 */
export function evaluateFormHandler(
  taskConfig: TaskConfiguration,
  schema: object,
  uiSchema: object,
  rules: FormRule[],
  formData: object,
  eventType: string,
  appContext?: object
) {
  const runnables = compileFormRunnables(taskConfig);
  const changes = {
    formData: formData,
    errors: undefined,
    idSchema: undefined
  };
  let handlerResults = undefined;

  // prepare a "streamlined" evaluation context
  const context = prepareHandlerEvaluationContext(
    schema,
    uiSchema,
    rules,
    changes,
    rules,
    {},
    appContext
  );

  // do we have a handler? If so, execute it and stash any returned values
  if (runnables[1] !== undefined) {
    const callable = runnables[1] as FormHandlerFunction;
    console.log('callable with ' + eventType);
    handlerResults = callable(eventType, context);
  }

  return handlerResults;
}
