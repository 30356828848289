import React, { FC, useEffect, useState } from 'react';
import { getActivitySubjectLink } from './ActivityLink';
import { CaseStatusBadge } from '../case/status/CaseStatus';
import {
  Case,
  caseConfigurationVersion,
  caseOrTaskMessage,
  UiMessageKey
} from '../shared/types';
import { useAppConfiguration } from '../shared/contexts/AppConfiguration';
import useAxios from 'axios-hooks';

export interface ActivityCaseSummaryProps {
  subject: Case;
}

export const ActivityCaseSummary: FC<ActivityCaseSummaryProps> = ({
  subject
}) => {
  const appConfig = useAppConfiguration();
  const [caseLabel, setCaseLabel] = useState<string>();
  const [haveCaseDetails, setHaveCaseDetails] = useState<boolean>(false);
  const [{ loading }, fetch] = useAxios(
    {
      url: `/api/case/${subject.id}`
    },
    { manual: true }
  );

  // this effect ensures that if we are passed an incomplete case structure,
  // (i.e. not enough information to retrieve the configuration for the case)
  // we go to the server and fetch it
  useEffect(() => {
    if (!haveCaseDetails) {
      if (subject.caseRef && subject.caseVersion) {
        setHaveCaseDetails(true);
      } else {
        fetch()
          .then((response) => {
            subject.caseRef = response.data.data.caseRef;
            subject.caseVersion = response.data.data.caseVersion;
            setHaveCaseDetails(true);
          })
          .catch(() => {});
      }
    }
  }, [fetch, haveCaseDetails, loading, subject]);

  // only try and locate the case configuration details if we've got a complete
  // case structure (i.e. we know the case reference and version)
  useEffect(() => {
    if (appConfig.loaded && haveCaseDetails) {
      const config = caseConfigurationVersion(
        appConfig,
        subject.caseRef,
        subject.caseVersion
      );
      setCaseLabel(
        `${caseOrTaskMessage(config, UiMessageKey.TitleSingular)} #${
          subject.id
        }`
      );
    }
  }, [
    appConfig,
    appConfig.loaded,
    haveCaseDetails,
    subject.caseRef,
    subject.caseVersion,
    subject.id
  ]);

  return (
    <>
      {haveCaseDetails && (
        <a href={getActivitySubjectLink('Case', subject.id)}>
          <span>{caseLabel}</span>
          <CaseStatusBadge
            status={subject.status}
            withLock={true}
          />
        </a>
      )}
    </>
  );
};
