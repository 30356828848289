import React, { useCallback, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import ListLoader from '../loaders/ListLoader';
import PaginationWrapper from '../shared/Pagination';
import { useApiList } from './useApi';
import { Close } from '../main/icons';
import _ from 'lodash';

function ListGroupItem({ children, ...rest }) {
  return (
    <button type="button" {...rest}>
      {children}
    </button>
  );
}

function DefaultListItem({ model = {}, ...rest }) {
  return <div {...rest}>{model.displayName}</div>;
}

const ModelListViewSelectMultiple = ({
  onItemsUpdated,
  apiUrl,
  additionalApiParams,
  listItemRenderer: ItemComponent = DefaultListItem,
  initialSelection,
  className,
  notFoundMessage,
  pusherChannelName,
  onPusherMessage,
  title,
  ...rest
}) => {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      max: 5,
      offset: 0,
      selectedItems: initialSelection
    },
    (initialArg) => initialArg
  );

  const apiParams = additionalApiParams || {};
  const { loading, error, items, total } = useApiList(apiUrl, {
    max: state.max,
    offset: state.offset,
    ...apiParams
  });

  useEffect(() => {
    if (Array.isArray(initialSelection)) {
      setState({ selectedItems: initialSelection });
    } else {
      setState({ selectedItems: [] });
    }
  }, [initialSelection]);

  function findItemIndex(item) {
    const itemJson = JSON.stringify(item);
    return (state.selectedItems || []).findIndex(
      (existing) => JSON.stringify(existing) === itemJson
    );
  }

  function onClick(item) {
    const existingIndex = findItemIndex(item);
    if (existingIndex < 0) {
      const items = (state.selectedItems || []).slice();
      items.push(item);
      setState({ selectedItems: items });
      onItemsUpdated && onItemsUpdated(items);
    } else {
      unselect(item);
    }
  }

  function unselect(item) {
    const existingIndex = findItemIndex(item);
    if (existingIndex > -1) {
      const items = (state.selectedItems || []).slice();
      items.splice(existingIndex, 1);
      setState({ selectedItems: items });
      onItemsUpdated && onItemsUpdated(items);
    }
  }

  const onPagination = useCallback(
    ({ max, offset }) => {
      setState({ max: max, offset: offset });
    },
    [setState]
  );

  function listItemClassName(item, extraClasses) {
    const existingIndex = findItemIndex(item);
    const isActive = existingIndex > -1;
    return `list-group-item list-group-item-action ${
      isActive ? 'active' : ''
    } ${extraClasses ? extraClasses : ''}`;
  }

  function notFoundView(notFoundMessage) {
    return (
      <div className="alert alert-info" data-testid="404">
        {notFoundMessage || 'Nothing found'}
      </div>
    );
  }

  function errorView() {
    return (
      <div className="alert alert-danger" data-testid="error">
        {error}
      </div>
    );
  }

  function hasItems() {
    if (!items) {
      return false;
    }
    return items.length > 0 || total > 0;
  }

  function selectedItemsView() {
    const selectedItems = state.selectedItems || [];
    if (!selectedItems.length) {
      return null;
    }
    return (
      <div data-testid="selected" className="border p-3">
        {title && <p className="small text-muted">{title}</p>}
        {selectedItems.map((item, idx) => (
          <div
            key={idx}
            data-testid={`selected${idx}`}
            className="mb-3 small d-flex align-items-center justify-content-between"
          >
            <div className="flex-column">
              <ItemComponent model={item} />
            </div>
            <button
              data-testid={`unselect${idx}`}
              onClick={() => unselect(item)}
              className="btn btn-sm btn-link"
              aria-label="Clear"
            >
              <Close style={{ fill: 'currentColor' }} />
            </button>
          </div>
        ))}
      </div>
    );
  }

  /**
   * Perform different rendering dependent on whether an item is a 'selectable'
   * and whether it can actually be selected.  This is a bit of a hack really,
   * because the original ModelListViewSelect* components aren't really very
   * generic at all...
   * item or not
   * @param item
   * @param key
   * @returns {JSX.Element}
   */
  function mapSelectableListGroupItem(item, key) {
    if (_.has(item, 'selectable')) {

      if (item.selectable) {
        return (
          <ListGroupItem
            key={`item${key}`}
            data-testid={`item${key}`}
            onClick={() => onClick(item)}
            className={listItemClassName(item, 'selectable-item')}
          >
            <ItemComponent model={item} {...rest} />
          </ListGroupItem>
        );
      }else{
        return (<ListGroupItem
          disabled={true}
          key={`item${key}`}
          data-testid={`item${key}`}
          className={listItemClassName(item, 'non-selectable-item')}
        >
          <ItemComponent model={item} {...rest} />
        </ListGroupItem>);
      }
    } else {
      return (
        <ListGroupItem
          key={`item${key}`}
          data-testid={`item${key}`}
          onClick={() => onClick(item)}
          className={listItemClassName(item)}
        >
          <ItemComponent model={item} {...rest} />
        </ListGroupItem>
      );
    }
  }

  function listGroupItemViews() {
    if (items && items.length) {
      return items.map((item, key) => mapSelectableListGroupItem(item, key));
    }
  }

  function hasPagination() {
    return !loading && !error && items && total > 0 && items.length < total;
  }

  return (
    <>
      {loading && <ListLoader data-testid="loader" />}
      {!loading && error && errorView()}
      {!loading && !error && !hasItems() && notFoundView(notFoundMessage)}
      {!loading && !error && hasItems() && (
        <div className={className || 'list-group'} data-testid="list">
          {listGroupItemViews()}
        </div>
      )}
      {hasPagination() && (
        <div
          data-testid="pagination"
          className="d-flex align-items-center justify-content-center"
        >
          <PaginationWrapper
            size={'sm'}
            className="mt-3"
            max={state.max}
            offset={state.offset}
            total={total}
            onChange={onPagination}
          />
        </div>
      )}
      {(hasPagination() || !hasItems()) && selectedItemsView()}
    </>
  );
};

ModelListViewSelectMultiple.propTypes = {
  onItemsUpdated: PropTypes.func,
  apiUrl: PropTypes.string.isRequired,
  additionalApiParams: PropTypes.object,
  listItemRenderer: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  initialSelection: PropTypes.array,
  notFoundMessage: PropTypes.string,
  title: PropTypes.string
};

export default ModelListViewSelectMultiple;
