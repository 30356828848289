import React, { useState } from 'react';
import { getURLSearchParams, getURLSearchParamsWithoutPagination, RouterHistory } from '../main/RouterHistory';
import PropTypes from 'prop-types';

/**
 * Is functional as standalone element which changes
 * query parameter ($parameterKey) in URL.
 */
export const CheckboxForUrl = ({ parameterKey, parameterLabel }) => {

  const params = getURLSearchParams();
  const [value, setValue] = useState(params.get(parameterKey) === "true");
  const onChange = (event) => {
    setValue(event.target.checked);
  };

  return (<div className={`form-check form-check-inline`}>
    <input className="form-check-input" type="checkbox" name={parameterKey + "-boolean"} id={parameterKey} checked={value}
      onChange={onChange} value={value} />
    <label className="form-check-label" htmlFor={parameterKey}>{parameterLabel}</label>
  </div>);
};

CheckboxForUrl.propTypes = {
  parameterKey: PropTypes.string.isRequired,
  parameterLabel: PropTypes.string.isRequired
};
