import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { ExternalLink } from "./icons";

const FooterComponent = () => (
  <footer className="d-print-none mt-auto mb-3 small">
    <div className="container pb-4 pt-2">
      <div className="row d-flex justify-content-center flex-nowrap">

        <ul className="nav">
          <li className="nav-item">
            <Link to="/" className="nav-link">
              <FormattedMessage id="nav.home" />
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/about" className="nav-link">
              <FormattedMessage id="nav.about" />
            </Link>
          </li>
          {process.env.REACT_APP_TERMS_URL && <li className="nav-item">
            <a className="nav-link" href={process.env.REACT_APP_TERMS_URL} target="_blank" rel="noopener noreferrer">
              <FormattedMessage id="nav.terms" /> <ExternalLink height={14} />
            </a>
          </li>}
          {process.env.REACT_APP_PRIVACY_URL && <li className="nav-item">
            <a className="nav-link" href={process.env.REACT_APP_PRIVACY_URL} target="_blank" rel="noopener noreferrer">
              <FormattedMessage id="nav.privacy" /> <ExternalLink height={14} />
            </a>
          </li>}
          <li className="nav-item">
            <Link to="/cookie-policy" className="nav-link">
              <FormattedMessage id="nav.cookies" />
            </Link>
          </li>
          <li className="nav-item">
            <a className="nav-link" href={`${process.env.REACT_APP_SERVER}/documentation/swagger-ui/`} target="_blank" rel="noopener noreferrer">
              <FormattedMessage id="nav.api" /> <ExternalLink height={14} />
            </a>
          </li>
          <li className="navbar-text mx-3">
            <small className="text-muted">
              <FormattedMessage id="general.version" /> {window.BUILD_VERSION}
            </small>
          </li>
        </ul>

      </div>
    </div>
  </footer>
);

export default FooterComponent;
