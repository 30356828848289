import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import { CasePriority } from './CasePriority';
import axios from 'axios';
import { FormattedMessage } from 'react-intl';

const ChangeCasePriorityModal = (props) => {
  const [selectedValue, setSelectedValue] = useState(null);
  const { Option, SingleValue } = components;
  const availablePriority = CasePriority.map((p) => {
    if (p.value === props.priority) {
      p.isDisabled = true;
    } else delete p.isDisabled;
    return p;
  });
  const defaultPriority = CasePriority.filter(
    (p) => p.value === props.priority
  );

  const IconOption = (props) => (
    <Option {...props}>
      {props.data.icon}&nbsp;&nbsp;{props.data.label}
    </Option>
  );

  const ValueOption = (props) => (
    <SingleValue {...props}>
      {props.data.icon}&nbsp;&nbsp;{props.data.label}
    </SingleValue>
  );

  const onSelectionChangeHandler = (selectedValue) => {
    if (selectedValue === defaultPriority) setSelectedValue(null);
    else setSelectedValue(selectedValue.value);
  };

  const submitChange = () => {
    if (selectedValue !== null) {
      axios
        .post(`/api/case/${props.caseId}/priority`, { priority: selectedValue })
        .then(() => {
          props.onModalClose(selectedValue);
        });
    }
  };

  return (
    <Modal
      data-testid="form-priority-dialog"
      centered
      show
      onHide={() => props.onModalClose()}
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>{props.modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <label>
          <FormattedMessage id="model.case.priority" />
        </label>
        <Select
          defaultValue={defaultPriority}
          options={availablePriority}
          onChange={onSelectionChangeHandler}
          isSearchable={false}
          components={{ Option: IconOption, SingleValue: ValueOption }}
        />
      </Modal.Body>
      <Modal.Footer>
        <button
          disabled={selectedValue === null}
          data-testid="confirm-submit-btn"
          type="button"
          className="btn btn-outline-secondary btn-sm pl-5 pr-5"
          onClick={() => submitChange()}
        >
          Submit
        </button>
      </Modal.Footer>
    </Modal>
  );
};

ChangeCasePriorityModal.propTypes = {
  modalTitle: PropTypes.string.isRequired,
  caseId: PropTypes.number.isRequired,
  priority: PropTypes.number.isRequired, // current case priority - used for filtering avaible selection items;
  onModalClose: PropTypes.func.isRequired
};

export default ChangeCasePriorityModal;
