import React, { useState } from 'react';
import axios from 'axios';
import ListLoader from '../loaders/ListLoader';
import { parseAxiosError } from '../shared/AxiosResponseErrorParser';
import RouterHistory from '../main/RouterHistory';
import { Page, PageNav } from '../shared/Page';
import { FormForSchema } from '@lookinglocal/react-jsonschema-form-extensions';
import * as AppWidgets from '../form/widgets/AppWidgets';

const ProfilesInvitePage = (props) => {
  const { profileId } = props.match.params;

  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const invite = (formData) => {
    setLoading(true);
    axios
      .post(`/api/auth/invite/${profileId}`, formData)
      .then(() => {
        setLoading(false);
        RouterHistory.push(`/profiles/show/${profileId}`);
      })
      .catch((e) => {
        setLoading(false);
        setErrorMessage(parseAxiosError(e).getMessage());
      });
  };

  const schema = {
    type: 'object',
    title: 'Create login account',
    description:
      'Following will create a login account but account holder is expected to verify email as well.',
    properties: {
      needsVerificationEmail: {
        title: 'Send verification email immediately?',
        type: 'boolean',
        default: false
      }
    }
  };

  const uiSchema = {
    needsVerificationEmail: {
      'ui:help': 'You can do that later on profile management screen',
      'ui:widget': 'radio'
    }
  };

  return (
    <Page>
      <PageNav title="nav.profiles.invite" />
      <div className="row">
        <div className="col-sm-6 offset-sm-3">
          {errorMessage && (
            <div className="alert alert-danger">{errorMessage}</div>
          )}
          {loading && <ListLoader />}
          {!loading && (
            <FormForSchema
              appWidgets={AppWidgets.appWidgets}
              schema={schema}
              uiSchema={uiSchema}
              onSubmit={invite}
              isSubmitVisible={true}
            />
          )}
        </div>
      </div>
    </Page>
  );
};

export default ProfilesInvitePage;
