import { FC } from 'react';
import { GeneralIcons } from './Icons';

/**
 * Props for a link button
 */
export interface LinkButtonProps {

  /**
   * The label for the button
   */
  label : string;


  /**
   * The location to link to
   */
  href : string;

  /**
   * The classname for the link
   */
  className : string;
}

/**
 * A simple component that renders a button that is a link
 */
export const LinkButton: FC<LinkButtonProps> = (props) => {
  return (
    <button {...props} onClick={()=> window.open(props.href,'_self')} aria-label={props.label}>
      {props.label}
      {GeneralIcons.CaretRight('')}
    </button>
  );
};
