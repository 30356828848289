import React from 'react';
import PropTypes from 'prop-types';
import ModelListViewSelectable from '../../models/ModelListViewSelectable';
import AbstractFlowStep from './AbstractFlowStep';
import { InputQueryWithCallback } from '../../search/InputText';
import { InputSize } from '../../search/InputSize';
import { SelectProfileRole } from '../../search/SelectProfileRole';
import { AssigneeListItem } from '../../profile/AssigneeListItem';
import { WithPermission } from '../../auth/AccessConditionals';
import { Auth0Context } from '../../auth/AuthContextProvider';

class SelectProfileStep extends AbstractFlowStep {

  static contextType = Auth0Context

  constructor (props) {
    super(props);
    this.state = {
      hasError: false,
      selection: props.prevSuccessResult, // from parent flow container
      additionalApiParams: {
        sort: 'name',
        order: 'asc',
        role: props.defaultRole || "" //default filter for role;
      },
      withSearch: props.withSearch === true
    };
    this.doNext = this.doNext.bind(this);
    this.onQueryChange = this.onQueryChange.bind(this);
    this.onRoleChange = this.onRoleChange.bind(this);
    this.onSearchSelection = this.onSearchSelection.bind(this);
  }

  /**
   * Check if selection was updated in parent flow container
   */
  componentDidUpdate (prevProps, prevState, snapshot) {
    if (this.props.prevSuccessResult !== prevProps.prevSuccessResult) {
      this.setState({
        selection: this.props.prevSuccessResult
      });
    }
  }

  doNext () {
    if (this.state.selection)
      this.props.onNextSuccess(this.state.selection);
  }

  onQueryChange(query) {
    const additionalApiParams = Object.assign({}, this.state.additionalApiParams || {}, {
      query: query
    });
    this.setState({ additionalApiParams: additionalApiParams });
  }

  onRoleChange(role) {
    const additionalApiParams = Object.assign({}, this.state.additionalApiParams || {}, {
      role: role
    });
    this.setState({ additionalApiParams: additionalApiParams });
  }

  onSearchSelection (item) {
    if (item && item.locked) this.setState({ selection: null });
    else this.setState({ selection: item });
  }

  render () {
    const { title, apiUrl, prevSuccessResult, allSuccessResults, onNextSuccess, ...rest } = this.props;
    const { profile } = this.context;

    return (<>
      <h3>{title}</h3>
      { this.state.withSearch && <div className="mb-3">
        <>
          <InputQueryWithCallback size={'sm'} placeholder="Name, phone, email" onSearch={this.onQueryChange} className="my-2" />
          <WithPermission name="profile:read">
            <SelectProfileRole size={InputSize.sm} className="my-2" useRouter={false} onChange={this.onRoleChange} value={this.state.additionalApiParams.role} />
          </WithPermission>
        </>
      </div> }

      <div className="list-group mb-3">
        <button type="button" data-testid="assign-me" onClick={() => this.onSearchSelection(profile)} className={`list-group-item list-group-item-action ${ JSON.stringify(profile) === JSON.stringify(this.state.selection) ? 'active' : ''}`}>
          <AssigneeListItem model={profile} />
        </button>
      </div>

      <ModelListViewSelectable
        apiUrl={apiUrl}
        additionalApiParams={this.state.additionalApiParams}
        initialSelection={this.state.selection}
        onItemSelected={this.onSearchSelection}
        listItemRenderer={AssigneeListItem}
        {...rest}/>
    </>);
  }
}

SelectProfileStep.propTypes = {
  ...AbstractFlowStep.propTypes,
  ...ModelListViewSelectable.propTypes,
  title: PropTypes.string.isRequired,
  apiUrl: PropTypes.string.isRequired,
  withSearch: PropTypes.bool.isRequired
};

export default SelectProfileStep;
