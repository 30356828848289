const localStorageKey_activityExpiresAt = '_activityExpiresAt';
const localStorageKey_lastLoginAt = '_lastLoginAt';

class SessionExpiryMonitor {

  static onLogin = function () {
    localStorage.setItem(localStorageKey_lastLoginAt, `${Date.now()}`); // store login time in local storage when login;
    localStorage.removeItem(localStorageKey_activityExpiresAt); // clear expired (inactivity/idle) time from local storage when login;
  };

  constructor({
    secondsIdleBeforeLogout, // how long can a user be inactive before being automatically logged out
    secondsToShowIdleWarning, // how long to inform user before they are logged out due to inactivity
    secondsSinceLastLoginBeforeLogout, // how long since last login until a user will be logged out (stale login)
    secondsToShowStaleLoginWarning, // how long is the warning period before logout due to time since last login
    onIdleTimeout, // no-args function called when limit reached for inactivity
    onStaleLoginTimeout, // no-args function called when limit reached for time since last login
    warnUserAboutInactivity, // function(millisToExpiry) called during warning period for inactivity, passed milliseconds until limit reached
    warnUserAboutStaleLogin, // function(millisToExpiry) called during warning period for stale login, passed milliseconds until limit reached
    onActivityDetected, // no-args function called when activity is detected (to dismiss warning)
    onLoginDetected // no-args function called when login is detected (to dismiss warning)
  }) {
    this.secondsIdleBeforeLogout = parseInt(secondsIdleBeforeLogout) || null;
    this.secondsSinceLastLoginBeforeLogout = parseInt(secondsSinceLastLoginBeforeLogout) || null;
    this.secondsToShowStaleLoginWarning = parseInt(secondsToShowStaleLoginWarning) || null;
    this.secondsToShowIdleWarning = parseInt(secondsToShowIdleWarning) || null;

    this.onIdleTimeout = onIdleTimeout;
    this.onStaleLoginTimeout = onStaleLoginTimeout;
    this.warnUserAboutInactivity = warnUserAboutInactivity;
    this.warnUserAboutStaleLogin = warnUserAboutStaleLogin;
    this.onActivityDetected = onActivityDetected;
    this.onLoginDetected = onLoginDetected;

    this.idleTimeoutAboutToExpire = false;
    this.loginTimeoutAboutToExpire = false;

    if (this.secondsIdleBeforeLogout && onIdleTimeout) {
      const activityExpiresAt = parseInt(localStorage.getItem(localStorageKey_activityExpiresAt), 10);
      if (activityExpiresAt > 0 && activityExpiresAt < Date.now()) {
        onIdleTimeout();
        return;
      }
    }

    if (this.secondsSinceLastLoginBeforeLogout && onStaleLoginTimeout) {
      const lastLoginAt = parseInt(localStorage.getItem(localStorageKey_lastLoginAt), 10);
      if (lastLoginAt > 0) {
        const millisUntilLoginExpires = Math.abs(lastLoginAt - Date.now());
        const secondsUntilLoginExpires = Math.floor((millisUntilLoginExpires / 1000));
        if (secondsUntilLoginExpires > this.secondsSinceLastLoginBeforeLogout) {
          onStaleLoginTimeout();
          return;
        }
      }
    }

    if (this.secondsIdleBeforeLogout) {
      this.eventHandler = this.activityDetected.bind(this);
      this.tracker();
      this.startInterval();
    }

    if (this.secondsSinceLastLoginBeforeLogout) this.startLoginInterval();
  }

  startLoginInterval() {
    this.intervalLogin = setInterval(() => {
      const lastLoginAt = parseInt(localStorage.getItem(localStorageKey_lastLoginAt), 10);
      if (lastLoginAt > 0) {
        const millisSinceLastLogin = Date.now() - lastLoginAt;
        const millisUntilLoginExpires = this.secondsSinceLastLoginBeforeLogout * 1000 - millisSinceLastLogin;
        if (millisUntilLoginExpires <=0) {
          if (this.onStaleLoginTimeout) {
            this.onStaleLoginTimeout();
            this.cleanUp();
          }
        } else if (this.secondsToShowStaleLoginWarning && this.warnUserAboutStaleLogin) {
          if (!this.loginTimeoutAboutToExpire) {
            // Warning is not showing
            // Check to see if we're now in the notification period
            if (millisUntilLoginExpires <= this.secondsToShowStaleLoginWarning * 1000) {
              this.loginTimeoutAboutToExpire = true;
            }
          }
          if (this.loginTimeoutAboutToExpire) {
            // Warning is showing (loginTimeoutAboutToExpire=false)
            // Check to see if we're still in the notification period
            if (millisUntilLoginExpires > this.secondsToShowStaleLoginWarning * 1000) {
              // In this window we think we're in the notification period, but actually we're not because
              // the user has logged in in another window and pushed lastLoginAt into the future
              if (this.onLoginDetected) this.onLoginDetected(); // dismiss the existing warning
            } else {
              // We are in the notification period
              this.warnUserAboutStaleLogin(millisUntilLoginExpires);
            }
          }
        }
      }
    }, 1000);
  }

  startInterval() {
    this.activityDetected();
    this.interval = setInterval(() => {
      const activityExpiresAt = parseInt(localStorage.getItem(localStorageKey_activityExpiresAt), 10);
      if (activityExpiresAt > 0) {
        if (activityExpiresAt < Date.now()) {
          if (this.onIdleTimeout) {
            this.onIdleTimeout();
            this.cleanUp();
          }
        } if (this.warnUserAboutInactivity && this.secondsToShowIdleWarning) {
          const timeToStartNotifying = activityExpiresAt - (this.secondsToShowIdleWarning * 1000); // time to start notifying
          if (!this.idleTimeoutAboutToExpire) {
            if (timeToStartNotifying <= Date.now()) {
              this.idleTimeoutAboutToExpire = true;
            }
          }
          if (this.idleTimeoutAboutToExpire) {
            // Check to see if we're now in the notification period
            if (timeToStartNotifying > Date.now()) {
              // In this window we think we're in the notification period, but actually we're not because
              // another window has registered activity and pushed activityExpiresAt into the future
              if (this.onActivityDetected) this.onActivityDetected();
            } else {
              this.warnUserAboutInactivity(activityExpiresAt - Date.now());
            }

          }
        }
      }
    }, 1000);
  }

  activityDetected() {
    if (this.idleTimeoutAboutToExpire) {
      this.idleTimeoutAboutToExpire = false;
      if (this.warnUserAboutInactivity) this.onActivityDetected();
    }
    if (!this.localStorage_activityExpiresAt_updated) this.localStorage_activityExpiresAt_updated = Date.now();
    if (Date.now() - this.localStorage_activityExpiresAt_updated > 1000) { // avoid hammering localstorage
      const activityExpiresAt = Date.now() + this.secondsIdleBeforeLogout * 1000;
      localStorage.setItem(localStorageKey_activityExpiresAt, `${activityExpiresAt}`);
      this.localStorage_activityExpiresAt_updated = Date.now();
    }
  }

  tracker() {
    window.addEventListener('touchstart', this.eventHandler);
    window.addEventListener('mousemove', this.eventHandler);
    window.addEventListener('mousedown', this.eventHandler);
    window.addEventListener('scroll', this.eventHandler, true);
    window.addEventListener('keydown', this.eventHandler);
  }

  cleanUp() {
    if (this.secondsSinceLastLoginBeforeLogout) clearInterval(this.intervalLogin);
    if (this.secondsIdleBeforeLogout) {
      clearInterval(this.interval);
      window.removeEventListener('touchstart', this.eventHandler);
      window.removeEventListener('mousemove', this.eventHandler);
      window.removeEventListener('mousedown', this.eventHandler);
      window.removeEventListener('scroll', this.eventHandler);
      window.removeEventListener('keydown', this.eventHandler);
    }
  }
}

export default SessionExpiryMonitor;
