import React from 'react';
import ModelInstanceView from '../models/ModelInstanceView';
import ProfileDetails from './ProfileDetails';
import {useDocumentTitle} from "../shared/hooks/DocumentTitleHooks";

const ProfilesShowPage = (props) => {
  const { profileId } = props.match.params;
  useDocumentTitle(`User profile ${profileId}`);
  return <ModelInstanceView apiUrl={`/api/profile/${profileId}`} viewItem={ProfileDetails} {...props}/>;
};

export default ProfilesShowPage;
