import React from 'react';
import { toast } from 'react-toastify';
import { TaskStatus, TaskStatusBadge } from '../task/TaskStatusBadge';
import Dotdotdot from 'react-dotdotdot';
import { CaseStatusBadge } from '../case/status/CaseStatus';
import PusherListener from './PusherListener';
import ToastCard from '../shared/ToastCard';
import PropTypes from 'prop-types';

function TaskAssigneeChange(activity, eventData) {
  // This event can have a comment (reassign) or not (assign), and the user may be null (if the assignee profile has no user)
  const sections = [
    <>
      Assigned to {eventData.assignee.displayName}
      {eventData.comment ? `by ${eventData.comment.owner.displayName}` : ''}
    </>
  ];
  if (eventData.comment) {
    sections.push(
      <>
        <div className="font-italic">
          <Dotdotdot clamp={3}>{eventData.comment.text}</Dotdotdot>
        </div>
      </>
    );
  }

  if (eventData.case !== undefined) {
    return {
      title: eventData.case.displayName,
      sections: sections,
      callToAction: {
        route: `/cases/show/${eventData.case.id}`,
        title: 'View case'
      }
    };
  } else {
    return {
      title: eventData.task.displayName,
      sections: sections,
      callToAction: {
        route: `/tasks/show/${eventData.task.id}`,
        title: 'View task'
      }
    };
  }
}

function CaseAssigneeChange(activity,eventData) {
  // This event can have a comment (reassign) or not (assign), and the user may be null (if the assignee profile has no user)
  const sections = [
    <>
      Assigned to {eventData.assignee.displayName}
      {eventData.comment ? `by ${eventData.comment.owner.displayName}` : ''}
    </>
  ];
  if (eventData.comment) {
    sections.push(
      <>
        <div className="font-italic">
          <Dotdotdot clamp={3}>{eventData.comment.text}</Dotdotdot>
        </div>
      </>
    );
  }
  return {
    title: eventData.case.displayName,
    sections: sections,
    callToAction: {
      route: `/cases/show/${eventData.case.id}`,
      title: 'View task'
    }
  };
}

function CaseStatusChange(activity,eventData) {
  return {
    title: `Case #${eventData.case.id}`,
    sections: [
      <>
        <CaseStatusBadge
          status={eventData.case.status}
          withDescription={true}
          className="mr-1"
          withLock={true}
        />
        {eventData.updatedBy && (
          <span className="ml-1"> by {eventData.updatedBy.displayName}</span>
        )}
      </>
    ],
    callToAction: {
      route: `/cases/show/${eventData.case.id}`,
      title: 'View case'
    }
  };
}

function TaskStatusChange(activity,eventData) {
  return {
    title: eventData.task.displayName,
    sections: [
      <>
        <TaskStatusBadge status={eventData.task.status} className="mr-1" />
        {TaskStatus[eventData.task.status].description}
        {eventData.updateBy && (
          <span> by {eventData.updateBy.displayName}</span>
        )}
      </>
    ],
    callToAction: {
      route: `/tasks/show/${eventData.task.id}`,
      title: 'View task'
    }
  };
}

function TaskCommentAdded(activity,eventData) {
  const sections = [
    <>
      <div>New comment by {eventData.comment.owner.displayName}</div>
    </>,
    <>
      <div className="font-italic">
        <Dotdotdot clamp={3}>{eventData.comment.text}</Dotdotdot>
      </div>
    </>
  ];
  if (eventData.comment.attachments.length > 0) {
    sections.push(<>+{eventData.comment.attachments.length} attachment(s)</>);
  }
  return {
    title: eventData.task.displayName,
    sections: sections,
    callToAction: {
      route: `/tasks/show/${eventData.task.id}`,
      title: 'View task'
    }
  };
}

function TaskCommentRemoved(activity,eventData) {
  const sections = [
    <>
      <div>Comment removed</div>
    </>,
    <>
      <div className="font-italic">
        <Dotdotdot clamp={3}>{eventData.comment.text}</Dotdotdot>
      </div>
    </>
  ];
  if (eventData.comment.attachments.length > 0) {
    sections.push(<>+{eventData.comment.attachments.length} attachment(s)</>);
  }
  return {
    title: eventData.task.displayName,
    sections: sections,
    callToAction: {
      route: `/tasks/show/${eventData.task.id}`,
      title: 'View task'
    }
  };
}

function TaskWatcherAdded(activity,eventData) {
  const sections = [
    <>
      <div>Watcher added</div>
    </>,
    <>
      <div className="font-italic">
        <Dotdotdot clamp={3}>{eventData.profile.displayName}</Dotdotdot>
      </div>
    </>
  ];
  return {
    title: eventData.task.displayName,
    sections: sections,
    callToAction: {
      route: `/tasks/show/${eventData.task.id}`,
      title: 'View task'
    }
  };
}

function TaskWatcherRemoved(activity,eventData) {
  const sections = [
    <>
      <div>Watcher removed</div>
    </>,
    <>
      <div className="font-italic">
        <Dotdotdot clamp={3}>{eventData.profile.displayName}</Dotdotdot>
      </div>
    </>
  ];
  return {
    title: eventData.task.displayName,
    sections: sections,
    callToAction: {
      route: `/tasks/show/${eventData.task.id}`,
      title: 'View task'
    }
  };
}

function CaseLocked(activity,eventData) {
  const sections = [
    <>
      <CaseStatusBadge
        status={eventData.case.status}
        withDescription={true}
        className="mr-1"
        withLock={true}
      />
      {eventData.updatedBy && (
        <span className="ml-1"> by {eventData.updatedBy.displayName}</span>
      )}
    </>
  ];
  return {
    title: `Case #${eventData.case.id} LOCKED`,
    sections: sections,
    callToAction: {
      route: `/cases/show/${eventData.case.id}`,
      title: 'View case'
    }
  };
}

function CaseUnlocked(activity,eventData) {
  const sections = [
    <>
      <CaseStatusBadge
        status={eventData.case.status}
        withDescription={true}
        className="mr-1"
        caseRef={eventData.case.caseRef}
        caseVersion={eventData.case.caseVersion}
        withLock={true}
      />
      {eventData.updatedBy && (
        <span className="ml-1"> by {eventData.updatedBy.displayName}</span>
      )}
    </>
  ];
  return {
    title: `Case #${eventData.case.id} UNLOCKED`,
    sections: sections,
    callToAction: {
      route: `/cases/show/${eventData.case.id}`,
      title: 'View case'
    }
  };
}

// TODO: their needs to be standardised activity structure/header so that any links
// generated in here make sense...
function TaskSaved(activity,eventData) {
  const sections = [
    <>
      <div>Task Saved</div>
    </>,
    <>
      <div className="font-italic">
        <Dotdotdot clamp={3}>{eventData.title}</Dotdotdot>
      </div>
    </>
  ];
  return {
    title: eventData.case.displayName,
    sections: sections,
    callToAction: {
      route: `/tasks/show/${eventData.taskId}`,
      title: 'View case'
    }
  };
}

function showToast(eventType, data) {
  let toastCardProps;

  switch (eventType) {
    case 'ASSIGNEE_CHANGE':
      toastCardProps = TaskAssigneeChange(undefined, data);
      break;
    case 'STATUS_CHANGE':
      toastCardProps = data.case
        ? CaseStatusChange(undefined, data)
        : data.task
          ? TaskStatusChange(undefined, data)
          : null;
      break;
    case 'COMMENT_ADDED':
      toastCardProps = TaskCommentAdded(undefined, data);
      break;
    default:
      toastCardProps = null;
  }

  if (toastCardProps) {
    toast(<ToastCard {...toastCardProps} />, {
      autoClose: false,
      closeOnClick: true,
      closeButton: false
    });
  }
}

/**
 * Component to register for Pusher notifications from the user profile's channel
 */
function PusherUserChannelListener({
  profileId,
  showNotifications,
  afterAssigneeChange,
  afterComment,
  afterStatusChange
}) {
  return (
    <PusherListener
      channelName={`user-${profileId}`}
      bindings={{
        'ASSIGNEE_CHANGE': (data) => {
          showNotifications && showToast('ASSIGNEE_CHANGE', data);
          afterAssigneeChange && afterAssigneeChange(data);
        },
        'STATUS_CHANGE': (data) => {
          showNotifications && showToast('STATUS_CHANGE', data);
          afterStatusChange && afterStatusChange(data);
        },
        'NEW_COMMENT': (data) => {
          showNotifications && showToast('NEW_COMMENT', data);
          afterComment && afterComment();
        }
      }}
    />
  );
}
PusherUserChannelListener.propTypes = {
  profileId: PropTypes.number.isRequired,
  showNotifications: PropTypes.bool.isRequired,
  afterAssigneeChange: PropTypes.func,
  afterComment: PropTypes.func,
  afterStatusChange: PropTypes.func
};

function PusherCaseChannelListener({
  caseId,
  showNotifications,
  afterComment,
  afterStatusChange
}) {
  return (
    <PusherListener
      channelName={`case-${caseId}`}
      bindings={{
        'STATUS_CHANGE': (data) => {
          showNotifications && showToast('STATUS_CHANGE', data);
          afterStatusChange && afterStatusChange(data);
        },
        'NEW_COMMENT': (data) => {
          afterComment && afterComment(data);
        }
      }}
    />
  );
}
PusherCaseChannelListener.propTypes = {
  caseId: PropTypes.number.isRequired,
  showNotifications: PropTypes.bool.isRequired,
  afterComment: PropTypes.func,
  afterStatusChange: PropTypes.func
};

function PusherTaskChannelListener({
  taskId,
  afterAssigneeChange,
  afterComment,
  afterStatusChange
}) {
  return (
    <PusherListener
      channelName={`task-${taskId}`}
      bindings={{
        'ASSIGNEE_CHANGE': (data) => {
          afterAssigneeChange && afterAssigneeChange(data);
        },
        'NEW_COMMENT': (data) => {
          afterComment && afterComment(data);
        },
        'STATUS_CHANGE': (data) => {
          afterStatusChange && afterStatusChange(data);
        }
      }}
    />
  );
}
PusherTaskChannelListener.propTypes = {
  taskId: PropTypes.number.isRequired,
  afterAssigneeChange: PropTypes.func,
  afterComment: PropTypes.func,
  afterStatusChange: PropTypes.func
};

export {
  PusherUserChannelListener,
  PusherCaseChannelListener,
  PusherTaskChannelListener,
  TaskAssigneeChange,
  CaseAssigneeChange,
  TaskCommentAdded,
  TaskCommentRemoved,
  TaskWatcherAdded,
  TaskWatcherRemoved,
  TaskSaved,
  TaskStatusChange,
  CaseStatusChange,
  CaseUnlocked,
  CaseLocked
};
