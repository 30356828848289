import React from 'react';
import { FormFromTaskConfigurationWithTitle } from './FormFromTaskRef';
import { Page, PageNav } from '../shared/Page';

const FormsShowPage = (props) => {
  const { taskRef, taskVersion } = props.match.params;
  return (<Page>
    <PageNav title="nav.tasks.forms.show" />
    <div className="row">
      <div className="col-sm-6 offset-sm-3">
        <p className="alert alert-warning">This is a preview</p>
        <FormFromTaskConfigurationWithTitle taskRef={taskRef} taskVersion={taskVersion}/>
      </div>
    </div>
  </Page>);
};

export default FormsShowPage;
