import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import CaseDetailsForm from './CaseDetailsForm';
import { parseAxiosError } from '../shared/AxiosResponseErrorParser';
import FlowContainerModal from '../flows/core/FlowContainerModal';
import FormStep from '../flows/core/FormStep';
import PreviewStep from '../flows/core/PreviewStep';
import { useAppConfiguration } from '../shared/contexts/AppConfiguration';
import {
  caseOrTaskMessage,
  caseOrTaskMessageElse,
  latestCaseConfiguration,
  UiMessageKey
} from '../shared/types';

/**
 * Wrapper to dynamically generate an anonymous content step based on the current case
 * configuration and a supplied final step message. This function will split the message
 * based on newlines, and render each line as a separate paragraph.
 * @param message
 * @returns {function(): *}
 * @constructor
 */
const GenerateFinalStepContent = (message) => {
  const lines = message ? message.split('\n') : [];
  return () => {
    return (
      <section>
        {lines.map((line, index) => (
          <p key={`msg_${index}`}>{line}</p>
        ))}
      </section>
    );
  };
};

const AddCaseFlowModal = (props) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const appConfig = useAppConfiguration();
  const [whatsNext, setWhatsNext] = useState(null);

  // grab the current case configuration
  useEffect(() => {
    if (appConfig.loaded) {
      const caseConfig = latestCaseConfiguration(appConfig, props.caseRef);
      setWhatsNext(
        caseOrTaskMessageElse(
          caseConfig,
          UiMessageKey.InfoNext,
          (config) =>
            `After the ${caseOrTaskMessage(
              caseConfig,
              UiMessageKey.TitleSingular
            )} is saved please assign tasks to the relevant people.`
        )
      );
    }
  }, [appConfig, appConfig.loaded, props.caseRef]);

  function onCancel() {
    props.onModalClose();
  }

  function onSubmit(data) {
    return axios
      .post('/api/case', {
        caseRef: props.caseRef,
        caseVersion: props.caseVersion,
        details: data[0]
      })
      .then(() => {
        props.onModalClose({ reload: true });
      })
      .catch((e) => {
        setErrorMessage(
          parseAxiosError(
            e,
            'Failed to retrieve results from server'
          ).getMessage()
        );
      });
  }

  return (
    <FlowContainerModal
      errorMessage={errorMessage}
      onFinalSubmit={onSubmit}
      onCancel={onCancel}
      {...props}
    >
      <FormStep
        key={'details'}
        title={''}
        caseRef={props.caseRef}
        appConfig={props.appConfig}
        schemaFormComponent={CaseDetailsForm}
      />
      <PreviewStep
        key={'finish'}
        title="What happens next?"
        component={GenerateFinalStepContent(whatsNext)}
        {...props}
      />
    </FlowContainerModal>
  );
};

AddCaseFlowModal.propTypes = {
  caseRef: PropTypes.string.isRequired,
  caseVersion: PropTypes.number.isRequired,
  appConfig: PropTypes.object.isRequired,
  onModalClose: PropTypes.func.isRequired,
  whatsNext: PropTypes.string
};

export default AddCaseFlowModal;
