import React, { FC, useCallback, useEffect,  useState } from 'react';
import {
  CaseConfiguration,
  caseOrTaskMessage,
  CaseStatus,
  caseStatusesByCaseVersion,
  displaySpecificationLabel,
  latestCaseConfiguration,
  UiMessageKey
} from '../../shared/types';
import { useAppConfiguration } from '../../shared/contexts/AppConfiguration';
import { newUUID } from '../../shared/functions';
import _ from 'lodash';
import { SearchWidgetContainer } from './SearchWidgetContainer';
import { SearchDropdownWidget } from './SearchDropdownWidget';
import { SearchTextWidget } from './SearchTextWidget';

/**
 * Props for the {@link CaseSearchFilter}
 */
export interface CaseSearchFilterProps {
  onQueryChanged: any;
}

/**
 * This is a general purpose component that provides a UX all for the construction
 * of a "params" object containing a series of search fields, specific to a
 * given case type. In order to use it, simply plug it into the area of the UX
 * you want to use it in, and then hook the onQueryChanged callback for changes
 * in the constructed parameter object
 * @param onQueryChanged
 * @constructor
 */
export const CaseSearchFilter: FC<CaseSearchFilterProps> = ({
  onQueryChanged
}) => {
  const appConfig = useAppConfiguration();
  const [loaded, setLoaded] = useState<boolean>(false);
  const [caseStatuses, setCaseStatuses] = useState<CaseStatus[]>();
  const [selectedCaseConfig, setSelectedCaseConfig] =
    useState<CaseConfiguration>();
  const [searchParams, setSearchParams] = useState<Record<string,string>>({});
  const [caseRef, setCaseRef] = useState<string>();
  const [caseStatus, setCaseStatus] = useState<string>();
  const [focusWidgetId, setFocusWidgetId] = useState<string>();

  // observers of this component can hook this event in order to get an updated
  // set of query parameters
  const queryChanged = useCallback((params: object) => {
    onQueryChanged(params);
  }, [onQueryChanged]);

  // called when the value changes in one of the widgets
  const onWidgetChange = useCallback(
    (tag: string, newValue?: string) => {
      console.log(`CHANGE: ${newValue}`);
      let caseRefChanged = false;

      // switch the form layout if we need to and blank the status
      if (tag === 'caseRef' && newValue) {
        const caseConfig = latestCaseConfiguration(appConfig, newValue);
        setCaseRef(newValue);
        setCaseStatus(undefined);
        setSelectedCaseConfig(caseConfig);
        setCaseStatuses(
          caseStatusesByCaseVersion(
            appConfig,
            caseConfig.ref,
            caseConfig.version
          )
        );
        caseRefChanged = true;
      }

      if (tag === 'caseStatus') {
        setCaseStatus(newValue === '' ? undefined : newValue);
      }

      // update the params, clear them out if we've switched case reference
      let newParams: Record<string, string> = searchParams ? searchParams : {};
      if (caseRefChanged) {
        newParams = { caseRef: newValue as string };
      } else {
        if (_.has(newParams, tag)) {
          if (!newValue || newValue === '') {
            delete newParams[tag];
          } else {
            newParams[tag] = newValue;
          }
        } else {
          if (newValue && newValue !== '') {
            newParams[tag] = newValue;
          }
        }
      }

      // set the params in state
      setSearchParams(newParams);

      // call the debounced search function
      queryChanged(newParams);

      // we do this here so that we correctly set focus to a child text input
      // if this was the last control to trigger a change in the query
      const element = document.getElementById(`search_text_${tag.replaceAll('/', "_")}`);
      if (element !== null) {
        setFocusWidgetId(tag);
      }

    },
    [appConfig, queryChanged, searchParams]
  );

  // set the initial case config and associated statuses
  useEffect(() => {
    if (appConfig.loaded && !loaded) {
      const caseConfig = appConfig.caseConfigurations[0];
      setCaseStatuses(
        caseStatusesByCaseVersion(appConfig, caseConfig.ref, caseConfig.version)
      );
      setCaseRef(caseConfig.ref);
      setSelectedCaseConfig(caseConfig);
      setLoaded(true);
      setSearchParams({ caseRef: caseConfig.ref });
      queryChanged({ caseRef: caseConfig.ref });
    }
  }, [loaded, appConfig, appConfig.loaded, searchParams, onWidgetChange, queryChanged]);

  return (
    <>
      {appConfig.loaded && caseStatuses && selectedCaseConfig && (
        <>
          <SearchWidgetContainer row={true}>
            <SearchDropdownWidget
              widgetKey="caseRef"
              onValueChange={onWidgetChange}
              initialSelection={caseRef}
              allowBlank={false}
              label={'Type'}
              items={appConfig.caseConfigurations}
              labelCallback={(config) =>
                caseOrTaskMessage(
                  config as CaseConfiguration,
                  UiMessageKey.TitleSingular
                )
              }
              valueCallback={(config) => (config as CaseConfiguration).ref}
            />
            <SearchDropdownWidget
              widgetKey="caseStatus"
              onValueChange={onWidgetChange}
              initialSelection={caseStatus}
              allowBlank={true}
              blankLabel={'Any'}
              label={'Status'}
              items={caseStatuses}
              labelCallback={(status) => (status as CaseStatus).label}
              valueCallback={(status) => (status as CaseStatus).id}
            />
          </SearchWidgetContainer>
          <SearchWidgetContainer row={false}>
            <CaseSearchFilterCaseFields
              caseConfig={selectedCaseConfig}
              searchParams={searchParams}
              onWidgetChanged={onWidgetChange}
              refreshCookie={newUUID()}
              focusFieldId={focusWidgetId}
            />
          </SearchWidgetContainer>
        </>
      )}
    </>
  );
};

/**
 * Props for the CaseSearchFilterCaseFields subcomponent
 */
interface CaseSearchFilterCaseFieldsProps {
  /**
   * The current case configuration
   */
  caseConfig: CaseConfiguration;

  /**
   * The current search parameters
   */
  searchParams: any;

  /**
   * How we notify a parent component that our values have changed
   * @param key
   * @param value
   */
  onWidgetChanged: (key: string, value?: string) => void;

  /**
   * In order to force a re-render of the component
   */
  refreshCookie : string;

  /**
   * The field to focus on
   */
  focusFieldId? : string

}

/**
 * This is really just an aggregation component that groups together a series of
 * {@link SearchTextWidget} components and aggregates their change events back
 * up the component tree. It's easier to reason about things (and less messy)
 * if we decompose things this way.  The other responsibility of this component
 * is to provide a centralised debounce pinch point for changes in the child
 * components
 * @param caseConfig a {@link CaseConfiguration} instance, used to create kids
 * @param searchParams used to set field default values on refresh
 * @param onWidgetChanged how a parent component is notified of child in a child
 *  widget
 * @param refreshCookie can be used to force a refresh
 * @param focusFieldId can be used to select the widget to focus
 * @constructor
 */
const CaseSearchFilterCaseFields: FC<CaseSearchFilterCaseFieldsProps> = ({
  caseConfig,
  searchParams,
  onWidgetChanged,
  refreshCookie,
  focusFieldId,
}) => {

  const [params, setParams] = useState<any>(searchParams);
  const debouncedChange = _.debounce(onWidgetChange, 500, {trailing : true, maxWait: 1000});

  useEffect(() => {
    setParams(searchParams);
  }, [searchParams, refreshCookie]);

  function onWidgetChange(widgetKey: string, newValue?: string) {
    console.log('innerChange');
    onWidgetChanged(widgetKey, newValue);
  }

  return (
    <>
      {caseConfig.meta.searchDisplaySpecifications!!.map((spec) => {
        return (
          <SearchTextWidget
            id={spec.valuePointer.replaceAll("/", "_")}
            key={newUUID()}
            label={displaySpecificationLabel(caseConfig, spec)}
            prompt={undefined}
            widgetKey={spec.valuePointer}
            initialValue={params[spec.valuePointer]}
            onValueChange={debouncedChange}
          />
        );
      })}
    </>
  );
};
