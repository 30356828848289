import {useCallback, useEffect} from 'react';

/**
 * Hook that allows you to set a static document title
 * @param title the new document title
 */
export const useDocumentTitle = (title: string) => {
  useEffect(() => {
    if (document.title !== title) {
      document.title = title;
    }
  }, [title]);
};

/**
 * Hook that allows you to set a dynamic document title based on a lambda. (Which will be memoised).
 * @param f a string producing function
 */
export const useDynamicDocumentTitle = (f: () => string) => {
  const title = useCallback(f, [f]);
  useEffect(() => {
    if (document.title !== title()) {
      document.title = title();
    }
  }, [f, title]);
};
