import { ExportTarget } from './types';
import { CaseConfiguration, TaskConfiguration } from '../shared/types';
import React, { FC, useState } from 'react';
import { FileTypeIcons } from '../shared/components/Icons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { SpinnerDotted } from 'spinners-react';
import axios from 'axios';
import moment from 'moment';

/**
 * The available export types
 */
export type ExportType = 'Csv' | 'Json';

/**
 * The available configuration types, including undefined
 */
export type ExportConfig = CaseConfiguration | TaskConfiguration | undefined;

/**
 * Props for an export button
 */
export interface ExportButtonProps {
  /**
   * The type of the export
   */
  exportType: ExportType;

  /**
   * The type of entity to be exported
   */
  exportTarget: ExportTarget;

  /**
   * The configuration used to drive the export.  If no configuration
   * is provided, then do a complete export
   */
  targetConfig: ExportConfig;
}

/**
 * Icon based on the type of export
 * @param exportType
 */
const exportIcon = (exportType: ExportType) => {
  switch (exportType) {
    case 'Csv':
      return FileTypeIcons.Csv('export-button-icon');
    case 'Json':
      return FileTypeIcons.Json('export-button-icon');
  }
};

/**
 * A pretty tooltip
 * @param exportType
 */
const exportTooltip = (exportType: ExportType) => {
  switch (exportType) {
    case 'Csv':
      return 'Export as CSV';
    case 'Json':
      return 'Export as JSON';
  }
};

/**
 * Rather long-winded but readable construction of an export endpoint URL
 * @param exportType
 * @param exportTarget
 * @param targetConfig
 */
const exportEndpointUrl = (
  exportType: ExportType,
  exportTarget: ExportTarget,
  targetConfig: ExportConfig
): string => {
  if (targetConfig) {
    switch (exportType) {
      case 'Csv': {
        switch (exportTarget) {
          case 'Case':
            return `/api/export/caseCsv?caseRef=${targetConfig.ref}`;
          case 'Task':
            return `/api/export/taskCsv?taskRef=${targetConfig.ref}`;
        }
        break;
      }
      case 'Json':
        switch (exportTarget) {
          case 'Case':
            return `/api/export/caseJson?caseRef=${targetConfig.ref}`;
          case 'Task':
            return `/api/export/taskJson?taskRef=${targetConfig.ref}`;
        }
        break;
    }
  } else {
    switch (exportType) {
      case 'Csv': {
        switch (exportTarget) {
          case 'Case':
            return '/api/export/caseCsv';
          case 'Task':
            return '/api/export/taskCsv';
        }
        break;
      }
      case 'Json':
        switch (exportTarget) {
          case 'Case':
            return '/api/export/caseJson';
          case 'Task':
            return '/api/export/taskJson';
        }
        break;
    }
  }
};

/**
 * Construct a one-time export filename
 * @param exportType
 * @param exportTarget
 * @param targetConfig
 */
const exportFilename = (
  exportType: ExportType,
  exportTarget: ExportTarget,
  targetConfig: ExportConfig
): string => {
  const extension = exportType === 'Csv' ? 'csv' : 'json';
  const prefix = exportTarget === 'Case' ? 'export-cases' : 'export-tasks';
  const suffix = targetConfig ? targetConfig.ref : 'all';
  const timestamp = moment(Date()).format('YYYYMMDD-hhmmss');
  return `${prefix}-${suffix}-${timestamp}.${extension}`;
};

/**
 * A button that knows how to download an export from the server, in either CSV
 * or JSON form
 * @param exportType
 * @param exportTarget the type of export we're interested in
 * @param targetConfig an optional [CaseConfiguration] or [TaskConfiguration].
 *                     If not provided, then all items of the selected type are exported
 * @constructor
 */
export const ExportButton: FC<ExportButtonProps> = ({
  exportType,
  exportTarget,
  targetConfig
}) => {
  const [waiting, setWaiting] = useState(false);

  // this function performs the actual magic...
  function doExport() {
    setWaiting(true);
    axios({
      url: exportEndpointUrl(exportType, exportTarget, targetConfig),
      method: 'GET',
      responseType: 'blob'
    }).then((response) => {
      // make sure we keep the history
      const href = URL.createObjectURL(response.data);

      // ghost an anchor element to drive the download
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute(
        'download',
        exportFilename(exportType, exportTarget, targetConfig)
      ); //or any other extension
      document.body.appendChild(link);
      link.click();

      // cleanup
      document.body.removeChild(link);
      URL.revokeObjectURL(href);

      // update our local state
      setWaiting(false);
    });
  }

  // pretty simple markup
  return (
    <OverlayTrigger
      placement="bottom"
      overlay={<Tooltip id={''}>{exportTooltip(exportType)}</Tooltip>}
    >
      <button
        aria-label={exportTooltip(exportType)}
        className={'btn btn-sm btn-outline-secondary export-button'}
        disabled={waiting}
        onClick={doExport}
      >
        {!waiting && exportIcon(exportType)}
        {waiting && <SpinnerDotted size={24} />}
      </button>
    </OverlayTrigger>
  );
};
