import React from 'react';

export default function CardLoader () {
  return (<div className="card mb-3">
    <div className="card-header">
      <span className="loading-text-filler"/>
    </div>
    <div className="card-body">
      <span className="loading-text-filler"/>
      <span className="loading-text-filler"/>
      <span className="loading-text-filler"/>
    </div>
  </div>);
}
