import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { MyProfileForm } from '../profile/Profile';
import FlowContainer, { CssColClassesFlow } from './core/FlowContainer';
import FormStep from './core/FormStep';
import CaseDetailsForm from '../case/CaseDetailsForm';
import { useAuthContext } from '../auth/AuthContextProvider';
import { parseAxiosError } from '../shared/AxiosResponseErrorParser';
import PreviewStep from './core/PreviewStep';
import RouterHistory from '../main/RouterHistory';
import { Page, PageNav } from '../shared/Page';
import { useIntl } from 'react-intl';
import Spinner from 'react-bootstrap/Spinner';
import { ConditionalFormForSchema } from '@lookinglocal/react-jsonschema-form-extensions';
import { Prompt, useParams } from 'react-router-dom';
import {
  versionedReference,
  caseOrTaskMessage,
  caseAssessmentTasks,
  UiMessageKey,
  latestCaseConfiguration,
  hasFeatureFlag,
  caseOrTaskMessageElse
} from '../shared/types';
import { useAppConfiguration } from '../shared/contexts/AppConfiguration';
import { appWidgets } from '../form/widgets/AppWidgets';

/**
 * Wrapper to dynamically generate an anonymous content step based on the current case
 * configuration and a supplied final step message. This function will split the message
 * based on newlines, and render each line as a separate paragraph.
 * @param message
 * @returns {function(): *}
 * @constructor
 */
const GenerateFinalStepContent = (message) => {
  const lines = message ? message.split('\n') : [];
  return () => {
    return (
      <section>
        {lines.map((line, index) => (
          <p key={`msg_${index}`}>{line}</p>
        ))}
      </section>
    );
  };
};

const AssessmentFlowPage = () => {
  const SUBMISSION = {
    PENDING: 'PENDING',
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR'
  };

  const appConfig = useAppConfiguration();
  const { caseRef } = useParams();
  const [caseConfig, setCaseConfig] = useState(undefined);
  const [assessmentTasks, setAssessmentTasks] = useState([]);
  const intl = useIntl();
  const { loading, profile, refreshProfile, sessionExpired } = useAuthContext();
  const [errorMessage, setErrorMessage] = useState(null);
  const [submissionState, setSubmissionState] = useState(SUBMISSION.PENDING);
  const [profileFormValues, setProfileFormValues] = useState(null);
  const [whatsNext, setWhatsNext] = useState(null);

  // prompt default browser message for reloading form;
  useEffect(() => {
    if (!sessionExpired)
      window.onbeforeunload = () => {
        return true;
      };
    else window.onbeforeunload = null;

    return () => {
      window.onbeforeunload = null;
    };
  }, [sessionExpired]);

  useEffect(() => {
    if (!loading && profile) {
      setProfileFormValues(profile);
    }
    if (appConfig.loaded) {
      const caseConfig = latestCaseConfiguration(appConfig, caseRef);
      setCaseConfig(caseConfig);
      setAssessmentTasks(caseAssessmentTasks(appConfig, caseConfig));
      setWhatsNext(
        caseOrTaskMessageElse(
          caseConfig,
          UiMessageKey.InfoNext,
          (config) =>
            `After the ${caseOrTaskMessage(
              caseConfig,
              UiMessageKey.TitleSingular
            )} is saved please assign tasks to the relevant people.`
        )
      );
    }
  }, [loading, profile, appConfig, caseRef]);

  if (loading || !profile || !assessmentTasks)
    return <Spinner animation={'border'} variant={'primary'} size={'sm'} />;

  function onCancel() {
    RouterHistory.goBack();
  }

  /**
   * The completed forms need to be packaged up into a map structure, where the keys are the versioned references
   * for the {@link TaskConfiguration} associated with the form. This allows us to utilise different discrete versions
   * of forms within the assessment flow.
   * @param assessmentForms
   * @param formModels
   * @returns a map structure which has task versioned references as keys, and the actual model data for the associated
   * form as a value
   */
  function mapAssessmentForms(assessmentForms, formModels) {
    const references = assessmentForms.map((task) => versionedReference(task));
    let map = {};
    let i = 0;
    for (const model of formModels) {
      map[references[i++]] = model;
    }
    return map;
  }

  function onSubmit(data) {
    let creatorDetails = undefined;
    let caseDetails = undefined;
    let assessmentFormModels = [];

    // if we have profile entry disabled for the current case configuration
    // we need to 'shift' the data pulled out from the flow by one, because
    // with profile entry, the user information is regarded as the first
    // 'form' in the flow...if we don't request entry of this information
    // then we pull the profile details from somewhere else in the state. We
    // slice out the assessment forms from a different position within the
    // data array, depending on whether we have profile entry enabled or not
    if (hasFeatureFlag(caseConfig, 'promptForProfileInAssessment')) {
      creatorDetails = data[0];
      caseDetails = data[1];
      assessmentFormModels = data.slice(2, -1);
    } else {
      creatorDetails = profileFormValues;
      caseDetails = data[0];
      assessmentFormModels = data.slice(1, -1);
    }

    return axios
      .post('/api/assessment', {
        caseRef: caseConfig.ref,
        caseVersion: caseConfig.version,
        creator: creatorDetails,
        details: caseDetails,
        forms: mapAssessmentForms(assessmentTasks, assessmentFormModels)
      })
      .then((response) => {
        setSubmissionState(SUBMISSION.SUCCESS);
        refreshProfile();
        if (response.data && response.data.data && response.data.data.id) {
          RouterHistory.push(`/cases/show/${response.data.data.id}`);
        } else {
          RouterHistory.push('/dashboard');
        }
      })
      .catch((e) => {
        setErrorMessage(
          parseAxiosError(
            e,
            'Failed to retrieve results from server'
          ).getMessage()
        );
        setSubmissionState(SUBMISSION.ERROR);
      });
  }

  return (
    <Page>
      <Prompt
        message={(location, action) => {
          if (!sessionExpired && (action === 'POP' || action === 'PUSH'))
            return `Are you sure you want to navigate away from this page?`;
        }}
      />
      <PageNav
        title={`Create ${caseOrTaskMessage(
          caseConfig,
          UiMessageKey.TitleSingular
        )}`}
      />
      <div className="row">
        <div className={CssColClassesFlow}>
          {submissionState === SUBMISSION.SUCCESS && (
            <div className="alert alert-success">
              Details successfully submitted
            </div>
          )}
          {submissionState === SUBMISSION.ERROR && (
            <div className="alert alert-danger">{errorMessage}</div>
          )}
        </div>
        {submissionState !== SUBMISSION.SUCCESS &&
          caseConfig &&
          profileFormValues != null && (
            <FlowContainer
              onFinalSubmit={onSubmit}
              primaryValues={
                hasFeatureFlag(caseConfig, 'promptForProfileInAssessment')
                  ? [profileFormValues, {}, {}]
                  : [{}, {}]
              }
              onCancel={onCancel}
            >
              {hasFeatureFlag(caseConfig, 'promptForProfileInAssessment') && (
                <FormStep
                  key={'profile'}
                  title="Your details"
                  schemaFormComponent={MyProfileForm}
                  appConfig={appConfig}
                />
              )}
              <FormStep
                key={'details'}
                title={caseOrTaskMessage(
                  caseConfig,
                  UiMessageKey.TitleSingular
                )}
                schemaFormComponent={CaseDetailsForm}
                caseRef={caseConfig.ref}
                caseVersion={caseConfig.version}
                appConfig={appConfig}
                appWidgets={appWidgets}
              />
              {assessmentTasks.map((task) => (
                <FormStep
                  key={versionedReference(task)}
                  title={task.form.schema.title}
                  schemaFormComponent={ConditionalFormForSchema}
                  schema={task.form.schema}
                  uiSchema={task.form.uiSchema}
                  rules={task.form.rules}
                  showErrorList={true}
                  caseRef={caseRef}
                  appConfig={appConfig}
                  appWidgets={appWidgets}
                />
              ))}
              <PreviewStep
                key={'finish'}
                title={intl.formatMessage({ id: 'nav.assessment.step.last' })}
                component={GenerateFinalStepContent(whatsNext)}
              />
            </FlowContainer>
          )}
      </div>
    </Page>
  );
};

export default AssessmentFlowPage;
