import React from 'react';
import { useAuthContext } from '../auth/AuthContextProvider';
import { PusherUserChannelListener } from '../pusher/PusherChannelListeners';
import { FormattedMessage } from 'react-intl';
import { SidebarToggle } from '../main/SidebarComponent';

export const Page = ({ className, children }) => (
  <div className={`container-fluid px-xl-5 pb-2 pb-sm-5 ${className || ''}`}>
    {children}
  </div>
);

// title with primary actions;
export const PageNav = ({ title, children, translate }) => {
  const { profile } = useAuthContext();

  return (
    <div className="row mx-xl-n5" data-testid="page-nav">
      <nav className="navbar header app-navbar flex-fill justify-content-start px-xl-5 mb-3">
        <div className={"navbar-toggle"}>
          <SidebarToggle className="d-lg-none mr-3" />
        </div>
        <div className="navbar-brand page-title" data-testid="page-nav-title">
          {translate ||
            (translate === undefined && (
              <h1 tabIndex={0}>
                <FormattedMessage id={title} />
              </h1>
            ))}
          {(translate !== undefined && !translate) &&
            <h1 tabIndex={0}>{title}</h1>
          }
        </div>
        <div className="navbar-children">
          {children}
        </div>
        {profile && (
          <PusherUserChannelListener
            profileId={profile.id}
            showNotifications={true}
          />
        )}
      </nav>
    </div>
  );
};

// secondary actions;
export const PageSubNav = ({ children }) => {
  return (
    <div className="sub-nav flex-fill mb-3">
      {children}
      {children && <hr />}
    </div>
  );
};
