import RouterHistory, { getURLSearchParamsWithoutPagination } from '../main/RouterHistory';
import { ActiveUrlQueries } from './ActiveFilters';
import { removeFilters } from '../shared/functions/removeFilters';
import { useEffect, useState } from 'react';

export const SearchFormHorizontal = ({children}) => {
  const history = RouterHistory
  const [hasChanges, setHasChanges] = useState(false);
  const [form, setForm] = useState(null);

  /**
   * We need to override the default behaviour on hitting the Enter key, otherwise the first
   * child component that handles this event will force the behaviour.
   * @param e
   */
  function handleKeyDown(e) {
    if (e.key === 'Enter'){
      e.preventDefault();
      e.stopPropagation();
    }
  }

  function initChangeDetection(form) {
    if (!form) return;
    Array.from(form).forEach(el => {
      if (el?.name) {
        return el.dataset.origValue = el.value;
      }
    });
  }

  function formHasChanges(form) {
    if (!form) return;
    return Array.from(form).some(el => {
        if (el?.name) {
          return 'origValue' in el.dataset && el.dataset.origValue !== el.value;
        }
      }
    );
  }

  useEffect(() => {
    const filterForm = document.getElementById("filter-form");
    if (filterForm) {
      setForm(filterForm);
      initChangeDetection(filterForm);
      filterForm.addEventListener("input", () => {
        setTimeout(() => {
          if (formHasChanges(filterForm)) setHasChanges(true)
          else setHasChanges(false);
        }, 100);
      });
    }
  }, [])

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.target);
    const formObject = Object.fromEntries(data.entries());
    const entryMap = Object.keys(formObject).map((key) => [key, formObject[key]]);
    initChangeDetection(form);
    setHasChanges(false);
    removeFilters();

    entryMap.forEach(obj => {
      if (obj[0].indexOf("-boolean") > -1) {
        const field = obj[0].split("-")[0];
        const value = obj[1];
        const urlQueryParams = getURLSearchParamsWithoutPagination();
        urlQueryParams.set(field, value);
        history.push({
          search: urlQueryParams.toString()
        });
      }
      else if (obj[1] && obj[1] !== "") {
        const urlQueryParams = getURLSearchParamsWithoutPagination();
        urlQueryParams.set(obj[0], obj[1]);
        history.push({
          search: urlQueryParams.toString()
        });
      }
    });
  };

  return (<form id="filter-form" data-testid="search-form-horizontal" className="search-form form-inline filter-border" onSubmit={handleSubmit} onKeyDown={e => handleKeyDown(e)}>
    {children}
    <button disabled={!hasChanges} type="submit" aria-label="Apply filters" className="btn btn-sm btn-primary filter mb-1">Apply changes</button>
    <ActiveUrlQueries />
  </form>);
};
